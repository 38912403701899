@import "indigo-pink.css";
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

/* You can add global styles to this file, and also import other style files */
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.card {
  float: left;
  margin: 20px;
  padding: 10px;
  background-color: #e6e9eb;
}
.card .quickstart {
  width: 150px;
  float: right;
}

.long-card {
  margin: 20px;
  padding: 10px;
  background-color: #e6e9eb;
}
.long-card .quickstart {
  width: 150px;
  float: right;
}

.title {
  font-size: 14px !important;
}

.card-title {
  font-weight: 700;
  padding: 5px;
  color: #45506d;
}

.card-sub-title {
  color: #FF8F2C;
}

.sub-text {
  font-size: 1em;
  color: #717171;
}

.card-footer {
  padding: 10px;
}

.centered {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.tile {
  width: 8vh;
  height: 8vh;
  font-size: 0.8em;
  float: left;
}

.grid-tile {
  width: 8vw;
  height: 8vh;
}

.new-workbook {
  height: 150px;
  width: 223px;
  margin: 10px;
  padding: 16px;
  color: #666;
  border: 3px #666 dashed;
  border-radius: 5px;
  cursor: pointer;
}

.workbooks-code-circle {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  font-size: 11px;
  font-weight: 800;
  color: #666;
  border: 1px solid #bbb;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  margin-right: 2px;
}

.workbook-card-content-wrapper {
  position: absolute;
  height: 100%;
}

.workbook-card-settings {
  position: relative;
  height: 30px;
  width: 30px;
  padding: 4px 6px;
  margin: 5px;
  border-radius: 50%;
  border: 1px solid #CCC;
  bottom: 20px;
  left: 55%;
}

.workbooks-code-wrapper {
  position: absolute;
  bottom: 18px;
  left: 5px;
}

.mat-mdc-list-base .mat-mdc-list-item .mat-line {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.menu-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.menu-btn {
  color: #888888;
  margin-bottom: 10px;
}

.btn-cancel {
  background-color: lightgrey;
  font-size: smaller;
  font-weight: bold;
  color: black;
  width: 100%;
}

.words-spinner {
  position: absolute;
  color: #485473;
  padding: 30%;
  margin-top: 100%;
}

.selectable-tile {
  margin: 5px;
}
.focus-theme .file-group-card, .focus-theme .word-group-item-card, .dark-theme .file-group-card, .dark-theme .word-group-item-card, .file-group-card, .word-group-item-card {
  margin: 10px;
  position: relative;
}

.word-group-item-card-content {
  padding: 10px;
  font-size: larger;
  font-weight: bold;
  margin-bottom: 0 !important;
}

/*= All Possible Tile Colors
========================================*/
.c-white {
  background: white;
}

.c-red {
  background: #d34755; /* Old browsers */
  background: -moz-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d34755), color-stop(100%, #ab1f2c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* IE10+ */
  background: linear-gradient(to bottom, #d34755 0%, #ab1f2c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#d34755", endColorstr="#ab1f2c",GradientType=0 ); /* IE6-9 */
}

.c-yellow {
  background: #ffd456; /* Old browsers */
  background: -moz-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffd456), color-stop(100%, #ffc600)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ffd456 0%, #ffc600 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffd456", endColorstr="#ffc600",GradientType=0 ); /* IE6-9 */
}

.c-lightyellow {
  background: rgb(255, 237, 177); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(255, 237, 177)), color-stop(100%, rgb(255, 225, 130))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffedb1", endColorstr="#ffe182",GradientType=0 ); /* IE6-9 */
}

.c-green {
  background: #48ad5e; /* Old browsers */
  background: -moz-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #48ad5e), color-stop(100%, #2c8b3c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* IE10+ */
  background: linear-gradient(to bottom, #48ad5e 0%, #2c8b3c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#48ad5e", endColorstr="#2c8b3c",GradientType=0 ); /* IE6-9 */
}

.c-lightgreen {
  background: rgb(206, 230, 156); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(206, 230, 156)), color-stop(100%, rgb(193, 229, 114))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#cee69c", endColorstr="#c1e572",GradientType=0 ); /* IE6-9 */
}

.c-blue {
  background: #4c72aa; /* Old browsers */
  background: -moz-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4c72aa), color-stop(100%, #1f57ab)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* IE10+ */
  background: linear-gradient(to bottom, #4c72aa 0%, #1f57ab 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#4c72aa", endColorstr="#1f57ab",GradientType=0 ); /* IE6-9 */
}

.c-lightblue {
  background: rgb(156, 196, 229); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(156, 196, 229)), color-stop(100%, rgb(129, 183, 226))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#9cc4e5", endColorstr="#81b7e2",GradientType=0 ); /* IE6-9 */
}

.c-peach {
  background: rgb(255, 209, 196); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(255, 209, 196)), color-stop(100%, rgb(255, 179, 158))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffd1c4", endColorstr="#ffb39e",GradientType=0 ); /* IE6-9 */
}

.c-purple {
  background: #696493; /* Old browsers */
  background: -moz-linear-gradient(top, #696493 0%, #504795 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #696493), color-stop(100%, #504795)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #696493 0%, #504795 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #696493 0%, #504795 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #696493 0%, #504795 100%); /* IE10+ */
  background: linear-gradient(to bottom, #696493 0%, #504795 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#696493", endColorstr="#504795",GradientType=0 ); /* IE6-9 */
}

.c-lightpurple {
  background: rgb(239, 196, 255); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(239, 196, 255)), color-stop(100%, rgb(228, 160, 255))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#efc4ff", endColorstr="#e4a0ff",GradientType=0 ); /* IE6-9 */
}

.c-orange {
  background: #ff9951; /* Old browsers */
  background: -moz-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff9951), color-stop(100%, #ff872c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ff9951 0%, #ff872c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ff9951", endColorstr="#ff872c",GradientType=0 ); /* IE6-9 */
}

.c-gray {
  background: #898881; /* Old browsers */
  background: -moz-linear-gradient(top, #898881 0%, #6e6d67 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #898881), color-stop(100%, #6e6d67)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #898881 0%, #6e6d67 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #898881 0%, #6e6d67 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #898881 0%, #6e6d67 100%); /* IE10+ */
  background: linear-gradient(to bottom, #898881 0%, #6e6d67 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#898881", endColorstr="#6e6d67",GradientType=0 ); /* IE6-9 */
}

.c-cream {
  background: #f4f1de; /* Old browsers */
  background: -moz-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f4f1de), color-stop(100%, #e1dec9)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* IE10+ */
  background: linear-gradient(to bottom, #f4f1de 0%, #e1dec9 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f4f1de", endColorstr="#e1dec9",GradientType=0 ); /* IE6-9 */
}

.c-pink {
  background: rgb(244, 90, 206); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(244, 90, 206)), color-stop(100%, rgb(247, 66, 202))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f45ace", endColorstr="#f742ca",GradientType=0 ); /* IE6-9 */
}

.c-transparent {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  border: none;
}

.c-clear {
  background: rgba(0, 0, 0, 0);
  box-shadow: none !important;
  border: none !important;
  /*display: none;*/
  visibility: hidden;
}

.header-tab-text {
  font-size: larger;
  margin-left: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

/*= All Possible Tile Font Colors
========================================*/
.fc-black {
  color: #1b1b1b !important;
}

.fc-gray {
  color: #898881 !important;
}

.fc-white {
  color: #ffffff !important;
}

.fc-red {
  color: #EC1414 !important;
}

.tile-query-window {
  background-color: white;
  position: relative;
  z-index: 2;
  min-width: 25%;
  max-width: 75%;
  border: 2px #485473 solid;
  opacity: 0.9;
  border-radius: 5px;
}

.focus-theme .whizzi-button-small, .focus-theme .whizzi-button-medium, .focus-theme .whizzi-button-large, .dark-theme .whizzi-button-small, .dark-theme .whizzi-button-medium, .dark-theme .whizzi-button-large, .whizzi-button-small, .whizzi-button-medium, .whizzi-button-large {
  border-radius: 10px;
  color: white;
  background-color: #FF8F2C;
  margin-right: 20px !important;
  cursor: pointer;
}

.whizzi-button-large {
  padding: 10px 40px;
  font-size: 25px;
}

.whizzi-button-medium {
  padding: 15px 20px;
  font-size: 15px;
}

.whizzi-button-small {
  padding: 5px 10px;
  font-size: 15px;
}

.focus-theme .file-options, .focus-theme .file-name-container, .dark-theme .file-options, .dark-theme .file-name-container, .file-options, .file-name-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  opacity: 0.7;
  border-radius: 0 0 4px 4px;
  color: white;
}

.file-name-container {
  overflow-wrap: break-word;
  max-width: 100%;
  color: white;
  padding: 5px;
}

.file-options {
  visibility: hidden;
  height: 100%;
  text-align: center;
}

.focus-theme .file-option-workbook, .focus-theme .file-option, .dark-theme .file-option-workbook, .dark-theme .file-option, .file-option-workbook, .file-option {
  padding: 10px;
  cursor: pointer;
}

.file-option {
  height: 50%;
}

.file-option-workbook {
  height: 100%;
  padding-top: 50%;
}

.file-group-card:hover .file-options {
  visibility: visible;
}

.file-group-card:hover .file-name-container {
  visibility: hidden;
}

mat-grid-list {
  border: white 1px solid;
}

.settings-dropdown {
  padding-top: 10px;
  padding-right: 35px;
}
.settings-dropdown:not(:first-child) {
  padding-top: 32px;
}

.mat-card .style-card {
  padding: 0 !important;
}

.style-card-type {
  margin: 50px;
  padding: 20px;
  font-size: larger;
  text-align: center;
  font-weight: bolder;
  border-radius: 5px;
  border: black solid 2px;
}

.account-detail-label {
  font-weight: bold;
  margin-bottom: 10px;
}

.account-detail-text {
  font-weight: normal;
  margin-left: 10px;
}

.plan-price-container {
  text-align: center;
  margin-bottom: 25px;
}

.plan-price-text {
  font-size: xx-large;
}

.price-plan-detail-container {
  text-align: center;
  margin-bottom: 15px;
}

.price-plan-details-text {
  font-size: 15px;
  color: #97A5AE;
  font-weight: 500;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.activity-canvas-disabled {
  pointer-events: none;
}

.activity-canvas-draw {
  cursor: url("/assets/svgs/regular/draw-cursor.svg"), auto;
}
.activity-canvas-draw.dark-theme {
  cursor: url("/assets/svgs/regular/draw-cursor-white.svg"), auto;
}

.activity-canvas-eraser {
  cursor: url("/assets/svgs/regular/eraser-cursor.svg") 10 10, auto;
}
.activity-canvas-eraser.dark-theme {
  cursor: url("/assets/svgs/regular/eraser-cursor-white.svg") 10 10, auto;
}

.activity-canvas-text-64 {
  cursor: url("/assets/svgs/regular/text-cursor-64px.svg") 32 59, auto;
}
.activity-canvas-text-64.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-64px-white.svg") 32 59, auto;
}

.activity-canvas-text-48 {
  cursor: url("/assets/svgs/regular/text-cursor-48px.svg") 24 44, auto;
}
.activity-canvas-text-48.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-48px-white.svg") 24 44, auto;
}

.activity-canvas-text-40 {
  cursor: url("/assets/svgs/regular/text-cursor-40px.svg") 20 36, auto;
}
.activity-canvas-text-40.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-40px-white.svg") 20 36, auto;
}

.activity-canvas-text-32 {
  cursor: url("/assets/svgs/regular/text-cursor-32px.svg") 16 28, auto;
}
.activity-canvas-text-32.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-32px-white.svg") 16 28, auto;
}

.activity-canvas-text-24 {
  cursor: url("/assets/svgs/regular/text-cursor-24px.svg") 12 22, auto;
}
.activity-canvas-text-24.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-24px-white.svg") 12 22, auto;
}

.mat-list-item-disabled.activity-menu-item {
  cursor: not-allowed !important;
}
.mat-list-item-disabled.activity-menu-item .mat-list-text > span {
  color: lightgray !important;
}
.mat-list-item-disabled.activity-menu-item.dark-mode {
  opacity: 40% !important;
}

.canvas-container canvas {
  border-color: transparent !important;
}

.canvas-pad-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: rgba(255, 255, 255, 0.44);
}

.workbook-activity-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.workbook-activity-container .activity-header {
  z-index: 21;
}
.workbook-activity-container .activity-header .header_activity_top {
  position: relative;
}
.workbook-activity-container #canvas-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.workbook-activity-container #canvas-container .pad-canvas {
  transition-duration: 0.5s;
  background: rgba(255, 255, 255, 0.15);
  position: absolute;
  z-index: 11;
  opacity: 0;
}
.workbook-activity-container #canvas-container .main-canvas-container, .workbook-activity-container #canvas-container .main-canvas {
  position: absolute;
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: center;
}
.workbook-activity-container #tileBank {
  height: 400px;
  min-height: 100px;
  overflow: hidden;
}
.workbook-activity-container #tileBank table {
  margin: auto;
}
.workbook-activity-container .blank_tile {
  margin-top: 0;
  height: unset;
}
.workbook-activity-container .blank_tile table {
  padding-top: 40px;
  padding-bottom: 40px;
}
.workbook-activity-container .ng-resizable-handle {
  bottom: 0% !important;
  left: 96% !important;
}
.workbook-activity-container .tiles-container {
  height: 100%;
  background: white;
  padding-bottom: 16px;
}
.workbook-activity-container .new-tile-container {
  overflow: auto;
  height: 100%;
  padding: 16px 0;
}

.blackboard-activity-container #tileDropzone {
  position: relative;
  top: -16px;
}
.blackboard-activity-container #canvas-container {
  background: white;
}

.workbook-tiles-activity-container #tileDropzone {
  position: relative;
  top: -16px;
  height: calc(100vh - 125px);
}
.workbook-tiles-activity-container .workbook_tile_div {
  height: unset !important;
  margin-top: 0 !important;
}
.workbook-tiles-activity-container .workbook-container .textbox-target {
  height: 100vh !important;
}

.wordcards-activity-container .textbox-target {
  padding-top: 16px;
}
.wordcards-activity-container .activity-header {
  z-index: 200;
}
.wordcards-activity-container #canvas-container .pad-canvas {
  z-index: 100 !important;
}

.pdf-viewer-activity-container .pdf-container {
  margin-top: 0 !important;
  padding-top: 32px;
  position: absolute;
}

textarea[data-fabric-hiddentextarea] {
  position: fixed !important;
}

mat-slider .mat-slider-ticks-container {
  background: #8e70ff;
}
mat-slider .mat-slider-thumb {
  border-color: #8e70ff;
  background: #8e70ff;
}

.bg-black {
  background: #000 !important;
}

.bg-white {
  background: #fff !important;
}

.line-warning-message {
  font-size: 12px;
  color: #ff5a5a;
  text-align: end;
}

mat-form-field.wz-select {
  all: initial;
  width: 100%;
}
mat-form-field.wz-select .mat-form-field-label {
  top: 33px;
  left: 16px;
  color: #210854;
  font-size: 16px;
  width: auto !important;
}
mat-form-field.wz-select.mat-form-field-should-float .mat-form-field-label {
  font-size: 13px;
  background-color: white;
  padding: 5px 10px;
  width: auto;
  top: 21px;
  left: 16px;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #866DFF !important;
}

.dark-theme .mat-mdc-dialog-surface {
  background-color: #0b041e !important;
}
.dark-theme mat-label, .dark-theme .mdc-radio__outer-circle, .dark-theme .mdc-radio__inner-circle, .dark-theme .mdc-text-field__input, .dark-theme .mat-mdc-dialog-content {
  color: rgba(255, 255, 255, 0.6) !important;
  border-color: rgba(255, 255, 255, 0.6) !important;
}
.dark-theme .mat-select-value-text, .dark-theme .mat-mdc-select-value-text, .dark-theme .mdc-form-field {
  color: white !important;
  border-color: white !important;
}
.dark-theme .words_tile_box .mat-button-wrapper {
  color: white !important;
}

.ripple-btn-disabled .mdc-button__ripple {
  display: none !important;
}

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  border-color: currentColor;
}
.dark-theme .mdc-notched-outline__leading, .dark-theme .mdc-notched-outline__notch, .dark-theme .mdc-notched-outline__trailing {
  border-color: #e0e0e0 !important;
}

.cdk-global-overlay-wrapper:has(mat-dialog-container.mdc-dialog--closing) {
  display: none;
}

mat-radio-button.setting_radio .mdc-label {
  color: #210854;
  font-size: 16px;
}
.dark-theme mat-radio-button.setting_radio .mdc-label {
  color: white !important;
}

.add-credit-card-modalbox .mat-mdc-dialog-container {
  padding: 24px;
}
.add-credit-card-modalbox .mat-mdc-dialog-container .mat-mdc-form-field {
  padding-top: 10px;
}
.add-credit-card-modalbox .mat-mdc-dialog-container .card-name {
  padding-top: 0;
}
.add-credit-card-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.logout-modal mat-dialog-actions {
  margin-right: 20px;
  margin-bottom: 8px;
}
.logout-modal mat-dialog-content {
  padding-bottom: 0 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  display: none;
}

.mdc-button__ripple, .mdc-checkbox__ripple {
  display: none;
}

[contenteditable=true] {
  padding: 4px;
  display: block;
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  opacity: 0.5;
}

.mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
}
body:not(.dark-theme) .mdc-tab-indicator__content--underline {
  border-color: #481e9f !important;
}
body.dark-theme .mdc-tab-indicator__content--underline {
  border-color: white !important;
}

.dark-theme .mat-mdc-checkbox .mdc-checkbox__background {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

.dark-theme .account-modal button.add-new-card-btn {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

.dark-theme .mat-mdc-dialog-container .mdc-dialog__title {
  color: white !important;
}

.dark-theme .mdc-list-item:hover .mdc-list-item__primary-text {
  color: white;
}

.dark-theme .mat-mdc-paginator {
  background: #18112f;
}
.dark-theme .mat-mdc-paginator .mat-mdc-paginator-range-label {
  color: white;
}
.dark-theme .mat-mdc-paginator .mdc-icon-button svg {
  fill: #866dff;
}
.dark-theme .mat-mdc-paginator .mdc-icon-button:disabled svg {
  fill: gray;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: 20px !important;
}

.dark-theme .mdc-list-item__primary-text {
  color: white;
}

.dark-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: white;
}

.dark-theme .mdc-checkbox__background {
  border-color: white !important;
}

.letter-card-dialog .mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.purple-checkbox {
  padding-bottom: 8px !important;
}
.purple-checkbox mat-list-option {
  padding: 0 16px 0 8px !important;
}
.purple-checkbox .delete-btn {
  padding-left: 16px !important;
}
.purple-checkbox .delete-btn .mdc-button__label {
  display: flex !important;
}
.purple-checkbox .mdc-list-item__end {
  margin-left: 0 !important;
}

.collection-name .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.collection-name .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.collection-name .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: gray;
  border-width: 1px;
  opacity: 1;
}

.workbook-builder-tab .mat-mdc-tab-body-content {
  height: calc(100% - 58px);
}

html, body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  height: 100%;
  background-color: #dfdee5;
}

button {
  letter-spacing: normal !important;
}

.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.mat-mdc-focus-indicator {
  background: transparent;
}

.blanktiles {
  min-height: 100px !important;
}

.flashcard {
  position: absolute;
  font-family: "Arial", sans-serif;
  font-size: 120px;
  line-height: 6rem;
  font-weight: bold;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 20vh;
  min-height: 433px;
  padding: 2.5% 15px;
  flex-shrink: 3;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08);
  color: #210854;
  background-color: white;
}
@media only screen and (max-width: 1280px) {
  .flashcard {
    height: auto !important;
  }
}
.dark-theme .flashcard {
  background-color: #18112f !important;
}

.flashcard-altered {
  font-family: "Arial", sans-serif;
  font-size: 120px;
  line-height: 6rem;
  font-weight: bold;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 20vh;
  min-height: 433px;
  padding: 2.5% 15px;
  flex-shrink: 3;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08);
  background-color: white;
  color: #210854;
}
@media only screen and (max-width: 1280px) {
  .flashcard-altered {
    height: auto !important;
  }
}

.mat-mdc-form-field-label {
  color: rgba(0, 0, 0, 0.87);
}

.mat-progress-bar-fill::after {
  background-color: #866dff;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #866dff;
}

.tally-box .tally {
  font-size: 16px !important;
}
.tally-box .tally .tally_index {
  color: #210854;
}

.char-limit {
  color: #a6a6a6;
  font-size: 12px;
  text-align: left;
  margin-left: 13px;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #866dff;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-mdc-form-field-label {
  color: #866dff;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-mdc-form-field-ripple {
  background-color: #866dff;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-select-arrow {
  color: #866dff !important;
}

.mat-input-element {
  caret-color: #866dff;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: $interface-color-light-medium !important;
}
.mat-mdc-progress-spinner circle {
  stroke: #866dff !important;
}

.sproutvideo-playlist {
  width: 100%;
}

.content:not(.mat-drawer-content) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.loading .spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}

.bumping-tiles {
  font-size: 1.6837vw;
  border: none;
  position: absolute;
  text-align: center;
  font-weight: bolder;
  border-radius: 4px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-stretch: normal;
  line-height: 60px;
  padding-right: 8px;
  padding-left: 8px;
  min-width: 60px;
  min-height: 60px;
  border: solid 1px rgba(72, 30, 159, 0.24);
}
.bumping-tiles.static-block {
  transition: all 0.3s ease-out;
}

.bumping-tiles-flashcards {
  font-size: 1.6837vw;
  border: none;
  position: absolute;
  text-align: center;
  font-weight: bolder;
  border-radius: 4px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-stretch: normal;
  line-height: 260px;
  padding-right: 10px;
  padding-left: 10px;
  min-width: 60px;
  height: 792px;
  border: solid 1px rgba(72, 30, 159, 0.24);
  margin-left: 5px;
  margin-right: 5px;
}
.bumping-tiles-flashcards.static-block {
  transition: all 0.3s ease-out;
}

.drawerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.btn-default {
  background-color: #866dff;
  font-size: smaller;
  font-weight: bold;
  color: white;
  width: 100%;
}

.plan-description-header {
  width: 100%;
  text-align: center;
  padding: 12px 0;
  margin-bottom: 10px;
  background-color: #F7FAFB;
  font-weight: bold;
  color: rgba(33, 8, 84, 0.56);
  font-size: 19px;
  color: rgb(109, 118, 127);
  border-bottom: 1px solid rgb(234, 236, 238);
}
.dark-theme .plan-description-header {
  background-color: transparent !important;
}

.whizzimo-link {
  color: #866dff;
  cursor: pointer;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 300px;
  display: inline-block;
}

.button-selected {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  font-style: italic;
  letter-spacing: 0.4px;
  text-transform: capitalize !important;
  background-color: white !important;
  border: 2px solid #866dff !important;
  border-radius: 4px;
}
.button-selected .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.button-selected .mdc-button__label {
  color: #866dff !important;
}

.fileContainer {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  box-shadow: none !important;
  line-height: 1.5em;
  color: white;
  height: 35px;
  padding: 5px;
}
.fileContainer .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.inactive-fileContainer {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none !important;
  line-height: 1.5em;
  color: rgba(0, 0, 0, 0.87);
  height: 35px;
  padding: 5px;
}
.inactive-fileContainer .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.fileContainer [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.inactve-fileContainer [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.red-circle {
  width: 8px;
  height: 8px;
  background: #ff5a5a;
  border-radius: 50px;
  position: absolute;
  right: 4px;
  top: 4px;
}

.x-small-tile {
  min-height: 30px !important;
  min-width: 30px !important;
  line-height: 30px !important;
  font-size: 0.9837vw !important;
}

.small-tile {
  min-height: 40px !important;
  min-width: 40px !important;
  line-height: 40px !important;
  font-size: 20px !important;
}

.medium-tile {
  min-height: 60px !important;
  min-width: 60px !important;
  line-height: 60px !important;
  font-size: 27.5px !important;
}

.large-tile {
  min-height: 80px !important;
  min-width: 80px !important;
  line-height: 80px !important;
  font-size: 32px !important;
}

.x-large-tile {
  min-height: 100px !important;
  min-width: 100px !important;
  line-height: 100px !important;
  font-size: 40px !important;
}

.xx-large-tile {
  min-height: 120px !important;
  min-width: 120px !important;
  line-height: 120px !important;
  font-size: 3.4837vw !important;
}

.xxx-large-tile {
  min-height: 140px !important;
  min-width: 140px !important;
  line-height: 140px !important;
  font-size: 4.0837vw !important;
}

.mat-radio-checked .mat-radio-outer-circle {
  border-color: #8e70ff;
}
.mat-radio-checked .mat-radio-inner-circle {
  background-color: #8e70ff;
}

.shadow {
  border: solid 1px rgba(134, 128, 145, 0.24);
  border-radius: 4px;
  min-width: 60px;
  height: 60px !important;
}

.black-white-text {
  color: black;
}

.purple-text {
  color: #866DFF !important;
}

.purple-checkbox .mat-mdc-checkbox-checked .mat-mdc-checkbox-layout .mat-mdc-checkbox-inner-container {
  background: #866DFF !important;
  color: #8e70ff !important;
}

.purple-checkbox-option .mat-pseudo-checkbox-checked {
  color: #866DFF !important;
}
.purple-checkbox-option .mat-pseudo-checkbox {
  color: #866DFF !important;
}

.pairing-dropdown {
  padding-left: 20px !important;
}

.add-success-light {
  background-color: #210854 !important;
}

.add-success-dark {
  background-color: #210854 !important;
}

.no-border {
  border: none !important;
}

.blenderbox-target {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.blenderbox-target .selected-line {
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  background: #888;
  height: 6px;
  width: 50px;
  transition: all 0.2s ease;
}
.blenderbox-target .selected-line::before {
  content: "";
  position: absolute;
  top: -0.625rem; /* Adjust to extend clickable area above and below */
  left: -0.875rem; /* Adjust to extend clickable area to the left and right */
  right: -0.875rem;
  bottom: -1.35rem;
  background: transparent;
  cursor: pointer;
}
.blenderbox-target .line-arrow {
  /* Add arrowhead at the end of the line */
  transition: all 0.2s ease;
  position: absolute;
  background: #888;
}
.blenderbox-target .line-arrow::after {
  content: "";
  position: absolute;
  right: -12px;
  /* Adjust to place arrowhead at the end of the line */
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #888;
}

/*= All Possible Tile Colors
========================================*/
.c-red {
  background: #cf1b3a !important;
}

.c-yellow {
  background: #ffca5a !important;
}

.c-peach {
  background: #fec9bf !important;
}

.c-lightyellow {
  background: #ffe79d !important;
}

.c-lightgreen {
  background: #ccf29c !important;
}

.c-green {
  background: #00ab44 !important;
}

.c-blue {
  background: #568aff !important;
}

.c-lightblue {
  background: #83bfe7 !important;
}

.c-purple {
  background: #8e70ff !important;
}

.c-lightpurple {
  background: #e4a9ff !important;
}

.c-orange {
  background: #ff8e42 !important;
}

.c-gray {
  background: #7c7c74 !important;
}

.c-pink {
  background: #ff76b8 !important;
}

.c-cream {
  background: #f6efda !important;
}

.c-transparent {
  background: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  border: none !important;
}

.c-clear {
  background: rgba(0, 0, 0, 0);
  box-shadow: none !important;
  border: none !important;
  display: none;
  z-index: 0 !important;
  visibility: hidden;
}

/*= All Possible Tile Colors
========================================*/
.more_words_modal_activity, .more_tiles_modal_activity {
  color: #866dff;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 12px;
  text-transform: uppercase;
  display: block;
  font-size: 14px;
}

.global-wrap {
  height: 100%;
  width: 100%;
  margin-bottom: 85px;
}

/* ===setting header ===*/
.setting_header_div {
  background: white;
  padding: 0px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
}
.setting_header_div .setting_header_div_left {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.setting_header_div .setting_header_div_left .help_icon {
  position: relative;
  top: 4px;
  left: 10px;
}
.setting_header_div .setting_header_div_left div {
  display: flex;
  align-items: center;
}
.setting_header_div .setting_header_div_left div h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  .setting_header_div .setting_header_div_left div h2 {
    font-size: 22px !important;
  }
}
.setting_header_div .account_setting_header_div_left {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.setting_header_div .account_setting_header_div_left h2 {
  color: #210854;
}
.setting_header_div .header-workbook-name {
  font-size: 14px;
  color: #866dff;
  cursor: pointer;
  margin: 0px 0px 7px;
  align-items: center;
  text-transform: uppercase;
  outline: none;
  font-weight: 500;
}
.setting_header_div .help_icon {
  cursor: pointer;
}
.setting_header_div .setting_header_div_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.setting_header_div .setting_header_div_right .setting-type-dropdown {
  float: none !important;
  margin-top: 25px;
  justify-content: flex-end !important;
}
.setting_header_div .setting_header_div_right .setting-type-dropdown .mat-mdc-form-field-infix {
  width: 276px;
  padding: 0 16px !important;
  color: #210854;
  font-size: 16px;
}
.setting_header_div .setting_header_div_right .setting-type-dropdown .mat-mdc-form-field-infix .mat-select-value-text {
  color: #210854;
  font-size: 16px;
}
.setting_header_div .setting_header_div_right .setting-type-dropdown .mat-mdc-form-field-label {
  top: 33px;
  left: 16px;
  color: #210854;
  font-size: 16px;
}
.setting_header_div .setting_header_div_right .setting-type-dropdown.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: white;
  padding: 5px 10px;
  width: auto;
  top: 21px;
  left: 16px;
}

/* ===setting header ===*/
/* === General setting page ===*/
.mat-mdc-option .mdc-list-item__primary-text {
  color: #210854;
  font-size: 14px !important;
  letter-spacing: normal;
}
.mat-mdc-option .mdc-list-item__primary-text img {
  width: 24px;
  position: relative;
  float: right;
}

.mat-option-disabled .mat-option-text {
  color: #dfdee5;
  font-size: 14px;
}
.mat-option-disabled .mat-option-text img {
  width: 24px;
  position: relative;
  top: 12px;
  float: right;
}

.setting_screens .content_div {
  display: flex;
  flex-direction: column;
  background: white;
  margin: 24px;
  border-radius: 8px;
}
.setting_screens .content_div .each_row {
  display: flex;
  height: 100%;
  border-bottom: 1px solid rgba(33, 8, 84, 0.08);
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px 24px;
}
.dark-theme .setting_screens .content_div .each_row {
  color: white;
}
.setting_screens .content_div .each_row:last-child {
  border-bottom: none;
}
.setting_screens .content_div .each_row .description-section {
  width: 40%;
}
.setting_screens .content_div .each_row .description-section h3 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #210854;
  font-weight: bold;
  letter-spacing: -0.01em;
  line-height: 1;
  margin: 0;
  margin-bottom: 8px;
}
.setting_screens .content_div .each_row .description-section p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #777183;
  line-height: 1.25;
  margin: 0;
}
.setting_screens .content_div .each_row .description_option {
  width: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.setting_screens .content_div .each_row .description_option p {
  color: rgba(0, 0, 0, 0.87);
}
.setting_screens .content_div .each_row .description_option .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.setting_screens .content_div .each_row .description_option .setting_radio {
  width: 100%;
}
.setting_screens .content_div .each_row .description_option .setting_radio .mat-radio-label {
  white-space: normal;
}
.setting_screens .content_div .each_row .description_option .setting_radio .mat-radio-ripple {
  color: #210854;
  font-size: 16px;
}
.setting_screens .content_div .each_row .description_option .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.setting_screens .content_div .each_row .description_option .mat-radio-checked .mat-radio-outer-circle {
  border-color: #8e70ff;
}
.setting_screens .content_div .each_row .description_option .mat-radio-checked .mat-radio-inner-circle {
  background-color: #8e70ff;
}
.setting_screens .content_div .each_row .description_option .words_input {
  width: 90px;
  display: inline-block;
  margin-top: 16px;
}
.setting_screens .content_div .each_row .description_option .label {
  font-size: 16px;
  color: rgba(33, 8, 84, 0.56);
  margin-left: 20px;
}
.setting_screens .content_div .each_row .description_option .flascard_radio {
  width: 100%;
  display: block;
  margin: 0 0 18px;
}
.setting_screens .content_div .each_row .description_option .flascard_radio .mat-radio-ripple {
  margin: 0 0 8px;
}
.setting_screens .content_div .each_row .description_option .flascard_radio p {
  color: rgba(13, 2, 35, 0.56);
  margin: 8px 29px 4px;
}
.setting_screens .content_div .custom_row {
  align-items: flex-start !important;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button {
  margin-right: 4px;
  border: 1px solid transparent;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button .mdc-radio__background {
  opacity: 0;
  display: none;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button .mat-radio-ripple {
  background: #e4e2ed;
  height: 40px;
  width: 40px;
  margin: 2px;
  padding: 0;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.mat-radio-checked {
  display: inline-block;
  height: 44px;
  border: solid 2px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.mat-radio-not-checked {
  border: solid 1px #ffffff;
  border-radius: 4px;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.gray .mat-radio-ripple {
  background: #e4e2ed;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.black .mat-radio-ripple {
  background: #000000;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.white .mat-radio-ripple {
  background: #fff;
  border: 1px solid rgba(33, 8, 84, 0.32);
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.palesky .mat-radio-ripple {
  background: #bde7ff;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.verypalegreen .mat-radio-ripple {
  background: #d6f7be;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.light-beige .mat-radio-ripple {
  background: #fff6b7;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.offwhite .mat-radio-ripple {
  background: #fbf5e5;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.lightpink .mat-radio-ripple {
  background: #ffdaec;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.softblue .mat-radio-ripple {
  background: #568aff;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.grapefruit .mat-radio-ripple {
  background: #ff5a5a;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.light-mustard .mat-radio-ripple {
  background: #ffca5a;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.soft-green .mat-radio-ripple {
  background: #6dd378;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.periwinkle .mat-radio-ripple {
  background: #8e70ff;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.orangeish .mat-radio-ripple {
  background: #ff8e42;
}
.setting_screens .content_div .colorblock_radio .colorblock_radio-button.bubblegum .mat-radio-ripple {
  background: #ff76b8;
}
.setting_screens .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 276px;
  height: 56px;
  display: flex;
  align-items: center;
  color: #210854;
}
.setting_screens .mat-mdc-form-field-underline {
  display: none;
}
.setting_screens .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body-content {
  height: auto;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-header {
  display: flex;
  overflow: hidden;
  position: sticky;
  flex-shrink: 0;
  top: 0px;
  z-index: 9;
  background-color: #ffffff;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #dfdee5;
  margin: 0px;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tab_title {
  margin: 0;
  padding: 22px 18px 40px;
  display: block;
  font-size: 16px;
  line-height: 1.25;
  color: rgba(13, 2, 35, 0.5607843137);
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tab_title_compound {
  margin: 22px 0px 40px;
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 1.25;
  color: rgba(13, 2, 35, 0.5607843137);
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .long-card {
  margin: 0px;
  background-color: transparent;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card {
  box-shadow: none !important;
  background-color: white;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .style-card-header {
  width: 100%;
  height: 30px;
  background-color: transparent;
  color: #210854;
  font-weight: bold;
  padding: 20px 0px 0px 0px !important;
  word-wrap: break-word;
  text-align: center;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .tile_bgcolor {
  background: transparent !important;
  display: flex;
  justify-content: center;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .tile_bgcolor .style-card-type {
  margin: 30px;
  font-size: larger;
  text-align: center;
  font-weight: bolder;
  border-radius: 5px;
  width: 76px;
  border: none;
  padding: 13px 0px;
  border: none;
  font-size: 32px;
  height: 38px;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .mat-mdc-form-field-infix {
  width: 150px;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: white;
  padding: 5px 10px;
  width: auto;
  top: 21px;
  left: 16px;
  color: #210854;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .mat-mdc-form-field-infix .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .style-card-type {
  margin: 20px auto;
  padding: 21px 0px;
  font-size: larger;
  text-align: center;
  font-weight: bolder;
  border-radius: 5px;
  width: 76px;
  height: 22px;
  border: none;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .compound_example {
  font-size: 16px;
  color: #210854;
  font-weight: 600;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .compound_mat_card {
  padding: 23px 0px;
  width: 76px;
  border-radius: 4px;
  line-height: 18px;
  font-size: 1.6837vw !important;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout_div {
  border-bottom: 1px solid rgba(33, 8, 84, 0.32);
  padding: 0px 0px 24px 0px;
  margin: 0px 20px !important;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout_div .mat-card {
  width: 76px;
  height: 20px;
  padding: 22px 0px;
  border-radius: 4px;
  text-align: center;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout .mat-grid-list {
  border: none;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout .mat-grid-tile {
  background-color: transparent !important;
}
.setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout .mat-grid-tile .mat-figure div {
  width: 100% !important;
  height: 100% !important;
}

/* === Geberal setting page ===*/
/*======= side nav bar ===*/
.mat-drawer {
  width: 358px;
  background-color: #210854 !important;
}
.mat-drawer .mat-drawer-inner-container {
  overflow: hidden;
}
.mat-drawer .mat-drawer-inner-container .side-container {
  max-width: 358px;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-top {
  justify-content: start !important;
  padding: 31px 32px 0px;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-top img {
  border-radius: 5px;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle {
  overflow: hidden !important;
  border-top: solid 1px rgba(134, 109, 255, 0.48);
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree {
  width: 100%;
  background: transparent;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-tree-node {
  color: #fff;
  font-size: 16px;
  position: relative;
  font-weight: bold;
  padding-left: 0px;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-tree-node[aria-expanded=true] .icon_btn::before {
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-tree-node .mat-button {
  padding: 0px 0px;
  text-align: left;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .icon_span_active {
  background-color: #481e9f;
  color: #fff;
  border-radius: 5px;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .icon_span_active .mdc-list-item__content {
  background-color: #481e9f !important;
  color: white;
  padding: 0px 47px;
  border-radius: 8px;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .icon_span_inactive {
  opacity: 72%;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .custom-mat-expansion-panel::before {
  margin-left: 0px;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel {
  background-color: #210854;
  color: white !important;
  width: 100%;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-header {
  background-color: #210854;
  color: white !important;
  padding-right: 60px;
  height: 18px !important;
  padding-left: 69px;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  position: relative;
  right: 1px;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body {
  background-color: #210854;
  color: white !important;
  padding: 0;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body .mdc-list-item__content {
  background-color: #210854;
  color: white;
  padding: 0px;
  padding-left: 48px;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body .mdc-list-item__content .mdc-list-item__primary-text {
  display: flex;
  flex-direction: row;
  align-items: end;
  color: white;
  overflow: initial;
  letter-spacing: normal;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body .mdc-list-item__content .mdc-list-item__primary-text {
  color: #210854 !important;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-indicator::after {
  color: white !important;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expanded {
  background-color: #210854;
  color: white !important;
  height: 18px !important;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-content {
  color: white;
  opacity: 1;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-content .mat-expansion-panel-header-title {
  background-color: #210854;
  color: white;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-middle .ng-scrollbar-visible {
  right: -9px !important;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-bottom {
  justify-content: start !important;
  border-top: solid 1px rgba(134, 109, 255, 0.48);
  padding: 11px 32px !important;
  height: 72px !important;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-icon {
  border-radius: 50px;
  padding: 12px 13px;
  color: white;
  margin-right: 10px;
  opacity: 0.32;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-text {
  align-self: flex-end !important;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-text h3 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  line-height: 1px;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-text button {
  background: transparent;
  border: transparent;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: #866dff;
  text-transform: uppercase;
}
.mat-drawer .mat-drawer-inner-container .side-container .side-bottom .name-text {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.mat-drawer .mat-drawer-inner-container .side-container .active_account .user-icon {
  background-color: #ffb5a0 !important;
  color: #210854 !important;
}
.mat-drawer .mat-drawer-inner-container .side-container .active_account .user-text button {
  color: #ffb5a0 !important;
}

/*======= side nav bar ===*/
/*======= activity page header ===*/
.activity_header_custom {
  width: 100%;
}
.activity_header_custom .mdc-button,
.activity_header_custom .mat-mdc-button,
.activity_header_custom .mat-mdc-button-base {
  padding: 0 16px !important;
  height: inherit;
}
.activity_header_custom .mat-mdc-button > .mat-icon {
  color: #fff;
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
}
.activity_header_custom .activity-header {
  background-color: white !important;
  box-shadow: none !important;
  width: 100% !important;
  z-index: 114;
  height: 40px;
  top: 85px;
}
.activity_header_custom .activity-header .tool_btn_custom {
  position: relative;
  min-width: unset;
  display: block;
  background-color: transparent;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  .activity_header_custom .activity-header .tool_btn_custom {
    min-width: 50px;
  }
}
.activity_header_custom .activity-header .tool_btn_custom::before {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background-repeat: no-repeat;
}
.activity_header_custom .activity-header .cdk-program-focused {
  display: none;
}
.activity_header_custom .activity-header .up::before {
  background-image: url("assets/img/theme1/down.png");
}
.activity_header_custom .activity-header .down {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .down::before {
  background-image: url("assets/img/up.png");
}
.activity_header_custom .activity-header .settings-button::before {
  width: 22px !important;
  height: 22px !important;
  background-image: url("assets/img/theme1/setting-2.png");
  background-size: contain;
}
.activity_header_custom .activity-header .settings-dropdown-container {
  position: absolute;
  right: 10px;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 8px;
  padding-top: 12px;
  width: 300px;
}
.activity_header_custom .activity-header .blendmode-button::before {
  background-image: url("assets/img/theme1/light-blender.png");
  background-size: contain;
}
.activity_header_custom .activity-header .active_blendmode {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_blendmode::before {
  background-image: url("assets/img/theme1/light-blender-active.png") !important;
}
.activity_header_custom .activity-header .rotate {
  color: #210854;
}
.activity_header_custom .activity-header .read-button::before {
  background-image: url("assets/img/theme1/light-book.png");
}
.activity_header_custom .activity-header .active_read {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_read::before {
  background-image: url("assets/img/theme1/light-book-active.png") !important;
}
.activity_header_custom .activity-header .folder-button::before {
  background-image: url("assets/img/theme1/light-page.png");
}
.activity_header_custom .activity-header .active_folder {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_folder::before {
  background-image: url("assets/img/theme1/light-book-active.png") !important;
}
.activity_header_custom .activity-header .edit-button::before {
  background-image: url("assets/img/theme1/light-pencil.png");
}
.activity_header_custom .activity-header .active_btn {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_draw {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_draw::before {
  background-image: url("assets/img/theme1/light-pencil-active.png") !important;
}
.activity_header_custom .activity-header .eraser-button::before {
  background-image: url("assets/img/theme1/light-erase.png");
  top: 5px !important;
}
.activity_header_custom .activity-header .active_eraser {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_eraser::before {
  background-image: url("assets/img/theme1/light-erase-active.png") !important;
}
.activity_header_custom .activity-header .active_color {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .undo-button::before {
  background-image: url("assets/img/theme1/light-undo.png");
}
.activity_header_custom .activity-header .active_undo {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_undo::before {
  background-image: url("assets/img/theme1/light-undo-active.png") !important;
}
.activity_header_custom .activity-header .delete-button::before {
  background-image: url("assets/img/theme1/light-delete.png");
}
.activity_header_custom .activity-header .active_delete {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_delete::before {
  background-image: url("assets/img/theme1/light-delete-active.png") !important;
}
.activity_header_custom .activity-header .visibility-button::before {
  background-image: url("assets/img/theme1/light-show.png");
}
.activity_header_custom .activity-header .active_visibility {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_visibility::before {
  background-image: url("assets/img/theme1/light-show-active.png") !important;
}
.activity_header_custom .activity-header .visibility_hidden {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .visibility_hidden::before {
  background-image: url("assets/img/theme1/light-hide.png") !important;
}
.activity_header_custom .activity-header .keyboard-button::before {
  background-image: url("assets/img/theme1/light-keyboard.png");
}
.activity_header_custom .activity-header .active_keyboard {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_keyboard::before {
  background-image: url("assets/img/theme1/light-keyboard-active.png") !important;
}
.activity_header_custom .activity-header .assignment-button::before {
  background-image: url("assets/img/theme1/light-lines-onoff.png");
}
.activity_header_custom .activity-header .active_assignment {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_assignment::before {
  background-image: url("assets/img/theme1/light-lines-onoff-active.png") !important;
}
.activity_header_custom .activity-header .format_size-button::before {
  background-image: url("assets/img/theme1/light-capitalize.png");
}
.activity_header_custom .activity-header .active_format_size {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_format_size::before {
  background-image: url("assets/img/theme1/light-capitalize-active.png") !important;
}
.activity_header_custom .activity-header .aspect_ratio-button::before {
  background-image: url("assets/img/theme1/light-resize.png");
}
.activity_header_custom .activity-header .active_aspect_ratio {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_aspect_ratio::before {
  background-image: url("assets/img/theme1/light-resize-active.png") !important;
}
.activity_header_custom .activity-header .search-plus-button::before {
  background-image: url("assets/img/theme1/light-zoom-in.png");
}
.activity_header_custom .activity-header .active_search_plus {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_search_plus::before {
  background-image: url("assets/img/theme1/light-zoom-in-active.png") !important;
}
.activity_header_custom .activity-header .search-minus-button::before {
  background-image: url("assets/img/theme1/light-zoom-out.png");
}
.activity_header_custom .activity-header .active_search_minus {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_search_minus::before {
  background-image: url("assets/img/theme1/light-zoom-out-active.png") !important;
  background-repeat: no-repeat;
}
.activity_header_custom .activity-header .landscape-button::before {
  background-image: url("assets/img/theme1/light-whole-word.png");
}
.activity_header_custom .activity-header .active_landscape {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_landscape::before {
  background-image: url("assets/img/theme1/light-whole-word-active.png") !important;
  background-repeat: no-repeat;
}
.activity_header_custom .activity-header .bumping-tiles-button::before {
  background-image: url("assets/img/theme1/light-tile-bumping-onoff.png");
}
.activity_header_custom .activity-header .active_bumping_tiles {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_bumping_tiles::before {
  background-image: url("assets/img/theme1/light-tile-bumping-onoff-active.png") !important;
  background-repeat: no-repeat;
}
.activity_header_custom .activity-header .rotate-canvas-button::before {
  appearance: none;
  background-image: none;
}
.activity_header_custom .activity-header .active_pdf_search_minus {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_pdf_search_minus::before {
  background-image: url("assets/img/theme1/light-zoom-out-active.png") !important;
  background-repeat: no-repeat;
}
.activity_header_custom .activity-header .view_array-button::before {
  background-image: url("assets/img/theme1/light-tiles.png");
}
.activity_header_custom .activity-header .active_view_array {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_view_array::before {
  background-image: url("assets/img/theme1/light-tiles-active.png") !important;
  background-repeat: no-repeat;
}
.activity_header_custom .activity-header .info-button::before {
  width: 27px !important;
  height: 26px !important;
  background-image: url("assets/img/theme1/light-help.png");
  position: relative;
  top: -3px;
  right: 3px;
}
.activity_header_custom .activity-header .active_info {
  background-color: #f1eeff;
}
.activity_header_custom .activity-header .active_info::before {
  background-image: url("assets/img/theme1/light-help-active.png") !important;
}
.activity_header_custom .activity-header .color-button .mdc-button__label {
  position: relative;
  bottom: 22px;
}
.activity_header_custom .activity-header .activity_dropdown {
  position: absolute !important;
  left: 21px;
  top: 0;
  /*.mat-mdc-form-field-wrapper{
  	padding-bottom: 10px !important;
  }
  .mat-select-value-text{
  	font-size: 32px;
  	color: #210854;
  	font-weight: bold;
  }
  .mat-mdc-form-field-underline{
  	background-color: transparent;
  }*/
}
.activity_header_custom .activity-header .activity_dropdown .dropdown_div {
  position: relative;
  height: 27px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 12px;
  top: -12px;
}
.activity_header_custom .activity-header .activity_dropdown .dropdown_div .top_dropdown {
  font-size: 14px;
  color: #866dff;
  position: relative;
  height: 30px;
  align-items: center;
  display: flex;
}
.activity_header_custom .activity-header .activity_dropdown .dropdown_div button {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  height: 16px;
  position: relative;
  top: -3px;
  cursor: pointer;
}
.activity_header_custom .activity-header .activity_dropdown .dropdown_div button img {
  height: 16px;
}
.activity_header_custom .activity-header .activity_dropdown .dropdown_child_div {
  position: relative;
  top: -35px;
  height: 32px;
  display: flex;
  align-items: center;
}
.activity_header_custom .activity-header .activity_dropdown .dropdown_child_div span {
  font-size: 32px;
  font-weight: bold;
  color: #210854;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 0px;
}
.activity_header_custom .activity-header .activity_dropdown .dropdown_child_div button {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.activity_header_custom .activity-header .visibility-button .mdc-button__label {
  position: absolute;
  top: -3px;
  left: 19px;
}
.activity_header_custom .activity-header .active-indicator {
  width: 100%;
  height: 5px;
  position: relative;
  background-color: #210854;
}
.activity_header_custom .pad-container {
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
}
.activity_header_custom .pad-container .pullout-handle {
  z-index: 120;
  width: 40px;
  height: 40px;
  border: solid 1px rgba(33, 8, 84, 0.08);
  background-color: white;
  border-radius: 50%;
  position: relative;
  background-image: none;
  margin: auto;
  opacity: 0;
}
.activity_header_custom .pad-container .pullout-handle::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 7px;
}
.activity_header_custom .pad-container .up {
  background-image: url("assets/img/theme1/down.png");
  background-position: center;
}
.activity_header_custom .pad-container .down {
  background-image: url("assets/img/up.png");
  background-position: center;
}
.activity_header_custom .pad-container .pullout-handle-up {
  z-index: 116;
  width: 40px;
  height: 40px;
  border: solid 1px rgba(33, 8, 84, 0.08);
  background-color: #ffffff;
  border-radius: 50%;
  position: relative;
  bottom: -22px;
  background-image: none;
  margin: auto;
}
.activity_header_custom .pad-container .pullout-handle-up::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 7px;
}

/*======= activity page header ===*/
/*======= blackboard page ===*/
.new-tile-container {
  overflow: hidden;
}

@media only screen and (min-height: 2160px) {
  .blackboard_custom {
    height: 91%;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .blackboard_custom {
    height: 86%;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .blackboard_custom {
    height: 86%;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .blackboard_custom {
    height: 82%;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .blackboard_custom {
    height: 80%;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .blackboard_custom {
    height: 78%;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .blackboard_custom {
    height: 74%;
  }
}
.blackboard_custom .new-tile-container {
  height: 100%;
  background-color: #e4e2ed;
}
.blackboard_custom .mat-figure {
  background-color: transparent;
}
.blackboard_custom .mat-figure .inside-tile {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
}
.blackboard_custom .line-holder .line {
  border-bottom: 1px solidrgba(#210854, 0.16);
}
.blackboard_custom .frozen-row-shadow {
  -webkit-box-shadow: 0 2px 4px 0 #dfd8f4;
  -moz-box-shadow: 0 2px 4px 0 #dfd8f4;
  box-shadow: 0 2px 4px 0 #dfd8f4;
  background: #e4e2ed;
}

/*======= blackboard page ===*/
/*======= sentence page ===*/
.sentence_div {
  margin: 0px 0px;
}
.sentence_div .sentence_mat_card {
  height: 433px !important;
  padding: 179px 0px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: #ffffff !important;
  text-align: center;
  width: 100%;
}
.sentence_div .sentence_mat_card span {
  font-size: 64px;
  color: #210854;
  font-weight: bold;
}

.sentence_tally {
  top: 10px !important;
  padding: 10px 10px;
  position: relative !important;
  display: flex;
  justify-content: center;
}

/*======= sentence page ===*/
/*======= PDF page ===*/
.pdf-container {
  display: flex;
  margin-top: 180px;
}
.pdf-container .pdf-navigators {
  z-index: 111;
  display: flex;
  justify-content: center;
  width: 125px;
  height: 150px;
  position: relative;
  top: 35vh;
}
.pdf-container .blackboard-canvas {
  position: absolute;
  z-index: 110;
  overflow: hidden;
}
.pdf-container .pdf-screen {
  flex: 0;
  align-items: center;
  border: none;
  box-shadow: none;
  width: 70vw;
}

.pdf_control_custom {
  justify-content: space-between !important;
  z-index: 111;
}
.pdf_control_custom .control_left .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 375px;
  height: 56px;
  border-radius: 4px;
  border: solid 1px rgba(33, 8, 84, 0.32);
  padding: 0 24px !important;
  display: flex;
  align-items: center;
  color: #210854;
}
.pdf_control_custom .control_left .mat-mdc-form-field-underline {
  display: none;
}
.pdf_control_custom .control_left .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.pdf_control_custom .control_left .mat-mdc-form-field-label {
  top: 35px;
  left: 16px;
  color: #210854;
  font-size: 16px;
  background-color: #e4e2ed;
  padding: 5px 10px;
}
.pdf_control_custom .control_left.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: #e4e2ed;
  padding: 5px 10px;
  width: auto;
  top: 20px;
  left: 16px;
}
.pdf_control_custom .control_right .mat-mdc-form-field-flex {
  width: 40px;
}
.pdf_control_custom .control_right span {
  margin-left: 25px;
}
.pdf_control_custom .control_right .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 59px;
  width: 100px;
  height: 56px;
  border-radius: 4px;
  border: solid 1px rgba(33, 8, 84, 0.32);
  padding: 0 0px !important;
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: #210854;
}
.pdf_control_custom .control_right .mat-mdc-form-field-infix input {
  text-align: center !important;
}
.pdf_control_custom .control_right .mat-mdc-form-field-underline {
  display: none;
}
.pdf_control_custom .control_right .mat-mdc-form-field-label-wrapper {
  left: -70px;
  top: 19px;
}
.pdf_control_custom .control_right .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  font-size: 16px;
  color: #210854;
}

#overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 101 !important;
  opacity: 0.4;
}
#overlay pdf-viewer {
  z-index: 0 !important;
  position: absolute;
  height: 100%;
  width: 100%;
}
#overlay pdf-viewer.hidden {
  opacity: 0;
}

.pdf_div {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  height: auto !important;
}
.pdf_div .back {
  display: flex;
  flex-direction: column;
}
.pdf_div .next {
  display: flex;
  flex-direction: column;
}
.pdf_div .pdf_mat_card {
  height: 100% !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: #ffffff !important;
  text-align: center;
  width: 100%;
}

/*======= PDF page ===*/
/*======= Tile layout ===*/
.tile-content_div .cdk-drag-placeholder {
  opacity: 0;
  display: none;
}
.tile-content_div .mat-figure {
  background-color: transparent;
}
.tile-content_div .mat-figure .inside-tile {
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
}

.tile-lay-out-bottom {
  background-color: white;
}

.tilelayout_div_footer {
  background-color: white;
}
.tilelayout_div_footer .mat-card-content {
  font-size: 1.6837vw !important;
}
.tilelayout_div_footer .mat-card {
  min-width: 49px;
  min-height: 60px;
  padding: 0px;
  border-radius: 4px;
  text-align: center;
  box-shadow: none;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
}

/*======= Tile layout ===*/
/*======= activity header resizable handle  ===*/
.ng-resizable-handle {
  background-color: #866dff !important;
  width: 40px !important;
  height: 40px !important;
}
.ng-resizable-handle::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme1/down-white.png");
  background-repeat: no-repeat;
  display: block;
  top: 15px;
  left: 8px;
}
.ng-resizable-handle::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme1/down-white.png");
  background-repeat: no-repeat;
  display: block;
  top: 2px;
  left: 8px;
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}

/*======= activity header resizable handle  ===*/
/*======= whiteboard page  ===*/
.whiteboard-container {
  height: 100%;
  width: 91%;
  position: absolute;
  left: 0;
  overflow: hidden;
  z-index: 0;
}
.whiteboard-container .wordcard {
  color: #210854;
  background-color: white;
}

/*======= whiteboard page  ===*/
/*======= phrases page  ===*/
.phrases_div {
  margin: 50px 0px;
  height: 100% !important;
}

.phrases_tally {
  padding: 10px 10px;
  position: relative !important;
  display: flex;
  justify-content: center;
}

.tally-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  top: 50px !important;
  height: auto !important;
  z-index: 1;
}

/*======= phrases page  ===*/
/*======= Spelling page  ===*/
.spelling_div .spelling_footer_div {
  background-color: white;
  display: flex;
  flex-direction: column;
}
.spelling_div .spelling_footer_div .spelling_span_words {
  margin-left: 76px;
  font-size: 16px;
  font-weight: 600;
  color: #bcbdbf;
  display: block;
  height: 32px;
}
.spelling_div .spelling_footer_div .spelling_btn {
  background: #ffb5a0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #210854;
}
.spelling_div .spelling_footer_div .skip_btn {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  background: #eeeeee;
  color: #888888;
  max-width: 75px;
}
.spelling_div .spelling_footer_div .spelling_icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 40px;
  margin-right: 32px;
}
.spelling_div .spelling_footer_div .spelling_icon i {
  color: #866dff;
}

/*======= Spelling page  ===*/
/*======= workbook page  ===*/
.workbook_tile_div {
  margin-top: 180px;
  height: 66%;
  position: relative;
  background-color: white;
}
@media only screen and (min-height: 2160px) {
  .workbook_tile_div {
    height: 91%;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .workbook_tile_div {
    height: 86%;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .workbook_tile_div {
    height: 86%;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .workbook_tile_div {
    height: 82%;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .workbook_tile_div {
    height: 80%;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .workbook_tile_div {
    height: 78%;
  }
}
@media only screen and (min-height: 768px) and (max-height: 900px) {
  .workbook_tile_div {
    height: 75%;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .workbook_tile_div {
    height: 74%;
  }
}
.workbook_tile_div .mat-figure {
  background-color: transparent;
}
.workbook_tile_div .mat-figure .inside-tile {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
}
.workbook_tile_div .line-holder .line {
  border-bottom: 1px solid rgba(33, 8, 84, 0.16);
}
.workbook_tile_div .dnd-drag-start {
  width: 50px;
  height: 50px;
}

/*======= workbook page  ===*/
/*======= tilelayout page  ===*/
.tilelayout_div .mat-figure {
  background-color: transparent;
}
.tilelayout_div .mat-figure .inside-tile {
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
}
.tilelayout_div .mat-figure:empty {
  border: none !important;
}

/*======= tilelayout page  ===*/
/*======= flashcard page  ===*/
.flashcard-box {
  position: relative;
}
.flashcard-box .back {
  top: 45%;
  position: relative;
  height: 100px;
}
@media only screen and (max-width: 1280px) {
  .flashcard-box .back .mat-button {
    top: 0% !important;
  }
}
.flashcard-box .next {
  top: 45%;
  position: relative;
  height: 100px;
}
@media only screen and (max-width: 1280px) {
  .flashcard-box .next .mat-button {
    top: 0% !important;
  }
}

.state-box {
  position: relative;
  top: 57px;
  border-radius: 16px;
  border: solid 1px rgba(33, 8, 84, 0.16);
  background: transparent !important;
  font-size: 14px;
  color: #210854 !important;
}

/*======= flashcard page  ===*/
/*======= mini tile page  ===*/
.mini_tile {
  margin-top: 180px;
  height: 66%;
}
@media only screen and (min-height: 2160px) {
  .mini_tile {
    height: 91%;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .mini_tile {
    height: 86%;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .mini_tile {
    height: 86%;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .mini_tile {
    height: 82%;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .mini_tile {
    height: 80%;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .mini_tile {
    height: 78%;
  }
}
@media only screen and (min-height: 768px) and (max-height: 900px) {
  .mini_tile {
    height: 75%;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .mini_tile {
    height: 74%;
  }
}
.mini_tile .center_align_tile > div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.mini_tile .center_align_tile > div .new-tile-block {
  position: relative !important;
  left: 0 !important;
  margin-right: 10px;
}
.mini_tile .center_align_tile > div .new-tile-block:last-child {
  margin-right: 0 !important;
}
.mini_tile .line-holder .line {
  border-bottom: 1px solid rgba(33, 8, 84, 0.16);
}
.mini_tile .dnd-drag-start {
  width: 50px;
  height: 50px;
}

/*======= mini tile page  ===*/
/*======= blank tile page  ===*/
@media only screen and (min-height: 2160px) {
  .blank_tile {
    height: 91vh;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .blank_tile {
    height: 86vh;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .blank_tile {
    height: 86vh;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .blank_tile {
    height: 82vh;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .blank_tile {
    height: 80vh;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .blank_tile {
    height: 78vh;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .blank_tile {
    height: 72vh;
  }
}
.blank_tile .center_align_tile > div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.blank_tile .center_align_tile > div .new-tile-block {
  position: relative !important;
  left: 0 !important;
  margin-right: 10px;
}
.blank_tile .center_align_tile > div .new-tile-block:last-child {
  margin-right: 0 !important;
}

/*======= mini tile page  ===*/
/*======= passage page  ===*/
.passage_tally {
  position: relative !important;
  display: flex;
  justify-content: center;
  height: 100px;
}

.passage_div .passage_mat_card {
  padding: 68px 88px !important;
  height: 500px !important;
  overflow-y: scroll !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: #ffffff !important;
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.passage_div .passage_mat_card span {
  color: #210854;
  font-size: 64px !important;
  font-weight: bold;
}

/*======= passage page  ===*/
/*======= time reading page  ===*/
.time_reading {
  margin: 0px 0px;
}
.time_reading .wordcards {
  font-size: 24px !important;
  color: #210854;
}
.time_reading .button-box {
  position: fixed;
  top: 40px;
  z-index: 117;
  right: 32px;
}
.time_reading .button-box .mat-mdc-button-base {
  background-color: #ffb5a0;
  border-radius: 4px;
  box-shadow: none;
}
.time_reading .button-box .mat-mdc-button-base .mdc-button__label {
  line-height: 1em !important;
  color: #210854;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.time_reading .wrong {
  text-decoration: line-through;
  color: #ff7463 !important;
}

.tile-container {
  width: 100% !important;
  position: relative;
  display: flex !important;
  flex-direction: row;
  padding-right: 8vw;
}
.tile-container .line-holder {
  display: none !important;
}
.tile-container .flashcard {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: auto !important;
  height: 433px !important;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08);
  background-color: white;
  font-size: 6vw !important;
  color: #210854 !important;
  margin: 0px -5px;
  border: none !important;
}
.tile-container .flashcard-altered {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: auto !important;
  height: 433px !important;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08);
  background-color: white;
  font-size: 6vw !important;
  color: #210854 !important;
  margin: 0px -5px;
  border: none !important;
}

.letter-box .lettercard {
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
}
.letter-box .back {
  position: relative;
  top: 42%;
}
.letter-box .next {
  position: relative;
  top: 42%;
}

.card {
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: white;
  font-size: 64px !important;
  padding: 38px 0px !important;
}
.card span {
  color: #210854;
}

.mat-mdc-card-subtitle,
.mat-mdc-card-content {
  font-size: 14px;
}

.side_bar_display {
  width: 72px !important;
}
.side_bar_display .side-top {
  padding: 32px 15px 0px !important;
}
.side_bar_display .side-middle {
  overflow-y: auto !important;
  overflow: hidden !important;
}
.side_bar_display .side-middle .ng-scroll-view {
  overflow-x: hidden;
}
.side_bar_display .side-middle .mat-expansion-panel-content {
  display: none;
}
.side_bar_display .side-middle .mat-tree {
  padding: 0px 0px 0px 0px !important;
}
.side_bar_display .side-middle .mat-tree .nav-icons {
  left: 12px !important;
}
.side_bar_display .side-middle .mat-tree .mat-tree-node {
  padding-left: 5px !important;
}
.side_bar_display .side-middle .mat-tree .mat-tree-node[role=treeitem] {
  display: none;
}
.side_bar_display .side-bottom {
  position: relative;
  left: -17px;
}
.side_bar_display .side-bottom .user-icon {
  margin-right: 20px !important;
}
.side_bar_display .content_div {
  width: 95%;
}

.side_navbar, .element-drag-disabled {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.add-save-setting-modalbox {
  width: 350px !important;
}
.add-save-setting-modalbox .mat-mdc-dialog-container {
  padding: 0 !important;
  background-color: white;
}
.add-save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content {
  margin: 0;
  padding: 25px 24px;
}
.add-save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.add-save-setting-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-wrapper {
  padding: 0;
  width: 100%;
}
.add-save-setting-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-infix {
  color: #210854;
}
.add-save-setting-modalbox .add-save-setting {
  width: 350px;
}
.add-save-setting-modalbox .add-save-setting .popup-header {
  border-bottom: solid 1px rgba(72, 30, 159, 0.08);
  padding: 19px 24px;
}
.add-save-setting-modalbox .add-save-setting .popup-header h1 {
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  color: #210854;
  font-weight: 600;
}
.add-save-setting-modalbox .add-save-setting .btn-container {
  text-align: right;
  justify-content: flex-end;
  padding: 0 24px;
}
.add-save-setting-modalbox .add-save-setting .btn-container .mat-button {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.add-save-setting-modalbox .add-save-setting .btn-container .mat-button .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.add-save-setting-modalbox .add-save-setting .btn-container .disable_save {
  opacity: 0.5;
}
.add-save-setting-modalbox .add-save-setting .mat-mdc-dialog-actions .mat-mdc-button-base {
  margin-left: 0;
}

.save-setting-modalbox .mat-mdc-dialog-container {
  padding: 0 !important;
  background-color: white;
}
.save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content {
  margin: 0;
  padding: 25px 24px;
}
.save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field-infix {
  color: rgba(0, 0, 0, 0.87);
}
.save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .delete {
  margin: 18px 0px;
}
.save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .delete .mat-button {
  padding: 0px 0px;
  background-color: transparent;
}
.save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .delete span {
  margin: 0px 18px;
  color: rgba(0, 0, 0, 0.87);
}
.save-setting-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-wrapper {
  padding: 0;
  width: 100%;
}
.save-setting-modalbox .add-save-setting {
  width: 350px;
}
.save-setting-modalbox .add-save-setting .popup-header {
  border-bottom: solid 1px rgba(33, 8, 84, 0.08);
  padding: 19px 24px;
}
.save-setting-modalbox .add-save-setting .popup-header h1 {
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  color: #210854;
  font-weight: 600;
}
.save-setting-modalbox .add-save-setting .btn-container {
  text-align: right;
  justify-content: flex-end;
  padding: 0 24px;
}
.save-setting-modalbox .add-save-setting .btn-container .mat-button {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.save-setting-modalbox .add-save-setting .btn-container .mat-button .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.save-setting-modalbox .add-save-setting .btn-container .disable_save {
  opacity: 0.5;
}
.save-setting-modalbox .add-save-setting .mat-mdc-dialog-actions .mat-mdc-button-base {
  margin-left: 0;
}

.activity-modalbox .mat-mdc-dialog-title {
  color: #210854 !important;
  font-weight: bold !important;
  letter-spacing: normal;
  margin-top: 24px;
}
.activity-modalbox .mat-mdc-dialog-content {
  max-height: 45vh;
  border-bottom: solid 1px rgba(33, 8, 84, 0.08);
  border-top: solid 1px rgba(33, 8, 84, 0.08);
  padding: 0 !important;
}
.activity-modalbox .mat-mdc-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(33, 8, 84, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 24px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
  cursor: pointer;
}
.activity-modalbox .mat-mdc-dialog-content .mat-mdc-list-item.selected {
  background: #EEEEEE !important;
}
.activity-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item {
  font-size: 18px !important;
  color: #210854;
  line-height: 30px !important;
}
.activity-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item_child {
  font-size: 14px !important;
  color: rgba(13, 2, 35, 0.87);
}
.activity-modalbox .mat-mdc-button-base {
  float: right;
  position: relative;
  top: 4px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px 24px;
  margin: 6px 0;
}
.activity-modalbox .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.activity-modalbox .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

/*====  Time reading modalbox   ====*/
.time_reading-modalbox {
  width: 350px;
}
.time_reading-modalbox .mat-mdc-dialog-container {
  background-color: white;
}
.time_reading-modalbox .mat-mdc-dialog-title {
  margin: 0 0 0px;
  color: #0d0223;
  font-weight: bold !important;
  font-size: 20px;
  margin-bottom: 16px;
}
.time_reading-modalbox .mat-dialog-content p {
  font-size: 16px;
  color: rgba(13, 2, 35, 0.56);
  line-height: 20px;
}
.time_reading-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  margin-right: 16px;
  margin-bottom: 8px;
}
.time_reading-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  background: transparent;
}
.time_reading-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  font-weight: bolder !important;
  letter-spacing: 0.4px;
}

/*====  Time reading modalbox   ====*/
/*====  score modalbox   ====*/
.score-modalbox {
  width: 350px;
}
.score-modalbox .mat-mdc-dialog-title {
  margin: 0 0 0px;
  color: #0d0223;
  font-weight: bold;
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 8px;
}
.score-modalbox .mat-dialog-content p {
  font-size: 16px;
  color: rgba(13, 2, 35, 0.56);
  line-height: 20px;
}
.score-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0px 0px !important;
  margin-right: 24px;
  margin-bottom: 8px;
}
.score-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.score-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  font-weight: bolder !important;
  letter-spacing: 0.4px;
}
.score-modalbox .mat-mdc-dialog-actions button:first-child {
  margin-right: 8px;
}

/*====  score modalbox   ====*/
/*==== display score modalbox   ====*/
.display-score-modalbox {
  width: 400px;
  height: 350px;
}
.display-score-modalbox .mat-mdc-dialog-title {
  margin: 0 0 12px;
  color: #0d0223;
  font-weight: bold;
  font-size: 20px;
}
.display-score-modalbox .mat-dialog-content {
  height: 70%;
}
.display-score-modalbox .mat-dialog-content .mat-mdc-list-item {
  height: 30px;
  font-size: 16px;
  color: rgba(33, 8, 84, 0.56);
}
.display-score-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0px 0px !important;
}
.display-score-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

/*==== display score modalbox   ====*/
/*====  activity-common-modalbox  ====*/
.activity-common-modalbox {
  height: 364px !important;
}
.activity-common-modalbox .mat-mdc-dialog-container {
  padding: 0px 0px;
  background-color: white;
}

/*====  activity-common-modalbox  ====*/
/*==== course list modalbox   ====*/
.course-list-modalbox {
  width: 350px !important;
  height: auto !important;
}
.course-list-modalbox .mat-mdc-dialog-container {
  overflow: hidden;
  background-color: white;
}
.course-list-modalbox .mat-mdc-dialog-title,
.course-list-modalbox .mdc-dialog__title {
  color: #210854 !important;
  font-weight: bold !important;
  margin-top: 24px;
  font-size: 18px !important;
}
.course-list-modalbox .mat-mdc-dialog-content {
  max-height: 45vh;
  border-bottom: solid 1px rgba(72, 30, 159, 0.08);
  border-top: solid 1px rgba(72, 30, 159, 0.08);
  padding: 0px 0px !important;
}
.course-list-modalbox .mat-mdc-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(72, 30, 159, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 0px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
  cursor: pointer;
}
.course-list-modalbox .mat-mdc-dialog-content .mdc-list-item__content {
  padding: 0px 24px !important;
}
.course-list-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item {
  font-size: 16px !important;
  color: #210854;
  line-height: 30px !important;
}
.course-list-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item_child {
  font-size: 14px !important;
}
.course-list-modalbox .mat-mdc-button-base {
  color: #866dff !important;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  float: right;
  margin: 10px;
  position: relative;
  background: transparent;
}

/*==== course list modalbox   ====*/
/*==== missing tiles modalbox   ====*/
.dark-theme .missing-tiles-modalbox .mat-mdc-dialog-title, .dark-theme .missing-tiles-modalbox .description {
  color: white !important;
}
.missing-tiles-modalbox .mat-mdc-dialog-container {
  background-color: white;
}
.missing-tiles-modalbox .mat-dialog-content {
  margin: 0;
  padding: 0;
}
.missing-tiles-modalbox .mat-dialog-content .mat-mdc-dialog-title {
  color: #210854;
  font-size: 24px;
}
.missing-tiles-modalbox .mat-dialog-content .mat-typography .mat-typography-p {
  color: rgba(33, 8, 84, 0.56);
}
.missing-tiles-modalbox .mat-mdc-dialog-actions .add_tile_missing {
  background-color: #ffb5a0;
  color: #210854;
  box-shadow: none;
  font-size: 14px;
  display: flex;
  flex-direction: row;
}
.missing-tiles-modalbox .mat-mdc-dialog-actions .add_tile_missing .mdc-button__label {
  width: 100%;
  color: #210854;
}
.missing-tiles-modalbox .mat-mdc-dialog-actions .skip_tile_missing {
  color: #210854;
  font-size: 14px;
  align-self: center;
  display: flex;
  flex-direction: row;
  background-color: lightgray;
}
.missing-tiles-modalbox .mat-mdc-dialog-actions .skip_tile_missing .mdc-button__label {
  width: 100%;
  color: #210854;
}

/*==== missing tiles modalbox   ====*/
/*==== small modalbox   ====*/
.small-dialog {
  max-width: 20vw !important;
}
.small-dialog .mat-mdc-dialog-title {
  color: #210854;
}
.small-dialog .mat-dialog-content {
  color: rgba(13, 2, 35, 0.5607843137);
}

/*==== small modalbox   ====*/
/*==== scrollbar   ====*/
.ng-scrollbar {
  --scrollbar-size: 8px !important;
  --scrollbar-thumb-color: rgba(94, 175, 255, 0.5)!important;
  --scrollbar-thumb-hover-color: dodgerblue;
  --scrollbar-border-radius: 4px;
}

.scrollbar {
  overflow-y: auto;
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(94, 175, 255, 0.3);
  border-radius: 5px;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(94, 175, 255, 0.5);
}

.scrollbar-grey {
  overflow-y: auto;
}

.scrollbar-grey::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.scrollbar-grey::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.scrollbar-grey::-webkit-scrollbar-thumb {
  background: rgba(179, 179, 179, 0.5);
  border-radius: 5px;
}

/* Handle on hover */
.scrollbar-grey::-webkit-scrollbar-thumb:hover {
  background: rgba(179, 179, 179, 0.5);
}

/*==== scrollbar   ====*/
/*==== curriculum   ====*/
.course_content_custom {
  height: 154px !important;
  min-height: 154px !important;
  padding: 32px 24px !important;
  background-color: white;
}
.course_content_custom .header {
  position: relative;
  display: flex;
  align-items: center;
}
.course_content_custom .header h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0;
}
.course_content_custom .header .help_icon {
  position: relative;
  left: 10px;
  top: 0px;
}
.course_content_custom .course-button {
  background-color: #ffb5a0 !important;
  border: 1px solid #ffb5a0;
  border-radius: 4px;
  height: 40px !important;
  width: auto !important;
  box-shadow: none !important;
  top: 50px;
  right: 15px;
  min-width: auto;
}
.course_content_custom .course-button .mdc-button__label {
  line-height: 40px !important;
  color: #210854;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.course_content_custom .course-button .mdc-button__label {
  line-height: 40px !important;
}

.dashboard_content_toggle {
  background-color: #dfdee5 !important;
}
.dashboard_content_toggle .mat-mdc-tab-body-wrapper {
  padding: 0px 24px;
  overflow: initial;
  margin-bottom: 50px !important;
}
.dashboard_content_toggle .mat-mdc-tab-body-wrapper .reoder {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  margin: 32px 10px 0px;
  box-shadow: none !important;
  line-height: 1.2;
  height: 40px;
}
.dashboard_content_toggle .mat-mdc-tab-body-wrapper .reoder .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.dashboard_content_toggle .mat-mdc-tab-body-wrapper .reoder .mdc-button__label {
  color: #fff;
}
.dashboard_content_toggle .mat-mdc-tab-body-wrapper .done {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  margin: 32px 10px 0px;
  box-shadow: none !important;
  line-height: 1.2;
  height: 40px;
}
.dashboard_content_toggle .mat-mdc-tab-body-wrapper .done .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.dashboard_content_toggle .mat-mdc-tab-body-wrapper .done .mdc-button__label {
  color: #fff;
}
.dashboard_content_toggle .mat-mdc-tab-body-wrapper .search-and-actions {
  background-color: #dfdee5 !important;
}
.dashboard_content_toggle .mat-mdc-tab-body-wrapper .quick-start {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  margin: 32px 10px 0px;
  box-shadow: none !important;
  line-height: 1.2;
  height: 40px;
}
.dashboard_content_toggle .mat-mdc-tab-body-wrapper .quick-start .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.dashboard_content_toggle .mat-mdc-tab-body-wrapper .quick-start .mat-button-wrapper {
  color: #fff;
}
.dashboard_content_toggle .mat-mdc-tab-header {
  border: none;
}
.dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container {
  background-color: white;
  padding: 0px 24px;
}
.dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 1;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab[aria-disabled=true] {
  opacity: 0.32;
}
.dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab__text-label {
  color: #481e9f !important;
}
.dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}
.dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
.dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
  border-color: #481e9f !important;
}

.dashboard_custom_card {
  padding: 24px 0px;
}
.dashboard_custom_card .blank_div_container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 32px 0px;
}
.dashboard_custom_card .blank_div_container .add_workbook_blank {
  background-color: #866dff !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 9px 5px 0px !important;
  background-image: none;
}
.dashboard_custom_card .blank_div_container .add_workbook_blank span {
  color: #fff !important;
  margin-bottom: 10px;
}
.dashboard_custom_card .blank_div_container .blank_div {
  background-color: #d8d5e7 !important;
  height: 118px !important;
  border-radius: 8px;
  box-shadow: none !important;
  margin: 9px 5px 0px !important;
}
.dashboard_custom_card .new-workbook {
  height: 118px !important;
  border: none !important;
  margin-right: 5px !important;
  border-radius: 8px !important;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (max-width: 1920px) {
  .dashboard_custom_card .new-workbook {
    width: 19.2% !important;
  }
}
@media only screen and (max-width: 1680px) {
  .dashboard_custom_card .new-workbook {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1366px) {
  .dashboard_custom_card .new-workbook {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .dashboard_custom_card .new-workbook {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .dashboard_custom_card .new-workbook {
    width: 31% !important;
  }
}
.dashboard_custom_card .new-workbook span {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.dashboard_custom_card .workbook {
  height: 118px !important;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: white !important;
  margin: 9px 5px 0px !important;
}
.dashboard_custom_card .workbook .mat-mdc-card-header {
  color: #210854;
  text-align: left;
  font-weight: 600;
}
.dashboard_custom_card .workbook .mat-mdc-card-header .mat-mdc-card-header-text {
  margin: 0 0px !important;
}
.dashboard_custom_card .workbook .mat-mdc-card-header .red-circle {
  width: 8px;
  height: 8px;
  background: #ff5a5a;
  border-radius: 50px;
  position: absolute;
  right: 4px;
  top: 4px;
}
.dashboard_custom_card .workbook .actions {
  position: relative;
}
.dashboard_custom_card .workbook .actions .begin-button {
  position: absolute;
  left: -6px;
  top: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.dashboard_custom_card .workbook .actions .begin-button .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dashboard_custom_card .workbook .actions .ellipsis {
  position: absolute;
  right: 0;
  background: none;
  box-shadow: none;
}
.dashboard_custom_card .workbook .actions .ellipsis .mdc-button__label {
  color: rgba(0, 0, 0, 0.3) !important;
}
@media only screen and (max-width: 1920px) {
  .dashboard_custom_card .content {
    width: 19.2% !important;
  }
}
@media only screen and (max-width: 1680px) {
  .dashboard_custom_card .content {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1366px) {
  .dashboard_custom_card .content {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .dashboard_custom_card .content {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .dashboard_custom_card .content {
    width: 31% !important;
  }
}

.dashboard_custom_card_reoder {
  padding: 24px 0px;
}
.dashboard_custom_card_reoder .new-workbook {
  width: 18.8% !important;
  height: 118px !important;
  border: none !important;
  border-radius: 8px !important;
  margin-right: 5px !important;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dashboard_custom_card_reoder .new-workbook div {
  margin-top: 10px;
}
.dashboard_custom_card_reoder .new-workbook span {
  color: #866dff;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.dashboard_custom_card_reoder .cdk-drop-list {
  width: 18.8% !important;
}
.dashboard_custom_card_reoder .cdk-drop-list .workbook {
  background-color: white !important;
}
.dashboard_custom_card_reoder .cdk-drop-list .workbook .example-handle .mat-mdc-card-header {
  color: #210854;
}
.dashboard_custom_card_reoder .content {
  padding: 7px 3px !important;
}
.dashboard_custom_card_reoder .workbook {
  height: 118px;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: white !important;
  margin: 9px 5px 0px !important;
}
.dashboard_custom_card_reoder .workbook .mat-mdc-card-header {
  color: #210854;
  text-align: left;
}
.dashboard_custom_card_reoder .workbook .mat-mdc-card-header .mat-mdc-card-header-text {
  margin: 0 0px !important;
}
.dashboard_custom_card_reoder .workbook .actions {
  color: #210854;
  position: relative;
  height: auto;
  padding: 0px 24px !important;
  justify-content: start !important;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px !important;
}
.dashboard_custom_card_reoder .workbook .ellipsis {
  background: none;
  box-shadow: none;
}
.dashboard_custom_card_reoder .workbook .ellipsis .mdc-button__label {
  color: #cac4d7 !important;
}
.dashboard_custom_card_reoder .workbook .action-div {
  display: flex;
  justify-content: space-between;
}
.dashboard_custom_card_reoder .workbook .remove-btn {
  font-weight: bold;
  background-color: transparent;
  border: none;
  margin-top: -16px;
  text-transform: uppercase;
  cursor: pointer;
}

.search-field {
  position: relative;
}
.search-field .search-field_input {
  width: 100%;
  border: 1px solid rgba(33, 8, 84, 0.08);
  padding: 10px 0px 10px 40px;
  border-radius: 4px;
  outline: none;
  background-color: white;
  color: black;
  font: inherit;
}
.search-field .search-field_input::placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.search-field .search-field_input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.search-field .search-field_input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.search-field .clear-button {
  position: absolute;
  right: 0rem;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  color: #999;
}
.search-field .clear-button:hover {
  color: #333;
}
.search-field::before {
  position: absolute;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme1/search.png");
  top: 7px;
  left: 10px;
}

/*==== curriculum   ====*/
/*====  work book menu modalbox  ====*/
.work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item {
  color: #210854;
  background-color: transparent;
}
.work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item:last-child {
  color: #ff7463;
}
.work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item:hover:not([disabled]) {
  background: rgba(33, 8, 84, 0.04);
}
.work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

/*====  work book menu modalbox  ====*/
/*====  manage course modalbox  ====*/
.manage-course-modalbox {
  width: 350px !important;
}
.manage-course-modalbox .mat-mdc-dialog-container {
  padding: 0px 0px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
  background-color: white;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  margin: 0 0 20px;
  display: block;
  padding: 19px 24px;
  border-bottom: 1px solid rgba(33, 8, 84, 0.08);
  color: #210854;
  font-size: 20px;
  font-weight: bold;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding: 0 24px;
  max-height: 65vh;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix {
  color: #0d0223;
  font-size: 16px;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  color: #0d0223;
  opacity: 0.56;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix .mdc-form-field-outline-thick {
  color: rgba(33, 8, 84, 0.08);
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .manage_modal_p {
  font-size: 16px;
  color: rgba(33, 8, 84, 0.56);
  margin: 0 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .manage_modal_p span {
  margin-right: 24px;
  height: 23px;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  margin: 6px 10px;
  justify-content: flex-end;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .cancel {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .cancel .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .save {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .save .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .share_copy_btn {
  color: #866dff;
  margin-left: 5px;
  font-size: smaller;
}

/*====  manage course modalbox  ====*/
/*====  wordlist modalbox  ====*/
.wordlist_modal {
  width: 350px !important;
}
.wordlist_modal .mat-mdc-dialog-container {
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
  background-color: white;
}
.wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: #210854;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 5px;
  padding: 0;
}
.wordlist_modal .mat-mdc-dialog-container .scrollable {
  overflow: hidden !important;
}
.wordlist_modal .mat-mdc-dialog-container .scrollable p {
  color: rgba(33, 8, 84, 0.56);
  font-size: 16px;
}
.wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  padding: 0px 0;
  min-height: 32px;
}
.wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-button {
  position: relative;
  top: 20px;
  left: 15px;
}
.wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-button .mdc-button__label {
  font-size: 14px;
  color: #866dff;
  font-weight: 600;
  text-transform: uppercase;
}

/*====  wordlist modalbox  ====*/
/*==== tutorial dialog modalbox  ====*/
.tutorial_dialog_modal .mat-mdc-dialog-container {
  padding: 1rem 0.5rem;
  border-radius: 8px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
  background-color: white;
}

/*====  tutorial dialog modalbox  ====*/
.setting_header_div_tutorials {
  background: white;
  padding: 6px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
}
.setting_header_div_tutorials h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0 !important;
}

.page_tutorials p {
  color: rgba(13, 2, 35, 0.5607843137);
  font-size: 16px !important;
  line-height: normal !important;
  margin: 15px 0 24px;
}

.sharing_header_div {
  background: white;
  padding: 0px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 96px;
  align-items: center;
  justify-content: space-between;
}
.sharing_header_div .help_icon {
  position: relative;
  top: 7px;
  left: 10px;
}
.sharing_header_div .sharing_header_div_left {
  display: flex;
}
.sharing_header_div .sharing_header_div_left .settings-header {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0;
}
.sharing_header_div .mat-mdc-button-base {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  line-height: 1.2;
  box-shadow: none;
  height: auto;
  padding: 0 16px;
}
.sharing_header_div .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.sharing_header_div .mat-mdc-button-base .mdc-button__label {
  font-weight: bold;
  letter-spacing: 0.4px !important;
}
.sharing_header_div .import-btn {
  background: #ffb5a0;
  border-color: #ffb5a0;
}
.sharing_header_div .import-btn .mdc-button__label {
  color: #210854;
}

.page_sharing {
  padding: 34px 24px;
  background: #dfdee5;
}
.page_sharing .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}
.page_sharing .page_sharing_heading {
  color: #210854;
  font-size: 20px;
  font-weight: bold;
}
.page_sharing .page_sharing_p {
  font-size: 16px;
  color: #777183;
  width: 38vw;
  line-height: normal;
}
.page_sharing .sharing_from, .page_sharing .search-input-box {
  margin: 24px 0px 0px;
  width: 209px;
  position: relative;
}
.page_sharing .sharing_from .sharing_from_input, .page_sharing .search-input-box .sharing_from_input {
  border: 1px solid rgba(33, 8, 84, 0.08);
  padding: 10px 0px 10px 40px;
  border-radius: 50px;
  outline: none;
  background-color: white;
  color: black;
  font: inherit;
}
.page_sharing .sharing_from .sharing_from_input::placeholder, .page_sharing .search-input-box .sharing_from_input::placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.page_sharing .sharing_from .sharing_from_input::-moz-placeholder, .page_sharing .search-input-box .sharing_from_input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.page_sharing .sharing_from .sharing_from_input::-webkit-input-placeholder, .page_sharing .search-input-box .sharing_from_input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.page_sharing .sharing_from::before, .page_sharing .search-input-box::before {
  position: absolute;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme1/search.png");
  top: 7px;
  left: 10px;
}

.sharing_custom_card {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 24px;
}
.sharing_custom_card .sharing_custom_card {
  font-size: 19px;
  color: #210854;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 30px;
}
.sharing_custom_card .new-workbook {
  width: 23.6% !important;
  height: 374px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  margin-right: 5px !important;
  padding: 24px 0px;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
@media only screen and (max-width: 1024px) {
  .sharing_custom_card .new-workbook {
    width: 30% !important;
  }
}
.sharing_custom_card .new-workbook .icon {
  width: 48px;
  height: 48px;
  background: #866dff;
  border-radius: 5px;
  margin: 10px 0px 10px 0px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}
.sharing_custom_card .new-workbook .new_workbook_heading {
  font-size: 20px;
  color: #210854;
  font-weight: bold;
  margin-bottom: 18px;
}
.sharing_custom_card .new-workbook .mdc-button__label {
  color: #866dff;
  font-size: 14px;
}
.sharing_custom_card .content {
  width: 23.6% !important;
  padding: 17px 0px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(33, 8, 84, 0.04);
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin: 9px 5px 0px !important;
}
@media only screen and (max-width: 1024px) {
  .sharing_custom_card .content {
    width: 30% !important;
  }
}
.sharing_custom_card .content::before {
  position: absolute;
  width: 100%;
  content: "";
  height: 1px;
  display: block;
  left: 0;
  top: 153px;
  z-index: 1;
  border-top: 1px solid #f1edf8;
}
.dark-theme .sharing_custom_card .content::before {
  border-top: 1px solid rgba(255, 255, 255, 0.08) !important;
}
.sharing_custom_card .content .mat-mdc-card-header {
  display: block;
  flex-direction: row;
  font-size: 20px;
  font-weight: bold;
  color: #210854;
  text-align: center;
  min-height: 48px;
  padding: 0;
}
.sharing_custom_card .content .mat-mdc-card-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}
.sharing_custom_card .content .mat-mdc-card-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
}
.sharing_custom_card .content .mat-mdc-card-actions .icon {
  width: 48px;
  height: 48px;
  background: #866dff;
  border-radius: 5px;
  margin: 20px 0px 10px 0px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sharing_custom_card .content .mat-mdc-card-actions .share_code {
  margin-bottom: 15px;
}
.sharing_custom_card .content .mat-mdc-card-actions .share_code p {
  margin: 15px 0 10px;
  font-size: 16px;
  color: #210854;
  font-weight: 600;
}
.sharing_custom_card .content .mat-mdc-card-actions .share_code span {
  color: rgba(33, 8, 84, 0.56);
  font-size: 16px;
}
.sharing_custom_card .content .mat-mdc-card-actions .share_code .sharing_copy {
  color: rgba(0, 0, 0, 0.87);
}
.sharing_custom_card .content .mat-mdc-card-actions .setting_include {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 163px;
}
.sharing_custom_card .content .mat-mdc-card-actions .setting_include p {
  margin: 15px 0 10px;
  font-size: 16px;
  color: #210854;
  font-weight: 600;
}
.sharing_custom_card .content .mat-mdc-card-actions .setting_include span {
  color: rgba(33, 8, 84, 0.56);
  font-size: 16px;
}
.sharing_custom_card .content .mat-mdc-card-actions .setting_include .mdc-button__label {
  color: #866dff;
}
.sharing_custom_card .content .mat-mdc-card-actions .delete-button {
  margin: 5px 0px 0px;
}
.sharing_custom_card .content .mat-mdc-card-actions .delete-button .mdc-button__label {
  color: #ff7463;
  font-size: 14px;
}

/*=== sharing page  ====*/
.add-collection-modalbox {
  width: 350px !important;
}
.add-collection-modalbox .mat-mdc-dialog-container {
  padding: 0px;
  overflow: hidden;
  background-color: white;
}
.add-collection-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  padding: 19px 24px;
  display: block;
  color: #0d0223;
  font-size: 20px;
  font-weight: bold;
}
.add-collection-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding: 0;
}
.add-collection-modalbox .mat-mdc-dialog-container .mdc-list {
  padding: 0px 0px;
}
.add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item {
  height: auto;
}
.add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item:not(:last-child) {
  border-bottom: 1px solid #f1edf8;
}
.dark-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item:not(:last-child) {
  border-bottom: 1px solid rgba(241, 237, 248, 0.12) !important;
}
.add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item .mdc-list-item__content {
  padding: 0px 24px 16px;
  cursor: pointer;
}
.add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item .mdc-list-item__content .activity_item {
  font-size: 16px !important;
  color: #210854;
}
.add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item .mdc-list-item__content .activity_item_child {
  color: rgba(33, 8, 84, 0.56);
  font-size: 14px !important;
}
.add-collection-modalbox .mat-mdc-button {
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}
.add-collection-modalbox .mat-mdc-button .mdc-button__label {
  text-transform: uppercase;
  color: #866dff;
  font-size: 14px;
}

.rename-modalbox {
  width: 350px !important;
}
.rename-modalbox .mat-mdc-dialog-container {
  background-color: white;
}
.rename-modalbox .mat-mdc-dialog-title {
  color: #0d0223;
  padding: 19px 24px 0;
  display: block;
  font-size: 20px;
  font-weight: bold;
}
.rename-modalbox .mat-mdc-form-field {
  width: 100%;
}
.rename-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 1em 0 1em 0;
  color: #210854;
}
.rename-modalbox .mat-mdc-form-field .mat-mdc-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.rename-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0 24px 10px;
}
.rename-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.rename-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.import-collection-modalbox {
  width: 350px !important;
}
.import-collection-modalbox .mat-mdc-dialog-container {
  background-color: white;
}
.import-collection-modalbox .mat-mdc-dialog-title {
  color: #0d0223 !important;
  padding: 19px 24px 0;
  display: block;
  font-size: 20px !important;
  font-weight: bold !important;
}
.import-collection-modalbox .mat-mdc-form-field {
  width: 100%;
}
.import-collection-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 0.8em 0 1em 0;
  color: #210854;
}
.import-collection-modalbox .mat-mdc-form-field .mat-mdc-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.import-collection-modalbox .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.import-collection-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0 24px 10px;
}
.import-collection-modalbox .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}

.mat-mdc-dialog-container .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
}

.delete-lesson-plan-step-actions .mat-flat-button {
  color: #866dff !important;
}

.type-edit-modalbox {
  overflow: hidden;
  width: 70vw !important;
}
.type-edit-modalbox .mat-mdc-dialog-title {
  padding: 19px 24px 0;
  display: block;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: normal;
}
body:not(.dark-theme) .type-edit-modalbox .mat-mdc-dialog-title {
  color: #210854 !important;
}
.dark-theme .type-edit-modalbox .mat-mdc-dialog-title {
  color: white !important;
}
.type-edit-modalbox .mat-mdc-form-field {
  width: 100%;
}
.type-edit-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 1em 0 1em 0;
  color: #210854;
}
.type-edit-modalbox .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.type-edit-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 8px 24px;
}
.type-edit-modalbox .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.type-edit-modalbox .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  height: 0px !important;
}
.type-edit-modalbox .mat-mdc-focused {
  background-color: none;
}
.type-edit-modalbox #no-padding .mat-mdc-form-field-infix {
  padding: 0px !important;
  border-top: 0px !important;
}
.type-edit-modalbox #no-padding .mat-mdc-form-field-infix textarea {
  background-color: white;
  border: 2px solid rgba(33, 8, 84, 0.32);
  color: black;
}

.collection-added-modalbox {
  width: 350px !important;
}
.collection-added-modalbox .mat-mdc-dialog-title {
  color: #0d0223;
  padding: 19px 24px 0;
  display: block;
  font-size: 20px;
  font-weight: bold;
}
.collection-added-modalbox .mat-dialog-content {
  font-size: 16px;
  color: rgba(33, 8, 84, 0.56);
}
.collection-added-modalbox .mat-dialog-content span {
  color: #210854;
}
.collection-added-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
}
.collection-added-modalbox .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}

/*===  sharing page  ====*/
.sharing_header_div_collection {
  background: white;
  padding: 0px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 120px;
  align-items: center;
  justify-content: space-between;
}
.sharing_header_div_collection .sharing_header_left_collection {
  display: flex;
  flex-direction: column;
}
.sharing_header_div_collection .sharing_header_left_collection span {
  color: #866dff;
  font-size: 14px;
  margin-bottom: 8px;
}
.sharing_header_div_collection .sharing_header_left_collection .settings-header {
  color: #210854;
  margin: 0 0 0;
}
.sharing_header_div_collection button {
  height: 40px !important;
  width: auto !important;
  right: 15px;
  min-width: auto;
  box-shadow: none !important;
  background-color: #ffb5a0 !important;
  border: 1px solid #ffb5a0;
  border-radius: 4px;
}
.sharing_header_div_collection button .mdc-button__label {
  line-height: 40px !important;
  color: #210854;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

.collection_field {
  margin: 24px 24px !important;
  padding: 0 !important;
}
.collection_field .mat-mdc-form-field-infix {
  width: 302px;
  color: #210854;
  font-size: 16px;
}
.collection_field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  color: #210854;
  opacity: 0.64;
}
.collection_field .mdc-form-field-outline {
  color: rgba(33, 8, 84, 0.64);
}

.sharing_custom_card_collection {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 24px;
}
.sharing_custom_card_collection .content {
  width: 23.6% !important;
  padding: 17px 17px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(33, 8, 84, 0.04);
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin: 9px 5px 0px !important;
}
.sharing_custom_card_collection .content .mat-mdc-card-header {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #210854;
  padding: 0 !important;
}
.sharing_custom_card_collection .content .mat-mdc-card-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 10px 0 0;
  margin-bottom: -10px;
  min-height: auto;
}
.sharing_custom_card_collection .content .mat-mdc-card-actions .add-button .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  font-weight: bold;
}
.sharing_custom_card_collection .content .mat-mdc-card-actions .delete-button .mdc-button__label {
  color: #ff7463;
  font-size: 14px;
  font-weight: bold;
}
.sharing_custom_card_collection .content .mat-mdc-card-actions .ellipsis {
  position: absolute;
  right: -20px;
}
.sharing_custom_card_collection .content .mat-mdc-card-actions .ellipsis .mdc-button__label {
  color: #cac4d7 !important;
}

/*===  sharing page  ====*/
/*===  Account  ====*/
.account_content_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #FFF;
  margin: 24px;
  border-radius: 8px;
}
.account_content_div .each_row {
  display: flex;
  border-bottom: 1px solid rgba(33, 8, 84, 0.32);
}
.account_content_div .each_row .description-section {
  padding: 24px 24px;
}
@media only screen and (max-width: 1024px) {
  .account_content_div .each_row .description-section {
    width: 30% !important;
  }
}
.account_content_div .each_row .description-section h3 {
  font-size: 20px;
  font-weight: bold;
  color: #210854;
}
.account_content_div .each_row .description_option {
  padding: 24px 24px;
  width: 50%;
}
@media only screen and (max-width: 1024px) {
  .account_content_div .each_row .description_option {
    width: 70% !important;
  }
}
.account_content_div .each_row .description_option h4 {
  color: #210854;
  font-size: 16px;
}
.account_content_div .each_row .description_option span {
  color: #777183;
  font-size: 16px;
  margin-left: 12px;
}
.account_content_div .each_row .description_option .add_card {
  font-size: 14px;
  color: #866dff;
  text-transform: uppercase;
  cursor: pointer;
}

.plan_description_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  padding: 24px 24px;
  flex-wrap: wrap;
}
.plan_description_div .mat-mdc-button-base {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
}
.plan_description_div .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.plan_description_div .mat-mdc-button-base .mdc-button__label {
  font-style: italic;
  letter-spacing: 0.4px;
  text-transform: capitalize !important;
  font-size: 18px;
}

/*===  Account  ====*/
/*===  add credit card modalbox  ====*/
.add-credit-card-modalbox {
  width: 350px;
  border-radius: 8px !important;
}
.add-credit-card-modalbox .mat-mdc-dialog-title {
  font-size: 20px;
  color: #0d0223;
  font-weight: bold;
  margin: 0 0 0;
}
.add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0px !important;
}
.add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 0 0;
}
.add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix input[type=text] {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: #210854 !important;
  border: 1px solid rgba(33, 8, 84, 0.64);
  padding-left: 10px;
}
.add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  top: 37px;
  left: 16px;
  color: #210854;
  font-size: 16px;
  opacity: 0.64;
}
.add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-underline {
  background-color: transparent !important;
}
.add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-ripple {
  display: none;
}
.add-credit-card-modalbox .mat-stroked-button {
  border-color: rgba(0, 0, 0, 0.12);
}
.add-credit-card-modalbox .mat-mdc-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.12);
}
.add-credit-card-modalbox .mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  background-color: white;
  width: auto !important;
  top: 32px !important;
  padding: 0px 7px;
  opacity: 1 !important;
}
.add-credit-card-modalbox .card_date_details {
  display: flex;
  justify-content: space-between;
}
.add-credit-card-modalbox .btn-default {
  background-color: transparent !important;
  font-weight: bold;
  color: #866dff;
  text-transform: uppercase;
  font-size: 14px !important;
}
.add-credit-card-modalbox .agree_span {
  color: #210854;
  opacity: 56%;
}
.add-credit-card-modalbox .mat-mdc-checkbox-checked .mat-mdc-checkbox-background {
  background-color: #866dff !important;
}

/*===  add credit card modalbox  ====*/
/*===  successful-update-modalbox  ====*/
.successful-update-modalbox {
  width: 350px !important;
}
.successful-update-modalbox .mat-mdc-dialog-container {
  padding: 19px 24px 5px;
  background-color: white;
}
.successful-update-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: #0d0223;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.successful-update-modalbox .mat-mdc-dialog-container .msg_success {
  font-size: 16px;
  color: rgba(33, 8, 84, 0.56);
}
.successful-update-modalbox .mat-mdc-dialog-container .ok_success_btn {
  width: auto !important;
  margin-right: 0px;
  float: right;
  margin-top: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.successful-update-modalbox .mat-mdc-dialog-container .ok_success_btn .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.successful-update-modalbox .mat-mdc-dialog-container .cdk-focused {
  display: none;
}

/*===  successful-update-modalbox  ====*/
/*===  selected-plan-modalbox  ====*/
.selected-plan-modalbox {
  width: 350px !important;
}
.selected-plan-modalbox .mat-mdc-dialog-container {
  padding: 17px 24px 5px;
  background-color: white;
}
.selected-plan-modalbox .mat-mdc-dialog-container .titel {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #0d0223;
  display: block;
}
.selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field {
  width: 100%;
}
.selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0px !important;
}
.selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 0 0;
}
.selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-infix input[type=text] {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: #210854 !important;
  border: 1px solid rgba(33, 8, 84, 0.64);
}
.selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  top: 37px;
  left: 16px;
  color: #210854;
  font-size: 16px;
  opacity: 0.64;
}
.selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-underline {
  background-color: transparent !important;
}
.selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-ripple {
  display: none;
}
.selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  background-color: #fff;
  width: auto !important;
  background: #fff;
  top: 32px !important;
  padding: 0px 7px;
  opacity: 1 !important;
}
.selected-plan-modalbox .mat-mdc-dialog-container .comfrm_new_plan_btn {
  width: auto !important;
  margin-right: 0px;
  float: right;
  margin-top: 20px;
}
.selected-plan-modalbox .mat-mdc-dialog-container .comfrm_new_plan_btn .mdc-button__label {
  color: #866dff;
}
.selected-plan-modalbox .mat-mdc-dialog-container .cdk-focused {
  display: none;
}

/*===  selected-plan-modalbox  ====*/
/*====  cancel-subscriptiton-modalbox  ====*/
.cancel-subscriptiton-modalbox {
  transform: scale(0.9) !important;
}
.cancel-subscriptiton-modalbox app-cancel-subscription {
  display: block;
  height: 100%;
}
.cancel-subscriptiton-modalbox .mat-mdc-dialog-container {
  padding: 17px 24px 10px;
  background-color: white;
}
.cancel-subscriptiton-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #0d0223;
  display: block;
}
.cancel-subscriptiton-modalbox .mat-mdc-dialog-container .mat-typography {
  padding: 0px;
}
.cancel-subscriptiton-modalbox .mat-mdc-dialog-container .btn-default {
  width: auto !important;
  margin-right: 0px;
  margin-bottom: 10px;
  background-color: transparent;
}
.cancel-subscriptiton-modalbox .mat-mdc-dialog-container .btn-default .mdc-button__label {
  color: #866dff;
}
.cancel-subscriptiton-modalbox .mat-mdc-dialog-container .cdk-focused {
  display: none;
}
.cancel-subscriptiton-modalbox .mat-mdc-dialog-container .are-you-sure-span {
  color: rgba(13, 2, 35, 0.5607843137);
  font-size: 16px;
}

/*====  cancel-subscriptiton-modalbox  ====*/
.feedback-form-panel {
  width: 500px;
}
.feedback-form-panel .feedback-form {
  width: 100%;
  height: 100%;
}
.feedback-form-panel .feedback-form .feedback-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.feedback-form-panel .feedback-form .feedback-form-container .header {
  color: rgba(13, 2, 35, 0.56);
}
.dark-theme .feedback-form-panel .feedback-form .feedback-form-container .header {
  color: white !important;
}
.feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-title {
  font-size: 20px;
  margin-bottom: 20px;
  color: #210854;
  letter-spacing: normal;
  display: block;
}
.dark-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-title {
  color: white !important;
}
.feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-content {
  color: rgba(13, 2, 35, 0.56);
  margin: 0;
  padding: 4px 24px;
  letter-spacing: normal;
  line-height: normal;
  overflow-x: hidden;
  display: flex;
}
.dark-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-content {
  color: white !important;
}
.feedback-form-panel .feedback-form .feedback-form-container .mat-typography {
  padding: 0px;
}

.unsaved-changes-panel {
  width: 400px;
}
.unsaved-changes-panel .mat-mdc-dialog-title {
  font-size: 20px;
  margin-bottom: 20px;
  color: #210854 !important;
  letter-spacing: normal;
  display: block;
}
.dark-theme .unsaved-changes-panel .mat-mdc-dialog-title {
  color: white !important;
}
.unsaved-changes-panel .mat-mdc-dialog-content {
  color: rgba(13, 2, 35, 0.56);
  margin: 0;
  padding: 4px 24px;
  letter-spacing: normal;
  line-height: normal;
  overflow-x: hidden;
  display: flex;
}
.dark-theme .unsaved-changes-panel .mat-mdc-dialog-content {
  color: white !important;
}
.unsaved-changes-panel .mat-typography {
  padding: 0px;
}

@media only screen and (max-width: 1280px) {
  .whizzimolibraries_container .drag-drop-box .new-workbook {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .whizzimolibraries_container .drag-drop-box .new-workbook {
    width: 31% !important;
  }
}
.whizzimolibraries_container .drag-drop-box .content {
  margin: 0px 9px 9px;
  background-color: white;
}
@media only screen and (max-width: 1280px) {
  .whizzimolibraries_container .drag-drop-box .content {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .whizzimolibraries_container .drag-drop-box .content {
    width: 31% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .new-workbook {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .new-workbook {
    width: 31% !important;
  }
}
.whizzimolibraries_container .drag-drop-box-whizzimoLibrary .content {
  margin: 0px 9px 9px;
  background-color: white;
}
@media only screen and (max-width: 1280px) {
  .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .content {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .content {
    width: 31% !important;
  }
}
.whizzimolibraries_container .whizzimolibraries_header {
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  align-items: center;
}
.whizzimolibraries_container .whizzimolibraries_header .header h2 {
  font-size: 32px;
  color: #210854;
}
.whizzimolibraries_container .whizzimolibraries_header .course-button {
  height: 40px !important;
  width: auto !important;
  box-shadow: none;
  right: 15px;
  min-width: auto;
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
}
.whizzimolibraries_container .whizzimolibraries_header .course-button .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.whizzimolibraries_container .mat-tab-header {
  background: white;
  border: none;
}
.whizzimolibraries_container .mat-tab-header .mdc-tab__content {
  color: #481e9f;
  text-transform: uppercase;
  font-weight: 600;
}
.whizzimolibraries_container .mat-tab-header .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}
.whizzimolibraries_container .mat-mdc-tab-body-content {
  padding: 24px 24px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .new-workbook {
  height: 125px;
  min-width: 200px;
  width: 18.8%;
  border-radius: 8px;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border: none;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  margin: 0px 9px 9px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .new-workbook span {
  color: #866dff;
  text-transform: uppercase;
  font-size: 14px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .mat-mdc-card-header {
  color: #210854;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .mat-mdc-card-header .mat-mdc-card-header-text {
  margin: 0 0px !important;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .add_to_course {
  padding-left: 10px;
  padding-right: 10px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .add_to_course .mdc-button__label {
  color: #866dff;
  text-transform: uppercase;
  font-size: 14px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .Added {
  padding-left: 10px;
  padding-right: 10px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .Added .mdc-button__label {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .remove {
  padding-left: 10px;
  padding-right: 10px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .remove .mdc-button__label {
  color: #ff7463;
  text-transform: uppercase;
  font-size: 14px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .ellipsis {
  position: absolute;
  right: 10px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .ellipsis .mdc-button__label {
  color: #866dff;
  opacity: 0.32;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from, .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box {
  margin: 24px 0px 10px;
  width: 209px;
  position: relative;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input, .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input {
  border: 1px solid rgba(33, 8, 84, 0.08);
  padding: 10px 0px 10px 40px;
  border-radius: 50px;
  outline: none;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  font: inherit;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input::placeholder, .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input::placeholder {
  color: rgba(0, 0, 0, 0.87);
}
.whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input::-moz-placeholder, .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.87);
}
.whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input::-webkit-input-placeholder, .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.87);
}
.whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from::before, .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box::before {
  position: absolute;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme1/search.png");
  top: 7px;
  left: 10px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .length_p {
  color: rgba(33, 8, 84, 0.56);
  font-size: 16px;
}
.whizzimolibraries_container .mat-mdc-tab-body-content .mat-progress-bar {
  border-radius: 50px;
}

.add-wordbook-modalbox .mat-mdc-dialog-container {
  border-radius: 8px;
  background-color: white;
}
.add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: #0d0223;
  font-weight: bold;
  margin-top: 24px;
  letter-spacing: normal;
}
.add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  max-height: 45vh;
  border-bottom: solid 1px rgba(13, 2, 35, 0.08);
  border-top: solid 1px rgba(13, 2, 35, 0.08);
  padding: 0px 0px;
}
.add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(13, 2, 35, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 0px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
  cursor: pointer;
}
.add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-list-item:last-of-type {
  border-bottom: none;
}
.add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mdc-list-item__content {
  padding: 0px 24px !important;
}
.add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mdc-list-item__content .addwork_item {
  font-size: 16px !important;
  color: #210854;
  line-height: 30px !important;
}
.add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mdc-list-item__content .addwork_p {
  font-size: 14px !important;
  color: rgba(13, 2, 35, 0.87);
  margin-bottom: 7px;
}
.add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  float: right;
  position: relative;
  top: 15px;
  font-family: "Roboto", sans-serif;
  padding: 4px 24px;
}

.add-wordbook-myLibrary-modalbox {
  width: 350px !important;
}
.add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container {
  border-radius: 8px;
  background-color: white;
}
.add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: #0d0223;
  font-weight: bold;
  padding: 19px 24px 0;
}
.add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content {
  max-height: 45vh;
  padding: 0px 0px;
}
.add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(13, 2, 35, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 0px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
}
.add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content .mdc-list-item__content {
  padding: 0px 24px !important;
}
.add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content .mdc-list-item__content .workbook_option_item {
  font-size: 16px !important;
  color: #210854;
  line-height: 30px !important;
}
.add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  float: right;
  position: relative;
  top: 15px;
}

.header_activity_top {
  position: fixed;
  display: flex;
  height: 85px !important;
  z-index: 115 !important;
  top: 0;
  background-color: white;
}
.header_activity_top .nav- {
  cursor: pointer;
  height: 38px;
  padding-top: 5px;
}
.header_activity_top .header-activity-name {
  font-size: 32px;
  font-weight: bold;
  color: #210854;
  height: 150px;
  align-items: center;
  line-height: 0px;
}
.header_activity_top .header-workbook-name {
  font-size: 14px;
  color: #866dff;
  position: relative;
  height: 30px;
  align-items: center;
  text-transform: uppercase;
}
.header_activity_top .mat-ripple-element {
  display: none;
}

/*===  add-course-modalbox  ===*/
.add-course-modalbox {
  width: 350px !important;
}
.add-course-modalbox .mat-mdc-dialog-container {
  padding: 0px;
  border-radius: 8px;
  background-color: white;
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  margin: 0 0 20px;
  display: block;
  padding: 19px 24px;
  color: #210854;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid rgba(13, 2, 35, 0.08);
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  margin: 0 0px;
  padding: 0 24px;
  max-height: 66vh;
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .add_cousre_modal_p_course {
  color: #210854;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 16px;
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .add_cousre_modal_subscription {
  color: grey;
  font-weight: bold;
  font-size: 16px;
  margin-top: 0;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .add_cousre_modal_subscription {
  color: white !important;
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix {
  color: #0d0223;
  font-size: 16px;
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .course_description {
  margin-top: 12px;
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .course_description .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  height: 59.5px;
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  padding: 0px 10px;
  margin-bottom: 0px;
  justify-content: flex-end;
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-mdc-button-base {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}

/*===  add-course-modalbox  ===*/
/* == setting page ==*/
.setting_screens {
  height: 100vh;
  overflow: auto;
  background-color: #dfdee5 !important;
}
.setting_screens .setting_header_div {
  height: 96px;
}
.setting_screens .tile-content_div {
  height: calc(100% - 79px);
  overflow-x: hidden;
}
.setting_screens .tile-content_div .mat-tab-group .mat-tab-header {
  top: 0px;
}
.setting_screens .tile-content_div .mat-mdc-tab-header {
  border: none;
}
.setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container {
  background-color: white;
}
.setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 1;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}
.setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
body:not(.dark-theme) .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #866dff !important;
}
.dark-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: white !important;
}

/* == setting page ==*/
/* == tile page  ==*/
.new-tile-container {
  overflow: scroll;
  background-color: #dfdee5;
}
.new-tile-container table {
  border: 10px;
  margin: auto;
}
.new-tile-container table tr {
  margin-bottom: 10px;
  border: none !important;
}
.new-tile-container table tr:last-child {
  margin-bottom: 0px;
}
.new-tile-container table tr td {
  margin: 5px;
  min-height: 60px;
  min-width: 60px;
  overflow: visible;
}

.inside-tile {
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-stretch: normal;
  font-size: 1.6837vw;
  font-style: normal;
  line-height: 60px;
  text-align: center;
  letter-spacing: normal;
  border-radius: 4px !important;
  border: solid 1px rgba(72, 30, 159, 0.24);
  min-width: 60px;
  min-height: 60px;
  z-index: 20;
}
.inside-tile > img {
  position: absolute;
  top: 20px;
  left: 5px;
}

.tile-layout-top {
  height: 74%;
}

/* == tile page ==*/
/* == edit lesson plan page ==*/
.edit-lesson-plan-header {
  background-color: white;
  padding: 24px 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.edit-lesson-plan-header .edit-lesson-plan-top-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-lesson-plan-header .edit-lesson-plan-top-header .lesson-plan-date {
  color: #866dff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}
.edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div {
  width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .begin-lesson-btn {
  background-color: #ffb5a0;
  border-radius: 4px;
}
.edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .begin-lesson-btn .mdc-button__label {
  line-height: 40px !important;
  color: #210854;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .save-later-btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .save-later-btn .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.edit-lesson-plan-header .mat-mdc-card-title {
  padding: 0;
}
.edit-lesson-plan-header .mat-mdc-card-title .errors_field {
  color: red;
  font-size: 16px;
  display: block;
}
.edit-lesson-plan-header .mat-mdc-card-title .lesson_plan_name {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  line-height: 1;
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.edit-lesson-plan-header .mat-mdc-card-title .lesson_plan_input {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  width: 40vw;
}
.edit-lesson-plan-header .mat-mdc-card-title .lesson_plan_title {
  margin: 0px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}

.missing-tiles-button {
  color: #866dff !important;
}

.lesson_notes {
  display: flex;
  width: 100%;
  margin: 32px 0px 0px;
}
.lesson_notes .mat-mdc-form-field-infix {
  font-size: 16px;
}
.lesson_notes .lesson_notes_label {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: bold;
  color: #210854;
}
.lesson_notes .lesson_notes_left {
  display: flex;
  flex-direction: column;
  margin-right: 250px;
}
.lesson_notes .lesson_notes_left .lesson_notes_text {
  font-size: 16px;
  color: rgba(13, 2, 35, 0.5607843137);
  font-weight: normal;
}
.lesson_notes .lesson_notes_right {
  display: flex;
  flex-direction: column;
}
.lesson_notes .lesson_notes_right .lesson_notes_right_text {
  font-size: 16px;
  color: rgba(13, 2, 35, 0.5607843137);
  font-weight: normal;
}
.lesson_notes .lesson_notes_right input {
  font-size: 14px;
  color: rgba(13, 2, 35, 0.5607843137);
  font-weight: normal;
  width: 400px;
}

.edit-lesson-plan-body {
  border-radius: 8px;
  height: calc(100vh - 209px);
  overflow: auto;
}
.edit-lesson-plan-body > .content {
  padding: 24px;
}
.edit-lesson-plan-body .long-card {
  border-bottom: 1px solid rgba(33, 8, 84, 0.32);
  border-radius: 0px;
  z-index: 1;
}
.edit-lesson-plan-body .long-card .more_words_modal_activity, .edit-lesson-plan-body .long-card .more_tiles_modal_activity {
  margin-bottom: 16px !important;
}
.edit-lesson-plan-body .long-card .pdf_file_step {
  color: #210854;
  font-size: 16px;
  font-weight: bold;
}
.edit-lesson-plan-body .long-card .pdf_files {
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 34px;
  background-color: transparent !important;
  border: 1px solid rgba(33, 8, 84, 0.32);
}
.edit-lesson-plan-body .long-card .pdf_files .mdc-button__label {
  color: #210854 !important;
}
.edit-lesson-plan-body .long-card .no_file_btn {
  padding: 0px;
  border: none;
}
.edit-lesson-plan-body .long-card .no_file_btn .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}

.add_step {
  cursor: pointer;
}
.add_step > .long-card {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.add_step span {
  font-size: 14px;
  font-weight: 500;
  color: #866dff;
  text-transform: uppercase;
}
.add_step mat-card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/*== edit lesson plan page ==*/
/*== lond card Forms==*/
.long-card {
  margin: 0;
  background-color: white;
  box-shadow: none !important;
  padding: 24px 24px !important;
}
.long-card .long-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
}
.long-card .long-card-header .long-card-header-right img {
  margin-right: 8px;
}
.long-card .long-card-header .long-card-header-right img:first-child {
  margin-right: 20px;
}
.long-card .long-card-header .long-card-header-right img:last-child {
  margin-left: 12px;
  margin-right: 0px;
}
.long-card .long-card-header .long-card-header-left {
  display: flex;
}
.long-card .long-card-header .long-card-header-left span {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #210854;
  font-weight: bold;
  letter-spacing: -0.01em;
}
.long-card .mat-mdc-form-field-underline {
  display: none;
}
.long-card .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.long-card .mat-mdc-form-field-label {
  top: 25px;
  left: 10px;
  color: #210854;
  font-size: 16px;
  padding: 5px 10px;
  background: white;
  width: auto !important;
}
.long-card.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: white;
  padding: 5px 10px;
  width: auto;
  top: 20px;
  left: 16px;
}
.long-card .words_shuffle_btn_div {
  display: inline-flex;
  height: 56px;
  align-items: flex-start;
  margin-left: 16px;
}
.long-card .words_shuffle_btn_div .words_view_input .mat-mdc-text-field-wrapper {
  width: 94px;
  height: 56px;
  padding: 0 24px !important;
  display: flex;
  align-items: center;
  color: #210854;
}
.long-card .words_shuffle_btn_div .words_view_input .mat-mdc-text-field-wrapper .mat-select-value-text {
  color: #210854;
  font-size: 16px;
}
.long-card .words_shuffle_btn_div .shuffle_btn {
  width: 94px;
  height: 56px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid #866dff;
  border-left: none;
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  background: transparent;
}
.long-card .words_shuffle_btn_div .shuffle_btn:disabled {
  border-color: gray !important;
  color: gray !important;
}
.long-card .starting_view_dropdown .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 191px;
  height: 56px;
  padding: 0 24px !important;
  display: flex;
  align-items: center;
  color: #210854;
}
.long-card .starting_view_dropdown .mat-mdc-form-field-infix .mat-select-value-text {
  color: #210854;
  font-size: 16px;
}
.long-card .starting_view_dropdown .mat-mdc-form-field-underline {
  display: none;
}
.long-card .starting_view_dropdown .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.long-card .starting_view_dropdown .mat-mdc-form-field-label {
  top: 29px;
  left: 10px;
  color: #210854;
  font-size: 16px;
  padding: 5px 10px;
}
.long-card .starting_view_dropdown.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: #fff;
  padding: 5px 10px;
  width: auto;
  top: 20px;
  left: 16px;
}
.dark-theme .long-card .starting_view_dropdown.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  background-color: #18112f !important;
}
.long-card .missing_tile_btn {
  border: 1px solid #210854;
  color: #210854;
  font-size: 14px;
  height: 57px;
  background-color: transparent;
}
.long-card .disabled_field .mat-mdc-form-field-infix {
  border: 1px solid #ccc6d7 !important;
}
.long-card .disabled_field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  color: #ccc6d7 !important;
}
.long-card .example-box {
  height: 56px;
}
.long-card .example-box .mat-button-wrapper {
  font-size: 24px;
  font-weight: bold;
  position: relative;
  padding: 0px 0px 0px 24px;
}
.long-card .cdk-drop-list .mat-mdc-button-base {
  height: 56px;
  border: 1px solid rgba(33, 8, 84, 0.32) !important;
}
.long-card .cdk-drop-list .mat-mdc-button-base .mdc-button__label {
  font-size: 24px;
  font-weight: bold;
  position: relative;
  padding: 0px 0px 0px 30px;
  color: rgba(0, 0, 0, 0.87);
}
.long-card .cdk-drop-list .mat-mdc-button-base .mdc-button__label::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  left: 0;
  top: 3px;
}
.long-card .cdk-drop-list .mat-mdc-button-base:not(.cdk-drag-disabled) .mdc-button__label::before {
  background-image: url("assets/img/theme1/move-modal.png");
}
.long-card .cdk-drop-list .mat-mdc-button-base.text-button {
  border: 0 !important;
  cursor: default;
}
.long-card .cdk-drop-list .mat-mdc-button-base.text-button .mdc-button__label::before {
  background-image: none !important;
}
.long-card .cdk-drop-list .moveIcon .mdc-button__label::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme1/add-circle-1.png");
  background-repeat: no-repeat;
  left: 0;
  top: 3px;
}
.long-card .custom-margin-activity-popup {
  margin-right: 24px !important;
  margin-bottom: 24px;
}
.long-card .custom-margin-activity-popup .mat-option[aria-disabled=true] {
  opacity: 0.32;
}

/*== lond card Forms==*/
/*== registration  page  ==*/
#registration_container {
  margin: 0px;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}
#registration_container .left-box {
  width: 72px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 0px;
  background-color: #210854;
  position: fixed;
  left: 0;
  top: 0;
}
#registration_container .left-box img {
  border-radius: 4px;
}
#registration_container .right-box {
  width: calc(100% - 72px);
  float: left;
  margin-left: 72px;
}
#registration_container .right-box .registration_header {
  height: 153px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
#registration_container .right-box .registration_header .registration_header_left {
  display: flex;
  flex-direction: column;
}
#registration_container .right-box .registration_header .registration_header_left span {
  color: #866dff;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 0 8px;
}
#registration_container .right-box .registration_header .registration_header_left h1 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0 0 16px;
  line-height: 1;
}
#registration_container .right-box .registration_header .registration_header_left p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #777183;
  margin: 0;
}
#registration_container .right-box .registration_header .registration_header_right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 91px;
}
#registration_container .right-box .registration_header .registration_header_right a {
  background-color: #ffb5a0;
  border-radius: 4px;
  color: #210854;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  padding: 12px 19px;
}
#registration_container .right-box .form_container {
  width: 435px;
  background: #fff;
  border-radius: 8px;
  margin: 24px;
  padding: 32px 24px;
}
#registration_container .right-box .form_container .mat-mdc-form-field {
  width: 100%;
}
#registration_container .right-box .form_container .mat-mdc-form-field .mat-input-element {
  color: #210854 !important;
  font-size: 16px !important;
}
#registration_container .right-box .form_container .mat-mdc-form-field .mat-mdc-form-field-label {
  color: rgba(33, 8, 84, 0.56);
}
#registration_container .right-box .form_container .mdc-label {
  white-space: normal;
  color: #210854;
  font-size: 16px;
  font-weight: 500;
}
#registration_container .right-box .form_container .mdc-label a {
  color: #866dff !important;
}
#registration_container .right-box .form_container .mat-mdc-checkbox {
  margin-bottom: 18px;
  display: flex;
}
#registration_container .right-box .form_container .mat-mdc-checkbox .mdc-checkbox {
  margin: 0px 16px 0px 0px;
  top: 3px;
  width: 18px;
  height: 18px;
}
#registration_container .right-box .form_container .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  border: 2px solid rgba(33, 8, 84, 0.16);
}
#registration_container .right-box .form_container .mat-select-value-text {
  color: #210854;
}
#registration_container .right-box .form_container .mat-select-arrow {
  color: #210854;
}
#registration_container .right-box .form_container .mat-mdc-checkbox-checked .mdc-checkbox__background {
  background-color: transparent !important;
  border: 2px solid #866dff !important;
}
#registration_container .right-box .form_container .mat-mdc-checkbox-checked .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke: #866dff !important;
}
#registration_container .right-box .form_container .mat-mdc-checkbox-checked .mdc-checkbox__checkmark-path {
  stroke-width: 3.13333px;
}
#registration_container .right-box .form_container .vp-bit span {
  margin-right: 16px;
  color: #210854;
}
#registration_container .right-box .form_container .alert {
  margin: 0px 0px 20px;
}
#registration_container .right-box .form_container .alert i {
  margin-right: 16px;
  color: #210854;
}
#registration_container .right-box .form_container .passwords_match {
  margin: 0px 0px 20px;
}
#registration_container .right-box .form_container .register_btn {
  background-color: #210854;
  border-radius: 4px;
  box-shadow: none !important;
  padding: 14px 17px;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  outline: 0;
  border: none;
}

/*== registration  page  ==*/
.workbook-body .mat-mdc-tab-header {
  padding: 0px 22px;
  background-color: white;
}
.workbook-body .mat-mdc-tab-header .mdc-tab__content {
  opacity: 1;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #481e9f;
  text-transform: uppercase;
}
.workbook-body .mat-mdc-tab-header .mdc-tab--active {
  opacity: 1 !important;
}
.workbook-body .mat-mdc-tab-header .mdc-tab--active .mdc-tab__content {
  opacity: 1 !important;
}
.workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}

.workbook_builder_filter .help_icon {
  position: relative;
  top: 2px;
  font-size: 18px;
  left: 10px;
}

.demo_plan_wordlist {
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px 0;
  font-size: 14px;
  color: #210854;
  text-align: center;
}

.workbook_builder_filter_main {
  background-color: white !important;
}
.workbook_builder_filter_main .filter-close span {
  color: black;
}

.words_container_filter {
  background-color: #dfdee5 !important;
}
.words_container_filter .filters-container .workbook_builder_filter_main .filter-body .filter-header .filter-title {
  color: #210854;
}
.words_container_filter .filters-container .workbook_builder_filter_main .filter-body .filter-header .filter-description {
  color: rgba(13, 2, 35, 0.5607843137);
}
.words_container_filter .results-container {
  margin-right: 24px;
}
.words_container_filter .results-container .word-list-header {
  background-color: #210854;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.words_container_filter .results-container .word-panel {
  background-color: white;
}
.words_container_filter .results-container .word-panel .word-content {
  color: #210854;
}
.words_container_filter .workbook_chat-container {
  background-color: white;
  color: rgba(13, 2, 35, 0.5607843137);
}
.words_container_filter .workbook_chat-container .chat-container {
  background-color: white;
  color: rgba(13, 2, 35, 0.5607843137);
}
.words_container_filter .workbook_chat-container .chat-container .chat-title {
  color: #210854;
}
.words_container_filter .workbook_chat-container .chat-container .chat-header {
  border-bottom: 1px solid #f1edf8;
}
.words_container_filter .workbook_chat-container .chat-messages .user-message {
  background-color: #866dff;
  color: white;
}
.words_container_filter .workbook_chat-container .chat-messages .ai-message {
  background-color: #866dff;
  color: white;
}
.words_container_filter .workbook_chat-container .chat-messages .loading-bubble {
  background-color: #866dff;
}
.words_container_filter .workbook_chat-container .chat-messages .dot {
  background-color: white;
}
.words_container_filter .workbook_chat-container .chat-input {
  border-top: 1px solid #f1edf8;
}
.words_container_filter .workbook_chat-container .chat-input textarea {
  border: 1px solid #f1edf8;
  background-color: #f2f2f2;
}
.words_container_filter .workbook_chat-container .chat-input .send-button {
  background: #866DFF !important;
  color: white !important;
}
.words_container_filter .selection-container {
  background-color: white;
}

.sentences-and-more-container {
  padding: 0 0px;
}
.sentences-and-more-container .search-container {
  background-color: #dfdee5 !important;
}
.sentences-and-more-container .section-container {
  background-color: #dfdee5 !important;
  padding: 0 24px 24px;
  border-radius: 0 !important;
}
.sentences-and-more-container .section-container .section {
  padding: 24px;
  background-color: white;
}
.sentences-and-more-container .section-container .section .section-header .section-header-text h2 {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #210854;
  margin: 0 0 8px;
}
.sentences-and-more-container .section-container .section .section-header .section-header-text .description {
  color: rgba(13, 2, 35, 0.5607843137);
  margin: 0 0 24px;
}
.sentences-and-more-container .section-container .section:not(:first-child) {
  border-top: solid 1px #f1edf8;
}
.dark-theme .sentences-and-more-container .section-container .section:not(:first-child) {
  border-top: 1px solid rgba(241, 237, 248, 0.12) !important;
}
.sentences-and-more-container .section-container .section:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.sentences-and-more-container .section-container .section:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.sentences-and-more-container .divider {
  width: 100%;
  height: 1px;
  border: solid 1px rgba(33, 8, 84, 0.32);
}
.sentences-and-more-container .section-button {
  color: #866dff;
}
.sentences-and-more-container .content-card {
  display: flex;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px rgba(33, 8, 84, 0.32);
  background-color: transparent;
}
.sentences-and-more-container .content-card span:first-child {
  color: rgba(0, 0, 0, 0.87);
}
.sentences-and-more-container .content-card button {
  background-color: transparent;
}
.sentences-and-more-container .content-card button .mdc-button__label {
  color: rgba(0, 0, 0, 0.87);
}
.sentences-and-more-container .card-text {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #210854;
}
.sentences-and-more-container .new-card-button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #866dff;
  border-color: #866dff;
  padding: 10px;
}

.workbook_item .activity_item_child {
  font-size: 14px !important;
  color: rgba(13, 2, 35, 0.5607843137);
  margin-bottom: 7px;
}

.custom_filter_card_content .custom_input_content textarea {
  width: 170px;
  height: 61px;
  font-size: 16px;
  padding: 10px 17px;
  background: transparent;
  box-shadow: none;
  border: 1px solid rgba(33, 8, 84, 0.32);
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto";
}
.custom_filter_card_content .text-box-wrapper {
  border: 1px solid rgba(33, 8, 84, 0.32);
  color: rgba(0, 0, 0, 0.87);
}

.quick-unselected {
  color: #210854;
}

.workbook_builder_header_main {
  background-color: white;
}
.workbook_builder_header_main .workbook_builder_header {
  min-width: calc(100% - 230px);
}
.workbook_builder_header_main .workbook_builder_header .workbook-title {
  color: #210854;
}
.workbook_builder_header_main .workbook_builder_header .workbook-owner {
  color: #866dff;
}
.workbook_builder_header_main .workbook_builder_header .workbook-description {
  color: rgba(13, 2, 35, 0.5607843137);
}
.workbook_builder_header_main .workbook_builder_header .mat-mdc-form-field {
  width: 90% !important;
}

.sentences-search-box .search_img {
  background-image: url("assets/img/theme1/search.png");
  width: 22px !important;
  height: 24px;
}

.files_search_container .search-box {
  padding: 8px !important;
  background-color: white;
}
.files_search_container .search-box .search_img {
  background-image: url("assets/img/theme1/search.png");
  width: 22px !important;
  height: 24px;
  margin-right: 10px;
}
.files_search_container .search-box input {
  color: rgba(0, 0, 0, 0.87);
}

.mat-form-field-appearance-outline .mat-mdc-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

.plan-description {
  background-color: white;
  width: 220px;
  border: 1px solid rgb(234, 236, 238);
  margin-bottom: 16px;
  z-index: 1;
  transition: transform 0.5s ease-in-out;
}
.plan-description:hover {
  transform: scale(1.02);
  z-index: 2;
  transition: transform 0.5s ease-in-out;
}
.plan-description .plan-price-text {
  color: rgba(0, 0, 0, 0.87);
}
.plan-description .plan-price-sub-text {
  color: rgba(0, 0, 0, 0.87);
}

.account_note {
  font-size: 16px;
  color: #777183;
}

.mat-mdc-select-panel {
  background-color: white;
  box-shadow: 0 4px 9px 0 rgba(33, 8, 84, 0.21);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.tile_footer {
  background-color: white;
}

.mat-mdc-menu-panel {
  background-color: white;
  box-shadow: 0 4px 9px 0 rgba(33, 8, 84, 0.21);
  max-width: 300px !important;
}

.mat-mdc-dialog-container {
  background-color: white;
  border: 2px solid white;
}

.tile-layout-top {
  background-color: #e4e2ed;
  overflow: hidden;
}

.tilelayout_div_footer .tileset_name {
  color: #210854;
}

.custom_filter_card_content .mat-mdc-form-field-infix {
  color: rgba(0, 0, 0, 0.87);
}

.account-container {
  padding: 20px;
  margin: 20px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.account-container .account-information {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.account-container .account-information-title h3 {
  color: #210854;
}
.account-container .detail {
  color: #210854;
  font-weight: 500;
  font-size: 16px;
}
.account-container button {
  background-color: transparent;
}
.account-container button .mdc-button__label {
  color: #866dff;
}
.account-container .info {
  color: rgba(13, 2, 35, 0.5607843137);
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
}
.account-container .divider {
  width: 100%;
  border-bottom: rgba(33, 8, 84, 0.32) 1px solid;
  margin-top: 20px;
  margin-bottom: 20px;
}
.account-container .card-input {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: #210854;
  border: 1px solid #210854 !important;
  padding-left: 10px;
}

.account-modal {
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.account-modal .account-information {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.account-modal .account-information-title h3 {
  color: #210854;
}
.account-modal .detail {
  color: #210854;
  font-weight: 500;
  font-size: 16px;
}
.account-modal button {
  background-color: transparent;
}
.account-modal button .mdc-button__label {
  color: #866dff;
}
.account-modal .info {
  color: rgba(13, 2, 35, 0.5607843137);
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
}
.account-modal .divider {
  width: 100%;
  border-bottom: rgba(33, 8, 84, 0.32) 1px solid;
  margin-top: 20px;
  margin-bottom: 20px;
}
.account-modal .card-input {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: #210854;
  border: 1px solid #210854 !important;
  padding-left: 10px;
}

.agreement-text {
  margin-left: 20px !important;
  color: rgba(33, 8, 84, 0.56) !important;
  font-size: 14px;
}

.blackboard-wrap {
  height: 100vh;
  background-color: #dfdee5;
  overflow: hidden;
}

.mini-tile-wrap {
  height: 100vh;
  background-color: #dfdee5;
}

.blackboard_footer {
  background-color: white;
  touch-action: none;
  top: 0;
}
.blackboard_footer .line {
  height: 62px;
  border-bottom: 2px solid rgba(33, 8, 84, 0.32);
}

.account_ht3 {
  color: #210854;
}

.files_search_container {
  background-color: #dfdee5;
}

.file-container {
  background-color: #dfdee5;
}
.file-container .upload_info {
  color: rgba(13, 2, 35, 0.5607843137);
}
.dark-theme .file-container .upload_info {
  color: white !important;
}

.bg_transparent_dark_salmon {
  background-color: transparent;
  color: #ff7463 !important;
}

.bg_salmon_btn {
  background-color: #ffb5a0 !important;
}
.bg_salmon_btn .mdc-button__label {
  color: #210854;
}

.bg_transparent_light_purple {
  background-color: transparent;
}
.bg_transparent_light_purple .mdc-button__label {
  color: #866dff;
}

.modal-container .header {
  color: #210854;
}

.workbook-body app-workbook-builder-words .button-container .find-words-button {
  background: #866DFF !important;
  color: white !important;
}
.workbook-body app-workbook-builder-words .button-container .save-close {
  color: #866DFF !important;
}
.workbook-body .mat-mdc-tab-header {
  border: none;
  border-bottom: solid 1px rgba(134, 109, 255, 0.48);
}
.workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container {
  background-color: white;
}
.workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 1;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}
.workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
.workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
}
body:not(.dark-theme) .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #481e9f !important;
}

.file-content {
  background: white;
}
.file-content .file-header h2 {
  color: #210854;
  margin: 20px 0 8px;
}
.file-content .description {
  color: rgba(13, 2, 35, 0.5607843137);
}
.file-content:not(:first-child) {
  border-top: solid 1px #f1edf8;
}
.dark-theme .file-content:not(:first-child) {
  border-top: 1px solid rgba(241, 237, 248, 0.12) !important;
}
.file-content:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.file-content:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.file-card-container {
  border: solid 1px #291f4f;
  color: black;
}
.file-card-container span:first-child {
  color: rgba(0, 0, 0, 0.87);
}
.file-card-container button {
  background-color: transparent;
}
.file-card-container button .mdc-button__label {
  color: rgba(0, 0, 0, 0.87);
}
.file-card-container .button-active {
  background-color: #866dff;
  border: 1px solid #866dff;
  height: 35px;
}
.file-card-container .button-active .mdc-button__label {
  color: white;
}
.file-card-container .button-inactive {
  background-color: transparent;
}
.file-card-container .button-inactive .mdc-button__label {
  color: rgba(0, 0, 0, 0.87);
}

.page_tutorials {
  background: #dfdee5;
}

.mat-drawer-content {
  background-color: #dfdee5;
}

.sharing_modal_delete .mat-mdc-dialog-title {
  color: #210854;
}
.sharing_modal_delete .mat-dialog-content p {
  color: rgba(13, 2, 35, 0.5607843137);
  margin: 0 0 0;
}

.TutorialDialog_modal .mat-mdc-dialog-title {
  color: #210854 !important;
  padding: 24px 24px 19px;
}

.sentences-search-box {
  background-color: white;
}
.sentences-search-box input {
  color: rgba(0, 0, 0, 0.87);
}

.word_group_bank h2 {
  color: #210854;
}
.word_group_bank table .card-text {
  color: #210854 !important;
}

.word_group_bank_menu_item .mat-menu-item {
  color: #210854;
}

.file_card_menu .mat-menu-item {
  color: #210854;
}

.words_tile_box {
  background-color: transparent !important;
  border: 1px solid rgba(33, 8, 84, 0.32) !important;
}
.words_tile_box .mdc-button__label {
  color: #210854 !important;
}

.sentence_example_box {
  background-color: transparent !important;
  border: 1px solid rgba(33, 8, 84, 0.32) !important;
}
.sentence_example_box .mdc-button__label {
  color: #210854 !important;
}

.edit_words_tile_mattab .mdc-tab__content {
  color: #481e9f;
}
.edit_words_tile_mattab .mat-mdc-tab-body-content {
  overflow: hidden;
}
.edit_words_tile_mattab .mat-mdc-tab-label-container {
  background-color: white;
}
.edit_words_tile_mattab .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 0.6;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.edit_words_tile_mattab .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}
.edit_words_tile_mattab .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
.edit_words_tile_mattab .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #866dff !important;
}

.pdf-right-control {
  color: #210854;
}

.input-divider {
  color: rgba(13, 2, 35, 0.56);
}

.same_plan_title {
  color: #210854;
}

.same_plan_content {
  color: rgba(13, 2, 35, 0.5607843137);
  font-weight: 500;
}

.demoplan_content {
  color: rgba(13, 2, 35, 0.5607843137);
  font-weight: 500;
}

.add_card_menu_h3 {
  color: #210854;
}

.focus-theme .side-container {
  border-right: solid 1px rgba(134, 109, 255, 0.48);
}

.delete_file_dialog .popup-header h1 {
  color: #210854;
}

.filter-card-tiles-header .search-box span {
  color: rgba(0, 0, 0, 0.72);
}
.filter-card-tiles-header .search-box input {
  color: rgba(0, 0, 0, 0.72);
}

.quick_container_h3 {
  color: #210854;
  font-size: 16px;
}

.pairing-section-container-span {
  color: #210854;
}

.pairing-section-container-h3 {
  color: #210854;
  margin-top: 0 !important;
}

.mat-bottom-sheet-container {
  background-color: #481e9f !important;
}

input.editable-text {
  color: gray;
  font-weight: normal;
  border: none;
  outline: none;
}

.hidden-btn {
  visibility: hidden;
}

mat-mdc-list-item.selected {
  background: #EEEEEE;
}
mat-mdc-list-item.selected.dark-mode {
  background: #866DFF;
}

.add-course.course-name .mat-mdc-form-field-wrapper, .for-internal-container .mat-mdc-form-field-wrapper {
  height: 56px;
}
.add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex, .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
  height: 100%;
}
.add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-label-wrapper, .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-label-wrapper {
  top: -16px;
}
.add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-infix, .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-infix {
  padding: 0.5em 0;
}
.add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-wrapper, .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-wrapper {
  margin: 0;
}
.add-course.course-name mat-label, .for-internal-container mat-label {
  color: #0d0223;
  opacity: 0.56;
}
.dark-theme .add-course.course-name mat-label, .dark-theme .for-internal-container mat-label {
  color: white !important;
}
.dark-theme .add-course.course-name input, .dark-theme .for-internal-container input {
  color: white;
}

.add-course.course_description .mat-mdc-form-field-infix {
  padding: 11px 0;
}
.add-course.course_description textarea {
  resize: none;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: normal;
}
.add-course.course_description textarea.mat-input-element {
  margin: 0;
}
.add-course.course_description .mat-mdc-form-field-wrapper {
  margin: 0;
}
.add-course.course_description mat-label {
  color: #0d0223;
  opacity: 0.56;
}
.dark-theme .add-course.course_description mat-label {
  color: white !important;
}
.dark-theme .add-course.course_description input {
  color: white;
}

.dark-theme .add_cousre_modal_subscription {
  color: white !important;
}

.custom-confirm-dialog {
  width: 360px;
}
.custom-confirm-dialog .mat-mdc-dialog-container {
  display: flex;
}
.custom-confirm-dialog .mat-mdc-dialog-container > * {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: #210854;
  margin-bottom: 0;
  padding: 19px 24px;
  letter-spacing: normal;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: white !important;
}
.custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content {
  color: rgba(13, 2, 35, 0.56);
  margin: 0;
  padding: 4px 24px;
  letter-spacing: normal;
  line-height: normal;
  overflow-x: hidden;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content {
  color: white !important;
}
.custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content.show-scores-dialog-content {
  margin-top: 8px;
  align-self: flex-start;
}
.custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content.show-scores-dialog-content .value {
  font-weight: 500;
  margin-left: 8px;
}
.custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  min-height: unset;
  padding: 0 14px 10px;
  justify-content: flex-end;
}
.custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .confirm-btn {
  padding: 10px;
  line-height: unset;
  background-color: transparent !important;
}
.custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .delete-btn {
  padding: 10px;
  line-height: unset;
  background-color: transparent !important;
}
.custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .delete-btn .mdc-button__label {
  color: var(---mdc-text-button-label-text-color);
  font-weight: bold !important;
}
.custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions button {
  padding: 10px;
  line-height: unset;
  background-color: transparent !important;
}
.custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .mdc-button__label {
  font-weight: bold !important;
}

.add-course-item .mdc-list-item__primary-text {
  color: #866DFF !important;
  letter-spacing: normal;
}

.side_bar_display mat-expansion-panel-header > span {
  display: none;
}

.custom-form-field label.mat-mdc-form-field-label {
  font-size: 13px;
  background-color: white;
  padding: 5px 10px;
  width: auto !important;
  top: 21px;
  left: 16px;
}
.dark-theme .custom-form-field label.mat-mdc-form-field-label {
  background-color: #18112f !important;
}

.word-selection-list .mdc-list-item__content {
  flex-direction: row !important;
}
.dark-theme .word-selection-list .mat-list-text {
  color: white;
}
.word-selection-list .mat-pseudo-checkbox {
  margin-right: 8px;
  border: 2px solid #866DFF;
  color: #866DFF;
}
.word-selection-list .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  color: #866DFF;
  border-color: #866DFF;
}

mat-mdc-checkbox.whizzimo-mat-mdc-checkbox {
  color: #210854;
}
mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mat-mdc-checkbox-frame {
  border-color: #866dff;
}
mat-mdc-checkbox.whizzimo-mat-mdc-checkbox:not(.mat-mdc-checkbox-checked) .mat-mdc-checkbox-frame {
  border-color: rgba(33, 8, 84, 0.24);
}
mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mdc-checkbox__checkmark-path {
  stroke: #866dff;
}

.dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox {
  color: white !important;
}
.dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mat-mdc-checkbox-frame {
  border-color: white !important;
}
.dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox:not(.mat-mdc-checkbox-checked) .mat-mdc-checkbox-frame {
  border-color: rgba(211, 211, 211, 0.3) !important;
}
.dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mdc-checkbox__checkmark-path {
  stroke: white !important;
}

.dark-theme .sort-tile-btn {
  color: #866dff !important;
}

.example-box.letter-tile {
  min-width: 72px;
  height: 64px !important;
  padding-left: 8px;
  padding-right: 12px;
}
.example-box.letter-tile .mdc-button__label {
  padding-left: 24px !important;
  font-size: 32px !important;
}
.example-box.letter-tile .mdc-button__label::before {
  top: 10px !important;
}
.example-box.letter-tile .mdc-button__label > span {
  display: inline-block;
  min-width: 26px;
  text-align: left;
}

.example-box.words_tile_box {
  min-width: 72px;
  height: 56px !important;
  padding-left: 8px;
  padding-right: 12px;
}
.example-box.words_tile_box .mdc-button__label {
  padding-left: 24px !important;
  display: inline-block;
  min-width: 26px;
  text-align: left;
}

.activity-content-label {
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  width: 160px;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #866dff;
  border-bottom: 2px solid #866dff;
  opacity: 1 !important;
  margin-bottom: 24px;
}

.text-holder::before {
  background-image: url("assets/img/theme1/move-modal.png");
}

.course-workbook-edit-workbook-items-container .drag-n-drop-zone {
  display: inline-block;
  margin-right: 10px;
  width: 100%;
  min-height: 60px;
  position: relative;
}
.course-workbook-edit-workbook-items-container .drag-n-drop-zone .text-button {
  position: absolute;
}
.course-workbook-edit-workbook-items-container .drag-n-drop-zone .tile-button {
  margin-right: 8px;
  margin-bottom: 8px;
  min-height: 34px;
}

.vertical-center {
  display: flex !important;
  align-items: center;
}

:not(.dark-theme) mat-tab-group .mdc-tab__content .mdc-tab__text-label {
  color: #481e9f;
}
.dark-theme mat-tab-group .mdc-tab__content .mdc-tab__text-label {
  color: white !important;
}
.dark-theme mat-tab-group .mat-ink-bar {
  background-color: white !important;
}

.search-input-box input {
  font-size: 16px;
  padding-right: 16px !important;
}
.search-input-box input::placeholder {
  color: rgba(33, 8, 84, 0.32) !important;
}

.input-error-message {
  font-size: 12px;
  text-align: left;
  margin: 0 13px;
  color: #ff8a65;
}

.mat-mdc-button[disabled=true] {
  border-color: gray !important;
  color: gray !important;
}
.mat-mdc-button[disabled=true] .mdc-button__label {
  color: #a6a6a6 !important;
}

.mat-mdc-form-field-invalid .mat-mdc-form-field-outline-thick {
  color: #f44336;
}

.workbook-name-modal .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
  padding: 0;
}

.rename-file-dialog .mat-mdc-form-field-wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}

.words-sort-label {
  font-size: 14px;
  color: #866dff;
}

.course-workbook-edit-tiles-container .top-tiles-container {
  max-height: 210px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 64px;
}
.course-workbook-edit-tiles-container .top-tiles-container .top-tile:last-child {
  flex: 1;
}

.more_tiles_modal_activity.disabled {
  color: gray;
  cursor: not-allowed;
}

.tile-updating-options {
  width: 360px;
}

.word-updating-options {
  width: 560px;
}

span.disabled, img.disabled {
  color: gray !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}

mat-mdc-form-field.disabled .mat-mdc-form-field-infix {
  border-color: gray !important;
}
mat-mdc-form-field.disabled .mat-mdc-form-field-infix input {
  color: gray !important;
}

.mat-button-disabled {
  color: gray !important;
  border-color: gray !important;
}

.label.disabled {
  color: gray !important;
}

.workbook-edit-menu-dialog {
  max-height: 900px;
  max-width: 88vw !important;
}
.workbook-edit-menu-dialog .mat-mdc-dialog-container {
  padding: 24px;
  overflow: hidden;
}

.course-workkbook-edit-words-table-container {
  overflow: auto;
  max-height: 200px;
}

app-course-workbook-edit-menu app-course-workbook-edit .long-card {
  cursor: pointer;
  padding: 0 !important;
}

.shuffle_tiles {
  border-radius: 4px !important;
  border: 1px solid #866dff !important;
  color: #866dff !important;
  width: 94px;
  height: 58px;
}
.shuffle_tiles:disabled {
  color: gray !important;
  border: 1px solid gray !important;
  cursor: default !important;
}

.color-menu .mat-button-toggle-checked {
  background: #f1eeff;
}

.letter-card-dialog {
  overflow: hidden;
}
.letter-card-dialog .mat-mdc-dialog-container {
  padding: 0;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  overflow: hidden;
  border: 0 !important;
}
.letter-card-dialog .mat-dialog-content {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.mat-mdc-menu-panel.mat-menu-color {
  overflow: hidden;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 1em 0 1em 0;
}

.mat-mdc-form-field-error-wrapper {
  font-size: 75%;
}

.mdc-list {
  padding: 0px !important;
}

.mat-mdc-list-base .mat-mdc-list-item {
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  padding: 0;
}
.mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__primary-text {
  display: flex;
  flex-direction: column;
  letter-spacing: normal;
}

.mat-mdc-dialog-container {
  border-radius: 4px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden !important;
  box-shadow: none !important;
}
.mat-mdc-dialog-container .mat-mdc-dialog-actions .mdc-button__label {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #866dff;
}

.mat-form-field-invalid .mdc-notched-outline--upgraded {
  color: #f44336 !important;
}

.mat-form-field-invalid .mat-mdc-floating-label {
  color: rgba(0, 0, 0, 0.6) !important;
}

.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  color: #210854 !important;
}

.mat-form-field-appearance-outline .mdc-form-field-outline {
  color: rgba(33, 8, 84, 0.18);
}

.mat-mdc-form-field-should-float .mdc-form-field-outline {
  color: #210854 !important;
}

.mdc-text-field .mdc-floating-label {
  top: 1.84375em !important;
  margin-top: -0.25em;
}

.mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border: none;
  border-radius: 50%;
}

.mat-mdc-tab .mdc-tab__text-label {
  justify-content: center;
  white-space: nowrap;
  letter-spacing: normal;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: normal;
}
.mat-mdc-option .mat-pseudo-checkbox {
  display: none;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  border-color: #866dff !important;
  background-color: white !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #866dff !important;
}

.mdc-dialog__title::before {
  height: auto !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #e0e0e0;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}

.dark-theme {
  /* You can add global styles to this file, and also import other style files */
  @import "indigo-pink.css";
  /*= All Possible Tile Colors
  ========================================*/
  /*= All Possible Tile Font Colors
  ========================================*/
  /*= All Possible Tile Colors
  ========================================*/
  /*= All Possible Tile Colors
  ========================================*/
  /* ===setting header ===*/
  /* ===setting header ===*/
  /* === General setting page ===*/
  /* === Geberal setting page ===*/
  /*======= side nav bar ===*/
  /*======= side nav bar ===*/
  /*======= activity page header ===*/
  /*======= activity page header ===*/
  /*======= blackboard page ===*/
  /*======= blackboard page ===*/
  /*======= sentence page ===*/
  /*======= sentence page ===*/
  /*======= PDF page ===*/
  /*======= PDF page ===*/
  /*======= Tile layout ===*/
  /*======= Tile layout ===*/
  /*======= activity header resizable handle  ===*/
  /*======= activity header resizable handle  ===*/
  /*======= whiteboard page  ===*/
  /*======= whiteboard page  ===*/
  /*======= phrases page  ===*/
  /*======= phrases page  ===*/
  /*======= Spelling page  ===*/
  /*======= Spelling page  ===*/
  /*======= workbook page  ===*/
  /*======= workbook page  ===*/
  /*======= tilelayout page  ===*/
  /*======= tilelayout page  ===*/
  /*======= flashcard page  ===*/
  /*======= flashcard page  ===*/
  /*======= mini tile page  ===*/
  /*======= mini tile page  ===*/
  /*======= blank tile page  ===*/
  /*======= mini tile page  ===*/
  /*======= passage page  ===*/
  /*======= passage page  ===*/
  /*======= time reading page  ===*/
  /*====  Time reading modalbox   ====*/
  /*====  Time reading modalbox   ====*/
  /*====  score modalbox   ====*/
  /*====  score modalbox   ====*/
  /*==== display score modalbox   ====*/
  /*==== display score modalbox   ====*/
  /*====  activity-common-modalbox  ====*/
  /*====  activity-common-modalbox  ====*/
  /*==== course list modalbox   ====*/
  /*==== course list modalbox   ====*/
  /*==== missing tiles modalbox   ====*/
  /*==== missing tiles modalbox   ====*/
  /*==== small modalbox   ====*/
  /*==== small modalbox   ====*/
  /*==== scrollbar   ====*/
  /* Track */
  /* Handle */
  /* Handle on hover */
  /* Track */
  /* Handle */
  /* Handle on hover */
  /*==== scrollbar   ====*/
  /*==== curriculum   ====*/
  /*==== curriculum   ====*/
  /*====  work book menu modalbox  ====*/
  /*====  work book menu modalbox  ====*/
  /*====  manage course modalbox  ====*/
  /*====  manage course modalbox  ====*/
  /*====  wordlist modalbox  ====*/
  /*====  wordlist modalbox  ====*/
  /*==== tutorial dialog modalbox  ====*/
  /*====  tutorial dialog modalbox  ====*/
  /*=== sharing page  ====*/
  /*===  sharing page  ====*/
  /*===  sharing page  ====*/
  /*===  Account  ====*/
  /*===  Account  ====*/
  /*===  add credit card modalbox  ====*/
  /*===  add credit card modalbox  ====*/
  /*===  successful-update-modalbox  ====*/
  /*===  successful-update-modalbox  ====*/
  /*===  selected-plan-modalbox  ====*/
  /*===  selected-plan-modalbox  ====*/
  /*====  cancel-subscriptiton-modalbox  ====*/
  /*====  cancel-subscriptiton-modalbox  ====*/
  /*===  add-course-modalbox  ===*/
  /*===  add-course-modalbox  ===*/
  /* == setting page ==*/
  /* == setting page ==*/
  /* == tile page  ==*/
  /* == tile page ==*/
  /* == edit lesson plan page ==*/
  /*== edit lesson plan page ==*/
  /*== lond card Forms==*/
  /*== lond card Forms==*/
  /*== registration  page  ==*/
  /*== registration  page  ==*/
}
.dark-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.dark-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.dark-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.dark-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .dark-theme .mat-ripple-element {
  display: none;
}

.dark-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .dark-theme .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.dark-theme .cdk-overlay-container, .dark-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.dark-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.dark-theme .cdk-overlay-container:empty {
  display: none;
}
.dark-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.dark-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.dark-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.dark-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .dark-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.dark-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.dark-theme .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.dark-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.dark-theme .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.dark-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.dark-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.dark-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.dark-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.dark-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.dark-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.dark-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.dark-theme .mat-focus-indicator {
  position: relative;
}
.dark-theme .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.dark-theme .mat-focus-indicator:focus::before {
  content: "";
}
.dark-theme .cdk-high-contrast-active .dark-theme {
  --mat-focus-indicator-display: block;
}
.dark-theme .mat-mdc-focus-indicator {
  position: relative;
}
.dark-theme .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.dark-theme .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.dark-theme .cdk-high-contrast-active .dark-theme {
  --mat-mdc-focus-indicator-display: block;
}
.dark-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.dark-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.dark-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.dark-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .dark-theme .mat-ripple-element {
  display: none;
}

.dark-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .dark-theme .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.dark-theme .cdk-overlay-container, .dark-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.dark-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.dark-theme .cdk-overlay-container:empty {
  display: none;
}
.dark-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.dark-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.dark-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.dark-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .dark-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.dark-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.dark-theme .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.dark-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.dark-theme .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.dark-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.dark-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.dark-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.dark-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.dark-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.dark-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.dark-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.dark-theme .mat-focus-indicator {
  position: relative;
}
.dark-theme .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.dark-theme .mat-focus-indicator:focus::before {
  content: "";
}
.dark-theme .cdk-high-contrast-active .dark-theme {
  --mat-focus-indicator-display: block;
}
.dark-theme .mat-mdc-focus-indicator {
  position: relative;
}
.dark-theme .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.dark-theme .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.dark-theme .cdk-high-contrast-active .dark-theme {
  --mat-mdc-focus-indicator-display: block;
}
.dark-theme .card {
  float: left;
  margin: 20px;
  padding: 10px;
  background-color: #e6e9eb;
}
.dark-theme .card .quickstart {
  width: 150px;
  float: right;
}
.dark-theme .long-card {
  margin: 20px;
  padding: 10px;
  background-color: #e6e9eb;
}
.dark-theme .long-card .quickstart {
  width: 150px;
  float: right;
}
.dark-theme .title {
  font-size: 14px !important;
}
.dark-theme .card-title {
  font-weight: 700;
  padding: 5px;
  color: #45506d;
}
.dark-theme .card-sub-title {
  color: #FF8F2C;
}
.dark-theme .sub-text {
  font-size: 1em;
  color: #717171;
}
.dark-theme .card-footer {
  padding: 10px;
}
.dark-theme .centered {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.dark-theme .tile {
  width: 8vh;
  height: 8vh;
  font-size: 0.8em;
  float: left;
}
.dark-theme .grid-tile {
  width: 8vw;
  height: 8vh;
}
.dark-theme .new-workbook {
  height: 150px;
  width: 223px;
  margin: 10px;
  padding: 16px;
  color: #666;
  border: 3px #666 dashed;
  border-radius: 5px;
  cursor: pointer;
}
.dark-theme .workbooks-code-circle {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  font-size: 11px;
  font-weight: 800;
  color: #666;
  border: 1px solid #bbb;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  margin-right: 2px;
}
.dark-theme .workbook-card-content-wrapper {
  position: absolute;
  height: 100%;
}
.dark-theme .workbook-card-settings {
  position: relative;
  height: 30px;
  width: 30px;
  padding: 4px 6px;
  margin: 5px;
  border-radius: 50%;
  border: 1px solid #CCC;
  bottom: 20px;
  left: 55%;
}
.dark-theme .workbooks-code-wrapper {
  position: absolute;
  bottom: 18px;
  left: 5px;
}
.dark-theme .mat-mdc-list-base .mat-mdc-list-item .mat-line {
  word-wrap: break-word;
  white-space: pre-wrap;
}
.dark-theme .menu-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}
.dark-theme .menu-btn {
  color: #888888;
  margin-bottom: 10px;
}
.dark-theme .btn-cancel {
  background-color: lightgrey;
  font-size: smaller;
  font-weight: bold;
  color: black;
  width: 100%;
}
.dark-theme .words-spinner {
  position: absolute;
  color: #485473;
  padding: 30%;
  margin-top: 100%;
}
.dark-theme .selectable-tile {
  margin: 5px;
}
.dark-theme .file-group-card, .dark-theme .word-group-item-card {
  margin: 10px;
  position: relative;
}
.dark-theme .word-group-item-card-content {
  padding: 10px;
  font-size: larger;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.dark-theme .c-white {
  background: white;
}
.dark-theme .c-red {
  background: #d34755; /* Old browsers */
  background: -moz-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d34755), color-stop(100%, #ab1f2c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* IE10+ */
  background: linear-gradient(to bottom, #d34755 0%, #ab1f2c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#d34755", endColorstr="#ab1f2c",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-yellow {
  background: #ffd456; /* Old browsers */
  background: -moz-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffd456), color-stop(100%, #ffc600)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ffd456 0%, #ffc600 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffd456", endColorstr="#ffc600",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-lightyellow {
  background: rgb(255, 237, 177); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(255, 237, 177)), color-stop(100%, rgb(255, 225, 130))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffedb1", endColorstr="#ffe182",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-green {
  background: #48ad5e; /* Old browsers */
  background: -moz-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #48ad5e), color-stop(100%, #2c8b3c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* IE10+ */
  background: linear-gradient(to bottom, #48ad5e 0%, #2c8b3c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#48ad5e", endColorstr="#2c8b3c",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-lightgreen {
  background: rgb(206, 230, 156); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(206, 230, 156)), color-stop(100%, rgb(193, 229, 114))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#cee69c", endColorstr="#c1e572",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-blue {
  background: #4c72aa; /* Old browsers */
  background: -moz-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4c72aa), color-stop(100%, #1f57ab)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* IE10+ */
  background: linear-gradient(to bottom, #4c72aa 0%, #1f57ab 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#4c72aa", endColorstr="#1f57ab",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-lightblue {
  background: rgb(156, 196, 229); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(156, 196, 229)), color-stop(100%, rgb(129, 183, 226))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#9cc4e5", endColorstr="#81b7e2",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-peach {
  background: rgb(255, 209, 196); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(255, 209, 196)), color-stop(100%, rgb(255, 179, 158))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffd1c4", endColorstr="#ffb39e",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-purple {
  background: #696493; /* Old browsers */
  background: -moz-linear-gradient(top, #696493 0%, #504795 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #696493), color-stop(100%, #504795)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #696493 0%, #504795 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #696493 0%, #504795 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #696493 0%, #504795 100%); /* IE10+ */
  background: linear-gradient(to bottom, #696493 0%, #504795 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#696493", endColorstr="#504795",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-lightpurple {
  background: rgb(239, 196, 255); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(239, 196, 255)), color-stop(100%, rgb(228, 160, 255))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#efc4ff", endColorstr="#e4a0ff",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-orange {
  background: #ff9951; /* Old browsers */
  background: -moz-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff9951), color-stop(100%, #ff872c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ff9951 0%, #ff872c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ff9951", endColorstr="#ff872c",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-gray {
  background: #898881; /* Old browsers */
  background: -moz-linear-gradient(top, #898881 0%, #6e6d67 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #898881), color-stop(100%, #6e6d67)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #898881 0%, #6e6d67 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #898881 0%, #6e6d67 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #898881 0%, #6e6d67 100%); /* IE10+ */
  background: linear-gradient(to bottom, #898881 0%, #6e6d67 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#898881", endColorstr="#6e6d67",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-cream {
  background: #f4f1de; /* Old browsers */
  background: -moz-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f4f1de), color-stop(100%, #e1dec9)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* IE10+ */
  background: linear-gradient(to bottom, #f4f1de 0%, #e1dec9 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f4f1de", endColorstr="#e1dec9",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-pink {
  background: rgb(244, 90, 206); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(244, 90, 206)), color-stop(100%, rgb(247, 66, 202))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f45ace", endColorstr="#f742ca",GradientType=0 ); /* IE6-9 */
}
.dark-theme .c-transparent {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  border: none;
}
.dark-theme .c-clear {
  background: rgba(0, 0, 0, 0);
  box-shadow: none !important;
  border: none !important;
  /*display: none;*/
  visibility: hidden;
}
.dark-theme .header-tab-text {
  font-size: larger;
  margin-left: 20px;
}
.dark-theme .cursor-pointer {
  cursor: pointer;
}
.dark-theme .fc-black {
  color: #1b1b1b !important;
}
.dark-theme .fc-gray {
  color: #898881 !important;
}
.dark-theme .fc-white {
  color: #ffffff !important;
}
.dark-theme .fc-red {
  color: #EC1414 !important;
}
.dark-theme .tile-query-window {
  background-color: white;
  position: relative;
  z-index: 2;
  min-width: 25%;
  max-width: 75%;
  border: 2px #485473 solid;
  opacity: 0.9;
  border-radius: 5px;
}
.dark-theme .whizzi-button-small, .dark-theme .whizzi-button-medium, .dark-theme .whizzi-button-large {
  border-radius: 10px;
  color: white;
  background-color: #FF8F2C;
  margin-right: 20px !important;
  cursor: pointer;
}
.dark-theme .whizzi-button-large {
  padding: 10px 40px;
  font-size: 25px;
}
.dark-theme .whizzi-button-medium {
  padding: 15px 20px;
  font-size: 15px;
}
.dark-theme .whizzi-button-small {
  padding: 5px 10px;
  font-size: 15px;
}
.dark-theme .file-options, .dark-theme .file-name-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  opacity: 0.7;
  border-radius: 0 0 4px 4px;
  color: white;
}
.dark-theme .file-name-container {
  overflow-wrap: break-word;
  max-width: 100%;
  color: white;
  padding: 5px;
}
.dark-theme .file-options {
  visibility: hidden;
  height: 100%;
  text-align: center;
}
.dark-theme .file-option-workbook, .dark-theme .file-option {
  padding: 10px;
  cursor: pointer;
}
.dark-theme .file-option {
  height: 50%;
}
.dark-theme .file-option-workbook {
  height: 100%;
  padding-top: 50%;
}
.dark-theme .file-group-card:hover .file-options {
  visibility: visible;
}
.dark-theme .file-group-card:hover .file-name-container {
  visibility: hidden;
}
.dark-theme mat-grid-list {
  border: white 1px solid;
}
.dark-theme .settings-dropdown {
  padding-top: 10px;
  padding-right: 35px;
}
.dark-theme .settings-dropdown:not(:first-child) {
  padding-top: 32px;
}
.dark-theme .mat-card .style-card {
  padding: 0 !important;
}
.dark-theme .style-card-type {
  margin: 50px;
  padding: 20px;
  font-size: larger;
  text-align: center;
  font-weight: bolder;
  border-radius: 5px;
  border: black solid 2px;
}
.dark-theme .account-detail-label {
  font-weight: bold;
  margin-bottom: 10px;
}
.dark-theme .account-detail-text {
  font-weight: normal;
  margin-left: 10px;
}
.dark-theme .plan-price-container {
  text-align: center;
  margin-bottom: 25px;
}
.dark-theme .plan-price-text {
  font-size: xx-large;
}
.dark-theme .price-plan-detail-container {
  text-align: center;
  margin-bottom: 15px;
}
.dark-theme .price-plan-details-text {
  font-size: 15px;
  color: #97A5AE;
  font-weight: 500;
}
.dark-theme ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
.dark-theme ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.dark-theme .activity-canvas-disabled {
  pointer-events: none;
}
.dark-theme .activity-canvas-draw {
  cursor: url("/assets/svgs/regular/draw-cursor.svg"), auto;
}
.dark-theme .activity-canvas-draw.dark-theme {
  cursor: url("/assets/svgs/regular/draw-cursor-white.svg"), auto;
}
.dark-theme .activity-canvas-eraser {
  cursor: url("/assets/svgs/regular/eraser-cursor.svg") 10 10, auto;
}
.dark-theme .activity-canvas-eraser.dark-theme {
  cursor: url("/assets/svgs/regular/eraser-cursor-white.svg") 10 10, auto;
}
.dark-theme .activity-canvas-text-64 {
  cursor: url("/assets/svgs/regular/text-cursor-64px.svg") 32 59, auto;
}
.dark-theme .activity-canvas-text-64.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-64px-white.svg") 32 59, auto;
}
.dark-theme .activity-canvas-text-48 {
  cursor: url("/assets/svgs/regular/text-cursor-48px.svg") 24 44, auto;
}
.dark-theme .activity-canvas-text-48.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-48px-white.svg") 24 44, auto;
}
.dark-theme .activity-canvas-text-40 {
  cursor: url("/assets/svgs/regular/text-cursor-40px.svg") 20 36, auto;
}
.dark-theme .activity-canvas-text-40.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-40px-white.svg") 20 36, auto;
}
.dark-theme .activity-canvas-text-32 {
  cursor: url("/assets/svgs/regular/text-cursor-32px.svg") 16 28, auto;
}
.dark-theme .activity-canvas-text-32.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-32px-white.svg") 16 28, auto;
}
.dark-theme .activity-canvas-text-24 {
  cursor: url("/assets/svgs/regular/text-cursor-24px.svg") 12 22, auto;
}
.dark-theme .activity-canvas-text-24.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-24px-white.svg") 12 22, auto;
}
.dark-theme .mat-list-item-disabled.activity-menu-item {
  cursor: not-allowed !important;
}
.dark-theme .mat-list-item-disabled.activity-menu-item .mat-list-text > span {
  color: lightgray !important;
}
.dark-theme .mat-list-item-disabled.activity-menu-item.dark-mode {
  opacity: 40% !important;
}
.dark-theme .canvas-container canvas {
  border-color: transparent !important;
}
.dark-theme .canvas-pad-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: rgba(255, 255, 255, 0.44);
}
.dark-theme .workbook-activity-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.dark-theme .workbook-activity-container .activity-header {
  z-index: 21;
}
.dark-theme .workbook-activity-container .activity-header .header_activity_top {
  position: relative;
}
.dark-theme .workbook-activity-container #canvas-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.dark-theme .workbook-activity-container #canvas-container .pad-canvas {
  transition-duration: 0.5s;
  background: rgba(255, 255, 255, 0.15);
  position: absolute;
  z-index: 11;
  opacity: 0;
}
.dark-theme .workbook-activity-container #canvas-container .main-canvas-container, .dark-theme .workbook-activity-container #canvas-container .main-canvas {
  position: absolute;
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: center;
}
.dark-theme .workbook-activity-container #tileBank {
  height: 400px;
  min-height: 100px;
  overflow: hidden;
}
.dark-theme .workbook-activity-container #tileBank table {
  margin: auto;
}
.dark-theme .workbook-activity-container .blank_tile {
  margin-top: 0;
  height: unset;
}
.dark-theme .workbook-activity-container .blank_tile table {
  padding-top: 40px;
  padding-bottom: 40px;
}
.dark-theme .workbook-activity-container .ng-resizable-handle {
  bottom: 0% !important;
  left: 96% !important;
}
.dark-theme .workbook-activity-container .tiles-container {
  height: 100%;
  background: white;
  padding-bottom: 16px;
}
.dark-theme .workbook-activity-container .new-tile-container {
  overflow: auto;
  height: 100%;
  padding: 16px 0;
}
.dark-theme .blackboard-activity-container #tileDropzone {
  position: relative;
  top: -16px;
}
.dark-theme .blackboard-activity-container #canvas-container {
  background: white;
}
.dark-theme .workbook-tiles-activity-container #tileDropzone {
  position: relative;
  top: -16px;
  height: calc(100vh - 125px);
}
.dark-theme .workbook-tiles-activity-container .workbook_tile_div {
  height: unset !important;
  margin-top: 0 !important;
}
.dark-theme .workbook-tiles-activity-container .workbook-container .textbox-target {
  height: 100vh !important;
}
.dark-theme .wordcards-activity-container .textbox-target {
  padding-top: 16px;
}
.dark-theme .wordcards-activity-container .activity-header {
  z-index: 200;
}
.dark-theme .wordcards-activity-container #canvas-container .pad-canvas {
  z-index: 100 !important;
}
.dark-theme .pdf-viewer-activity-container .pdf-container {
  margin-top: 0 !important;
  padding-top: 32px;
  position: absolute;
}
.dark-theme textarea[data-fabric-hiddentextarea] {
  position: fixed !important;
}
.dark-theme mat-slider .mat-slider-ticks-container {
  background: #8e70ff;
}
.dark-theme mat-slider .mat-slider-thumb {
  border-color: #8e70ff;
  background: #8e70ff;
}
.dark-theme .bg-black {
  background: #000 !important;
}
.dark-theme .bg-white {
  background: #fff !important;
}
.dark-theme .line-warning-message {
  font-size: 12px;
  color: #ff5a5a;
  text-align: end;
}
.dark-theme mat-form-field.wz-select {
  all: initial;
  width: 100%;
}
.dark-theme mat-form-field.wz-select .mat-form-field-label {
  top: 33px;
  left: 16px;
  color: white;
  font-size: 16px;
  width: auto !important;
}
.dark-theme mat-form-field.wz-select.mat-form-field-should-float .mat-form-field-label {
  font-size: 13px;
  background-color: #18112f;
  padding: 5px 10px;
  width: auto;
  top: 21px;
  left: 16px;
}
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #866DFF !important;
}
.dark-theme .dark-theme .mat-mdc-dialog-surface {
  background-color: #0b041e !important;
}
.dark-theme .dark-theme mat-label, .dark-theme .dark-theme .mdc-radio__outer-circle, .dark-theme .dark-theme .mdc-radio__inner-circle, .dark-theme .dark-theme .mdc-text-field__input, .dark-theme .dark-theme .mat-mdc-dialog-content {
  color: rgba(255, 255, 255, 0.6) !important;
  border-color: rgba(255, 255, 255, 0.6) !important;
}
.dark-theme .dark-theme .mat-select-value-text, .dark-theme .dark-theme .mat-mdc-select-value-text, .dark-theme .dark-theme .mdc-form-field {
  color: white !important;
  border-color: white !important;
}
.dark-theme .dark-theme .words_tile_box .mat-button-wrapper {
  color: white !important;
}
.dark-theme .ripple-btn-disabled .mdc-button__ripple {
  display: none !important;
}
.dark-theme .mdc-notched-outline__leading, .dark-theme .mdc-notched-outline__notch, .dark-theme .mdc-notched-outline__trailing {
  border-color: currentColor;
}
.dark-theme .dark-theme .mdc-notched-outline__leading, .dark-theme .dark-theme .mdc-notched-outline__notch, .dark-theme .dark-theme .mdc-notched-outline__trailing {
  border-color: #e0e0e0 !important;
}
.dark-theme .cdk-global-overlay-wrapper:has(mat-dialog-container.mdc-dialog--closing) {
  display: none;
}
.dark-theme mat-radio-button.setting_radio .mdc-label {
  color: #210854;
  font-size: 16px;
}
.dark-theme .dark-theme mat-radio-button.setting_radio .mdc-label {
  color: white !important;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-dialog-container {
  padding: 24px;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-dialog-container .mat-mdc-form-field {
  padding-top: 10px;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-dialog-container .card-name {
  padding-top: 0;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.dark-theme .logout-modal mat-dialog-actions {
  margin-right: 20px;
  margin-bottom: 8px;
}
.dark-theme .logout-modal mat-dialog-content {
  padding-bottom: 0 !important;
}
.dark-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  display: none;
}
.dark-theme .mdc-button__ripple, .dark-theme .mdc-checkbox__ripple {
  display: none;
}
.dark-theme [contenteditable=true] {
  padding: 4px;
  display: block;
}
.dark-theme [contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  opacity: 0.5;
}
.dark-theme .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
}
body:not(.dark-theme) .dark-theme .mdc-tab-indicator__content--underline {
  border-color: #481e9f !important;
}
body.dark-theme .dark-theme .mdc-tab-indicator__content--underline {
  border-color: white !important;
}
.dark-theme .dark-theme .mat-mdc-checkbox .mdc-checkbox__background {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.dark-theme .dark-theme .account-modal button.add-new-card-btn {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.dark-theme .dark-theme .mat-mdc-dialog-container .mdc-dialog__title {
  color: white !important;
}
.dark-theme .dark-theme .mdc-list-item:hover .mdc-list-item__primary-text {
  color: white;
}
.dark-theme .dark-theme .mat-mdc-paginator {
  background: #18112f;
}
.dark-theme .dark-theme .mat-mdc-paginator .mat-mdc-paginator-range-label {
  color: white;
}
.dark-theme .dark-theme .mat-mdc-paginator .mdc-icon-button svg {
  fill: #866dff;
}
.dark-theme .dark-theme .mat-mdc-paginator .mdc-icon-button:disabled svg {
  fill: gray;
}
.dark-theme .mat-mdc-dialog-container .mdc-dialog__content {
  line-height: 20px !important;
}
.dark-theme .dark-theme .mdc-list-item__primary-text {
  color: white;
}
.dark-theme .dark-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: white;
}
.dark-theme .dark-theme .mdc-checkbox__background {
  border-color: white !important;
}
.dark-theme .letter-card-dialog .mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}
.dark-theme .purple-checkbox {
  padding-bottom: 8px !important;
}
.dark-theme .purple-checkbox mat-list-option {
  padding: 0 16px 0 8px !important;
}
.dark-theme .purple-checkbox .delete-btn {
  padding-left: 16px !important;
}
.dark-theme .purple-checkbox .delete-btn .mdc-button__label {
  display: flex !important;
}
.dark-theme .purple-checkbox .mdc-list-item__end {
  margin-left: 0 !important;
}
.dark-theme .collection-name .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme .collection-name .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme .collection-name .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: gray;
  border-width: 1px;
  opacity: 1;
}
.dark-theme .workbook-builder-tab .mat-mdc-tab-body-content {
  height: calc(100% - 58px);
}
.dark-theme html, .dark-theme body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  height: 100%;
  background-color: #291f4f;
}
.dark-theme button {
  letter-spacing: normal !important;
}
.dark-theme .container {
  height: 100%;
  width: 100%;
  position: relative;
}
.dark-theme .mat-mdc-focus-indicator {
  background: transparent;
}
.dark-theme .blanktiles {
  min-height: 100px !important;
}
.dark-theme .flashcard {
  position: absolute;
  font-family: "Arial", sans-serif;
  font-size: 120px;
  line-height: 6rem;
  font-weight: bold;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 20vh;
  min-height: 433px;
  padding: 2.5% 15px;
  flex-shrink: 3;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 0.08);
  color: white;
  background-color: white;
}
@media only screen and (max-width: 1280px) {
  .dark-theme .flashcard {
    height: auto !important;
  }
}
.dark-theme .dark-theme .flashcard {
  background-color: #18112f !important;
}
.dark-theme .flashcard-altered {
  font-family: "Arial", sans-serif;
  font-size: 120px;
  line-height: 6rem;
  font-weight: bold;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 20vh;
  min-height: 433px;
  padding: 2.5% 15px;
  flex-shrink: 3;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 0.08);
  background-color: #18112f;
  color: white;
}
@media only screen and (max-width: 1280px) {
  .dark-theme .flashcard-altered {
    height: auto !important;
  }
}
.dark-theme .mat-mdc-form-field-label {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-progress-bar-fill::after {
  background-color: #866dff;
}
.dark-theme .mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #866dff;
}
.dark-theme .tally-box .tally {
  font-size: 16px !important;
}
.dark-theme .tally-box .tally .tally_index {
  color: white;
}
.dark-theme .char-limit {
  color: #a6a6a6;
  font-size: 12px;
  text-align: left;
  margin-left: 13px;
}
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #866dff;
}
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-mdc-form-field-label {
  color: #866dff;
}
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-mdc-form-field-ripple {
  background-color: #866dff;
}
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-select-arrow {
  color: #866dff !important;
}
.dark-theme .mat-input-element {
  caret-color: #866dff;
}
.dark-theme .mat-button-focus-overlay {
  background-color: transparent !important;
}
.dark-theme .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: $interface-color-light-medium !important;
}
.dark-theme .mat-mdc-progress-spinner circle {
  stroke: #866dff !important;
}
.dark-theme .sproutvideo-playlist {
  width: 100%;
}
.dark-theme .content:not(.mat-drawer-content) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dark-theme .loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.dark-theme .loading .spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}
.dark-theme .bumping-tiles {
  font-size: 1.6837vw;
  border: none;
  position: absolute;
  text-align: center;
  font-weight: bolder;
  border-radius: 4px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-stretch: normal;
  line-height: 60px;
  padding-right: 8px;
  padding-left: 8px;
  min-width: 60px;
  min-height: 60px;
  border: solid 1px rgba(72, 30, 159, 0.24);
}
.dark-theme .bumping-tiles.static-block {
  transition: all 0.3s ease-out;
}
.dark-theme .bumping-tiles-flashcards {
  font-size: 1.6837vw;
  border: none;
  position: absolute;
  text-align: center;
  font-weight: bolder;
  border-radius: 4px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-stretch: normal;
  line-height: 260px;
  padding-right: 10px;
  padding-left: 10px;
  min-width: 60px;
  height: 792px;
  border: solid 1px rgba(72, 30, 159, 0.24);
  margin-left: 5px;
  margin-right: 5px;
}
.dark-theme .bumping-tiles-flashcards.static-block {
  transition: all 0.3s ease-out;
}
.dark-theme .drawerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.dark-theme .btn-default {
  background-color: #866dff;
  font-size: smaller;
  font-weight: bold;
  color: white;
  width: 100%;
}
.dark-theme .plan-description-header {
  width: 100%;
  text-align: center;
  padding: 12px 0;
  margin-bottom: 10px;
  background-color: #F7FAFB;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.56);
  font-size: 19px;
  color: rgb(109, 118, 127);
  border-bottom: 1px solid rgb(234, 236, 238);
}
.dark-theme .dark-theme .plan-description-header {
  background-color: transparent !important;
}
.dark-theme .whizzimo-link {
  color: #866dff;
  cursor: pointer;
}
.dark-theme .ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 300px;
  display: inline-block;
}
.dark-theme .button-selected {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  font-style: italic;
  letter-spacing: 0.4px;
  text-transform: capitalize !important;
  background-color: white !important;
  border: 2px solid #866dff !important;
  border-radius: 4px;
}
.dark-theme .button-selected .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.dark-theme .button-selected .mdc-button__label {
  color: #866dff !important;
}
.dark-theme .fileContainer {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  box-shadow: none !important;
  line-height: 1.5em;
  color: white;
  height: 35px;
  padding: 5px;
}
.dark-theme .fileContainer .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.dark-theme .inactive-fileContainer {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none !important;
  line-height: 1.5em;
  color: rgba(0, 0, 0, 0.87);
  height: 35px;
  padding: 5px;
}
.dark-theme .inactive-fileContainer .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .fileContainer [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
.dark-theme .inactve-fileContainer [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
.dark-theme .red-circle {
  width: 8px;
  height: 8px;
  background: #ff5a5a;
  border-radius: 50px;
  position: absolute;
  right: 4px;
  top: 4px;
}
.dark-theme .x-small-tile {
  min-height: 30px !important;
  min-width: 30px !important;
  line-height: 30px !important;
  font-size: 0.9837vw !important;
}
.dark-theme .small-tile {
  min-height: 40px !important;
  min-width: 40px !important;
  line-height: 40px !important;
  font-size: 20px !important;
}
.dark-theme .medium-tile {
  min-height: 60px !important;
  min-width: 60px !important;
  line-height: 60px !important;
  font-size: 27.5px !important;
}
.dark-theme .large-tile {
  min-height: 80px !important;
  min-width: 80px !important;
  line-height: 80px !important;
  font-size: 32px !important;
}
.dark-theme .x-large-tile {
  min-height: 100px !important;
  min-width: 100px !important;
  line-height: 100px !important;
  font-size: 40px !important;
}
.dark-theme .xx-large-tile {
  min-height: 120px !important;
  min-width: 120px !important;
  line-height: 120px !important;
  font-size: 3.4837vw !important;
}
.dark-theme .xxx-large-tile {
  min-height: 140px !important;
  min-width: 140px !important;
  line-height: 140px !important;
  font-size: 4.0837vw !important;
}
.dark-theme .mat-radio-checked .mat-radio-outer-circle {
  border-color: #8e70ff;
}
.dark-theme .mat-radio-checked .mat-radio-inner-circle {
  background-color: #8e70ff;
}
.dark-theme .shadow {
  border: solid 1px rgba(134, 128, 145, 0.24);
  border-radius: 4px;
  min-width: 60px;
  height: 60px !important;
}
.dark-theme .black-white-text {
  color: white;
}
.dark-theme .purple-text {
  color: #866DFF !important;
}
.dark-theme .purple-checkbox .mat-mdc-checkbox-checked .mat-mdc-checkbox-layout .mat-mdc-checkbox-inner-container {
  background: #866DFF !important;
  color: #8e70ff !important;
}
.dark-theme .purple-checkbox-option .mat-pseudo-checkbox-checked {
  color: #866DFF !important;
}
.dark-theme .purple-checkbox-option .mat-pseudo-checkbox {
  color: #866DFF !important;
}
.dark-theme .pairing-dropdown {
  padding-left: 20px !important;
}
.dark-theme .add-success-light {
  background-color: #210854 !important;
}
.dark-theme .add-success-dark {
  background-color: #210854 !important;
}
.dark-theme .no-border {
  border: none !important;
}
.dark-theme .blenderbox-target {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.dark-theme .blenderbox-target .selected-line {
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  background: #888;
  height: 6px;
  width: 50px;
  transition: all 0.2s ease;
}
.dark-theme .blenderbox-target .selected-line::before {
  content: "";
  position: absolute;
  top: -0.625rem; /* Adjust to extend clickable area above and below */
  left: -0.875rem; /* Adjust to extend clickable area to the left and right */
  right: -0.875rem;
  bottom: -1.35rem;
  background: transparent;
  cursor: pointer;
}
.dark-theme .blenderbox-target .line-arrow {
  /* Add arrowhead at the end of the line */
  transition: all 0.2s ease;
  position: absolute;
  background: #888;
}
.dark-theme .blenderbox-target .line-arrow::after {
  content: "";
  position: absolute;
  right: -12px;
  /* Adjust to place arrowhead at the end of the line */
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #888;
}
.dark-theme .c-red {
  background: #cf1b3a !important;
}
.dark-theme .c-yellow {
  background: #ffca5a !important;
}
.dark-theme .c-peach {
  background: #fec9bf !important;
}
.dark-theme .c-lightyellow {
  background: #ffe79d !important;
}
.dark-theme .c-lightgreen {
  background: #ccf29c !important;
}
.dark-theme .c-green {
  background: #00ab44 !important;
}
.dark-theme .c-blue {
  background: #568aff !important;
}
.dark-theme .c-lightblue {
  background: #83bfe7 !important;
}
.dark-theme .c-purple {
  background: #8e70ff !important;
}
.dark-theme .c-lightpurple {
  background: #e4a9ff !important;
}
.dark-theme .c-orange {
  background: #ff8e42 !important;
}
.dark-theme .c-gray {
  background: #7c7c74 !important;
}
.dark-theme .c-pink {
  background: #ff76b8 !important;
}
.dark-theme .c-cream {
  background: #f6efda !important;
}
.dark-theme .c-transparent {
  background: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  border: none !important;
}
.dark-theme .c-clear {
  background: rgba(0, 0, 0, 0);
  box-shadow: none !important;
  border: none !important;
  display: none;
  z-index: 0 !important;
  visibility: hidden;
}
.dark-theme .more_words_modal_activity, .dark-theme .more_tiles_modal_activity {
  color: #866dff;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 12px;
  text-transform: uppercase;
  display: block;
  font-size: 14px;
}
.dark-theme .global-wrap {
  height: 100%;
  width: 100%;
  margin-bottom: 85px;
}
.dark-theme .setting_header_div {
  background: #18112f;
  padding: 0px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
}
.dark-theme .setting_header_div .setting_header_div_left {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.dark-theme .setting_header_div .setting_header_div_left .help_icon {
  position: relative;
  top: 4px;
  left: 10px;
}
.dark-theme .setting_header_div .setting_header_div_left div {
  display: flex;
  align-items: center;
}
.dark-theme .setting_header_div .setting_header_div_left div h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: white !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  .dark-theme .setting_header_div .setting_header_div_left div h2 {
    font-size: 22px !important;
  }
}
.dark-theme .setting_header_div .account_setting_header_div_left {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.dark-theme .setting_header_div .account_setting_header_div_left h2 {
  color: white;
}
.dark-theme .setting_header_div .header-workbook-name {
  font-size: 14px;
  color: #866dff;
  cursor: pointer;
  margin: 0px 0px 7px;
  align-items: center;
  text-transform: uppercase;
  outline: none;
  font-weight: 500;
}
.dark-theme .setting_header_div .help_icon {
  cursor: pointer;
}
.dark-theme .setting_header_div .setting_header_div_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dark-theme .setting_header_div .setting_header_div_right .setting-type-dropdown {
  float: none !important;
  margin-top: 25px;
  justify-content: flex-end !important;
}
.dark-theme .setting_header_div .setting_header_div_right .setting-type-dropdown .mat-mdc-form-field-infix {
  width: 276px;
  padding: 0 16px !important;
  color: white;
  font-size: 16px;
}
.dark-theme .setting_header_div .setting_header_div_right .setting-type-dropdown .mat-mdc-form-field-infix .mat-select-value-text {
  color: white;
  font-size: 16px;
}
.dark-theme .setting_header_div .setting_header_div_right .setting-type-dropdown .mat-mdc-form-field-label {
  top: 33px;
  left: 16px;
  color: white;
  font-size: 16px;
}
.dark-theme .setting_header_div .setting_header_div_right .setting-type-dropdown.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: #18112f;
  padding: 5px 10px;
  width: auto;
  top: 21px;
  left: 16px;
}
.dark-theme .mat-mdc-option .mdc-list-item__primary-text {
  color: white;
  font-size: 14px !important;
  letter-spacing: normal;
}
.dark-theme .mat-mdc-option .mdc-list-item__primary-text img {
  width: 24px;
  position: relative;
  float: right;
}
.dark-theme .mat-option-disabled .mat-option-text {
  color: #dfdee5;
  font-size: 14px;
}
.dark-theme .mat-option-disabled .mat-option-text img {
  width: 24px;
  position: relative;
  top: 12px;
  float: right;
}
.dark-theme .setting_screens .content_div {
  display: flex;
  flex-direction: column;
  background: #18112f;
  margin: 24px;
  border-radius: 8px;
}
.dark-theme .setting_screens .content_div .each_row {
  display: flex;
  height: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px 24px;
}
.dark-theme .dark-theme .setting_screens .content_div .each_row {
  color: white;
}
.dark-theme .setting_screens .content_div .each_row:last-child {
  border-bottom: none;
}
.dark-theme .setting_screens .content_div .each_row .description-section {
  width: 40%;
}
.dark-theme .setting_screens .content_div .each_row .description-section h3 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: white;
  font-weight: bold;
  letter-spacing: -0.01em;
  line-height: 1;
  margin: 0;
  margin-bottom: 8px;
}
.dark-theme .setting_screens .content_div .each_row .description-section p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #777183;
  line-height: 1.25;
  margin: 0;
}
.dark-theme .setting_screens .content_div .each_row .description_option {
  width: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.dark-theme .setting_screens .content_div .each_row .description_option p {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .setting_screens .content_div .each_row .description_option .mat-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .setting_screens .content_div .each_row .description_option .setting_radio {
  width: 100%;
}
.dark-theme .setting_screens .content_div .each_row .description_option .setting_radio .mat-radio-label {
  white-space: normal;
}
.dark-theme .setting_screens .content_div .each_row .description_option .setting_radio .mat-radio-ripple {
  color: white;
  font-size: 16px;
}
.dark-theme .setting_screens .content_div .each_row .description_option .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.54);
}
.dark-theme .setting_screens .content_div .each_row .description_option .mat-radio-checked .mat-radio-outer-circle {
  border-color: #8e70ff;
}
.dark-theme .setting_screens .content_div .each_row .description_option .mat-radio-checked .mat-radio-inner-circle {
  background-color: #8e70ff;
}
.dark-theme .setting_screens .content_div .each_row .description_option .words_input {
  width: 90px;
  display: inline-block;
  margin-top: 16px;
}
.dark-theme .setting_screens .content_div .each_row .description_option .label {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.56);
  margin-left: 20px;
}
.dark-theme .setting_screens .content_div .each_row .description_option .flascard_radio {
  width: 100%;
  display: block;
  margin: 0 0 18px;
}
.dark-theme .setting_screens .content_div .each_row .description_option .flascard_radio .mat-radio-ripple {
  margin: 0 0 8px;
}
.dark-theme .setting_screens .content_div .each_row .description_option .flascard_radio p {
  color: rgba(255, 255, 255, 0.56);
  margin: 8px 29px 4px;
}
.dark-theme .setting_screens .content_div .custom_row {
  align-items: flex-start !important;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button {
  margin-right: 4px;
  border: 1px solid transparent;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button .mdc-radio__background {
  opacity: 0;
  display: none;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button .mat-radio-ripple {
  background: #e4e2ed;
  height: 40px;
  width: 40px;
  margin: 2px;
  padding: 0;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.mat-radio-checked {
  display: inline-block;
  height: 44px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.mat-radio-not-checked {
  border: solid 1px #ffffff;
  border-radius: 4px;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.gray .mat-radio-ripple {
  background: #e4e2ed;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.black .mat-radio-ripple {
  background: #000000;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.white .mat-radio-ripple {
  background: #fff;
  border: 1px solid rgba(211, 211, 211, 0.3);
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.palesky .mat-radio-ripple {
  background: #bde7ff;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.verypalegreen .mat-radio-ripple {
  background: #d6f7be;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.light-beige .mat-radio-ripple {
  background: #fff6b7;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.offwhite .mat-radio-ripple {
  background: #fbf5e5;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.lightpink .mat-radio-ripple {
  background: #ffdaec;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.softblue .mat-radio-ripple {
  background: #568aff;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.grapefruit .mat-radio-ripple {
  background: #ff5a5a;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.light-mustard .mat-radio-ripple {
  background: #ffca5a;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.soft-green .mat-radio-ripple {
  background: #6dd378;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.periwinkle .mat-radio-ripple {
  background: #8e70ff;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.orangeish .mat-radio-ripple {
  background: #ff8e42;
}
.dark-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.bubblegum .mat-radio-ripple {
  background: #ff76b8;
}
.dark-theme .setting_screens .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 276px;
  height: 56px;
  display: flex;
  align-items: center;
  color: white;
}
.dark-theme .setting_screens .mat-mdc-form-field-underline {
  display: none;
}
.dark-theme .setting_screens .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body-content {
  height: auto;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-header {
  display: flex;
  overflow: hidden;
  position: sticky;
  flex-shrink: 0;
  top: 0px;
  z-index: 9;
  background-color: #ffffff;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #291f4f;
  margin: 0px;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tab_title {
  margin: 0;
  padding: 22px 18px 40px;
  display: block;
  font-size: 16px;
  line-height: 1.25;
  color: white;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tab_title_compound {
  margin: 22px 0px 40px;
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 1.25;
  color: white;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .long-card {
  margin: 0px;
  background-color: transparent;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card {
  box-shadow: none !important;
  background-color: #18112f;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .style-card-header {
  width: 100%;
  height: 30px;
  background-color: transparent;
  color: white;
  font-weight: bold;
  padding: 20px 0px 0px 0px !important;
  word-wrap: break-word;
  text-align: center;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .tile_bgcolor {
  background: transparent !important;
  display: flex;
  justify-content: center;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .tile_bgcolor .style-card-type {
  margin: 30px;
  font-size: larger;
  text-align: center;
  font-weight: bolder;
  border-radius: 5px;
  width: 76px;
  border: none;
  padding: 13px 0px;
  border: none;
  font-size: 32px;
  height: 38px;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .mat-mdc-form-field-infix {
  width: 150px;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: #18112f;
  padding: 5px 10px;
  width: auto;
  top: 21px;
  left: 16px;
  color: white;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .mat-mdc-form-field-infix .mat-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .style-card-type {
  margin: 20px auto;
  padding: 21px 0px;
  font-size: larger;
  text-align: center;
  font-weight: bolder;
  border-radius: 5px;
  width: 76px;
  height: 22px;
  border: none;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .compound_example {
  font-size: 16px;
  color: white;
  font-weight: 600;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .compound_mat_card {
  padding: 23px 0px;
  width: 76px;
  border-radius: 4px;
  line-height: 18px;
  font-size: 1.6837vw !important;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout_div {
  border-bottom: 1px solid rgba(211, 211, 211, 0.3);
  padding: 0px 0px 24px 0px;
  margin: 0px 20px !important;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout_div .mat-card {
  width: 76px;
  height: 20px;
  padding: 22px 0px;
  border-radius: 4px;
  text-align: center;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout .mat-grid-list {
  border: none;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout .mat-grid-tile {
  background-color: transparent !important;
}
.dark-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout .mat-grid-tile .mat-figure div {
  width: 100% !important;
  height: 100% !important;
}
.dark-theme .mat-drawer {
  width: 358px;
  background-color: #0b041e !important;
}
.dark-theme .mat-drawer .mat-drawer-inner-container {
  overflow: hidden;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container {
  max-width: 358px;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-top {
  justify-content: start !important;
  padding: 31px 32px 0px;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-top img {
  border-radius: 5px;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle {
  overflow: hidden !important;
  border-top: solid 1px rgba(134, 109, 255, 0.48);
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree {
  width: 100%;
  background: transparent;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-tree-node {
  color: #fff;
  font-size: 16px;
  position: relative;
  font-weight: bold;
  padding-left: 0px;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-tree-node[aria-expanded=true] .icon_btn::before {
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-tree-node .mat-button {
  padding: 0px 0px;
  text-align: left;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .icon_span_active {
  background-color: #291f4f;
  color: #fff;
  border-radius: 5px;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .icon_span_active .mdc-list-item__content {
  background-color: #291f4f !important;
  color: white;
  padding: 0px 47px;
  border-radius: 8px;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .icon_span_inactive {
  opacity: 72%;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .custom-mat-expansion-panel::before {
  margin-left: 0px;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel {
  background-color: #0b041e;
  color: white !important;
  width: 100%;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-header {
  background-color: #0b041e;
  color: white !important;
  padding-right: 60px;
  height: 18px !important;
  padding-left: 69px;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  position: relative;
  right: 1px;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body {
  background-color: #0b041e;
  color: white !important;
  padding: 0;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body .mdc-list-item__content {
  background-color: #0b041e;
  color: white;
  padding: 0px;
  padding-left: 48px;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body .mdc-list-item__content .mdc-list-item__primary-text {
  display: flex;
  flex-direction: row;
  align-items: end;
  color: white;
  overflow: initial;
  letter-spacing: normal;
}
.focus-theme .dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body .mdc-list-item__content .mdc-list-item__primary-text {
  color: #210854 !important;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-indicator::after {
  color: white !important;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expanded {
  background-color: #0b041e;
  color: white !important;
  height: 18px !important;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-content {
  color: white;
  opacity: 1;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-content .mat-expansion-panel-header-title {
  background-color: #0b041e;
  color: white;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .ng-scrollbar-visible {
  right: -9px !important;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom {
  justify-content: start !important;
  border-top: solid 1px rgba(134, 109, 255, 0.48);
  padding: 11px 32px !important;
  height: 72px !important;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-icon {
  border-radius: 50px;
  padding: 12px 13px;
  color: white;
  margin-right: 10px;
  opacity: 0.32;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-text {
  align-self: flex-end !important;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-text h3 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  line-height: 1px;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-text button {
  background: transparent;
  border: transparent;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: #866dff;
  text-transform: uppercase;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom .name-text {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .active_account .user-icon {
  background-color: #ffb5a0 !important;
  color: white !important;
}
.dark-theme .mat-drawer .mat-drawer-inner-container .side-container .active_account .user-text button {
  color: #ffb5a0 !important;
}
.dark-theme .activity_header_custom {
  width: 100%;
}
.dark-theme .activity_header_custom .mdc-button,
.dark-theme .activity_header_custom .mat-mdc-button,
.dark-theme .activity_header_custom .mat-mdc-button-base {
  padding: 0 16px !important;
  height: inherit;
}
.dark-theme .activity_header_custom .mat-mdc-button > .mat-icon {
  color: #fff;
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
}
.dark-theme .activity_header_custom .activity-header {
  background-color: #18112f !important;
  box-shadow: none !important;
  width: 100% !important;
  z-index: 114;
  height: 40px;
  top: 85px;
}
.dark-theme .activity_header_custom .activity-header .tool_btn_custom {
  position: relative;
  min-width: unset;
  display: block;
  background-color: transparent;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  .dark-theme .activity_header_custom .activity-header .tool_btn_custom {
    min-width: 50px;
  }
}
.dark-theme .activity_header_custom .activity-header .tool_btn_custom::before {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background-repeat: no-repeat;
}
.dark-theme .activity_header_custom .activity-header .cdk-program-focused {
  display: none;
}
.dark-theme .activity_header_custom .activity-header .up::before {
  background-image: url("assets/img/theme-dark/dark-down.png");
}
.dark-theme .activity_header_custom .activity-header .down {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .down::before {
  background-image: url("assets/img/up.png");
}
.dark-theme .activity_header_custom .activity-header .settings-button::before {
  width: 22px !important;
  height: 22px !important;
  background-image: url("assets/img/theme-dark/setting-2.png");
  background-size: contain;
}
.dark-theme .activity_header_custom .activity-header .settings-dropdown-container {
  position: absolute;
  right: 10px;
  background-color: #18112f;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 8px;
  padding-top: 12px;
  width: 300px;
}
.dark-theme .activity_header_custom .activity-header .blendmode-button::before {
  background-image: url("assets/img/theme-dark/dark-blender.png");
  background-size: contain;
}
.dark-theme .activity_header_custom .activity-header .active_blendmode {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_blendmode::before {
  background-image: url("assets/img/theme-dark/dark-blender-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .rotate {
  color: #fff;
}
.dark-theme .activity_header_custom .activity-header .read-button::before {
  background-image: url("assets/img/theme-dark/dark-book.png");
}
.dark-theme .activity_header_custom .activity-header .active_read {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_read::before {
  background-image: url("assets/img/theme-dark/dark-book-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .folder-button::before {
  background-image: url("assets/img/theme-dark/dark-page.png");
}
.dark-theme .activity_header_custom .activity-header .active_folder {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_folder::before {
  background-image: url("assets/img/theme-dark/dark-book-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .edit-button::before {
  background-image: url("assets/img/theme-dark/dark-pencil.png");
}
.dark-theme .activity_header_custom .activity-header .active_btn {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_draw {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_draw::before {
  background-image: url("assets/img/theme-dark/dark-pencil-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .eraser-button::before {
  background-image: url("assets/img/theme-dark/dark-erase.png");
  top: 5px !important;
}
.dark-theme .activity_header_custom .activity-header .active_eraser {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_eraser::before {
  background-image: url("assets/img/theme-dark/dark-erase-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .active_color {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .undo-button::before {
  background-image: url("assets/img/theme-dark/dark-undo.png");
}
.dark-theme .activity_header_custom .activity-header .active_undo {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_undo::before {
  background-image: url("assets/img/theme-dark/dark-undo-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .delete-button::before {
  background-image: url("assets/img/theme-dark/dark-delete.png");
}
.dark-theme .activity_header_custom .activity-header .active_delete {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_delete::before {
  background-image: url("assets/img/theme-dark/dark-delete-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .visibility-button::before {
  background-image: url("assets/img/theme-dark/dark-show.png");
}
.dark-theme .activity_header_custom .activity-header .active_visibility {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_visibility::before {
  background-image: url("assets/img/theme-dark/dark-show-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .visibility_hidden {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .visibility_hidden::before {
  background-image: url("assets/img/theme-dark/dark-hide.png") !important;
}
.dark-theme .activity_header_custom .activity-header .keyboard-button::before {
  background-image: url("assets/img/theme-dark/dark-keyboard.png");
}
.dark-theme .activity_header_custom .activity-header .active_keyboard {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_keyboard::before {
  background-image: url("assets/img/theme-dark/dark-keyboard-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .assignment-button::before {
  background-image: url("assets/img/theme-dark/dark-lines-onoff.png");
}
.dark-theme .activity_header_custom .activity-header .active_assignment {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_assignment::before {
  background-image: url("assets/img/theme-dark/dark-lines-onoff-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .format_size-button::before {
  background-image: url("assets/img/theme-dark/dark-capitalize.png");
}
.dark-theme .activity_header_custom .activity-header .active_format_size {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_format_size::before {
  background-image: url("assets/img/theme-dark/dark-capitalize-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .aspect_ratio-button::before {
  background-image: url("assets/img/theme-dark/dark-resize.png");
}
.dark-theme .activity_header_custom .activity-header .active_aspect_ratio {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_aspect_ratio::before {
  background-image: url("assets/img/theme-dark/dark-resize-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .search-plus-button::before {
  background-image: url("assets/img/theme-dark/dark-zoom-in.png");
}
.dark-theme .activity_header_custom .activity-header .active_search_plus {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_search_plus::before {
  background-image: url("assets/img/theme-dark/dark-zoom-in-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .search-minus-button::before {
  background-image: url("assets/img/theme-dark/dark-zoom-out.png");
}
.dark-theme .activity_header_custom .activity-header .active_search_minus {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_search_minus::before {
  background-image: url("assets/img/theme-dark/dark-zoom-out-active.png") !important;
  background-repeat: no-repeat;
}
.dark-theme .activity_header_custom .activity-header .landscape-button::before {
  background-image: url("assets/img/theme-dark/dark-whole-word.png");
}
.dark-theme .activity_header_custom .activity-header .active_landscape {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_landscape::before {
  background-image: url("assets/img/theme-dark/dark-whole-word-active.png") !important;
  background-repeat: no-repeat;
}
.dark-theme .activity_header_custom .activity-header .bumping-tiles-button::before {
  background-image: url("assets/img/theme-dark/dark-tile-bumping-onoff.png");
}
.dark-theme .activity_header_custom .activity-header .active_bumping_tiles {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_bumping_tiles::before {
  background-image: url("assets/img/theme-dark/dark-tile-bumping-onoff-active.png") !important;
  background-repeat: no-repeat;
}
.dark-theme .activity_header_custom .activity-header .rotate-canvas-button::before {
  appearance: none;
  background-image: none;
}
.dark-theme .activity_header_custom .activity-header .active_pdf_search_minus {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_pdf_search_minus::before {
  background-image: url("assets/img/theme-dark/dark-zoom-out-active.png") !important;
  background-repeat: no-repeat;
}
.dark-theme .activity_header_custom .activity-header .view_array-button::before {
  background-image: url("assets/img/theme-dark/dark-tiles.png");
}
.dark-theme .activity_header_custom .activity-header .active_view_array {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_view_array::before {
  background-image: url("assets/img/theme-dark/dark-tiles-active.png") !important;
  background-repeat: no-repeat;
}
.dark-theme .activity_header_custom .activity-header .info-button::before {
  width: 27px !important;
  height: 26px !important;
  background-image: url("assets/img/theme-dark/dark-help.png");
  position: relative;
  top: -3px;
  right: 3px;
}
.dark-theme .activity_header_custom .activity-header .active_info {
  background-color: #f1eeff;
}
.dark-theme .activity_header_custom .activity-header .active_info::before {
  background-image: url("assets/img/theme-dark/dark-help-active.png") !important;
}
.dark-theme .activity_header_custom .activity-header .color-button .mdc-button__label {
  position: relative;
  bottom: 22px;
}
.dark-theme .activity_header_custom .activity-header .activity_dropdown {
  position: absolute !important;
  left: 21px;
  top: 0;
  /*.mat-mdc-form-field-wrapper{
  	padding-bottom: 10px !important;
  }
  .mat-select-value-text{
  	font-size: 32px;
  	color: #210854;
  	font-weight: bold;
  }
  .mat-mdc-form-field-underline{
  	background-color: transparent;
  }*/
}
.dark-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_div {
  position: relative;
  height: 27px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 12px;
  top: -12px;
}
.dark-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_div .top_dropdown {
  font-size: 14px;
  color: #866dff;
  position: relative;
  height: 30px;
  align-items: center;
  display: flex;
}
.dark-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_div button {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  height: 16px;
  position: relative;
  top: -3px;
  cursor: pointer;
}
.dark-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_div button img {
  height: 16px;
}
.dark-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_child_div {
  position: relative;
  top: -35px;
  height: 32px;
  display: flex;
  align-items: center;
}
.dark-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_child_div span {
  font-size: 32px;
  font-weight: bold;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 0px;
}
.dark-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_child_div button {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.dark-theme .activity_header_custom .activity-header .visibility-button .mdc-button__label {
  position: absolute;
  top: -3px;
  left: 19px;
}
.dark-theme .activity_header_custom .activity-header .active-indicator {
  width: 100%;
  height: 5px;
  position: relative;
  background-color: white;
}
.dark-theme .activity_header_custom .pad-container {
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
}
.dark-theme .activity_header_custom .pad-container .pullout-handle {
  z-index: 120;
  width: 40px;
  height: 40px;
  border: solid 1px rgba(255, 255, 255, 0.08);
  background-color: #866dff;
  border-radius: 50%;
  position: relative;
  background-image: none;
  margin: auto;
  opacity: 0;
}
.dark-theme .activity_header_custom .pad-container .pullout-handle::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 7px;
}
.dark-theme .activity_header_custom .pad-container .up {
  background-image: url("assets/img/theme-dark/dark-down.png");
  background-position: center;
}
.dark-theme .activity_header_custom .pad-container .down {
  background-image: url("assets/img/up.png");
  background-position: center;
}
.dark-theme .activity_header_custom .pad-container .pullout-handle-up {
  z-index: 116;
  width: 40px;
  height: 40px;
  border: solid 1px rgba(255, 255, 255, 0.08);
  background-color: #ffffff;
  border-radius: 50%;
  position: relative;
  bottom: -22px;
  background-image: none;
  margin: auto;
}
.dark-theme .activity_header_custom .pad-container .pullout-handle-up::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 7px;
}
.dark-theme .new-tile-container {
  overflow: hidden;
}
@media only screen and (min-height: 2160px) {
  .dark-theme .blackboard_custom {
    height: 91%;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .dark-theme .blackboard_custom {
    height: 86%;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .dark-theme .blackboard_custom {
    height: 86%;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .dark-theme .blackboard_custom {
    height: 82%;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .dark-theme .blackboard_custom {
    height: 80%;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .dark-theme .blackboard_custom {
    height: 78%;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .dark-theme .blackboard_custom {
    height: 74%;
  }
}
.dark-theme .blackboard_custom .new-tile-container {
  height: 100%;
  background-color: #291f4f;
}
.dark-theme .blackboard_custom .mat-figure {
  background-color: transparent;
}
.dark-theme .blackboard_custom .mat-figure .inside-tile {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
}
.dark-theme .blackboard_custom .line-holder .line {
  border-bottom: 1px solidrgba(white, 0.16);
}
.dark-theme .blackboard_custom .frozen-row-shadow {
  -webkit-box-shadow: 0 2px 4px 0 #dfd8f4;
  -moz-box-shadow: 0 2px 4px 0 #dfd8f4;
  box-shadow: 0 2px 4px 0 #dfd8f4;
  background: #e4e2ed;
}
.dark-theme .sentence_div {
  margin: 0px 0px;
}
.dark-theme .sentence_div .sentence_mat_card {
  height: 433px !important;
  padding: 179px 0px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: #ffffff !important;
  text-align: center;
  width: 100%;
}
.dark-theme .sentence_div .sentence_mat_card span {
  font-size: 64px;
  color: white;
  font-weight: bold;
}
.dark-theme .sentence_tally {
  top: 10px !important;
  padding: 10px 10px;
  position: relative !important;
  display: flex;
  justify-content: center;
}
.dark-theme .pdf-container {
  display: flex;
  margin-top: 180px;
}
.dark-theme .pdf-container .pdf-navigators {
  z-index: 111;
  display: flex;
  justify-content: center;
  width: 125px;
  height: 150px;
  position: relative;
  top: 35vh;
}
.dark-theme .pdf-container .blackboard-canvas {
  position: absolute;
  z-index: 110;
  overflow: hidden;
}
.dark-theme .pdf-container .pdf-screen {
  flex: 0;
  align-items: center;
  border: none;
  box-shadow: none;
  width: 70vw;
}
.dark-theme .pdf_control_custom {
  justify-content: space-between !important;
  z-index: 111;
}
.dark-theme .pdf_control_custom .control_left .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 375px;
  height: 56px;
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.32);
  padding: 0 24px !important;
  display: flex;
  align-items: center;
  color: white;
}
.dark-theme .pdf_control_custom .control_left .mat-mdc-form-field-underline {
  display: none;
}
.dark-theme .pdf_control_custom .control_left .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.dark-theme .pdf_control_custom .control_left .mat-mdc-form-field-label {
  top: 35px;
  left: 16px;
  color: white;
  font-size: 16px;
  background-color: #e4e2ed;
  padding: 5px 10px;
}
.dark-theme .pdf_control_custom .control_left.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: #e4e2ed;
  padding: 5px 10px;
  width: auto;
  top: 20px;
  left: 16px;
}
.dark-theme .pdf_control_custom .control_right .mat-mdc-form-field-flex {
  width: 40px;
}
.dark-theme .pdf_control_custom .control_right span {
  margin-left: 25px;
}
.dark-theme .pdf_control_custom .control_right .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 59px;
  width: 100px;
  height: 56px;
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.32);
  padding: 0 0px !important;
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: white;
}
.dark-theme .pdf_control_custom .control_right .mat-mdc-form-field-infix input {
  text-align: center !important;
}
.dark-theme .pdf_control_custom .control_right .mat-mdc-form-field-underline {
  display: none;
}
.dark-theme .pdf_control_custom .control_right .mat-mdc-form-field-label-wrapper {
  left: -70px;
  top: 19px;
}
.dark-theme .pdf_control_custom .control_right .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  font-size: 16px;
  color: white;
}
.dark-theme #overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 101 !important;
  opacity: 0.4;
}
.dark-theme #overlay pdf-viewer {
  z-index: 0 !important;
  position: absolute;
  height: 100%;
  width: 100%;
}
.dark-theme #overlay pdf-viewer.hidden {
  opacity: 0;
}
.dark-theme .pdf_div {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  height: auto !important;
}
.dark-theme .pdf_div .back {
  display: flex;
  flex-direction: column;
}
.dark-theme .pdf_div .next {
  display: flex;
  flex-direction: column;
}
.dark-theme .pdf_div .pdf_mat_card {
  height: 100% !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 0.08) !important;
  background-color: #ffffff !important;
  text-align: center;
  width: 100%;
}
.dark-theme .tile-content_div .cdk-drag-placeholder {
  opacity: 0;
  display: none;
}
.dark-theme .tile-content_div .mat-figure {
  background-color: transparent;
}
.dark-theme .tile-content_div .mat-figure .inside-tile {
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
}
.dark-theme .tile-lay-out-bottom {
  background-color: #18112f;
}
.dark-theme .tilelayout_div_footer {
  background-color: #18112f;
}
.dark-theme .tilelayout_div_footer .mat-card-content {
  font-size: 1.6837vw !important;
}
.dark-theme .tilelayout_div_footer .mat-card {
  min-width: 49px;
  min-height: 60px;
  padding: 0px;
  border-radius: 4px;
  text-align: center;
  box-shadow: none;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
}
.dark-theme .ng-resizable-handle {
  background-color: #866dff !important;
  width: 40px !important;
  height: 40px !important;
}
.dark-theme .ng-resizable-handle::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-dark/down-white.png");
  background-repeat: no-repeat;
  display: block;
  top: 15px;
  left: 8px;
}
.dark-theme .ng-resizable-handle::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-dark/down-white.png");
  background-repeat: no-repeat;
  display: block;
  top: 2px;
  left: 8px;
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
.dark-theme .whiteboard-container {
  height: 100%;
  width: 91%;
  position: absolute;
  left: 0;
  overflow: hidden;
  z-index: 0;
}
.dark-theme .whiteboard-container .wordcard {
  color: white;
  background-color: #18112f;
}
.dark-theme .phrases_div {
  margin: 50px 0px;
  height: 100% !important;
}
.dark-theme .phrases_tally {
  padding: 10px 10px;
  position: relative !important;
  display: flex;
  justify-content: center;
}
.dark-theme .tally-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  top: 50px !important;
  height: auto !important;
  z-index: 1;
}
.dark-theme .spelling_div .spelling_footer_div {
  background-color: #18112f;
  display: flex;
  flex-direction: column;
}
.dark-theme .spelling_div .spelling_footer_div .spelling_span_words {
  margin-left: 76px;
  font-size: 16px;
  font-weight: 600;
  color: #bcbdbf;
  display: block;
  height: 32px;
}
.dark-theme .spelling_div .spelling_footer_div .spelling_btn {
  background: #ffb5a0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: white;
}
.dark-theme .spelling_div .spelling_footer_div .skip_btn {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  background: #eeeeee;
  color: #888888;
  max-width: 75px;
}
.dark-theme .spelling_div .spelling_footer_div .spelling_icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 40px;
  margin-right: 32px;
}
.dark-theme .spelling_div .spelling_footer_div .spelling_icon i {
  color: #866dff;
}
.dark-theme .workbook_tile_div {
  margin-top: 180px;
  height: 66%;
  position: relative;
  background-color: #18112f;
}
@media only screen and (min-height: 2160px) {
  .dark-theme .workbook_tile_div {
    height: 91%;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .dark-theme .workbook_tile_div {
    height: 86%;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .dark-theme .workbook_tile_div {
    height: 86%;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .dark-theme .workbook_tile_div {
    height: 82%;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .dark-theme .workbook_tile_div {
    height: 80%;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .dark-theme .workbook_tile_div {
    height: 78%;
  }
}
@media only screen and (min-height: 768px) and (max-height: 900px) {
  .dark-theme .workbook_tile_div {
    height: 75%;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .dark-theme .workbook_tile_div {
    height: 74%;
  }
}
.dark-theme .workbook_tile_div .mat-figure {
  background-color: transparent;
}
.dark-theme .workbook_tile_div .mat-figure .inside-tile {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
}
.dark-theme .workbook_tile_div .line-holder .line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}
.dark-theme .workbook_tile_div .dnd-drag-start {
  width: 50px;
  height: 50px;
}
.dark-theme .tilelayout_div .mat-figure {
  background-color: transparent;
}
.dark-theme .tilelayout_div .mat-figure .inside-tile {
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
}
.dark-theme .tilelayout_div .mat-figure:empty {
  border: none !important;
}
.dark-theme .flashcard-box {
  position: relative;
}
.dark-theme .flashcard-box .back {
  top: 45%;
  position: relative;
  height: 100px;
}
@media only screen and (max-width: 1280px) {
  .dark-theme .flashcard-box .back .mat-button {
    top: 0% !important;
  }
}
.dark-theme .flashcard-box .next {
  top: 45%;
  position: relative;
  height: 100px;
}
@media only screen and (max-width: 1280px) {
  .dark-theme .flashcard-box .next .mat-button {
    top: 0% !important;
  }
}
.dark-theme .state-box {
  position: relative;
  top: 57px;
  border-radius: 16px;
  border: solid 1px rgba(255, 255, 255, 0.16);
  background: transparent !important;
  font-size: 14px;
  color: white !important;
}
.dark-theme .mini_tile {
  margin-top: 180px;
  height: 66%;
}
@media only screen and (min-height: 2160px) {
  .dark-theme .mini_tile {
    height: 91%;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .dark-theme .mini_tile {
    height: 86%;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .dark-theme .mini_tile {
    height: 86%;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .dark-theme .mini_tile {
    height: 82%;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .dark-theme .mini_tile {
    height: 80%;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .dark-theme .mini_tile {
    height: 78%;
  }
}
@media only screen and (min-height: 768px) and (max-height: 900px) {
  .dark-theme .mini_tile {
    height: 75%;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .dark-theme .mini_tile {
    height: 74%;
  }
}
.dark-theme .mini_tile .center_align_tile > div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.dark-theme .mini_tile .center_align_tile > div .new-tile-block {
  position: relative !important;
  left: 0 !important;
  margin-right: 10px;
}
.dark-theme .mini_tile .center_align_tile > div .new-tile-block:last-child {
  margin-right: 0 !important;
}
.dark-theme .mini_tile .line-holder .line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}
.dark-theme .mini_tile .dnd-drag-start {
  width: 50px;
  height: 50px;
}
@media only screen and (min-height: 2160px) {
  .dark-theme .blank_tile {
    height: 91vh;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .dark-theme .blank_tile {
    height: 86vh;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .dark-theme .blank_tile {
    height: 86vh;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .dark-theme .blank_tile {
    height: 82vh;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .dark-theme .blank_tile {
    height: 80vh;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .dark-theme .blank_tile {
    height: 78vh;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .dark-theme .blank_tile {
    height: 72vh;
  }
}
.dark-theme .blank_tile .center_align_tile > div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.dark-theme .blank_tile .center_align_tile > div .new-tile-block {
  position: relative !important;
  left: 0 !important;
  margin-right: 10px;
}
.dark-theme .blank_tile .center_align_tile > div .new-tile-block:last-child {
  margin-right: 0 !important;
}
.dark-theme .passage_tally {
  position: relative !important;
  display: flex;
  justify-content: center;
  height: 100px;
}
.dark-theme .passage_div .passage_mat_card {
  padding: 68px 88px !important;
  height: 500px !important;
  overflow-y: scroll !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 0.08) !important;
  background-color: #ffffff !important;
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.dark-theme .passage_div .passage_mat_card span {
  color: white;
  font-size: 64px !important;
  font-weight: bold;
}
.dark-theme .time_reading {
  margin: 0px 0px;
}
.dark-theme .time_reading .wordcards {
  font-size: 24px !important;
  color: white;
}
.dark-theme .time_reading .button-box {
  position: fixed;
  top: 40px;
  z-index: 117;
  right: 32px;
}
.dark-theme .time_reading .button-box .mat-mdc-button-base {
  background-color: #ffb5a0;
  border-radius: 4px;
  box-shadow: none;
}
.dark-theme .time_reading .button-box .mat-mdc-button-base .mdc-button__label {
  line-height: 1em !important;
  color: #210854;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .time_reading .wrong {
  text-decoration: line-through;
  color: #ff7463 !important;
}
.dark-theme .tile-container {
  width: 100% !important;
  position: relative;
  display: flex !important;
  flex-direction: row;
  padding-right: 8vw;
}
.dark-theme .tile-container .line-holder {
  display: none !important;
}
.dark-theme .tile-container .flashcard {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: auto !important;
  height: 433px !important;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 0.08);
  background-color: #18112f;
  font-size: 6vw !important;
  color: white !important;
  margin: 0px -5px;
  border: none !important;
}
.dark-theme .tile-container .flashcard-altered {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: auto !important;
  height: 433px !important;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 0.08);
  background-color: #18112f;
  font-size: 6vw !important;
  color: white !important;
  margin: 0px -5px;
  border: none !important;
}
.dark-theme .letter-box .lettercard {
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 0.08) !important;
}
.dark-theme .letter-box .back {
  position: relative;
  top: 42%;
}
.dark-theme .letter-box .next {
  position: relative;
  top: 42%;
}
.dark-theme .card {
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 0.08) !important;
  background-color: #18112f;
  font-size: 64px !important;
  padding: 38px 0px !important;
}
.dark-theme .card span {
  color: white;
}
.dark-theme .mat-mdc-card-subtitle,
.dark-theme .mat-mdc-card-content {
  font-size: 14px;
}
.dark-theme .side_bar_display {
  width: 72px !important;
}
.dark-theme .side_bar_display .side-top {
  padding: 32px 15px 0px !important;
}
.dark-theme .side_bar_display .side-middle {
  overflow-y: auto !important;
  overflow: hidden !important;
}
.dark-theme .side_bar_display .side-middle .ng-scroll-view {
  overflow-x: hidden;
}
.dark-theme .side_bar_display .side-middle .mat-expansion-panel-content {
  display: none;
}
.dark-theme .side_bar_display .side-middle .mat-tree {
  padding: 0px 0px 0px 0px !important;
}
.dark-theme .side_bar_display .side-middle .mat-tree .nav-icons {
  left: 12px !important;
}
.dark-theme .side_bar_display .side-middle .mat-tree .mat-tree-node {
  padding-left: 5px !important;
}
.dark-theme .side_bar_display .side-middle .mat-tree .mat-tree-node[role=treeitem] {
  display: none;
}
.dark-theme .side_bar_display .side-bottom {
  position: relative;
  left: -17px;
}
.dark-theme .side_bar_display .side-bottom .user-icon {
  margin-right: 20px !important;
}
.dark-theme .side_bar_display .content_div {
  width: 95%;
}
.dark-theme .side_navbar, .dark-theme .element-drag-disabled {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.dark-theme .add-save-setting-modalbox {
  width: 350px !important;
}
.dark-theme .add-save-setting-modalbox .mat-mdc-dialog-container {
  padding: 0 !important;
  background-color: #0b041e;
}
.dark-theme .add-save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content {
  margin: 0;
  padding: 25px 24px;
}
.dark-theme .add-save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.dark-theme .add-save-setting-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-wrapper {
  padding: 0;
  width: 100%;
}
.dark-theme .add-save-setting-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-infix {
  color: white;
}
.dark-theme .add-save-setting-modalbox .add-save-setting {
  width: 350px;
}
.dark-theme .add-save-setting-modalbox .add-save-setting .popup-header {
  border-bottom: solid 1px rgba(72, 30, 159, 0.08);
  padding: 19px 24px;
}
.dark-theme .add-save-setting-modalbox .add-save-setting .popup-header h1 {
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  color: white;
  font-weight: 600;
}
.dark-theme .add-save-setting-modalbox .add-save-setting .btn-container {
  text-align: right;
  justify-content: flex-end;
  padding: 0 24px;
}
.dark-theme .add-save-setting-modalbox .add-save-setting .btn-container .mat-button {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.dark-theme .add-save-setting-modalbox .add-save-setting .btn-container .mat-button .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .add-save-setting-modalbox .add-save-setting .btn-container .disable_save {
  opacity: 0.5;
}
.dark-theme .add-save-setting-modalbox .add-save-setting .mat-mdc-dialog-actions .mat-mdc-button-base {
  margin-left: 0;
}
.dark-theme .save-setting-modalbox .mat-mdc-dialog-container {
  padding: 0 !important;
  background-color: #0b041e;
}
.dark-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content {
  margin: 0;
  padding: 25px 24px;
}
.dark-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.dark-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.dark-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field-infix {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .delete {
  margin: 18px 0px;
}
.dark-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .delete .mat-button {
  padding: 0px 0px;
  background-color: transparent;
}
.dark-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .delete span {
  margin: 0px 18px;
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-wrapper {
  padding: 0;
  width: 100%;
}
.dark-theme .save-setting-modalbox .add-save-setting {
  width: 350px;
}
.dark-theme .save-setting-modalbox .add-save-setting .popup-header {
  border-bottom: solid 1px rgba(255, 255, 255, 0.08);
  padding: 19px 24px;
}
.dark-theme .save-setting-modalbox .add-save-setting .popup-header h1 {
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  color: white;
  font-weight: 600;
}
.dark-theme .save-setting-modalbox .add-save-setting .btn-container {
  text-align: right;
  justify-content: flex-end;
  padding: 0 24px;
}
.dark-theme .save-setting-modalbox .add-save-setting .btn-container .mat-button {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.dark-theme .save-setting-modalbox .add-save-setting .btn-container .mat-button .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .save-setting-modalbox .add-save-setting .btn-container .disable_save {
  opacity: 0.5;
}
.dark-theme .save-setting-modalbox .add-save-setting .mat-mdc-dialog-actions .mat-mdc-button-base {
  margin-left: 0;
}
.dark-theme .activity-modalbox .mat-mdc-dialog-title {
  color: white !important;
  font-weight: bold !important;
  letter-spacing: normal;
  margin-top: 24px;
}
.dark-theme .activity-modalbox .mat-mdc-dialog-content {
  max-height: 45vh;
  border-bottom: solid 1px rgba(255, 255, 255, 0.08);
  border-top: solid 1px rgba(255, 255, 255, 0.08);
  padding: 0 !important;
}
.dark-theme .activity-modalbox .mat-mdc-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(255, 255, 255, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 24px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
  cursor: pointer;
}
.dark-theme .activity-modalbox .mat-mdc-dialog-content .mat-mdc-list-item.selected {
  background: #EEEEEE !important;
}
.dark-theme .activity-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item {
  font-size: 18px !important;
  color: white;
  line-height: 30px !important;
}
.dark-theme .activity-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item_child {
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .activity-modalbox .mat-mdc-button-base {
  float: right;
  position: relative;
  top: 4px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px 24px;
  margin: 6px 0;
}
.dark-theme .activity-modalbox .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .activity-modalbox .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .time_reading-modalbox {
  width: 350px;
}
.dark-theme .time_reading-modalbox .mat-mdc-dialog-container {
  background-color: #0b041e;
}
.dark-theme .time_reading-modalbox .mat-mdc-dialog-title {
  margin: 0 0 0px;
  color: white;
  font-weight: bold !important;
  font-size: 20px;
  margin-bottom: 16px;
}
.dark-theme .time_reading-modalbox .mat-dialog-content p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.56);
  line-height: 20px;
}
.dark-theme .time_reading-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  margin-right: 16px;
  margin-bottom: 8px;
}
.dark-theme .time_reading-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  background: transparent;
}
.dark-theme .time_reading-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  font-weight: bolder !important;
  letter-spacing: 0.4px;
}
.dark-theme .score-modalbox {
  width: 350px;
}
.dark-theme .score-modalbox .mat-mdc-dialog-title {
  margin: 0 0 0px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 8px;
}
.dark-theme .score-modalbox .mat-dialog-content p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.56);
  line-height: 20px;
}
.dark-theme .score-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0px 0px !important;
  margin-right: 24px;
  margin-bottom: 8px;
}
.dark-theme .score-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .score-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  font-weight: bolder !important;
  letter-spacing: 0.4px;
}
.dark-theme .score-modalbox .mat-mdc-dialog-actions button:first-child {
  margin-right: 8px;
}
.dark-theme .display-score-modalbox {
  width: 400px;
  height: 350px;
}
.dark-theme .display-score-modalbox .mat-mdc-dialog-title {
  margin: 0 0 12px;
  color: white;
  font-weight: bold;
  font-size: 20px;
}
.dark-theme .display-score-modalbox .mat-dialog-content {
  height: 70%;
}
.dark-theme .display-score-modalbox .mat-dialog-content .mat-mdc-list-item {
  height: 30px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.56);
}
.dark-theme .display-score-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0px 0px !important;
}
.dark-theme .display-score-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .activity-common-modalbox {
  height: 364px !important;
}
.dark-theme .activity-common-modalbox .mat-mdc-dialog-container {
  padding: 0px 0px;
  background-color: #0b041e;
}
.dark-theme .course-list-modalbox {
  width: 350px !important;
  height: auto !important;
}
.dark-theme .course-list-modalbox .mat-mdc-dialog-container {
  overflow: hidden;
  background-color: #0b041e;
}
.dark-theme .course-list-modalbox .mat-mdc-dialog-title,
.dark-theme .course-list-modalbox .mdc-dialog__title {
  color: white !important;
  font-weight: bold !important;
  margin-top: 24px;
  font-size: 18px !important;
}
.dark-theme .course-list-modalbox .mat-mdc-dialog-content {
  max-height: 45vh;
  border-bottom: solid 1px rgba(72, 30, 159, 0.08);
  border-top: solid 1px rgba(72, 30, 159, 0.08);
  padding: 0px 0px !important;
}
.dark-theme .course-list-modalbox .mat-mdc-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(72, 30, 159, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 0px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
  cursor: pointer;
}
.dark-theme .course-list-modalbox .mat-mdc-dialog-content .mdc-list-item__content {
  padding: 0px 24px !important;
}
.dark-theme .course-list-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item {
  font-size: 16px !important;
  color: white;
  line-height: 30px !important;
}
.dark-theme .course-list-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item_child {
  font-size: 14px !important;
}
.dark-theme .course-list-modalbox .mat-mdc-button-base {
  color: #866dff !important;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  float: right;
  margin: 10px;
  position: relative;
  background: transparent;
}
.dark-theme .dark-theme .missing-tiles-modalbox .mat-mdc-dialog-title, .dark-theme .dark-theme .missing-tiles-modalbox .description {
  color: white !important;
}
.dark-theme .missing-tiles-modalbox .mat-mdc-dialog-container {
  background-color: #0b041e;
}
.dark-theme .missing-tiles-modalbox .mat-dialog-content {
  margin: 0;
  padding: 0;
}
.dark-theme .missing-tiles-modalbox .mat-dialog-content .mat-mdc-dialog-title {
  color: white;
  font-size: 24px;
}
.dark-theme .missing-tiles-modalbox .mat-dialog-content .mat-typography .mat-typography-p {
  color: rgba(255, 255, 255, 0.56);
}
.dark-theme .missing-tiles-modalbox .mat-mdc-dialog-actions .add_tile_missing {
  background-color: #ffb5a0;
  color: #210854;
  box-shadow: none;
  font-size: 14px;
  display: flex;
  flex-direction: row;
}
.dark-theme .missing-tiles-modalbox .mat-mdc-dialog-actions .add_tile_missing .mdc-button__label {
  width: 100%;
  color: #210854;
}
.dark-theme .missing-tiles-modalbox .mat-mdc-dialog-actions .skip_tile_missing {
  color: #210854;
  font-size: 14px;
  align-self: center;
  display: flex;
  flex-direction: row;
  background-color: lightgray;
}
.dark-theme .missing-tiles-modalbox .mat-mdc-dialog-actions .skip_tile_missing .mdc-button__label {
  width: 100%;
  color: #210854;
}
.dark-theme .small-dialog {
  max-width: 20vw !important;
}
.dark-theme .small-dialog .mat-mdc-dialog-title {
  color: white;
}
.dark-theme .small-dialog .mat-dialog-content {
  color: white;
}
.dark-theme .ng-scrollbar {
  --scrollbar-size: 8px !important;
  --scrollbar-thumb-color: rgba(94, 175, 255, 0.5)!important;
  --scrollbar-thumb-hover-color: dodgerblue;
  --scrollbar-border-radius: 4px;
}
.dark-theme .scrollbar {
  overflow-y: auto;
}
.dark-theme .scrollbar::-webkit-scrollbar {
  width: 10px;
}
.dark-theme .scrollbar::-webkit-scrollbar-track {
  border-radius: 5px;
}
.dark-theme .scrollbar::-webkit-scrollbar-thumb {
  background: rgba(94, 175, 255, 0.3);
  border-radius: 5px;
}
.dark-theme .scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(94, 175, 255, 0.5);
}
.dark-theme .scrollbar-grey {
  overflow-y: auto;
}
.dark-theme .scrollbar-grey::-webkit-scrollbar {
  width: 15px;
}
.dark-theme .scrollbar-grey::-webkit-scrollbar-track {
  background: white;
}
.dark-theme .scrollbar-grey::-webkit-scrollbar-thumb {
  background: rgba(179, 179, 179, 0.5);
  border-radius: 5px;
}
.dark-theme .scrollbar-grey::-webkit-scrollbar-thumb:hover {
  background: rgba(179, 179, 179, 0.5);
}
.dark-theme .course_content_custom {
  height: 154px !important;
  min-height: 154px !important;
  padding: 32px 24px !important;
  background-color: #18112f;
}
.dark-theme .course_content_custom .header {
  position: relative;
  display: flex;
  align-items: center;
}
.dark-theme .course_content_custom .header h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: white !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0;
}
.dark-theme .course_content_custom .header .help_icon {
  position: relative;
  left: 10px;
  top: 0px;
}
.dark-theme .course_content_custom .course-button {
  background-color: #ffb5a0 !important;
  border: 1px solid #ffb5a0;
  border-radius: 4px;
  height: 40px !important;
  width: auto !important;
  box-shadow: none !important;
  top: 50px;
  right: 15px;
  min-width: auto;
}
.dark-theme .course_content_custom .course-button .mdc-button__label {
  line-height: 40px !important;
  color: #210854;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.dark-theme .course_content_custom .course-button .mdc-button__label {
  line-height: 40px !important;
}
.dark-theme .dashboard_content_toggle {
  background-color: #291f4f !important;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper {
  padding: 0px 24px;
  overflow: initial;
  margin-bottom: 50px !important;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .reoder {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  margin: 32px 10px 0px;
  box-shadow: none !important;
  line-height: 1.2;
  height: 40px;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .reoder .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .reoder .mdc-button__label {
  color: #fff;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .done {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  margin: 32px 10px 0px;
  box-shadow: none !important;
  line-height: 1.2;
  height: 40px;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .done .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .done .mdc-button__label {
  color: #fff;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .search-and-actions {
  background-color: #291f4f !important;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .quick-start {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  margin: 32px 10px 0px;
  box-shadow: none !important;
  line-height: 1.2;
  height: 40px;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .quick-start .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .quick-start .mat-button-wrapper {
  color: #fff;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-header {
  border: none;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container {
  background-color: #18112f;
  padding: 0px 24px;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 1;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab[aria-disabled=true] {
  opacity: 0.32;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab__text-label {
  color: #866dff !important;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #866dff;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
.dark-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
  border-color: #481e9f !important;
}
.dark-theme .dashboard_custom_card {
  padding: 24px 0px;
}
.dark-theme .dashboard_custom_card .blank_div_container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 32px 0px;
}
.dark-theme .dashboard_custom_card .blank_div_container .add_workbook_blank {
  background-color: #866dff !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 9px 5px 0px !important;
  background-image: none;
}
.dark-theme .dashboard_custom_card .blank_div_container .add_workbook_blank span {
  color: #fff !important;
  margin-bottom: 10px;
}
.dark-theme .dashboard_custom_card .blank_div_container .blank_div {
  background-color: #18112f !important;
  height: 118px !important;
  border-radius: 8px;
  box-shadow: none !important;
  margin: 9px 5px 0px !important;
}
.dark-theme .dashboard_custom_card .new-workbook {
  height: 118px !important;
  border: none !important;
  margin-right: 5px !important;
  border-radius: 8px !important;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (max-width: 1920px) {
  .dark-theme .dashboard_custom_card .new-workbook {
    width: 19.2% !important;
  }
}
@media only screen and (max-width: 1680px) {
  .dark-theme .dashboard_custom_card .new-workbook {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1366px) {
  .dark-theme .dashboard_custom_card .new-workbook {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .dark-theme .dashboard_custom_card .new-workbook {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .dark-theme .dashboard_custom_card .new-workbook {
    width: 31% !important;
  }
}
.dark-theme .dashboard_custom_card .new-workbook span {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.dark-theme .dashboard_custom_card .workbook {
  height: 118px !important;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: #18112f !important;
  margin: 9px 5px 0px !important;
}
.dark-theme .dashboard_custom_card .workbook .mat-mdc-card-header {
  color: white;
  text-align: left;
  font-weight: 600;
}
.dark-theme .dashboard_custom_card .workbook .mat-mdc-card-header .mat-mdc-card-header-text {
  margin: 0 0px !important;
}
.dark-theme .dashboard_custom_card .workbook .mat-mdc-card-header .red-circle {
  width: 8px;
  height: 8px;
  background: #ff5a5a;
  border-radius: 50px;
  position: absolute;
  right: 4px;
  top: 4px;
}
.dark-theme .dashboard_custom_card .workbook .actions {
  position: relative;
}
.dark-theme .dashboard_custom_card .workbook .actions .begin-button {
  position: absolute;
  left: -6px;
  top: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.dark-theme .dashboard_custom_card .workbook .actions .begin-button .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .dashboard_custom_card .workbook .actions .ellipsis {
  position: absolute;
  right: 0;
  background: none;
  box-shadow: none;
}
.dark-theme .dashboard_custom_card .workbook .actions .ellipsis .mdc-button__label {
  color: rgba(255, 255, 255, 0.3) !important;
}
@media only screen and (max-width: 1920px) {
  .dark-theme .dashboard_custom_card .content {
    width: 19.2% !important;
  }
}
@media only screen and (max-width: 1680px) {
  .dark-theme .dashboard_custom_card .content {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1366px) {
  .dark-theme .dashboard_custom_card .content {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .dark-theme .dashboard_custom_card .content {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .dark-theme .dashboard_custom_card .content {
    width: 31% !important;
  }
}
.dark-theme .dashboard_custom_card_reoder {
  padding: 24px 0px;
}
.dark-theme .dashboard_custom_card_reoder .new-workbook {
  width: 18.8% !important;
  height: 118px !important;
  border: none !important;
  border-radius: 8px !important;
  margin-right: 5px !important;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dark-theme .dashboard_custom_card_reoder .new-workbook div {
  margin-top: 10px;
}
.dark-theme .dashboard_custom_card_reoder .new-workbook span {
  color: #866dff;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.dark-theme .dashboard_custom_card_reoder .cdk-drop-list {
  width: 18.8% !important;
}
.dark-theme .dashboard_custom_card_reoder .cdk-drop-list .workbook {
  background-color: #18112f !important;
}
.dark-theme .dashboard_custom_card_reoder .cdk-drop-list .workbook .example-handle .mat-mdc-card-header {
  color: white;
}
.dark-theme .dashboard_custom_card_reoder .content {
  padding: 7px 3px !important;
}
.dark-theme .dashboard_custom_card_reoder .workbook {
  height: 118px;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: #18112f !important;
  margin: 9px 5px 0px !important;
}
.dark-theme .dashboard_custom_card_reoder .workbook .mat-mdc-card-header {
  color: white;
  text-align: left;
}
.dark-theme .dashboard_custom_card_reoder .workbook .mat-mdc-card-header .mat-mdc-card-header-text {
  margin: 0 0px !important;
}
.dark-theme .dashboard_custom_card_reoder .workbook .actions {
  color: white;
  position: relative;
  height: auto;
  padding: 0px 24px !important;
  justify-content: start !important;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px !important;
}
.dark-theme .dashboard_custom_card_reoder .workbook .ellipsis {
  background: none;
  box-shadow: none;
}
.dark-theme .dashboard_custom_card_reoder .workbook .ellipsis .mdc-button__label {
  color: #cac4d7 !important;
}
.dark-theme .dashboard_custom_card_reoder .workbook .action-div {
  display: flex;
  justify-content: space-between;
}
.dark-theme .dashboard_custom_card_reoder .workbook .remove-btn {
  font-weight: bold;
  background-color: transparent;
  border: none;
  margin-top: -16px;
  text-transform: uppercase;
  cursor: pointer;
}
.dark-theme .search-field {
  position: relative;
}
.dark-theme .search-field .search-field_input {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.08);
  padding: 10px 0px 10px 40px;
  border-radius: 4px;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font: inherit;
}
.dark-theme .search-field .search-field_input::placeholder {
  color: rgba(255, 255, 255, 0.32);
}
.dark-theme .search-field .search-field_input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.32);
}
.dark-theme .search-field .search-field_input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.32);
}
.dark-theme .search-field .clear-button {
  position: absolute;
  right: 0rem;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  color: #999;
}
.dark-theme .search-field .clear-button:hover {
  color: #333;
}
.dark-theme .search-field::before {
  position: absolute;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-dark/search.png");
  top: 7px;
  left: 10px;
}
.dark-theme .work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item {
  color: white;
  background-color: transparent;
}
.dark-theme .work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item:last-child {
  color: #ff7463;
}
.dark-theme .work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item:hover:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme .work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.dark-theme .manage-course-modalbox {
  width: 350px !important;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container {
  padding: 0px 0px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
  background-color: #0b041e;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  margin: 0 0 20px;
  display: block;
  padding: 19px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding: 0 24px;
  max-height: 65vh;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix {
  color: white;
  font-size: 16px;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  color: white;
  opacity: 0.56;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix .mdc-form-field-outline-thick {
  color: rgba(255, 255, 255, 0.08);
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .manage_modal_p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.56);
  margin: 0 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .manage_modal_p span {
  margin-right: 24px;
  height: 23px;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  margin: 6px 10px;
  justify-content: flex-end;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .cancel {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .cancel .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .save {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .save .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .share_copy_btn {
  color: #866dff;
  margin-left: 5px;
  font-size: smaller;
}
.dark-theme .wordlist_modal {
  width: 350px !important;
}
.dark-theme .wordlist_modal .mat-mdc-dialog-container {
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
  background-color: #0b041e;
}
.dark-theme .wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 5px;
  padding: 0;
}
.dark-theme .wordlist_modal .mat-mdc-dialog-container .scrollable {
  overflow: hidden !important;
}
.dark-theme .wordlist_modal .mat-mdc-dialog-container .scrollable p {
  color: rgba(255, 255, 255, 0.56);
  font-size: 16px;
}
.dark-theme .wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  padding: 0px 0;
  min-height: 32px;
}
.dark-theme .wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-button {
  position: relative;
  top: 20px;
  left: 15px;
}
.dark-theme .wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-button .mdc-button__label {
  font-size: 14px;
  color: #866dff;
  font-weight: 600;
  text-transform: uppercase;
}
.dark-theme .tutorial_dialog_modal .mat-mdc-dialog-container {
  padding: 1rem 0.5rem;
  border-radius: 8px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
  background-color: #0b041e;
}
.dark-theme .setting_header_div_tutorials {
  background: #18112f;
  padding: 6px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
}
.dark-theme .setting_header_div_tutorials h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: white !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0 !important;
}
.dark-theme .page_tutorials p {
  color: white;
  font-size: 16px !important;
  line-height: normal !important;
  margin: 15px 0 24px;
}
.dark-theme .sharing_header_div {
  background: #18112f;
  padding: 0px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 96px;
  align-items: center;
  justify-content: space-between;
}
.dark-theme .sharing_header_div .help_icon {
  position: relative;
  top: 7px;
  left: 10px;
}
.dark-theme .sharing_header_div .sharing_header_div_left {
  display: flex;
}
.dark-theme .sharing_header_div .sharing_header_div_left .settings-header {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: white !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0;
}
.dark-theme .sharing_header_div .mat-mdc-button-base {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  line-height: 1.2;
  box-shadow: none;
  height: auto;
  padding: 0 16px;
}
.dark-theme .sharing_header_div .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.dark-theme .sharing_header_div .mat-mdc-button-base .mdc-button__label {
  font-weight: bold;
  letter-spacing: 0.4px !important;
}
.dark-theme .sharing_header_div .import-btn {
  background: #ffb5a0;
  border-color: #ffb5a0;
}
.dark-theme .sharing_header_div .import-btn .mdc-button__label {
  color: #210854;
}
.dark-theme .page_sharing {
  padding: 34px 24px;
  background: #291f4f;
}
.dark-theme .page_sharing .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}
.dark-theme .page_sharing .page_sharing_heading {
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.dark-theme .page_sharing .page_sharing_p {
  font-size: 16px;
  color: #777183;
  width: 38vw;
  line-height: normal;
}
.dark-theme .page_sharing .sharing_from, .dark-theme .page_sharing .search-input-box {
  margin: 24px 0px 0px;
  width: 209px;
  position: relative;
}
.dark-theme .page_sharing .sharing_from .sharing_from_input, .dark-theme .page_sharing .search-input-box .sharing_from_input {
  border: 1px solid rgba(255, 255, 255, 0.08);
  padding: 10px 0px 10px 40px;
  border-radius: 50px;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font: inherit;
}
.dark-theme .page_sharing .sharing_from .sharing_from_input::placeholder, .dark-theme .page_sharing .search-input-box .sharing_from_input::placeholder {
  color: rgba(255, 255, 255, 0.32);
}
.dark-theme .page_sharing .sharing_from .sharing_from_input::-moz-placeholder, .dark-theme .page_sharing .search-input-box .sharing_from_input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.32);
}
.dark-theme .page_sharing .sharing_from .sharing_from_input::-webkit-input-placeholder, .dark-theme .page_sharing .search-input-box .sharing_from_input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.32);
}
.dark-theme .page_sharing .sharing_from::before, .dark-theme .page_sharing .search-input-box::before {
  position: absolute;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-dark/search.png");
  top: 7px;
  left: 10px;
}
.dark-theme .sharing_custom_card {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 24px;
}
.dark-theme .sharing_custom_card .sharing_custom_card {
  font-size: 19px;
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 30px;
}
.dark-theme .sharing_custom_card .new-workbook {
  width: 23.6% !important;
  height: 374px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  margin-right: 5px !important;
  padding: 24px 0px;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
@media only screen and (max-width: 1024px) {
  .dark-theme .sharing_custom_card .new-workbook {
    width: 30% !important;
  }
}
.dark-theme .sharing_custom_card .new-workbook .icon {
  width: 48px;
  height: 48px;
  background: #866dff;
  border-radius: 5px;
  margin: 10px 0px 10px 0px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}
.dark-theme .sharing_custom_card .new-workbook .new_workbook_heading {
  font-size: 20px;
  color: white;
  font-weight: bold;
  margin-bottom: 18px;
}
.dark-theme .sharing_custom_card .new-workbook .mdc-button__label {
  color: #866dff;
  font-size: 14px;
}
.dark-theme .sharing_custom_card .content {
  width: 23.6% !important;
  padding: 17px 0px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.04);
  background-color: #18112f;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin: 9px 5px 0px !important;
}
@media only screen and (max-width: 1024px) {
  .dark-theme .sharing_custom_card .content {
    width: 30% !important;
  }
}
.dark-theme .sharing_custom_card .content::before {
  position: absolute;
  width: 100%;
  content: "";
  height: 1px;
  display: block;
  left: 0;
  top: 153px;
  z-index: 1;
  border-top: 1px solid #f1edf8;
}
.dark-theme .dark-theme .sharing_custom_card .content::before {
  border-top: 1px solid rgba(255, 255, 255, 0.08) !important;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-header {
  display: block;
  flex-direction: row;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: center;
  min-height: 48px;
  padding: 0;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .icon {
  width: 48px;
  height: 48px;
  background: #866dff;
  border-radius: 5px;
  margin: 20px 0px 10px 0px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .share_code {
  margin-bottom: 15px;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .share_code p {
  margin: 15px 0 10px;
  font-size: 16px;
  color: white;
  font-weight: 600;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .share_code span {
  color: rgba(255, 255, 255, 0.56);
  font-size: 16px;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .share_code .sharing_copy {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .setting_include {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 163px;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .setting_include p {
  margin: 15px 0 10px;
  font-size: 16px;
  color: white;
  font-weight: 600;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .setting_include span {
  color: rgba(255, 255, 255, 0.56);
  font-size: 16px;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .setting_include .mdc-button__label {
  color: #866dff;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .delete-button {
  margin: 5px 0px 0px;
}
.dark-theme .sharing_custom_card .content .mat-mdc-card-actions .delete-button .mdc-button__label {
  color: #ff7463;
  font-size: 14px;
}
.dark-theme .add-collection-modalbox {
  width: 350px !important;
}
.dark-theme .add-collection-modalbox .mat-mdc-dialog-container {
  padding: 0px;
  overflow: hidden;
  background-color: #0b041e;
}
.dark-theme .add-collection-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  padding: 19px 24px;
  display: block;
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.dark-theme .add-collection-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding: 0;
}
.dark-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list {
  padding: 0px 0px;
}
.dark-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item {
  height: auto;
}
.dark-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item:not(:last-child) {
  border-bottom: 1px solid #f1edf8;
}
.dark-theme .dark-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item:not(:last-child) {
  border-bottom: 1px solid rgba(241, 237, 248, 0.12) !important;
}
.dark-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item .mdc-list-item__content {
  padding: 0px 24px 16px;
  cursor: pointer;
}
.dark-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item .mdc-list-item__content .activity_item {
  font-size: 16px !important;
  color: white;
}
.dark-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item .mdc-list-item__content .activity_item_child {
  color: rgba(255, 255, 255, 0.56);
  font-size: 14px !important;
}
.dark-theme .add-collection-modalbox .mat-mdc-button {
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}
.dark-theme .add-collection-modalbox .mat-mdc-button .mdc-button__label {
  text-transform: uppercase;
  color: #866dff;
  font-size: 14px;
}
.dark-theme .rename-modalbox {
  width: 350px !important;
}
.dark-theme .rename-modalbox .mat-mdc-dialog-container {
  background-color: #0b041e;
}
.dark-theme .rename-modalbox .mat-mdc-dialog-title {
  color: white;
  padding: 19px 24px 0;
  display: block;
  font-size: 20px;
  font-weight: bold;
}
.dark-theme .rename-modalbox .mat-mdc-form-field {
  width: 100%;
}
.dark-theme .rename-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 1em 0 1em 0;
  color: white;
}
.dark-theme .rename-modalbox .mat-mdc-form-field .mat-mdc-form-field-label {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .rename-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0 24px 10px;
}
.dark-theme .rename-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.dark-theme .rename-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .import-collection-modalbox {
  width: 350px !important;
}
.dark-theme .import-collection-modalbox .mat-mdc-dialog-container {
  background-color: #0b041e;
}
.dark-theme .import-collection-modalbox .mat-mdc-dialog-title {
  color: white !important;
  padding: 19px 24px 0;
  display: block;
  font-size: 20px !important;
  font-weight: bold !important;
}
.dark-theme .import-collection-modalbox .mat-mdc-form-field {
  width: 100%;
}
.dark-theme .import-collection-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 0.8em 0 1em 0;
  color: white;
}
.dark-theme .import-collection-modalbox .mat-mdc-form-field .mat-mdc-form-field-label {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .import-collection-modalbox .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.dark-theme .import-collection-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0 24px 10px;
}
.dark-theme .import-collection-modalbox .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.dark-theme .mat-mdc-dialog-container .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
}
.dark-theme .delete-lesson-plan-step-actions .mat-flat-button {
  color: #866dff !important;
}
.dark-theme .type-edit-modalbox {
  overflow: hidden;
  width: 70vw !important;
}
.dark-theme .type-edit-modalbox .mat-mdc-dialog-title {
  padding: 19px 24px 0;
  display: block;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: normal;
}
body:not(.dark-theme) .dark-theme .type-edit-modalbox .mat-mdc-dialog-title {
  color: #210854 !important;
}
.dark-theme .dark-theme .type-edit-modalbox .mat-mdc-dialog-title {
  color: white !important;
}
.dark-theme .type-edit-modalbox .mat-mdc-form-field {
  width: 100%;
}
.dark-theme .type-edit-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 1em 0 1em 0;
  color: white;
}
.dark-theme .type-edit-modalbox .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.dark-theme .type-edit-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 8px 24px;
}
.dark-theme .type-edit-modalbox .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.dark-theme .type-edit-modalbox .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  height: 0px !important;
}
.dark-theme .type-edit-modalbox .mat-mdc-focused {
  background-color: none;
}
.dark-theme .type-edit-modalbox #no-padding .mat-mdc-form-field-infix {
  padding: 0px !important;
  border-top: 0px !important;
}
.dark-theme .type-edit-modalbox #no-padding .mat-mdc-form-field-infix textarea {
  background-color: #0b041e;
  border: 2px solid rgba(211, 211, 211, 0.3);
  color: white;
}
.dark-theme .collection-added-modalbox {
  width: 350px !important;
}
.dark-theme .collection-added-modalbox .mat-mdc-dialog-title {
  color: white;
  padding: 19px 24px 0;
  display: block;
  font-size: 20px;
  font-weight: bold;
}
.dark-theme .collection-added-modalbox .mat-dialog-content {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.56);
}
.dark-theme .collection-added-modalbox .mat-dialog-content span {
  color: white;
}
.dark-theme .collection-added-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
}
.dark-theme .collection-added-modalbox .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.dark-theme .sharing_header_div_collection {
  background: #18112f;
  padding: 0px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 120px;
  align-items: center;
  justify-content: space-between;
}
.dark-theme .sharing_header_div_collection .sharing_header_left_collection {
  display: flex;
  flex-direction: column;
}
.dark-theme .sharing_header_div_collection .sharing_header_left_collection span {
  color: #866dff;
  font-size: 14px;
  margin-bottom: 8px;
}
.dark-theme .sharing_header_div_collection .sharing_header_left_collection .settings-header {
  color: white;
  margin: 0 0 0;
}
.dark-theme .sharing_header_div_collection button {
  height: 40px !important;
  width: auto !important;
  right: 15px;
  min-width: auto;
  box-shadow: none !important;
  background-color: #ffb5a0 !important;
  border: 1px solid #ffb5a0;
  border-radius: 4px;
}
.dark-theme .sharing_header_div_collection button .mdc-button__label {
  line-height: 40px !important;
  color: white;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .collection_field {
  margin: 24px 24px !important;
  padding: 0 !important;
}
.dark-theme .collection_field .mat-mdc-form-field-infix {
  width: 302px;
  color: white;
  font-size: 16px;
}
.dark-theme .collection_field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  color: white;
  opacity: 0.64;
}
.dark-theme .collection_field .mdc-form-field-outline {
  color: rgba(255, 255, 255, 0.64);
}
.dark-theme .sharing_custom_card_collection {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 24px;
}
.dark-theme .sharing_custom_card_collection .content {
  width: 23.6% !important;
  padding: 17px 17px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.04);
  background-color: #18112f;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin: 9px 5px 0px !important;
}
.dark-theme .sharing_custom_card_collection .content .mat-mdc-card-header {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding: 0 !important;
}
.dark-theme .sharing_custom_card_collection .content .mat-mdc-card-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 10px 0 0;
  margin-bottom: -10px;
  min-height: auto;
}
.dark-theme .sharing_custom_card_collection .content .mat-mdc-card-actions .add-button .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  font-weight: bold;
}
.dark-theme .sharing_custom_card_collection .content .mat-mdc-card-actions .delete-button .mdc-button__label {
  color: #ff7463;
  font-size: 14px;
  font-weight: bold;
}
.dark-theme .sharing_custom_card_collection .content .mat-mdc-card-actions .ellipsis {
  position: absolute;
  right: -20px;
}
.dark-theme .sharing_custom_card_collection .content .mat-mdc-card-actions .ellipsis .mdc-button__label {
  color: #cac4d7 !important;
}
.dark-theme .account_content_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #FFF;
  margin: 24px;
  border-radius: 8px;
}
.dark-theme .account_content_div .each_row {
  display: flex;
  border-bottom: 1px solid rgba(211, 211, 211, 0.3);
}
.dark-theme .account_content_div .each_row .description-section {
  padding: 24px 24px;
}
@media only screen and (max-width: 1024px) {
  .dark-theme .account_content_div .each_row .description-section {
    width: 30% !important;
  }
}
.dark-theme .account_content_div .each_row .description-section h3 {
  font-size: 20px;
  font-weight: bold;
  color: white;
}
.dark-theme .account_content_div .each_row .description_option {
  padding: 24px 24px;
  width: 50%;
}
@media only screen and (max-width: 1024px) {
  .dark-theme .account_content_div .each_row .description_option {
    width: 70% !important;
  }
}
.dark-theme .account_content_div .each_row .description_option h4 {
  color: white;
  font-size: 16px;
}
.dark-theme .account_content_div .each_row .description_option span {
  color: #777183;
  font-size: 16px;
  margin-left: 12px;
}
.dark-theme .account_content_div .each_row .description_option .add_card {
  font-size: 14px;
  color: #866dff;
  text-transform: uppercase;
  cursor: pointer;
}
.dark-theme .plan_description_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  padding: 24px 24px;
  flex-wrap: wrap;
}
.dark-theme .plan_description_div .mat-mdc-button-base {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
}
.dark-theme .plan_description_div .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.dark-theme .plan_description_div .mat-mdc-button-base .mdc-button__label {
  font-style: italic;
  letter-spacing: 0.4px;
  text-transform: capitalize !important;
  font-size: 18px;
}
.dark-theme .add-credit-card-modalbox {
  width: 350px;
  border-radius: 8px !important;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-dialog-title {
  font-size: 20px;
  color: white;
  font-weight: bold;
  margin: 0 0 0;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0px !important;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 0 0;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix input[type=text] {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.64);
  padding-left: 10px;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  top: 37px;
  left: 16px;
  color: white;
  font-size: 16px;
  opacity: 0.64;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-underline {
  background-color: transparent !important;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-ripple {
  display: none;
}
.dark-theme .add-credit-card-modalbox .mat-stroked-button {
  border-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .add-credit-card-modalbox .mat-mdc-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .add-credit-card-modalbox .mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  background-color: #0b041e;
  width: auto !important;
  top: 32px !important;
  padding: 0px 7px;
  opacity: 1 !important;
}
.dark-theme .add-credit-card-modalbox .card_date_details {
  display: flex;
  justify-content: space-between;
}
.dark-theme .add-credit-card-modalbox .btn-default {
  background-color: transparent !important;
  font-weight: bold;
  color: #866dff;
  text-transform: uppercase;
  font-size: 14px !important;
}
.dark-theme .add-credit-card-modalbox .agree_span {
  color: white;
  opacity: 56%;
}
.dark-theme .add-credit-card-modalbox .mat-mdc-checkbox-checked .mat-mdc-checkbox-background {
  background-color: #866dff !important;
}
.dark-theme .successful-update-modalbox {
  width: 350px !important;
}
.dark-theme .successful-update-modalbox .mat-mdc-dialog-container {
  padding: 19px 24px 5px;
  background-color: #0b041e;
}
.dark-theme .successful-update-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.dark-theme .successful-update-modalbox .mat-mdc-dialog-container .msg_success {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.56);
}
.dark-theme .successful-update-modalbox .mat-mdc-dialog-container .ok_success_btn {
  width: auto !important;
  margin-right: 0px;
  float: right;
  margin-top: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.dark-theme .successful-update-modalbox .mat-mdc-dialog-container .ok_success_btn .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .successful-update-modalbox .mat-mdc-dialog-container .cdk-focused {
  display: none;
}
.dark-theme .selected-plan-modalbox {
  width: 350px !important;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container {
  padding: 17px 24px 5px;
  background-color: #0b041e;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .titel {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
  display: block;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field {
  width: 100%;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0px !important;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 0 0;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-infix input[type=text] {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.64);
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  top: 37px;
  left: 16px;
  color: white;
  font-size: 16px;
  opacity: 0.64;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-underline {
  background-color: transparent !important;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-ripple {
  display: none;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  background-color: #fff;
  width: auto !important;
  background: #fff;
  top: 32px !important;
  padding: 0px 7px;
  opacity: 1 !important;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .comfrm_new_plan_btn {
  width: auto !important;
  margin-right: 0px;
  float: right;
  margin-top: 20px;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .comfrm_new_plan_btn .mdc-button__label {
  color: #866dff;
}
.dark-theme .selected-plan-modalbox .mat-mdc-dialog-container .cdk-focused {
  display: none;
}
.dark-theme .cancel-subscriptiton-modalbox {
  transform: scale(0.9) !important;
}
.dark-theme .cancel-subscriptiton-modalbox app-cancel-subscription {
  display: block;
  height: 100%;
}
.dark-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container {
  padding: 17px 24px 10px;
  background-color: #0b041e;
}
.dark-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
  display: block;
}
.dark-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .mat-typography {
  padding: 0px;
}
.dark-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .btn-default {
  width: auto !important;
  margin-right: 0px;
  margin-bottom: 10px;
  background-color: transparent;
}
.dark-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .btn-default .mdc-button__label {
  color: #866dff;
}
.dark-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .cdk-focused {
  display: none;
}
.dark-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .are-you-sure-span {
  color: white;
  font-size: 16px;
}
.dark-theme .feedback-form-panel {
  width: 500px;
}
.dark-theme .feedback-form-panel .feedback-form {
  width: 100%;
  height: 100%;
}
.dark-theme .feedback-form-panel .feedback-form .feedback-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dark-theme .feedback-form-panel .feedback-form .feedback-form-container .header {
  color: rgba(13, 2, 35, 0.56);
}
.dark-theme .dark-theme .feedback-form-panel .feedback-form .feedback-form-container .header {
  color: white !important;
}
.dark-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-title {
  font-size: 20px;
  margin-bottom: 20px;
  color: #210854;
  letter-spacing: normal;
  display: block;
}
.dark-theme .dark-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-title {
  color: white !important;
}
.dark-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-content {
  color: rgba(13, 2, 35, 0.56);
  margin: 0;
  padding: 4px 24px;
  letter-spacing: normal;
  line-height: normal;
  overflow-x: hidden;
  display: flex;
}
.dark-theme .dark-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-content {
  color: white !important;
}
.dark-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-typography {
  padding: 0px;
}
.dark-theme .unsaved-changes-panel {
  width: 400px;
}
.dark-theme .unsaved-changes-panel .mat-mdc-dialog-title {
  font-size: 20px;
  margin-bottom: 20px;
  color: #210854 !important;
  letter-spacing: normal;
  display: block;
}
.dark-theme .dark-theme .unsaved-changes-panel .mat-mdc-dialog-title {
  color: white !important;
}
.dark-theme .unsaved-changes-panel .mat-mdc-dialog-content {
  color: rgba(13, 2, 35, 0.56);
  margin: 0;
  padding: 4px 24px;
  letter-spacing: normal;
  line-height: normal;
  overflow-x: hidden;
  display: flex;
}
.dark-theme .dark-theme .unsaved-changes-panel .mat-mdc-dialog-content {
  color: white !important;
}
.dark-theme .unsaved-changes-panel .mat-typography {
  padding: 0px;
}
@media only screen and (max-width: 1280px) {
  .dark-theme .whizzimolibraries_container .drag-drop-box .new-workbook {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .dark-theme .whizzimolibraries_container .drag-drop-box .new-workbook {
    width: 31% !important;
  }
}
.dark-theme .whizzimolibraries_container .drag-drop-box .content {
  margin: 0px 9px 9px;
  background-color: #18112f;
}
@media only screen and (max-width: 1280px) {
  .dark-theme .whizzimolibraries_container .drag-drop-box .content {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .dark-theme .whizzimolibraries_container .drag-drop-box .content {
    width: 31% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .dark-theme .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .new-workbook {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .dark-theme .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .new-workbook {
    width: 31% !important;
  }
}
.dark-theme .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .content {
  margin: 0px 9px 9px;
  background-color: #18112f;
}
@media only screen and (max-width: 1280px) {
  .dark-theme .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .content {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .dark-theme .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .content {
    width: 31% !important;
  }
}
.dark-theme .whizzimolibraries_container .whizzimolibraries_header {
  background: #18112f;
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  align-items: center;
}
.dark-theme .whizzimolibraries_container .whizzimolibraries_header .header h2 {
  font-size: 32px;
  color: white;
}
.dark-theme .whizzimolibraries_container .whizzimolibraries_header .course-button {
  height: 40px !important;
  width: auto !important;
  box-shadow: none;
  right: 15px;
  min-width: auto;
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
}
.dark-theme .whizzimolibraries_container .whizzimolibraries_header .course-button .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.dark-theme .whizzimolibraries_container .mat-tab-header {
  background: #18112f;
  border: none;
}
.dark-theme .whizzimolibraries_container .mat-tab-header .mdc-tab__content {
  color: #866dff;
  text-transform: uppercase;
  font-weight: 600;
}
.dark-theme .whizzimolibraries_container .mat-tab-header .mat-ink-bar {
  height: 4px;
  background-color: #866dff;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content {
  padding: 24px 24px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .new-workbook {
  height: 125px;
  min-width: 200px;
  width: 18.8%;
  border-radius: 8px;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border: none;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  margin: 0px 9px 9px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .new-workbook span {
  color: #866dff;
  text-transform: uppercase;
  font-size: 14px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .mat-mdc-card-header {
  color: white;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .mat-mdc-card-header .mat-mdc-card-header-text {
  margin: 0 0px !important;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .add_to_course {
  padding-left: 10px;
  padding-right: 10px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .add_to_course .mdc-button__label {
  color: #866dff;
  text-transform: uppercase;
  font-size: 14px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .Added {
  padding-left: 10px;
  padding-right: 10px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .Added .mdc-button__label {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .remove {
  padding-left: 10px;
  padding-right: 10px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .remove .mdc-button__label {
  color: #ff7463;
  text-transform: uppercase;
  font-size: 14px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .ellipsis {
  position: absolute;
  right: 10px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .ellipsis .mdc-button__label {
  color: #866dff;
  opacity: 0.32;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from, .dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box {
  margin: 24px 0px 10px;
  width: 209px;
  position: relative;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input, .dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input {
  border: 1px solid rgba(255, 255, 255, 0.08);
  padding: 10px 0px 10px 40px;
  border-radius: 50px;
  outline: none;
  background-color: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.87);
  font: inherit;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input::placeholder, .dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input::placeholder {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input::-moz-placeholder, .dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input::-webkit-input-placeholder, .dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from::before, .dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box::before {
  position: absolute;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-dark/search.png");
  top: 7px;
  left: 10px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .length_p {
  color: rgba(255, 255, 255, 0.56);
  font-size: 16px;
}
.dark-theme .whizzimolibraries_container .mat-mdc-tab-body-content .mat-progress-bar {
  border-radius: 50px;
}
.dark-theme .add-wordbook-modalbox .mat-mdc-dialog-container {
  border-radius: 8px;
  background-color: #0b041e;
}
.dark-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: white;
  font-weight: bold;
  margin-top: 24px;
  letter-spacing: normal;
}
.dark-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  max-height: 45vh;
  border-bottom: solid 1px rgba(255, 255, 255, 0.08);
  border-top: solid 1px rgba(255, 255, 255, 0.08);
  padding: 0px 0px;
}
.dark-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(255, 255, 255, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 0px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
  cursor: pointer;
}
.dark-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-list-item:last-of-type {
  border-bottom: none;
}
.dark-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mdc-list-item__content {
  padding: 0px 24px !important;
}
.dark-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mdc-list-item__content .addwork_item {
  font-size: 16px !important;
  color: white;
  line-height: 30px !important;
}
.dark-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mdc-list-item__content .addwork_p {
  font-size: 14px !important;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 7px;
}
.dark-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  float: right;
  position: relative;
  top: 15px;
  font-family: "Roboto", sans-serif;
  padding: 4px 24px;
}
.dark-theme .add-wordbook-myLibrary-modalbox {
  width: 350px !important;
}
.dark-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container {
  border-radius: 8px;
  background-color: #0b041e;
}
.dark-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: white;
  font-weight: bold;
  padding: 19px 24px 0;
}
.dark-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content {
  max-height: 45vh;
  padding: 0px 0px;
}
.dark-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(255, 255, 255, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 0px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
}
.dark-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content .mdc-list-item__content {
  padding: 0px 24px !important;
}
.dark-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content .mdc-list-item__content .workbook_option_item {
  font-size: 16px !important;
  color: white;
  line-height: 30px !important;
}
.dark-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  float: right;
  position: relative;
  top: 15px;
}
.dark-theme .header_activity_top {
  position: fixed;
  display: flex;
  height: 85px !important;
  z-index: 115 !important;
  top: 0;
  background-color: #18112f;
}
.dark-theme .header_activity_top .nav- {
  cursor: pointer;
  height: 38px;
  padding-top: 5px;
}
.dark-theme .header_activity_top .header-activity-name {
  font-size: 32px;
  font-weight: bold;
  color: white;
  height: 150px;
  align-items: center;
  line-height: 0px;
}
.dark-theme .header_activity_top .header-workbook-name {
  font-size: 14px;
  color: #866dff;
  position: relative;
  height: 30px;
  align-items: center;
  text-transform: uppercase;
}
.dark-theme .header_activity_top .mat-ripple-element {
  display: none;
}
.dark-theme .add-course-modalbox {
  width: 350px !important;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container {
  padding: 0px;
  border-radius: 8px;
  background-color: #0b041e;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  margin: 0 0 20px;
  display: block;
  padding: 19px 24px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  margin: 0 0px;
  padding: 0 24px;
  max-height: 66vh;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .add_cousre_modal_p_course {
  color: white;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 16px;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .add_cousre_modal_subscription {
  color: grey;
  font-weight: bold;
  font-size: 16px;
  margin-top: 0;
}
.dark-theme .dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .add_cousre_modal_subscription {
  color: white !important;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix {
  color: white;
  font-size: 16px;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .course_description {
  margin-top: 12px;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .course_description .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  height: 59.5px;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  padding: 0px 10px;
  margin-bottom: 0px;
  justify-content: flex-end;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-mdc-button-base {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.dark-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .setting_screens {
  height: 100vh;
  overflow: auto;
  background-color: #291f4f !important;
}
.dark-theme .setting_screens .setting_header_div {
  height: 96px;
}
.dark-theme .setting_screens .tile-content_div {
  height: calc(100% - 79px);
  overflow-x: hidden;
}
.dark-theme .setting_screens .tile-content_div .mat-tab-group .mat-tab-header {
  top: 0px;
}
.dark-theme .setting_screens .tile-content_div .mat-mdc-tab-header {
  border: none;
}
.dark-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container {
  background-color: #18112f;
}
.dark-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 1;
  color: rgba(255, 255, 255, 0.87);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.dark-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #866dff;
}
.dark-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
body:not(.dark-theme) .dark-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #866dff !important;
}
.dark-theme .dark-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: white !important;
}
.dark-theme .new-tile-container {
  overflow: scroll;
  background-color: #291f4f;
}
.dark-theme .new-tile-container table {
  border: 10px;
  margin: auto;
}
.dark-theme .new-tile-container table tr {
  margin-bottom: 10px;
  border: none !important;
}
.dark-theme .new-tile-container table tr:last-child {
  margin-bottom: 0px;
}
.dark-theme .new-tile-container table tr td {
  margin: 5px;
  min-height: 60px;
  min-width: 60px;
  overflow: visible;
}
.dark-theme .inside-tile {
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-stretch: normal;
  font-size: 1.6837vw;
  font-style: normal;
  line-height: 60px;
  text-align: center;
  letter-spacing: normal;
  border-radius: 4px !important;
  border: solid 1px rgba(72, 30, 159, 0.24);
  min-width: 60px;
  min-height: 60px;
  z-index: 20;
}
.dark-theme .inside-tile > img {
  position: absolute;
  top: 20px;
  left: 5px;
}
.dark-theme .tile-layout-top {
  height: 74%;
}
.dark-theme .edit-lesson-plan-header {
  background-color: #18112f;
  padding: 24px 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.dark-theme .edit-lesson-plan-header .edit-lesson-plan-top-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dark-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .lesson-plan-date {
  color: #866dff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}
.dark-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div {
  width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dark-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .begin-lesson-btn {
  background-color: #ffb5a0;
  border-radius: 4px;
}
.dark-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .begin-lesson-btn .mdc-button__label {
  line-height: 40px !important;
  color: #210854;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .save-later-btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.dark-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .save-later-btn .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.dark-theme .edit-lesson-plan-header .mat-mdc-card-title {
  padding: 0;
}
.dark-theme .edit-lesson-plan-header .mat-mdc-card-title .errors_field {
  color: red;
  font-size: 16px;
  display: block;
}
.dark-theme .edit-lesson-plan-header .mat-mdc-card-title .lesson_plan_name {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: white !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  line-height: 1;
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.dark-theme .edit-lesson-plan-header .mat-mdc-card-title .lesson_plan_input {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  width: 40vw;
}
.dark-theme .edit-lesson-plan-header .mat-mdc-card-title .lesson_plan_title {
  margin: 0px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dark-theme .missing-tiles-button {
  color: #866dff !important;
}
.dark-theme .lesson_notes {
  display: flex;
  width: 100%;
  margin: 32px 0px 0px;
}
.dark-theme .lesson_notes .mat-mdc-form-field-infix {
  font-size: 16px;
}
.dark-theme .lesson_notes .lesson_notes_label {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: bold;
  color: white;
}
.dark-theme .lesson_notes .lesson_notes_left {
  display: flex;
  flex-direction: column;
  margin-right: 250px;
}
.dark-theme .lesson_notes .lesson_notes_left .lesson_notes_text {
  font-size: 16px;
  color: white;
  font-weight: normal;
}
.dark-theme .lesson_notes .lesson_notes_right {
  display: flex;
  flex-direction: column;
}
.dark-theme .lesson_notes .lesson_notes_right .lesson_notes_right_text {
  font-size: 16px;
  color: white;
  font-weight: normal;
}
.dark-theme .lesson_notes .lesson_notes_right input {
  font-size: 14px;
  color: white;
  font-weight: normal;
  width: 400px;
}
.dark-theme .edit-lesson-plan-body {
  border-radius: 8px;
  height: calc(100vh - 209px);
  overflow: auto;
}
.dark-theme .edit-lesson-plan-body > .content {
  padding: 24px;
}
.dark-theme .edit-lesson-plan-body .long-card {
  border-bottom: 1px solid rgba(211, 211, 211, 0.3);
  border-radius: 0px;
  z-index: 1;
}
.dark-theme .edit-lesson-plan-body .long-card .more_words_modal_activity, .dark-theme .edit-lesson-plan-body .long-card .more_tiles_modal_activity {
  margin-bottom: 16px !important;
}
.dark-theme .edit-lesson-plan-body .long-card .pdf_file_step {
  color: white;
  font-size: 16px;
  font-weight: bold;
}
.dark-theme .edit-lesson-plan-body .long-card .pdf_files {
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 34px;
  background-color: transparent !important;
  border: 1px solid rgba(211, 211, 211, 0.3);
}
.dark-theme .edit-lesson-plan-body .long-card .pdf_files .mdc-button__label {
  color: white !important;
}
.dark-theme .edit-lesson-plan-body .long-card .no_file_btn {
  padding: 0px;
  border: none;
}
.dark-theme .edit-lesson-plan-body .long-card .no_file_btn .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.dark-theme .add_step {
  cursor: pointer;
}
.dark-theme .add_step > .long-card {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.dark-theme .add_step span {
  font-size: 14px;
  font-weight: 500;
  color: #866dff;
  text-transform: uppercase;
}
.dark-theme .add_step mat-card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.dark-theme .long-card {
  margin: 0;
  background-color: #18112f;
  box-shadow: none !important;
  padding: 24px 24px !important;
}
.dark-theme .long-card .long-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
}
.dark-theme .long-card .long-card-header .long-card-header-right img {
  margin-right: 8px;
}
.dark-theme .long-card .long-card-header .long-card-header-right img:first-child {
  margin-right: 20px;
}
.dark-theme .long-card .long-card-header .long-card-header-right img:last-child {
  margin-left: 12px;
  margin-right: 0px;
}
.dark-theme .long-card .long-card-header .long-card-header-left {
  display: flex;
}
.dark-theme .long-card .long-card-header .long-card-header-left span {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: white;
  font-weight: bold;
  letter-spacing: -0.01em;
}
.dark-theme .long-card .mat-mdc-form-field-underline {
  display: none;
}
.dark-theme .long-card .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.dark-theme .long-card .mat-mdc-form-field-label {
  top: 25px;
  left: 10px;
  color: white;
  font-size: 16px;
  padding: 5px 10px;
  background: #18112f;
  width: auto !important;
}
.dark-theme .long-card.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: #18112f;
  padding: 5px 10px;
  width: auto;
  top: 20px;
  left: 16px;
}
.dark-theme .long-card .words_shuffle_btn_div {
  display: inline-flex;
  height: 56px;
  align-items: flex-start;
  margin-left: 16px;
}
.dark-theme .long-card .words_shuffle_btn_div .words_view_input .mat-mdc-text-field-wrapper {
  width: 94px;
  height: 56px;
  padding: 0 24px !important;
  display: flex;
  align-items: center;
  color: white;
}
.dark-theme .long-card .words_shuffle_btn_div .words_view_input .mat-mdc-text-field-wrapper .mat-select-value-text {
  color: white;
  font-size: 16px;
}
.dark-theme .long-card .words_shuffle_btn_div .shuffle_btn {
  width: 94px;
  height: 56px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid #866dff;
  border-left: none;
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  background: transparent;
}
.dark-theme .long-card .words_shuffle_btn_div .shuffle_btn:disabled {
  border-color: gray !important;
  color: gray !important;
}
.dark-theme .long-card .starting_view_dropdown .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 191px;
  height: 56px;
  padding: 0 24px !important;
  display: flex;
  align-items: center;
  color: white;
}
.dark-theme .long-card .starting_view_dropdown .mat-mdc-form-field-infix .mat-select-value-text {
  color: white;
  font-size: 16px;
}
.dark-theme .long-card .starting_view_dropdown .mat-mdc-form-field-underline {
  display: none;
}
.dark-theme .long-card .starting_view_dropdown .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.dark-theme .long-card .starting_view_dropdown .mat-mdc-form-field-label {
  top: 29px;
  left: 10px;
  color: white;
  font-size: 16px;
  padding: 5px 10px;
}
.dark-theme .long-card .starting_view_dropdown.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: #fff;
  padding: 5px 10px;
  width: auto;
  top: 20px;
  left: 16px;
}
.dark-theme .dark-theme .long-card .starting_view_dropdown.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  background-color: #18112f !important;
}
.dark-theme .long-card .missing_tile_btn {
  border: 1px solid white;
  color: white;
  font-size: 14px;
  height: 57px;
  background-color: transparent;
}
.dark-theme .long-card .disabled_field .mat-mdc-form-field-infix {
  border: 1px solid #ccc6d7 !important;
}
.dark-theme .long-card .disabled_field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  color: #ccc6d7 !important;
}
.dark-theme .long-card .example-box {
  height: 56px;
}
.dark-theme .long-card .example-box .mat-button-wrapper {
  font-size: 24px;
  font-weight: bold;
  position: relative;
  padding: 0px 0px 0px 24px;
}
.dark-theme .long-card .cdk-drop-list .mat-mdc-button-base {
  height: 56px;
  border: 1px solid rgba(211, 211, 211, 0.3) !important;
}
.dark-theme .long-card .cdk-drop-list .mat-mdc-button-base .mdc-button__label {
  font-size: 24px;
  font-weight: bold;
  position: relative;
  padding: 0px 0px 0px 30px;
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .long-card .cdk-drop-list .mat-mdc-button-base .mdc-button__label::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  left: 0;
  top: 3px;
}
.dark-theme .long-card .cdk-drop-list .mat-mdc-button-base:not(.cdk-drag-disabled) .mdc-button__label::before {
  background-image: url("assets/img/theme-dark/move.png");
}
.dark-theme .long-card .cdk-drop-list .mat-mdc-button-base.text-button {
  border: 0 !important;
  cursor: default;
}
.dark-theme .long-card .cdk-drop-list .mat-mdc-button-base.text-button .mdc-button__label::before {
  background-image: none !important;
}
.dark-theme .long-card .cdk-drop-list .moveIcon .mdc-button__label::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-dark/add-circle-1.png");
  background-repeat: no-repeat;
  left: 0;
  top: 3px;
}
.dark-theme .long-card .custom-margin-activity-popup {
  margin-right: 24px !important;
  margin-bottom: 24px;
}
.dark-theme .long-card .custom-margin-activity-popup .mat-option[aria-disabled=true] {
  opacity: 0.32;
}
.dark-theme #registration_container {
  margin: 0px;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}
.dark-theme #registration_container .left-box {
  width: 72px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 0px;
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
}
.dark-theme #registration_container .left-box img {
  border-radius: 4px;
}
.dark-theme #registration_container .right-box {
  width: calc(100% - 72px);
  float: left;
  margin-left: 72px;
}
.dark-theme #registration_container .right-box .registration_header {
  height: 153px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.dark-theme #registration_container .right-box .registration_header .registration_header_left {
  display: flex;
  flex-direction: column;
}
.dark-theme #registration_container .right-box .registration_header .registration_header_left span {
  color: #866dff;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 0 8px;
}
.dark-theme #registration_container .right-box .registration_header .registration_header_left h1 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: white !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0 0 16px;
  line-height: 1;
}
.dark-theme #registration_container .right-box .registration_header .registration_header_left p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #777183;
  margin: 0;
}
.dark-theme #registration_container .right-box .registration_header .registration_header_right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 91px;
}
.dark-theme #registration_container .right-box .registration_header .registration_header_right a {
  background-color: #ffb5a0;
  border-radius: 4px;
  color: #210854;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  padding: 12px 19px;
}
.dark-theme #registration_container .right-box .form_container {
  width: 435px;
  background: #fff;
  border-radius: 8px;
  margin: 24px;
  padding: 32px 24px;
}
.dark-theme #registration_container .right-box .form_container .mat-mdc-form-field {
  width: 100%;
}
.dark-theme #registration_container .right-box .form_container .mat-mdc-form-field .mat-input-element {
  color: white !important;
  font-size: 16px !important;
}
.dark-theme #registration_container .right-box .form_container .mat-mdc-form-field .mat-mdc-form-field-label {
  color: rgba(255, 255, 255, 0.56);
}
.dark-theme #registration_container .right-box .form_container .mdc-label {
  white-space: normal;
  color: white;
  font-size: 16px;
  font-weight: 500;
}
.dark-theme #registration_container .right-box .form_container .mdc-label a {
  color: #866dff !important;
}
.dark-theme #registration_container .right-box .form_container .mat-mdc-checkbox {
  margin-bottom: 18px;
  display: flex;
}
.dark-theme #registration_container .right-box .form_container .mat-mdc-checkbox .mdc-checkbox {
  margin: 0px 16px 0px 0px;
  top: 3px;
  width: 18px;
  height: 18px;
}
.dark-theme #registration_container .right-box .form_container .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  border: 2px solid rgba(255, 255, 255, 0.16);
}
.dark-theme #registration_container .right-box .form_container .mat-select-value-text {
  color: white;
}
.dark-theme #registration_container .right-box .form_container .mat-select-arrow {
  color: white;
}
.dark-theme #registration_container .right-box .form_container .mat-mdc-checkbox-checked .mdc-checkbox__background {
  background-color: transparent !important;
  border: 2px solid #866dff !important;
}
.dark-theme #registration_container .right-box .form_container .mat-mdc-checkbox-checked .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke: #866dff !important;
}
.dark-theme #registration_container .right-box .form_container .mat-mdc-checkbox-checked .mdc-checkbox__checkmark-path {
  stroke-width: 3.13333px;
}
.dark-theme #registration_container .right-box .form_container .vp-bit span {
  margin-right: 16px;
  color: white;
}
.dark-theme #registration_container .right-box .form_container .alert {
  margin: 0px 0px 20px;
}
.dark-theme #registration_container .right-box .form_container .alert i {
  margin-right: 16px;
  color: white;
}
.dark-theme #registration_container .right-box .form_container .passwords_match {
  margin: 0px 0px 20px;
}
.dark-theme #registration_container .right-box .form_container .register_btn {
  background-color: #210854;
  border-radius: 4px;
  box-shadow: none !important;
  padding: 14px 17px;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  outline: 0;
  border: none;
}
.dark-theme .workbook-body .mat-mdc-tab-header {
  padding: 0px 22px;
  background-color: #18112f;
}
.dark-theme .workbook-body .mat-mdc-tab-header .mdc-tab__content {
  opacity: 1;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #866dff;
  text-transform: uppercase;
}
.dark-theme .workbook-body .mat-mdc-tab-header .mdc-tab--active {
  opacity: 1 !important;
}
.dark-theme .workbook-body .mat-mdc-tab-header .mdc-tab--active .mdc-tab__content {
  opacity: 1 !important;
}
.dark-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #866dff;
}
.dark-theme .workbook_builder_filter .help_icon {
  position: relative;
  top: 2px;
  font-size: 18px;
  left: 10px;
}
.dark-theme .demo_plan_wordlist {
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px 0;
  font-size: 14px;
  color: #210854;
  text-align: center;
}
.dark-theme .workbook_builder_filter_main {
  background-color: #18112f !important;
}
.dark-theme .workbook_builder_filter_main .filter-close span {
  color: white;
}
.dark-theme .words_container_filter {
  background-color: #291f4f !important;
}
.dark-theme .words_container_filter .filters-container .workbook_builder_filter_main .filter-body .filter-header .filter-title {
  color: white;
}
.dark-theme .words_container_filter .filters-container .workbook_builder_filter_main .filter-body .filter-header .filter-description {
  color: white;
}
.dark-theme .words_container_filter .results-container {
  margin-right: 24px;
}
.dark-theme .words_container_filter .results-container .word-list-header {
  background-color: #0b041e;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.dark-theme .words_container_filter .results-container .word-panel {
  background-color: #18112f;
}
.dark-theme .words_container_filter .results-container .word-panel .word-content {
  color: white;
}
.dark-theme .words_container_filter .workbook_chat-container {
  background-color: #18112f;
  color: white;
}
.dark-theme .words_container_filter .workbook_chat-container .chat-container {
  background-color: #18112f;
  color: white;
}
.dark-theme .words_container_filter .workbook_chat-container .chat-container .chat-title {
  color: white;
}
.dark-theme .words_container_filter .workbook_chat-container .chat-container .chat-header {
  border-bottom: 1px solid #f1edf8;
}
.dark-theme .words_container_filter .workbook_chat-container .chat-messages .user-message {
  background-color: #866dff;
  color: white;
}
.dark-theme .words_container_filter .workbook_chat-container .chat-messages .ai-message {
  background-color: #866dff;
  color: white;
}
.dark-theme .words_container_filter .workbook_chat-container .chat-messages .loading-bubble {
  background-color: #866dff;
}
.dark-theme .words_container_filter .workbook_chat-container .chat-messages .dot {
  background-color: white;
}
.dark-theme .words_container_filter .workbook_chat-container .chat-input {
  border-top: 1px solid #f1edf8;
}
.dark-theme .words_container_filter .workbook_chat-container .chat-input textarea {
  border: 1px solid #f1edf8;
  background-color: #f2f2f2;
}
.dark-theme .words_container_filter .workbook_chat-container .chat-input .send-button {
  background: #866DFF !important;
  color: white !important;
}
.dark-theme .words_container_filter .selection-container {
  background-color: #18112f;
}
.dark-theme .sentences-and-more-container {
  padding: 0 0px;
}
.dark-theme .sentences-and-more-container .search-container {
  background-color: #291f4f !important;
}
.dark-theme .sentences-and-more-container .section-container {
  background-color: #291f4f !important;
  padding: 0 24px 24px;
  border-radius: 0 !important;
}
.dark-theme .sentences-and-more-container .section-container .section {
  padding: 24px;
  background-color: #18112f;
}
.dark-theme .sentences-and-more-container .section-container .section .section-header .section-header-text h2 {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: white;
  margin: 0 0 8px;
}
.dark-theme .sentences-and-more-container .section-container .section .section-header .section-header-text .description {
  color: white;
  margin: 0 0 24px;
}
.dark-theme .sentences-and-more-container .section-container .section:not(:first-child) {
  border-top: solid 1px #f1edf8;
}
.dark-theme .dark-theme .sentences-and-more-container .section-container .section:not(:first-child) {
  border-top: 1px solid rgba(241, 237, 248, 0.12) !important;
}
.dark-theme .sentences-and-more-container .section-container .section:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.dark-theme .sentences-and-more-container .section-container .section:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.dark-theme .sentences-and-more-container .divider {
  width: 100%;
  height: 1px;
  border: solid 1px rgba(211, 211, 211, 0.3);
}
.dark-theme .sentences-and-more-container .section-button {
  color: #866dff;
}
.dark-theme .sentences-and-more-container .content-card {
  display: flex;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px rgba(211, 211, 211, 0.3);
  background-color: transparent;
}
.dark-theme .sentences-and-more-container .content-card span:first-child {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .sentences-and-more-container .content-card button {
  background-color: transparent;
}
.dark-theme .sentences-and-more-container .content-card button .mdc-button__label {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .sentences-and-more-container .card-text {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: white;
}
.dark-theme .sentences-and-more-container .new-card-button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #866dff;
  border-color: #866dff;
  padding: 10px;
}
.dark-theme .workbook_item .activity_item_child {
  font-size: 14px !important;
  color: white;
  margin-bottom: 7px;
}
.dark-theme .custom_filter_card_content .custom_input_content textarea {
  width: 170px;
  height: 61px;
  font-size: 16px;
  padding: 10px 17px;
  background: transparent;
  box-shadow: none;
  border: 1px solid rgba(211, 211, 211, 0.3);
  color: rgba(255, 255, 255, 0.87);
  font-family: "Roboto";
}
.dark-theme .custom_filter_card_content .text-box-wrapper {
  border: 1px solid rgba(211, 211, 211, 0.3);
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .quick-unselected {
  color: white;
}
.dark-theme .workbook_builder_header_main {
  background-color: #18112f;
}
.dark-theme .workbook_builder_header_main .workbook_builder_header {
  min-width: calc(100% - 230px);
}
.dark-theme .workbook_builder_header_main .workbook_builder_header .workbook-title {
  color: white;
}
.dark-theme .workbook_builder_header_main .workbook_builder_header .workbook-owner {
  color: #866dff;
}
.dark-theme .workbook_builder_header_main .workbook_builder_header .workbook-description {
  color: white;
}
.dark-theme .workbook_builder_header_main .workbook_builder_header .mat-mdc-form-field {
  width: 90% !important;
}
.dark-theme .sentences-search-box .search_img {
  background-image: url("assets/img/theme-dark/search.png");
  width: 22px !important;
  height: 24px;
}
.dark-theme .files_search_container .search-box {
  padding: 8px !important;
  background-color: rgba(255, 255, 255, 0);
}
.dark-theme .files_search_container .search-box .search_img {
  background-image: url("assets/img/theme-dark/search.png");
  width: 22px !important;
  height: 24px;
  margin-right: 10px;
}
.dark-theme .files_search_container .search-box input {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-form-field-appearance-outline .mat-mdc-form-field-outline {
  color: rgba(255, 255, 255, 0.12);
}
.dark-theme .plan-description {
  background-color: #18112f;
  width: 220px;
  border: 1px solid rgb(234, 236, 238);
  margin-bottom: 16px;
  z-index: 1;
  transition: transform 0.5s ease-in-out;
}
.dark-theme .plan-description:hover {
  transform: scale(1.02);
  z-index: 2;
  transition: transform 0.5s ease-in-out;
}
.dark-theme .plan-description .plan-price-text {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .plan-description .plan-price-sub-text {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .account_note {
  font-size: 16px;
  color: #777183;
}
.dark-theme .mat-mdc-select-panel {
  background-color: #0b041e;
  box-shadow: 0 4px 9px 0 rgba(33, 8, 84, 0.21);
}
.dark-theme .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .tile_footer {
  background-color: #18112f;
}
.dark-theme .mat-mdc-menu-panel {
  background-color: #0b041e;
  box-shadow: 0 4px 9px 0 rgba(33, 8, 84, 0.21);
  max-width: 300px !important;
}
.dark-theme .mat-mdc-dialog-container {
  background-color: #0b041e;
  border: 2px solid white;
}
.dark-theme .tile-layout-top {
  background-color: #291f4f;
  overflow: hidden;
}
.dark-theme .tilelayout_div_footer .tileset_name {
  color: white;
}
.dark-theme .custom_filter_card_content .mat-mdc-form-field-infix {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .account-container {
  padding: 20px;
  margin: 20px;
  background-color: #18112f;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dark-theme .account-container .account-information {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.dark-theme .account-container .account-information-title h3 {
  color: white;
}
.dark-theme .account-container .detail {
  color: white;
  font-weight: 500;
  font-size: 16px;
}
.dark-theme .account-container button {
  background-color: transparent;
}
.dark-theme .account-container button .mdc-button__label {
  color: #866dff;
}
.dark-theme .account-container .info {
  color: white;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
}
.dark-theme .account-container .divider {
  width: 100%;
  border-bottom: rgba(211, 211, 211, 0.3) 1px solid;
  margin-top: 20px;
  margin-bottom: 20px;
}
.dark-theme .account-container .card-input {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: white;
  border: 1px solid white !important;
  padding-left: 10px;
}
.dark-theme .account-modal {
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dark-theme .account-modal .account-information {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.dark-theme .account-modal .account-information-title h3 {
  color: white;
}
.dark-theme .account-modal .detail {
  color: white;
  font-weight: 500;
  font-size: 16px;
}
.dark-theme .account-modal button {
  background-color: transparent;
}
.dark-theme .account-modal button .mdc-button__label {
  color: #866dff;
}
.dark-theme .account-modal .info {
  color: white;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
}
.dark-theme .account-modal .divider {
  width: 100%;
  border-bottom: rgba(211, 211, 211, 0.3) 1px solid;
  margin-top: 20px;
  margin-bottom: 20px;
}
.dark-theme .account-modal .card-input {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: white;
  border: 1px solid white !important;
  padding-left: 10px;
}
.dark-theme .agreement-text {
  margin-left: 20px !important;
  color: rgba(255, 255, 255, 0.56) !important;
  font-size: 14px;
}
.dark-theme .blackboard-wrap {
  height: 100vh;
  background-color: #291f4f;
  overflow: hidden;
}
.dark-theme .mini-tile-wrap {
  height: 100vh;
  background-color: #291f4f;
}
.dark-theme .blackboard_footer {
  background-color: #18112f;
  touch-action: none;
  top: 0;
}
.dark-theme .blackboard_footer .line {
  height: 62px;
  border-bottom: 2px solid rgba(211, 211, 211, 0.3);
}
.dark-theme .account_ht3 {
  color: white;
}
.dark-theme .files_search_container {
  background-color: #291f4f;
}
.dark-theme .file-container {
  background-color: #291f4f;
}
.dark-theme .file-container .upload_info {
  color: rgba(13, 2, 35, 0.5607843137);
}
.dark-theme .dark-theme .file-container .upload_info {
  color: white !important;
}
.dark-theme .bg_transparent_dark_salmon {
  background-color: transparent;
  color: #ff7463 !important;
}
.dark-theme .bg_salmon_btn {
  background-color: #ffb5a0 !important;
}
.dark-theme .bg_salmon_btn .mdc-button__label {
  color: #210854;
}
.dark-theme .bg_transparent_light_purple {
  background-color: transparent;
}
.dark-theme .bg_transparent_light_purple .mdc-button__label {
  color: #866dff;
}
.dark-theme .modal-container .header {
  color: white;
}
.dark-theme .workbook-body app-workbook-builder-words .button-container .find-words-button {
  background: #866DFF !important;
  color: white !important;
}
.dark-theme .workbook-body app-workbook-builder-words .button-container .save-close {
  color: #866DFF !important;
}
.dark-theme .workbook-body .mat-mdc-tab-header {
  border: none;
  border-bottom: solid 1px rgba(134, 109, 255, 0.48);
}
.dark-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container {
  background-color: #18112f;
}
.dark-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 1;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.dark-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #866dff;
}
.dark-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
.dark-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
}
body:not(.dark-theme) .dark-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #481e9f !important;
}
.dark-theme .file-content {
  background: #18112f;
}
.dark-theme .file-content .file-header h2 {
  color: white;
  margin: 20px 0 8px;
}
.dark-theme .file-content .description {
  color: white;
}
.dark-theme .file-content:not(:first-child) {
  border-top: solid 1px #f1edf8;
}
.dark-theme .dark-theme .file-content:not(:first-child) {
  border-top: 1px solid rgba(241, 237, 248, 0.12) !important;
}
.dark-theme .file-content:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.dark-theme .file-content:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.dark-theme .file-card-container {
  border: solid 1px #291f4f;
  color: white;
}
.dark-theme .file-card-container span:first-child {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .file-card-container button {
  background-color: transparent;
}
.dark-theme .file-card-container button .mdc-button__label {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .file-card-container .button-active {
  background-color: #866dff;
  border: 1px solid #866dff;
  height: 35px;
}
.dark-theme .file-card-container .button-active .mdc-button__label {
  color: white;
}
.dark-theme .file-card-container .button-inactive {
  background-color: transparent;
}
.dark-theme .file-card-container .button-inactive .mdc-button__label {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .page_tutorials {
  background: #291f4f;
}
.dark-theme .mat-drawer-content {
  background-color: #291f4f;
}
.dark-theme .sharing_modal_delete .mat-mdc-dialog-title {
  color: white;
}
.dark-theme .sharing_modal_delete .mat-dialog-content p {
  color: white;
  margin: 0 0 0;
}
.dark-theme .TutorialDialog_modal .mat-mdc-dialog-title {
  color: white !important;
  padding: 24px 24px 19px;
}
.dark-theme .sentences-search-box {
  background-color: rgba(255, 255, 255, 0);
}
.dark-theme .sentences-search-box input {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .word_group_bank h2 {
  color: white;
}
.dark-theme .word_group_bank table .card-text {
  color: white !important;
}
.dark-theme .word_group_bank_menu_item .mat-menu-item {
  color: white;
}
.dark-theme .file_card_menu .mat-menu-item {
  color: white;
}
.dark-theme .words_tile_box {
  background-color: transparent !important;
  border: 1px solid rgba(211, 211, 211, 0.3) !important;
}
.dark-theme .words_tile_box .mdc-button__label {
  color: white !important;
}
.dark-theme .sentence_example_box {
  background-color: transparent !important;
  border: 1px solid rgba(211, 211, 211, 0.3) !important;
}
.dark-theme .sentence_example_box .mdc-button__label {
  color: white !important;
}
.dark-theme .edit_words_tile_mattab .mdc-tab__content {
  color: #866dff;
}
.dark-theme .edit_words_tile_mattab .mat-mdc-tab-body-content {
  overflow: hidden;
}
.dark-theme .edit_words_tile_mattab .mat-mdc-tab-label-container {
  background-color: #18112f;
}
.dark-theme .edit_words_tile_mattab .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 0.6;
  color: rgba(255, 255, 255, 0.87);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.dark-theme .edit_words_tile_mattab .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #866dff;
}
.dark-theme .edit_words_tile_mattab .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
.dark-theme .edit_words_tile_mattab .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #866dff !important;
}
.dark-theme .pdf-right-control {
  color: white;
}
.dark-theme .input-divider {
  color: rgba(255, 255, 255, 0.56);
}
.dark-theme .same_plan_title {
  color: white;
}
.dark-theme .same_plan_content {
  color: white;
  font-weight: 500;
}
.dark-theme .demoplan_content {
  color: white;
  font-weight: 500;
}
.dark-theme .add_card_menu_h3 {
  color: white;
}
.dark-theme .focus-theme .side-container {
  border-right: solid 1px rgba(134, 109, 255, 0.48);
}
.dark-theme .delete_file_dialog .popup-header h1 {
  color: white;
}
.dark-theme .filter-card-tiles-header .search-box span {
  color: rgba(255, 255, 255, 0.72);
}
.dark-theme .filter-card-tiles-header .search-box input {
  color: rgba(255, 255, 255, 0.72);
}
.dark-theme .quick_container_h3 {
  color: white;
  font-size: 16px;
}
.dark-theme .pairing-section-container-span {
  color: white;
}
.dark-theme .pairing-section-container-h3 {
  color: white;
  margin-top: 0 !important;
}
.dark-theme .mat-bottom-sheet-container {
  background-color: #481e9f !important;
}
.dark-theme input.editable-text {
  color: gray;
  font-weight: normal;
  border: none;
  outline: none;
}
.dark-theme .hidden-btn {
  visibility: hidden;
}
.dark-theme mat-mdc-list-item.selected {
  background: #EEEEEE;
}
.dark-theme mat-mdc-list-item.selected.dark-mode {
  background: #866DFF;
}
.dark-theme .add-course.course-name .mat-mdc-form-field-wrapper, .dark-theme .for-internal-container .mat-mdc-form-field-wrapper {
  height: 56px;
}
.dark-theme .add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex, .dark-theme .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
  height: 100%;
}
.dark-theme .add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-label-wrapper, .dark-theme .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-label-wrapper {
  top: -16px;
}
.dark-theme .add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-infix, .dark-theme .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-infix {
  padding: 0.5em 0;
}
.dark-theme .add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-wrapper, .dark-theme .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-wrapper {
  margin: 0;
}
.dark-theme .add-course.course-name mat-label, .dark-theme .for-internal-container mat-label {
  color: #0d0223;
  opacity: 0.56;
}
.dark-theme .dark-theme .add-course.course-name mat-label, .dark-theme .dark-theme .for-internal-container mat-label {
  color: white !important;
}
.dark-theme .dark-theme .add-course.course-name input, .dark-theme .dark-theme .for-internal-container input {
  color: white;
}
.dark-theme .add-course.course_description .mat-mdc-form-field-infix {
  padding: 11px 0;
}
.dark-theme .add-course.course_description textarea {
  resize: none;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: normal;
}
.dark-theme .add-course.course_description textarea.mat-input-element {
  margin: 0;
}
.dark-theme .add-course.course_description .mat-mdc-form-field-wrapper {
  margin: 0;
}
.dark-theme .add-course.course_description mat-label {
  color: #0d0223;
  opacity: 0.56;
}
.dark-theme .dark-theme .add-course.course_description mat-label {
  color: white !important;
}
.dark-theme .dark-theme .add-course.course_description input {
  color: white;
}
.dark-theme .dark-theme .add_cousre_modal_subscription {
  color: white !important;
}
.dark-theme .custom-confirm-dialog {
  width: 360px;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container {
  display: flex;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container > * {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: #210854;
  margin-bottom: 0;
  padding: 19px 24px;
  letter-spacing: normal;
}
.dark-theme .dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: white !important;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content {
  color: rgba(13, 2, 35, 0.56);
  margin: 0;
  padding: 4px 24px;
  letter-spacing: normal;
  line-height: normal;
  overflow-x: hidden;
}
.dark-theme .dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content {
  color: white !important;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content.show-scores-dialog-content {
  margin-top: 8px;
  align-self: flex-start;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content.show-scores-dialog-content .value {
  font-weight: 500;
  margin-left: 8px;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  min-height: unset;
  padding: 0 14px 10px;
  justify-content: flex-end;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .confirm-btn {
  padding: 10px;
  line-height: unset;
  background-color: transparent !important;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .delete-btn {
  padding: 10px;
  line-height: unset;
  background-color: transparent !important;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .delete-btn .mdc-button__label {
  color: var(---mdc-text-button-label-text-color);
  font-weight: bold !important;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions button {
  padding: 10px;
  line-height: unset;
  background-color: transparent !important;
}
.dark-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .mdc-button__label {
  font-weight: bold !important;
}
.dark-theme .add-course-item .mdc-list-item__primary-text {
  color: #866DFF !important;
  letter-spacing: normal;
}
.dark-theme .side_bar_display mat-expansion-panel-header > span {
  display: none;
}
.dark-theme .custom-form-field label.mat-mdc-form-field-label {
  font-size: 13px;
  background-color: white;
  padding: 5px 10px;
  width: auto !important;
  top: 21px;
  left: 16px;
}
.dark-theme .dark-theme .custom-form-field label.mat-mdc-form-field-label {
  background-color: #18112f !important;
}
.dark-theme .word-selection-list .mdc-list-item__content {
  flex-direction: row !important;
}
.dark-theme .dark-theme .word-selection-list .mat-list-text {
  color: white;
}
.dark-theme .word-selection-list .mat-pseudo-checkbox {
  margin-right: 8px;
  border: 2px solid #866DFF;
  color: #866DFF;
}
.dark-theme .word-selection-list .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  color: #866DFF;
  border-color: #866DFF;
}
.dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox {
  color: #210854;
}
.dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mat-mdc-checkbox-frame {
  border-color: #866dff;
}
.dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox:not(.mat-mdc-checkbox-checked) .mat-mdc-checkbox-frame {
  border-color: rgba(33, 8, 84, 0.24);
}
.dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mdc-checkbox__checkmark-path {
  stroke: #866dff;
}
.dark-theme .dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox {
  color: white !important;
}
.dark-theme .dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mat-mdc-checkbox-frame {
  border-color: white !important;
}
.dark-theme .dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox:not(.mat-mdc-checkbox-checked) .mat-mdc-checkbox-frame {
  border-color: rgba(211, 211, 211, 0.3) !important;
}
.dark-theme .dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mdc-checkbox__checkmark-path {
  stroke: white !important;
}
.dark-theme .dark-theme .sort-tile-btn {
  color: #866dff !important;
}
.dark-theme .example-box.letter-tile {
  min-width: 72px;
  height: 64px !important;
  padding-left: 8px;
  padding-right: 12px;
}
.dark-theme .example-box.letter-tile .mdc-button__label {
  padding-left: 24px !important;
  font-size: 32px !important;
}
.dark-theme .example-box.letter-tile .mdc-button__label::before {
  top: 10px !important;
}
.dark-theme .example-box.letter-tile .mdc-button__label > span {
  display: inline-block;
  min-width: 26px;
  text-align: left;
}
.dark-theme .example-box.words_tile_box {
  min-width: 72px;
  height: 56px !important;
  padding-left: 8px;
  padding-right: 12px;
}
.dark-theme .example-box.words_tile_box .mdc-button__label {
  padding-left: 24px !important;
  display: inline-block;
  min-width: 26px;
  text-align: left;
}
.dark-theme .activity-content-label {
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  width: 160px;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #866dff;
  border-bottom: 2px solid #866dff;
  opacity: 1 !important;
  margin-bottom: 24px;
}
.dark-theme .text-holder::before {
  background-image: url("assets/img/theme-dark/move.png");
}
.dark-theme .course-workbook-edit-workbook-items-container .drag-n-drop-zone {
  display: inline-block;
  margin-right: 10px;
  width: 100%;
  min-height: 60px;
  position: relative;
}
.dark-theme .course-workbook-edit-workbook-items-container .drag-n-drop-zone .text-button {
  position: absolute;
}
.dark-theme .course-workbook-edit-workbook-items-container .drag-n-drop-zone .tile-button {
  margin-right: 8px;
  margin-bottom: 8px;
  min-height: 34px;
}
.dark-theme .vertical-center {
  display: flex !important;
  align-items: center;
}
:not(.dark-theme) .dark-theme mat-tab-group .mdc-tab__content .mdc-tab__text-label {
  color: #481e9f;
}
.dark-theme .dark-theme mat-tab-group .mdc-tab__content .mdc-tab__text-label {
  color: white !important;
}
.dark-theme .dark-theme mat-tab-group .mat-ink-bar {
  background-color: white !important;
}
.dark-theme .search-input-box input {
  font-size: 16px;
  padding-right: 16px !important;
}
.dark-theme .search-input-box input::placeholder {
  color: rgba(33, 8, 84, 0.32) !important;
}
.dark-theme .input-error-message {
  font-size: 12px;
  text-align: left;
  margin: 0 13px;
  color: #ff8a65;
}
.dark-theme .mat-mdc-button[disabled=true] {
  border-color: gray !important;
  color: gray !important;
}
.dark-theme .mat-mdc-button[disabled=true] .mdc-button__label {
  color: #a6a6a6 !important;
}
.dark-theme .mat-mdc-form-field-invalid .mat-mdc-form-field-outline-thick {
  color: #f44336;
}
.dark-theme .workbook-name-modal .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
  padding: 0;
}
.dark-theme .rename-file-dialog .mat-mdc-form-field-wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}
.dark-theme .words-sort-label {
  font-size: 14px;
  color: #866dff;
}
.dark-theme .course-workbook-edit-tiles-container .top-tiles-container {
  max-height: 210px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 64px;
}
.dark-theme .course-workbook-edit-tiles-container .top-tiles-container .top-tile:last-child {
  flex: 1;
}
.dark-theme .more_tiles_modal_activity.disabled {
  color: gray;
  cursor: not-allowed;
}
.dark-theme .tile-updating-options {
  width: 360px;
}
.dark-theme .word-updating-options {
  width: 560px;
}
.dark-theme span.disabled, .dark-theme img.disabled {
  color: gray !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}
.dark-theme mat-mdc-form-field.disabled .mat-mdc-form-field-infix {
  border-color: gray !important;
}
.dark-theme mat-mdc-form-field.disabled .mat-mdc-form-field-infix input {
  color: gray !important;
}
.dark-theme .mat-button-disabled {
  color: gray !important;
  border-color: gray !important;
}
.dark-theme .label.disabled {
  color: gray !important;
}
.dark-theme .workbook-edit-menu-dialog {
  max-height: 900px;
  max-width: 88vw !important;
}
.dark-theme .workbook-edit-menu-dialog .mat-mdc-dialog-container {
  padding: 24px;
  overflow: hidden;
}
.dark-theme .course-workkbook-edit-words-table-container {
  overflow: auto;
  max-height: 200px;
}
.dark-theme app-course-workbook-edit-menu app-course-workbook-edit .long-card {
  cursor: pointer;
  padding: 0 !important;
}
.dark-theme .shuffle_tiles {
  border-radius: 4px !important;
  border: 1px solid #866dff !important;
  color: #866dff !important;
  width: 94px;
  height: 58px;
}
.dark-theme .shuffle_tiles:disabled {
  color: gray !important;
  border: 1px solid gray !important;
  cursor: default !important;
}
.dark-theme .color-menu .mat-button-toggle-checked {
  background: #f1eeff;
}
.dark-theme .letter-card-dialog {
  overflow: hidden;
}
.dark-theme .letter-card-dialog .mat-mdc-dialog-container {
  padding: 0;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 0.08) !important;
  overflow: hidden;
  border: 0 !important;
}
.dark-theme .letter-card-dialog .mat-dialog-content {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.dark-theme .mat-mdc-menu-panel.mat-menu-color {
  overflow: hidden;
}
.dark-theme .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 1em 0 1em 0;
}
.dark-theme .mat-mdc-form-field-error-wrapper {
  font-size: 75%;
}
.dark-theme .mdc-list {
  padding: 0px !important;
}
.dark-theme .mat-mdc-list-base .mat-mdc-list-item {
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  padding: 0;
}
.dark-theme .mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__primary-text {
  display: flex;
  flex-direction: column;
  letter-spacing: normal;
}
.dark-theme .mat-mdc-dialog-container {
  border-radius: 4px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
}
.dark-theme .mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden !important;
  box-shadow: none !important;
}
.dark-theme .mat-mdc-dialog-container .mat-mdc-dialog-actions .mdc-button__label {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #866dff;
}
.dark-theme .mat-form-field-invalid .mdc-notched-outline--upgraded {
  color: #f44336 !important;
}
.dark-theme .mat-form-field-invalid .mat-mdc-floating-label {
  color: rgba(0, 0, 0, 0.6) !important;
}
.dark-theme .mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  color: white !important;
}
.dark-theme .mat-form-field-appearance-outline .mdc-form-field-outline {
  color: rgba(255, 255, 255, 0.18);
}
.dark-theme .mat-mdc-form-field-should-float .mdc-form-field-outline {
  color: white !important;
}
.dark-theme .mdc-text-field .mdc-floating-label {
  top: 1.84375em !important;
  margin-top: -0.25em;
}
.dark-theme .mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border: none;
  border-radius: 50%;
}
.dark-theme .mat-mdc-tab .mdc-tab__text-label {
  justify-content: center;
  white-space: nowrap;
  letter-spacing: normal;
}
.dark-theme .mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: normal;
}
.dark-theme .mat-mdc-option .mat-pseudo-checkbox {
  display: none;
}
.dark-theme .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  border-color: #866dff !important;
  background-color: white !important;
}
.dark-theme .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #866dff !important;
}
.dark-theme .mdc-dialog__title::before {
  height: auto !important;
}
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #e0e0e0;
}
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}

.focus-theme {
  /* You can add global styles to this file, and also import other style files */
  @import "indigo-pink.css";
  /*= All Possible Tile Colors
  ========================================*/
  /*= All Possible Tile Font Colors
  ========================================*/
  /*= All Possible Tile Colors
  ========================================*/
  /*= All Possible Tile Colors
  ========================================*/
  /* ===setting header ===*/
  /* ===setting header ===*/
  /* === General setting page ===*/
  /* === Geberal setting page ===*/
  /*======= side nav bar ===*/
  /*======= side nav bar ===*/
  /*======= activity page header ===*/
  /*======= activity page header ===*/
  /*======= blackboard page ===*/
  /*======= blackboard page ===*/
  /*======= sentence page ===*/
  /*======= sentence page ===*/
  /*======= PDF page ===*/
  /*======= PDF page ===*/
  /*======= Tile layout ===*/
  /*======= Tile layout ===*/
  /*======= activity header resizable handle  ===*/
  /*======= activity header resizable handle  ===*/
  /*======= whiteboard page  ===*/
  /*======= whiteboard page  ===*/
  /*======= phrases page  ===*/
  /*======= phrases page  ===*/
  /*======= Spelling page  ===*/
  /*======= Spelling page  ===*/
  /*======= workbook page  ===*/
  /*======= workbook page  ===*/
  /*======= tilelayout page  ===*/
  /*======= tilelayout page  ===*/
  /*======= flashcard page  ===*/
  /*======= flashcard page  ===*/
  /*======= mini tile page  ===*/
  /*======= mini tile page  ===*/
  /*======= blank tile page  ===*/
  /*======= mini tile page  ===*/
  /*======= passage page  ===*/
  /*======= passage page  ===*/
  /*======= time reading page  ===*/
  /*====  Time reading modalbox   ====*/
  /*====  Time reading modalbox   ====*/
  /*====  score modalbox   ====*/
  /*====  score modalbox   ====*/
  /*==== display score modalbox   ====*/
  /*==== display score modalbox   ====*/
  /*====  activity-common-modalbox  ====*/
  /*====  activity-common-modalbox  ====*/
  /*==== course list modalbox   ====*/
  /*==== course list modalbox   ====*/
  /*==== missing tiles modalbox   ====*/
  /*==== missing tiles modalbox   ====*/
  /*==== small modalbox   ====*/
  /*==== small modalbox   ====*/
  /*==== scrollbar   ====*/
  /* Track */
  /* Handle */
  /* Handle on hover */
  /* Track */
  /* Handle */
  /* Handle on hover */
  /*==== scrollbar   ====*/
  /*==== curriculum   ====*/
  /*==== curriculum   ====*/
  /*====  work book menu modalbox  ====*/
  /*====  work book menu modalbox  ====*/
  /*====  manage course modalbox  ====*/
  /*====  manage course modalbox  ====*/
  /*====  wordlist modalbox  ====*/
  /*====  wordlist modalbox  ====*/
  /*==== tutorial dialog modalbox  ====*/
  /*====  tutorial dialog modalbox  ====*/
  /*=== sharing page  ====*/
  /*===  sharing page  ====*/
  /*===  sharing page  ====*/
  /*===  Account  ====*/
  /*===  Account  ====*/
  /*===  add credit card modalbox  ====*/
  /*===  add credit card modalbox  ====*/
  /*===  successful-update-modalbox  ====*/
  /*===  successful-update-modalbox  ====*/
  /*===  selected-plan-modalbox  ====*/
  /*===  selected-plan-modalbox  ====*/
  /*====  cancel-subscriptiton-modalbox  ====*/
  /*====  cancel-subscriptiton-modalbox  ====*/
  /*===  add-course-modalbox  ===*/
  /*===  add-course-modalbox  ===*/
  /* == setting page ==*/
  /* == setting page ==*/
  /* == tile page  ==*/
  /* == tile page ==*/
  /* == edit lesson plan page ==*/
  /*== edit lesson plan page ==*/
  /*== lond card Forms==*/
  /*== lond card Forms==*/
  /*== registration  page  ==*/
  /*== registration  page  ==*/
}
.focus-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.focus-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.focus-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.focus-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .focus-theme .mat-ripple-element {
  display: none;
}

.focus-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .focus-theme .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.focus-theme .cdk-overlay-container, .focus-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.focus-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.focus-theme .cdk-overlay-container:empty {
  display: none;
}
.focus-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.focus-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.focus-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.focus-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .focus-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.focus-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.focus-theme .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.focus-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.focus-theme .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.focus-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.focus-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.focus-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.focus-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.focus-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.focus-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.focus-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.focus-theme .mat-focus-indicator {
  position: relative;
}
.focus-theme .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.focus-theme .mat-focus-indicator:focus::before {
  content: "";
}
.focus-theme .cdk-high-contrast-active .focus-theme {
  --mat-focus-indicator-display: block;
}
.focus-theme .mat-mdc-focus-indicator {
  position: relative;
}
.focus-theme .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.focus-theme .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.focus-theme .cdk-high-contrast-active .focus-theme {
  --mat-mdc-focus-indicator-display: block;
}
.focus-theme .mat-ripple {
  overflow: hidden;
  position: relative;
}
.focus-theme .mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.focus-theme .mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.focus-theme .mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .focus-theme .mat-ripple-element {
  display: none;
}

.focus-theme .cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .focus-theme .cdk-visually-hidden {
  left: auto;
  right: 0;
}
.focus-theme .cdk-overlay-container, .focus-theme .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.focus-theme .cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.focus-theme .cdk-overlay-container:empty {
  display: none;
}
.focus-theme .cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.focus-theme .cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}
.focus-theme .cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.focus-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .focus-theme .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}
.focus-theme .cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}
.focus-theme .cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.focus-theme .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}
.focus-theme .cdk-overlay-backdrop-noop-animation {
  transition: none;
}
.focus-theme .cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}
.focus-theme .cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
.focus-theme textarea.cdk-textarea-autosize {
  resize: none;
}
.focus-theme textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}
.focus-theme textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}
@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.focus-theme .cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}
.focus-theme .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}
.focus-theme .mat-focus-indicator {
  position: relative;
}
.focus-theme .mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.focus-theme .mat-focus-indicator:focus::before {
  content: "";
}
.focus-theme .cdk-high-contrast-active .focus-theme {
  --mat-focus-indicator-display: block;
}
.focus-theme .mat-mdc-focus-indicator {
  position: relative;
}
.focus-theme .mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.focus-theme .mat-mdc-focus-indicator:focus::before {
  content: "";
}
.focus-theme .cdk-high-contrast-active .focus-theme {
  --mat-mdc-focus-indicator-display: block;
}
.focus-theme .card {
  float: left;
  margin: 20px;
  padding: 10px;
  background-color: #e6e9eb;
}
.focus-theme .card .quickstart {
  width: 150px;
  float: right;
}
.focus-theme .long-card {
  margin: 20px;
  padding: 10px;
  background-color: #e6e9eb;
}
.focus-theme .long-card .quickstart {
  width: 150px;
  float: right;
}
.focus-theme .title {
  font-size: 14px !important;
}
.focus-theme .card-title {
  font-weight: 700;
  padding: 5px;
  color: #45506d;
}
.focus-theme .card-sub-title {
  color: #FF8F2C;
}
.focus-theme .sub-text {
  font-size: 1em;
  color: #717171;
}
.focus-theme .card-footer {
  padding: 10px;
}
.focus-theme .centered {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.focus-theme .tile {
  width: 8vh;
  height: 8vh;
  font-size: 0.8em;
  float: left;
}
.focus-theme .grid-tile {
  width: 8vw;
  height: 8vh;
}
.focus-theme .new-workbook {
  height: 150px;
  width: 223px;
  margin: 10px;
  padding: 16px;
  color: #666;
  border: 3px #666 dashed;
  border-radius: 5px;
  cursor: pointer;
}
.focus-theme .workbooks-code-circle {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  font-size: 11px;
  font-weight: 800;
  color: #666;
  border: 1px solid #bbb;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  margin-right: 2px;
}
.focus-theme .workbook-card-content-wrapper {
  position: absolute;
  height: 100%;
}
.focus-theme .workbook-card-settings {
  position: relative;
  height: 30px;
  width: 30px;
  padding: 4px 6px;
  margin: 5px;
  border-radius: 50%;
  border: 1px solid #CCC;
  bottom: 20px;
  left: 55%;
}
.focus-theme .workbooks-code-wrapper {
  position: absolute;
  bottom: 18px;
  left: 5px;
}
.focus-theme .mat-mdc-list-base .mat-mdc-list-item .mat-line {
  word-wrap: break-word;
  white-space: pre-wrap;
}
.focus-theme .menu-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}
.focus-theme .menu-btn {
  color: #888888;
  margin-bottom: 10px;
}
.focus-theme .btn-cancel {
  background-color: lightgrey;
  font-size: smaller;
  font-weight: bold;
  color: black;
  width: 100%;
}
.focus-theme .words-spinner {
  position: absolute;
  color: #485473;
  padding: 30%;
  margin-top: 100%;
}
.focus-theme .selectable-tile {
  margin: 5px;
}
.focus-theme .file-group-card, .focus-theme .word-group-item-card {
  margin: 10px;
  position: relative;
}
.focus-theme .word-group-item-card-content {
  padding: 10px;
  font-size: larger;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.focus-theme .c-white {
  background: white;
}
.focus-theme .c-red {
  background: #d34755; /* Old browsers */
  background: -moz-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #d34755), color-stop(100%, #ab1f2c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #d34755 0%, #ab1f2c 100%); /* IE10+ */
  background: linear-gradient(to bottom, #d34755 0%, #ab1f2c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#d34755", endColorstr="#ab1f2c",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-yellow {
  background: #ffd456; /* Old browsers */
  background: -moz-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffd456), color-stop(100%, #ffc600)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ffd456 0%, #ffc600 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffd456", endColorstr="#ffc600",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-lightyellow {
  background: rgb(255, 237, 177); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(255, 237, 177)), color-stop(100%, rgb(255, 225, 130))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(255, 237, 177) 0%, rgb(255, 225, 130) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffedb1", endColorstr="#ffe182",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-green {
  background: #48ad5e; /* Old browsers */
  background: -moz-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #48ad5e), color-stop(100%, #2c8b3c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #48ad5e 0%, #2c8b3c 100%); /* IE10+ */
  background: linear-gradient(to bottom, #48ad5e 0%, #2c8b3c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#48ad5e", endColorstr="#2c8b3c",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-lightgreen {
  background: rgb(206, 230, 156); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(206, 230, 156)), color-stop(100%, rgb(193, 229, 114))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(206, 230, 156) 0%, rgb(193, 229, 114) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#cee69c", endColorstr="#c1e572",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-blue {
  background: #4c72aa; /* Old browsers */
  background: -moz-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4c72aa), color-stop(100%, #1f57ab)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #4c72aa 0%, #1f57ab 100%); /* IE10+ */
  background: linear-gradient(to bottom, #4c72aa 0%, #1f57ab 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#4c72aa", endColorstr="#1f57ab",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-lightblue {
  background: rgb(156, 196, 229); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(156, 196, 229)), color-stop(100%, rgb(129, 183, 226))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(156, 196, 229) 0%, rgb(129, 183, 226) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#9cc4e5", endColorstr="#81b7e2",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-peach {
  background: rgb(255, 209, 196); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(255, 209, 196)), color-stop(100%, rgb(255, 179, 158))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(255, 209, 196) 0%, rgb(255, 179, 158) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffd1c4", endColorstr="#ffb39e",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-purple {
  background: #696493; /* Old browsers */
  background: -moz-linear-gradient(top, #696493 0%, #504795 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #696493), color-stop(100%, #504795)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #696493 0%, #504795 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #696493 0%, #504795 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #696493 0%, #504795 100%); /* IE10+ */
  background: linear-gradient(to bottom, #696493 0%, #504795 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#696493", endColorstr="#504795",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-lightpurple {
  background: rgb(239, 196, 255); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(239, 196, 255)), color-stop(100%, rgb(228, 160, 255))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(239, 196, 255) 0%, rgb(228, 160, 255) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#efc4ff", endColorstr="#e4a0ff",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-orange {
  background: #ff9951; /* Old browsers */
  background: -moz-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff9951), color-stop(100%, #ff872c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ff9951 0%, #ff872c 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ff9951 0%, #ff872c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ff9951", endColorstr="#ff872c",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-gray {
  background: #898881; /* Old browsers */
  background: -moz-linear-gradient(top, #898881 0%, #6e6d67 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #898881), color-stop(100%, #6e6d67)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #898881 0%, #6e6d67 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #898881 0%, #6e6d67 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #898881 0%, #6e6d67 100%); /* IE10+ */
  background: linear-gradient(to bottom, #898881 0%, #6e6d67 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#898881", endColorstr="#6e6d67",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-cream {
  background: #f4f1de; /* Old browsers */
  background: -moz-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f4f1de), color-stop(100%, #e1dec9)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f4f1de 0%, #e1dec9 100%); /* IE10+ */
  background: linear-gradient(to bottom, #f4f1de 0%, #e1dec9 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f4f1de", endColorstr="#e1dec9",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-pink {
  background: rgb(244, 90, 206); /* Old browsers */
  background: -moz-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgb(244, 90, 206)), color-stop(100%, rgb(247, 66, 202))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgb(244, 90, 206) 0%, rgb(247, 66, 202) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f45ace", endColorstr="#f742ca",GradientType=0 ); /* IE6-9 */
}
.focus-theme .c-transparent {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  border: none;
}
.focus-theme .c-clear {
  background: rgba(0, 0, 0, 0);
  box-shadow: none !important;
  border: none !important;
  /*display: none;*/
  visibility: hidden;
}
.focus-theme .header-tab-text {
  font-size: larger;
  margin-left: 20px;
}
.focus-theme .cursor-pointer {
  cursor: pointer;
}
.focus-theme .fc-black {
  color: #1b1b1b !important;
}
.focus-theme .fc-gray {
  color: #898881 !important;
}
.focus-theme .fc-white {
  color: #ffffff !important;
}
.focus-theme .fc-red {
  color: #EC1414 !important;
}
.focus-theme .tile-query-window {
  background-color: white;
  position: relative;
  z-index: 2;
  min-width: 25%;
  max-width: 75%;
  border: 2px #485473 solid;
  opacity: 0.9;
  border-radius: 5px;
}
.focus-theme .whizzi-button-small, .focus-theme .whizzi-button-medium, .focus-theme .whizzi-button-large {
  border-radius: 10px;
  color: white;
  background-color: #FF8F2C;
  margin-right: 20px !important;
  cursor: pointer;
}
.focus-theme .whizzi-button-large {
  padding: 10px 40px;
  font-size: 25px;
}
.focus-theme .whizzi-button-medium {
  padding: 15px 20px;
  font-size: 15px;
}
.focus-theme .whizzi-button-small {
  padding: 5px 10px;
  font-size: 15px;
}
.focus-theme .file-options, .focus-theme .file-name-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  opacity: 0.7;
  border-radius: 0 0 4px 4px;
  color: white;
}
.focus-theme .file-name-container {
  overflow-wrap: break-word;
  max-width: 100%;
  color: white;
  padding: 5px;
}
.focus-theme .file-options {
  visibility: hidden;
  height: 100%;
  text-align: center;
}
.focus-theme .file-option-workbook, .focus-theme .file-option {
  padding: 10px;
  cursor: pointer;
}
.focus-theme .file-option {
  height: 50%;
}
.focus-theme .file-option-workbook {
  height: 100%;
  padding-top: 50%;
}
.focus-theme .file-group-card:hover .file-options {
  visibility: visible;
}
.focus-theme .file-group-card:hover .file-name-container {
  visibility: hidden;
}
.focus-theme mat-grid-list {
  border: white 1px solid;
}
.focus-theme .settings-dropdown {
  padding-top: 10px;
  padding-right: 35px;
}
.focus-theme .settings-dropdown:not(:first-child) {
  padding-top: 32px;
}
.focus-theme .mat-card .style-card {
  padding: 0 !important;
}
.focus-theme .style-card-type {
  margin: 50px;
  padding: 20px;
  font-size: larger;
  text-align: center;
  font-weight: bolder;
  border-radius: 5px;
  border: black solid 2px;
}
.focus-theme .account-detail-label {
  font-weight: bold;
  margin-bottom: 10px;
}
.focus-theme .account-detail-text {
  font-weight: normal;
  margin-left: 10px;
}
.focus-theme .plan-price-container {
  text-align: center;
  margin-bottom: 25px;
}
.focus-theme .plan-price-text {
  font-size: xx-large;
}
.focus-theme .price-plan-detail-container {
  text-align: center;
  margin-bottom: 15px;
}
.focus-theme .price-plan-details-text {
  font-size: 15px;
  color: #97A5AE;
  font-weight: 500;
}
.focus-theme ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
.focus-theme ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.focus-theme .activity-canvas-disabled {
  pointer-events: none;
}
.focus-theme .activity-canvas-draw {
  cursor: url("/assets/svgs/regular/draw-cursor.svg"), auto;
}
.focus-theme .activity-canvas-draw.dark-theme {
  cursor: url("/assets/svgs/regular/draw-cursor-white.svg"), auto;
}
.focus-theme .activity-canvas-eraser {
  cursor: url("/assets/svgs/regular/eraser-cursor.svg") 10 10, auto;
}
.focus-theme .activity-canvas-eraser.dark-theme {
  cursor: url("/assets/svgs/regular/eraser-cursor-white.svg") 10 10, auto;
}
.focus-theme .activity-canvas-text-64 {
  cursor: url("/assets/svgs/regular/text-cursor-64px.svg") 32 59, auto;
}
.focus-theme .activity-canvas-text-64.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-64px-white.svg") 32 59, auto;
}
.focus-theme .activity-canvas-text-48 {
  cursor: url("/assets/svgs/regular/text-cursor-48px.svg") 24 44, auto;
}
.focus-theme .activity-canvas-text-48.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-48px-white.svg") 24 44, auto;
}
.focus-theme .activity-canvas-text-40 {
  cursor: url("/assets/svgs/regular/text-cursor-40px.svg") 20 36, auto;
}
.focus-theme .activity-canvas-text-40.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-40px-white.svg") 20 36, auto;
}
.focus-theme .activity-canvas-text-32 {
  cursor: url("/assets/svgs/regular/text-cursor-32px.svg") 16 28, auto;
}
.focus-theme .activity-canvas-text-32.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-32px-white.svg") 16 28, auto;
}
.focus-theme .activity-canvas-text-24 {
  cursor: url("/assets/svgs/regular/text-cursor-24px.svg") 12 22, auto;
}
.focus-theme .activity-canvas-text-24.dark-theme {
  cursor: url("/assets/svgs/regular/text-cursor-24px-white.svg") 12 22, auto;
}
.focus-theme .mat-list-item-disabled.activity-menu-item {
  cursor: not-allowed !important;
}
.focus-theme .mat-list-item-disabled.activity-menu-item .mat-list-text > span {
  color: lightgray !important;
}
.focus-theme .mat-list-item-disabled.activity-menu-item.dark-mode {
  opacity: 40% !important;
}
.focus-theme .canvas-container canvas {
  border-color: transparent !important;
}
.focus-theme .canvas-pad-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: rgba(255, 255, 255, 0.44);
}
.focus-theme .workbook-activity-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.focus-theme .workbook-activity-container .activity-header {
  z-index: 21;
}
.focus-theme .workbook-activity-container .activity-header .header_activity_top {
  position: relative;
}
.focus-theme .workbook-activity-container #canvas-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.focus-theme .workbook-activity-container #canvas-container .pad-canvas {
  transition-duration: 0.5s;
  background: rgba(255, 255, 255, 0.15);
  position: absolute;
  z-index: 11;
  opacity: 0;
}
.focus-theme .workbook-activity-container #canvas-container .main-canvas-container, .focus-theme .workbook-activity-container #canvas-container .main-canvas {
  position: absolute;
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: center;
}
.focus-theme .workbook-activity-container #tileBank {
  height: 400px;
  min-height: 100px;
  overflow: hidden;
}
.focus-theme .workbook-activity-container #tileBank table {
  margin: auto;
}
.focus-theme .workbook-activity-container .blank_tile {
  margin-top: 0;
  height: unset;
}
.focus-theme .workbook-activity-container .blank_tile table {
  padding-top: 40px;
  padding-bottom: 40px;
}
.focus-theme .workbook-activity-container .ng-resizable-handle {
  bottom: 0% !important;
  left: 96% !important;
}
.focus-theme .workbook-activity-container .tiles-container {
  height: 100%;
  background: white;
  padding-bottom: 16px;
}
.focus-theme .workbook-activity-container .new-tile-container {
  overflow: auto;
  height: 100%;
  padding: 16px 0;
}
.focus-theme .blackboard-activity-container #tileDropzone {
  position: relative;
  top: -16px;
}
.focus-theme .blackboard-activity-container #canvas-container {
  background: white;
}
.focus-theme .workbook-tiles-activity-container #tileDropzone {
  position: relative;
  top: -16px;
  height: calc(100vh - 125px);
}
.focus-theme .workbook-tiles-activity-container .workbook_tile_div {
  height: unset !important;
  margin-top: 0 !important;
}
.focus-theme .workbook-tiles-activity-container .workbook-container .textbox-target {
  height: 100vh !important;
}
.focus-theme .wordcards-activity-container .textbox-target {
  padding-top: 16px;
}
.focus-theme .wordcards-activity-container .activity-header {
  z-index: 200;
}
.focus-theme .wordcards-activity-container #canvas-container .pad-canvas {
  z-index: 100 !important;
}
.focus-theme .pdf-viewer-activity-container .pdf-container {
  margin-top: 0 !important;
  padding-top: 32px;
  position: absolute;
}
.focus-theme textarea[data-fabric-hiddentextarea] {
  position: fixed !important;
}
.focus-theme mat-slider .mat-slider-ticks-container {
  background: #8e70ff;
}
.focus-theme mat-slider .mat-slider-thumb {
  border-color: #8e70ff;
  background: #8e70ff;
}
.focus-theme .bg-black {
  background: #000 !important;
}
.focus-theme .bg-white {
  background: #fff !important;
}
.focus-theme .line-warning-message {
  font-size: 12px;
  color: #ff5a5a;
  text-align: end;
}
.focus-theme mat-form-field.wz-select {
  all: initial;
  width: 100%;
}
.focus-theme mat-form-field.wz-select .mat-form-field-label {
  top: 33px;
  left: 16px;
  color: #210854;
  font-size: 16px;
  width: auto !important;
}
.focus-theme mat-form-field.wz-select.mat-form-field-should-float .mat-form-field-label {
  font-size: 13px;
  background-color: white;
  padding: 5px 10px;
  width: auto;
  top: 21px;
  left: 16px;
}
.focus-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.focus-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #866DFF !important;
}
.focus-theme .dark-theme .mat-mdc-dialog-surface {
  background-color: #0b041e !important;
}
.focus-theme .dark-theme mat-label, .focus-theme .dark-theme .mdc-radio__outer-circle, .focus-theme .dark-theme .mdc-radio__inner-circle, .focus-theme .dark-theme .mdc-text-field__input, .focus-theme .dark-theme .mat-mdc-dialog-content {
  color: rgba(255, 255, 255, 0.6) !important;
  border-color: rgba(255, 255, 255, 0.6) !important;
}
.focus-theme .dark-theme .mat-select-value-text, .focus-theme .dark-theme .mat-mdc-select-value-text, .focus-theme .dark-theme .mdc-form-field {
  color: white !important;
  border-color: white !important;
}
.focus-theme .dark-theme .words_tile_box .mat-button-wrapper {
  color: white !important;
}
.focus-theme .ripple-btn-disabled .mdc-button__ripple {
  display: none !important;
}
.focus-theme .mdc-notched-outline__leading, .focus-theme .mdc-notched-outline__notch, .focus-theme .mdc-notched-outline__trailing {
  border-color: currentColor;
}
.dark-theme .focus-theme .mdc-notched-outline__leading, .dark-theme .focus-theme .mdc-notched-outline__notch, .dark-theme .focus-theme .mdc-notched-outline__trailing {
  border-color: #e0e0e0 !important;
}
.focus-theme .cdk-global-overlay-wrapper:has(mat-dialog-container.mdc-dialog--closing) {
  display: none;
}
.focus-theme mat-radio-button.setting_radio .mdc-label {
  color: #210854;
  font-size: 16px;
}
.dark-theme .focus-theme mat-radio-button.setting_radio .mdc-label {
  color: white !important;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-dialog-container {
  padding: 24px;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-dialog-container .mat-mdc-form-field {
  padding-top: 10px;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-dialog-container .card-name {
  padding-top: 0;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.focus-theme .logout-modal mat-dialog-actions {
  margin-right: 20px;
  margin-bottom: 8px;
}
.focus-theme .logout-modal mat-dialog-content {
  padding-bottom: 0 !important;
}
.focus-theme .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  display: none;
}
.focus-theme .mdc-button__ripple, .focus-theme .mdc-checkbox__ripple {
  display: none;
}
.focus-theme [contenteditable=true] {
  padding: 4px;
  display: block;
}
.focus-theme [contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  opacity: 0.5;
}
.focus-theme .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
}
body:not(.dark-theme) .focus-theme .mdc-tab-indicator__content--underline {
  border-color: #481e9f !important;
}
body.dark-theme .focus-theme .mdc-tab-indicator__content--underline {
  border-color: white !important;
}
.dark-theme .focus-theme .mat-mdc-checkbox .mdc-checkbox__background {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.dark-theme .focus-theme .account-modal button.add-new-card-btn {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.dark-theme .focus-theme .mat-mdc-dialog-container .mdc-dialog__title {
  color: white !important;
}
.dark-theme .focus-theme .mdc-list-item:hover .mdc-list-item__primary-text {
  color: white;
}
.dark-theme .focus-theme .mat-mdc-paginator {
  background: #18112f;
}
.dark-theme .focus-theme .mat-mdc-paginator .mat-mdc-paginator-range-label {
  color: white;
}
.dark-theme .focus-theme .mat-mdc-paginator .mdc-icon-button svg {
  fill: #866dff;
}
.dark-theme .focus-theme .mat-mdc-paginator .mdc-icon-button:disabled svg {
  fill: gray;
}
.focus-theme .mat-mdc-dialog-container .mdc-dialog__content {
  line-height: 20px !important;
}
.dark-theme .focus-theme .mdc-list-item__primary-text {
  color: white;
}
.dark-theme .focus-theme .mdc-list-item.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: white;
}
.dark-theme .focus-theme .mdc-checkbox__background {
  border-color: white !important;
}
.focus-theme .letter-card-dialog .mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}
.focus-theme .purple-checkbox {
  padding-bottom: 8px !important;
}
.focus-theme .purple-checkbox mat-list-option {
  padding: 0 16px 0 8px !important;
}
.focus-theme .purple-checkbox .delete-btn {
  padding-left: 16px !important;
}
.focus-theme .purple-checkbox .delete-btn .mdc-button__label {
  display: flex !important;
}
.focus-theme .purple-checkbox .mdc-list-item__end {
  margin-left: 0 !important;
}
.focus-theme .collection-name .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.focus-theme .collection-name .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.focus-theme .collection-name .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: gray;
  border-width: 1px;
  opacity: 1;
}
.focus-theme .workbook-builder-tab .mat-mdc-tab-body-content {
  height: calc(100% - 58px);
}
.focus-theme html, .focus-theme body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  height: 100%;
  background-color: #dfdee5;
}
.focus-theme button {
  letter-spacing: normal !important;
}
.focus-theme .container {
  height: 100%;
  width: 100%;
  position: relative;
}
.focus-theme .mat-mdc-focus-indicator {
  background: transparent;
}
.focus-theme .blanktiles {
  min-height: 100px !important;
}
.focus-theme .flashcard {
  position: absolute;
  font-family: "Arial", sans-serif;
  font-size: 120px;
  line-height: 6rem;
  font-weight: bold;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 20vh;
  min-height: 433px;
  padding: 2.5% 15px;
  flex-shrink: 3;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08);
  color: #210854;
  background-color: white;
}
@media only screen and (max-width: 1280px) {
  .focus-theme .flashcard {
    height: auto !important;
  }
}
.dark-theme .focus-theme .flashcard {
  background-color: #18112f !important;
}
.focus-theme .flashcard-altered {
  font-family: "Arial", sans-serif;
  font-size: 120px;
  line-height: 6rem;
  font-weight: bold;
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 20vh;
  min-height: 433px;
  padding: 2.5% 15px;
  flex-shrink: 3;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08);
  background-color: white;
  color: #210854;
}
@media only screen and (max-width: 1280px) {
  .focus-theme .flashcard-altered {
    height: auto !important;
  }
}
.focus-theme .mat-mdc-form-field-label {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .mat-progress-bar-fill::after {
  background-color: #866dff;
}
.focus-theme .mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #866dff;
}
.focus-theme .tally-box .tally {
  font-size: 16px !important;
}
.focus-theme .tally-box .tally .tally_index {
  color: #210854;
}
.focus-theme .char-limit {
  color: #a6a6a6;
  font-size: 12px;
  text-align: left;
  margin-left: 13px;
}
.focus-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.focus-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.focus-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #866dff;
}
.focus-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-mdc-form-field-label {
  color: #866dff;
}
.focus-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-mdc-form-field-ripple {
  background-color: #866dff;
}
.focus-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mat-select-arrow {
  color: #866dff !important;
}
.focus-theme .mat-input-element {
  caret-color: #866dff;
}
.focus-theme .mat-button-focus-overlay {
  background-color: transparent !important;
}
.focus-theme .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: $interface-color-light-medium !important;
}
.focus-theme .mat-mdc-progress-spinner circle {
  stroke: #866dff !important;
}
.focus-theme .sproutvideo-playlist {
  width: 100%;
}
.focus-theme .content:not(.mat-drawer-content) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.focus-theme .loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.focus-theme .loading .spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}
.focus-theme .bumping-tiles {
  font-size: 1.6837vw;
  border: none;
  position: absolute;
  text-align: center;
  font-weight: bolder;
  border-radius: 4px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-stretch: normal;
  line-height: 60px;
  padding-right: 8px;
  padding-left: 8px;
  min-width: 60px;
  min-height: 60px;
  border: solid 1px rgba(72, 30, 159, 0.24);
}
.focus-theme .bumping-tiles.static-block {
  transition: all 0.3s ease-out;
}
.focus-theme .bumping-tiles-flashcards {
  font-size: 1.6837vw;
  border: none;
  position: absolute;
  text-align: center;
  font-weight: bolder;
  border-radius: 4px;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-stretch: normal;
  line-height: 260px;
  padding-right: 10px;
  padding-left: 10px;
  min-width: 60px;
  height: 792px;
  border: solid 1px rgba(72, 30, 159, 0.24);
  margin-left: 5px;
  margin-right: 5px;
}
.focus-theme .bumping-tiles-flashcards.static-block {
  transition: all 0.3s ease-out;
}
.focus-theme .drawerContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.focus-theme .btn-default {
  background-color: #866dff;
  font-size: smaller;
  font-weight: bold;
  color: white;
  width: 100%;
}
.focus-theme .plan-description-header {
  width: 100%;
  text-align: center;
  padding: 12px 0;
  margin-bottom: 10px;
  background-color: #F7FAFB;
  font-weight: bold;
  color: rgba(33, 8, 84, 0.56);
  font-size: 19px;
  color: rgb(109, 118, 127);
  border-bottom: 1px solid rgb(234, 236, 238);
}
.dark-theme .focus-theme .plan-description-header {
  background-color: transparent !important;
}
.focus-theme .whizzimo-link {
  color: #866dff;
  cursor: pointer;
}
.focus-theme .ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 300px;
  display: inline-block;
}
.focus-theme .button-selected {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  font-style: italic;
  letter-spacing: 0.4px;
  text-transform: capitalize !important;
  background-color: white !important;
  border: 2px solid #866dff !important;
  border-radius: 4px;
}
.focus-theme .button-selected .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.focus-theme .button-selected .mdc-button__label {
  color: #866dff !important;
}
.focus-theme .fileContainer {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  box-shadow: none !important;
  line-height: 1.5em;
  color: white;
  height: 35px;
  padding: 5px;
}
.focus-theme .fileContainer .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.focus-theme .inactive-fileContainer {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none !important;
  line-height: 1.5em;
  color: rgba(0, 0, 0, 0.87);
  height: 35px;
  padding: 5px;
}
.focus-theme .inactive-fileContainer .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .fileContainer [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
.focus-theme .inactve-fileContainer [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
.focus-theme .red-circle {
  width: 8px;
  height: 8px;
  background: #ff5a5a;
  border-radius: 50px;
  position: absolute;
  right: 4px;
  top: 4px;
}
.focus-theme .x-small-tile {
  min-height: 30px !important;
  min-width: 30px !important;
  line-height: 30px !important;
  font-size: 0.9837vw !important;
}
.focus-theme .small-tile {
  min-height: 40px !important;
  min-width: 40px !important;
  line-height: 40px !important;
  font-size: 20px !important;
}
.focus-theme .medium-tile {
  min-height: 60px !important;
  min-width: 60px !important;
  line-height: 60px !important;
  font-size: 27.5px !important;
}
.focus-theme .large-tile {
  min-height: 80px !important;
  min-width: 80px !important;
  line-height: 80px !important;
  font-size: 32px !important;
}
.focus-theme .x-large-tile {
  min-height: 100px !important;
  min-width: 100px !important;
  line-height: 100px !important;
  font-size: 40px !important;
}
.focus-theme .xx-large-tile {
  min-height: 120px !important;
  min-width: 120px !important;
  line-height: 120px !important;
  font-size: 3.4837vw !important;
}
.focus-theme .xxx-large-tile {
  min-height: 140px !important;
  min-width: 140px !important;
  line-height: 140px !important;
  font-size: 4.0837vw !important;
}
.focus-theme .mat-radio-checked .mat-radio-outer-circle {
  border-color: #8e70ff;
}
.focus-theme .mat-radio-checked .mat-radio-inner-circle {
  background-color: #8e70ff;
}
.focus-theme .shadow {
  border: solid 1px rgba(134, 128, 145, 0.24);
  border-radius: 4px;
  min-width: 60px;
  height: 60px !important;
}
.focus-theme .black-white-text {
  color: black;
}
.focus-theme .purple-text {
  color: #866DFF !important;
}
.focus-theme .purple-checkbox .mat-mdc-checkbox-checked .mat-mdc-checkbox-layout .mat-mdc-checkbox-inner-container {
  background: #866DFF !important;
  color: #8e70ff !important;
}
.focus-theme .purple-checkbox-option .mat-pseudo-checkbox-checked {
  color: #866DFF !important;
}
.focus-theme .purple-checkbox-option .mat-pseudo-checkbox {
  color: #866DFF !important;
}
.focus-theme .pairing-dropdown {
  padding-left: 20px !important;
}
.focus-theme .add-success-light {
  background-color: #210854 !important;
}
.focus-theme .add-success-dark {
  background-color: #210854 !important;
}
.focus-theme .no-border {
  border: none !important;
}
.focus-theme .blenderbox-target {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.focus-theme .blenderbox-target .selected-line {
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  background: #888;
  height: 6px;
  width: 50px;
  transition: all 0.2s ease;
}
.focus-theme .blenderbox-target .selected-line::before {
  content: "";
  position: absolute;
  top: -0.625rem; /* Adjust to extend clickable area above and below */
  left: -0.875rem; /* Adjust to extend clickable area to the left and right */
  right: -0.875rem;
  bottom: -1.35rem;
  background: transparent;
  cursor: pointer;
}
.focus-theme .blenderbox-target .line-arrow {
  /* Add arrowhead at the end of the line */
  transition: all 0.2s ease;
  position: absolute;
  background: #888;
}
.focus-theme .blenderbox-target .line-arrow::after {
  content: "";
  position: absolute;
  right: -12px;
  /* Adjust to place arrowhead at the end of the line */
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #888;
}
.focus-theme .c-red {
  background: #cf1b3a !important;
}
.focus-theme .c-yellow {
  background: #ffca5a !important;
}
.focus-theme .c-peach {
  background: #fec9bf !important;
}
.focus-theme .c-lightyellow {
  background: #ffe79d !important;
}
.focus-theme .c-lightgreen {
  background: #ccf29c !important;
}
.focus-theme .c-green {
  background: #00ab44 !important;
}
.focus-theme .c-blue {
  background: #568aff !important;
}
.focus-theme .c-lightblue {
  background: #83bfe7 !important;
}
.focus-theme .c-purple {
  background: #8e70ff !important;
}
.focus-theme .c-lightpurple {
  background: #e4a9ff !important;
}
.focus-theme .c-orange {
  background: #ff8e42 !important;
}
.focus-theme .c-gray {
  background: #7c7c74 !important;
}
.focus-theme .c-pink {
  background: #ff76b8 !important;
}
.focus-theme .c-cream {
  background: #f6efda !important;
}
.focus-theme .c-transparent {
  background: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  border: none !important;
}
.focus-theme .c-clear {
  background: rgba(0, 0, 0, 0);
  box-shadow: none !important;
  border: none !important;
  display: none;
  z-index: 0 !important;
  visibility: hidden;
}
.focus-theme .more_words_modal_activity, .focus-theme .more_tiles_modal_activity {
  color: #866dff;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 12px;
  text-transform: uppercase;
  display: block;
  font-size: 14px;
}
.focus-theme .global-wrap {
  height: 100%;
  width: 100%;
  margin-bottom: 85px;
}
.focus-theme .setting_header_div {
  background: white;
  padding: 0px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
}
.focus-theme .setting_header_div .setting_header_div_left {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.focus-theme .setting_header_div .setting_header_div_left .help_icon {
  position: relative;
  top: 4px;
  left: 10px;
}
.focus-theme .setting_header_div .setting_header_div_left div {
  display: flex;
  align-items: center;
}
.focus-theme .setting_header_div .setting_header_div_left div h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  .focus-theme .setting_header_div .setting_header_div_left div h2 {
    font-size: 22px !important;
  }
}
.focus-theme .setting_header_div .account_setting_header_div_left {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.focus-theme .setting_header_div .account_setting_header_div_left h2 {
  color: #210854;
}
.focus-theme .setting_header_div .header-workbook-name {
  font-size: 14px;
  color: #866dff;
  cursor: pointer;
  margin: 0px 0px 7px;
  align-items: center;
  text-transform: uppercase;
  outline: none;
  font-weight: 500;
}
.focus-theme .setting_header_div .help_icon {
  cursor: pointer;
}
.focus-theme .setting_header_div .setting_header_div_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.focus-theme .setting_header_div .setting_header_div_right .setting-type-dropdown {
  float: none !important;
  margin-top: 25px;
  justify-content: flex-end !important;
}
.focus-theme .setting_header_div .setting_header_div_right .setting-type-dropdown .mat-mdc-form-field-infix {
  width: 276px;
  padding: 0 16px !important;
  color: #210854;
  font-size: 16px;
}
.focus-theme .setting_header_div .setting_header_div_right .setting-type-dropdown .mat-mdc-form-field-infix .mat-select-value-text {
  color: #210854;
  font-size: 16px;
}
.focus-theme .setting_header_div .setting_header_div_right .setting-type-dropdown .mat-mdc-form-field-label {
  top: 33px;
  left: 16px;
  color: #210854;
  font-size: 16px;
}
.focus-theme .setting_header_div .setting_header_div_right .setting-type-dropdown.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: white;
  padding: 5px 10px;
  width: auto;
  top: 21px;
  left: 16px;
}
.focus-theme .mat-mdc-option .mdc-list-item__primary-text {
  color: #210854;
  font-size: 14px !important;
  letter-spacing: normal;
}
.focus-theme .mat-mdc-option .mdc-list-item__primary-text img {
  width: 24px;
  position: relative;
  float: right;
}
.focus-theme .mat-option-disabled .mat-option-text {
  color: #dfdee5;
  font-size: 14px;
}
.focus-theme .mat-option-disabled .mat-option-text img {
  width: 24px;
  position: relative;
  top: 12px;
  float: right;
}
.focus-theme .setting_screens .content_div {
  display: flex;
  flex-direction: column;
  background: white;
  margin: 24px;
  border-radius: 8px;
}
.focus-theme .setting_screens .content_div .each_row {
  display: flex;
  height: 100%;
  border-bottom: 1px solid rgba(33, 8, 84, 0.08);
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px 24px;
}
.dark-theme .focus-theme .setting_screens .content_div .each_row {
  color: white;
}
.focus-theme .setting_screens .content_div .each_row:last-child {
  border-bottom: none;
}
.focus-theme .setting_screens .content_div .each_row .description-section {
  width: 40%;
}
.focus-theme .setting_screens .content_div .each_row .description-section h3 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #210854;
  font-weight: bold;
  letter-spacing: -0.01em;
  line-height: 1;
  margin: 0;
  margin-bottom: 8px;
}
.focus-theme .setting_screens .content_div .each_row .description-section p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #777183;
  line-height: 1.25;
  margin: 0;
}
.focus-theme .setting_screens .content_div .each_row .description_option {
  width: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.focus-theme .setting_screens .content_div .each_row .description_option p {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .setting_screens .content_div .each_row .description_option .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .setting_screens .content_div .each_row .description_option .setting_radio {
  width: 100%;
}
.focus-theme .setting_screens .content_div .each_row .description_option .setting_radio .mat-radio-label {
  white-space: normal;
}
.focus-theme .setting_screens .content_div .each_row .description_option .setting_radio .mat-radio-ripple {
  color: #210854;
  font-size: 16px;
}
.focus-theme .setting_screens .content_div .each_row .description_option .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.focus-theme .setting_screens .content_div .each_row .description_option .mat-radio-checked .mat-radio-outer-circle {
  border-color: #8e70ff;
}
.focus-theme .setting_screens .content_div .each_row .description_option .mat-radio-checked .mat-radio-inner-circle {
  background-color: #8e70ff;
}
.focus-theme .setting_screens .content_div .each_row .description_option .words_input {
  width: 90px;
  display: inline-block;
  margin-top: 16px;
}
.focus-theme .setting_screens .content_div .each_row .description_option .label {
  font-size: 16px;
  color: rgba(33, 8, 84, 0.56);
  margin-left: 20px;
}
.focus-theme .setting_screens .content_div .each_row .description_option .flascard_radio {
  width: 100%;
  display: block;
  margin: 0 0 18px;
}
.focus-theme .setting_screens .content_div .each_row .description_option .flascard_radio .mat-radio-ripple {
  margin: 0 0 8px;
}
.focus-theme .setting_screens .content_div .each_row .description_option .flascard_radio p {
  color: rgba(13, 2, 35, 0.56);
  margin: 8px 29px 4px;
}
.focus-theme .setting_screens .content_div .custom_row {
  align-items: flex-start !important;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button {
  margin-right: 4px;
  border: 1px solid transparent;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button .mdc-radio__background {
  opacity: 0;
  display: none;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button .mat-radio-ripple {
  background: #e4e2ed;
  height: 40px;
  width: 40px;
  margin: 2px;
  padding: 0;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.mat-radio-checked {
  display: inline-block;
  height: 44px;
  border: solid 2px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.mat-radio-not-checked {
  border: solid 1px #ffffff;
  border-radius: 4px;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.gray .mat-radio-ripple {
  background: #e4e2ed;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.black .mat-radio-ripple {
  background: #000000;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.white .mat-radio-ripple {
  background: #fff;
  border: 1px solid #210854;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.palesky .mat-radio-ripple {
  background: #bde7ff;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.verypalegreen .mat-radio-ripple {
  background: #d6f7be;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.light-beige .mat-radio-ripple {
  background: #fff6b7;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.offwhite .mat-radio-ripple {
  background: #fbf5e5;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.lightpink .mat-radio-ripple {
  background: #ffdaec;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.softblue .mat-radio-ripple {
  background: #568aff;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.grapefruit .mat-radio-ripple {
  background: #ff5a5a;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.light-mustard .mat-radio-ripple {
  background: #ffca5a;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.soft-green .mat-radio-ripple {
  background: #6dd378;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.periwinkle .mat-radio-ripple {
  background: #8e70ff;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.orangeish .mat-radio-ripple {
  background: #ff8e42;
}
.focus-theme .setting_screens .content_div .colorblock_radio .colorblock_radio-button.bubblegum .mat-radio-ripple {
  background: #ff76b8;
}
.focus-theme .setting_screens .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 276px;
  height: 56px;
  display: flex;
  align-items: center;
  color: #210854;
}
.focus-theme .setting_screens .mat-mdc-form-field-underline {
  display: none;
}
.focus-theme .setting_screens .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body-content {
  height: auto;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-header {
  display: flex;
  overflow: hidden;
  position: sticky;
  flex-shrink: 0;
  top: 0px;
  z-index: 9;
  background-color: #ffffff;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #dfdee5;
  margin: 0px;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tab_title {
  margin: 0;
  padding: 22px 18px 40px;
  display: block;
  font-size: 16px;
  line-height: 1.25;
  color: rgba(13, 2, 35, 0.5607843137);
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tab_title_compound {
  margin: 22px 0px 40px;
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 1.25;
  color: rgba(13, 2, 35, 0.5607843137);
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .long-card {
  margin: 0px;
  background-color: transparent;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card {
  box-shadow: none !important;
  background-color: white;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .style-card-header {
  width: 100%;
  height: 30px;
  background-color: transparent;
  color: #210854;
  font-weight: bold;
  padding: 20px 0px 0px 0px !important;
  word-wrap: break-word;
  text-align: center;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .tile_bgcolor {
  background: transparent !important;
  display: flex;
  justify-content: center;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .tile_bgcolor .style-card-type {
  margin: 30px;
  font-size: larger;
  text-align: center;
  font-weight: bolder;
  border-radius: 5px;
  width: 76px;
  border: none;
  padding: 13px 0px;
  border: none;
  font-size: 32px;
  height: 38px;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .mat-mdc-form-field-infix {
  width: 150px;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: white;
  padding: 5px 10px;
  width: auto;
  top: 21px;
  left: 16px;
  color: #210854;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .mat-mdc-form-field-infix .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .style-card-type {
  margin: 20px auto;
  padding: 21px 0px;
  font-size: larger;
  text-align: center;
  font-weight: bolder;
  border-radius: 5px;
  width: 76px;
  height: 22px;
  border: none;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .mat-mdc-card .compound_example {
  font-size: 16px;
  color: #210854;
  font-weight: 600;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .compound_mat_card {
  padding: 23px 0px;
  width: 76px;
  border-radius: 4px;
  line-height: 18px;
  font-size: 1.6837vw !important;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout_div {
  border-bottom: 1px solid #210854;
  padding: 0px 0px 24px 0px;
  margin: 0px 20px !important;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout_div .mat-card {
  width: 76px;
  height: 20px;
  padding: 22px 0px;
  border-radius: 4px;
  text-align: center;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout .mat-grid-list {
  border: none;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout .mat-grid-tile {
  background-color: transparent !important;
}
.focus-theme .setting_screens .mat-mdc-tab-group .mat-mdc-tab-body .tile_layout .mat-grid-tile .mat-figure div {
  width: 100% !important;
  height: 100% !important;
}
.focus-theme .mat-drawer {
  width: 358px;
  background-color: white !important;
}
.focus-theme .mat-drawer .mat-drawer-inner-container {
  overflow: hidden;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container {
  max-width: 358px;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-top {
  justify-content: start !important;
  padding: 31px 32px 0px;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-top img {
  border-radius: 5px;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle {
  overflow: hidden !important;
  border-top: solid 1px rgba(134, 109, 255, 0.48);
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree {
  width: 100%;
  background: transparent;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-tree-node {
  color: #fff;
  font-size: 16px;
  position: relative;
  font-weight: bold;
  padding-left: 0px;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-tree-node[aria-expanded=true] .icon_btn::before {
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-tree-node .mat-button {
  padding: 0px 0px;
  text-align: left;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .icon_span_active {
  background-color: rgba(223, 222, 229, 0.4784313725);
  color: #fff;
  border-radius: 5px;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .icon_span_active .mdc-list-item__content {
  background-color: rgba(223, 222, 229, 0.4784313725) !important;
  color: white;
  padding: 0px 47px;
  border-radius: 8px;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .icon_span_inactive {
  opacity: 72%;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .custom-mat-expansion-panel::before {
  margin-left: 0px;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel {
  background-color: white;
  color: white !important;
  width: 100%;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-header {
  background-color: white;
  color: white !important;
  padding-right: 60px;
  height: 18px !important;
  padding-left: 69px;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  position: relative;
  right: 1px;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body {
  background-color: white;
  color: white !important;
  padding: 0;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body .mdc-list-item__content {
  background-color: white;
  color: #210854;
  padding: 0px;
  padding-left: 48px;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body .mdc-list-item__content .mdc-list-item__primary-text {
  display: flex;
  flex-direction: row;
  align-items: end;
  color: white;
  overflow: initial;
  letter-spacing: normal;
}
.focus-theme .focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-panel-body .mdc-list-item__content .mdc-list-item__primary-text {
  color: #210854 !important;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expansion-indicator::after {
  color: #210854 !important;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-expansion-panel .mat-expanded {
  background-color: white;
  color: white !important;
  height: 18px !important;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-content {
  color: white;
  opacity: 1;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .mat-tree .mat-content .mat-expansion-panel-header-title {
  background-color: white;
  color: #210854;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-middle .ng-scrollbar-visible {
  right: -9px !important;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom {
  justify-content: start !important;
  border-top: solid 1px rgba(134, 109, 255, 0.48);
  padding: 11px 32px !important;
  height: 72px !important;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-icon {
  border-radius: 50px;
  padding: 12px 13px;
  color: #210854;
  margin-right: 10px;
  opacity: 0.32;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-text {
  align-self: flex-end !important;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-text h3 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  line-height: 1px;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom .user-text button {
  background: transparent;
  border: transparent;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  color: #866dff;
  text-transform: uppercase;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .side-bottom .name-text {
  color: #210854;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .active_account .user-icon {
  background-color: #ffb5a0 !important;
  color: #210854 !important;
}
.focus-theme .mat-drawer .mat-drawer-inner-container .side-container .active_account .user-text button {
  color: #ffb5a0 !important;
}
.focus-theme .activity_header_custom {
  width: 100%;
}
.focus-theme .activity_header_custom .mdc-button,
.focus-theme .activity_header_custom .mat-mdc-button,
.focus-theme .activity_header_custom .mat-mdc-button-base {
  padding: 0 16px !important;
  height: inherit;
}
.focus-theme .activity_header_custom .mat-mdc-button > .mat-icon {
  color: #fff;
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
}
.focus-theme .activity_header_custom .activity-header {
  background-color: white !important;
  box-shadow: none !important;
  width: 100% !important;
  z-index: 114;
  height: 40px;
  top: 85px;
}
.focus-theme .activity_header_custom .activity-header .tool_btn_custom {
  position: relative;
  min-width: unset;
  display: block;
  background-color: transparent;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  .focus-theme .activity_header_custom .activity-header .tool_btn_custom {
    min-width: 50px;
  }
}
.focus-theme .activity_header_custom .activity-header .tool_btn_custom::before {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background-repeat: no-repeat;
}
.focus-theme .activity_header_custom .activity-header .cdk-program-focused {
  display: none;
}
.focus-theme .activity_header_custom .activity-header .up::before {
  background-image: url("assets/img/theme-focus/down.png");
}
.focus-theme .activity_header_custom .activity-header .down {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .down::before {
  background-image: url("assets/img/up.png");
}
.focus-theme .activity_header_custom .activity-header .settings-button::before {
  width: 22px !important;
  height: 22px !important;
  background-image: url("assets/img/theme-focus/setting-2.png");
  background-size: contain;
}
.focus-theme .activity_header_custom .activity-header .settings-dropdown-container {
  position: absolute;
  right: 10px;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 8px;
  padding-top: 12px;
  width: 300px;
}
.focus-theme .activity_header_custom .activity-header .blendmode-button::before {
  background-image: url("assets/img/theme-focus/focus-blender.png");
  background-size: contain;
}
.focus-theme .activity_header_custom .activity-header .active_blendmode {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_blendmode::before {
  background-image: url("assets/img/theme-focus/focus-blender-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .rotate {
  color: #210854;
}
.focus-theme .activity_header_custom .activity-header .read-button::before {
  background-image: url("assets/img/theme-focus/focus-book.png");
}
.focus-theme .activity_header_custom .activity-header .active_read {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_read::before {
  background-image: url("assets/img/theme-focus/focus-book-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .folder-button::before {
  background-image: url("assets/img/theme-focus/focus-page.png");
}
.focus-theme .activity_header_custom .activity-header .active_folder {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_folder::before {
  background-image: url("assets/img/theme-focus/focus-book-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .edit-button::before {
  background-image: url("assets/img/theme-focus/focus-pencil.png");
}
.focus-theme .activity_header_custom .activity-header .active_btn {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_draw {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_draw::before {
  background-image: url("assets/img/theme-focus/focus-pencil-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .eraser-button::before {
  background-image: url("assets/img/theme-focus/focus-erase.png");
  top: 5px !important;
}
.focus-theme .activity_header_custom .activity-header .active_eraser {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_eraser::before {
  background-image: url("assets/img/theme-focus/focus-erase-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .active_color {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .undo-button::before {
  background-image: url("assets/img/theme-focus/focus-undo.png");
}
.focus-theme .activity_header_custom .activity-header .active_undo {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_undo::before {
  background-image: url("assets/img/theme-focus/focus-undo-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .delete-button::before {
  background-image: url("assets/img/theme-focus/focus-delete.png");
}
.focus-theme .activity_header_custom .activity-header .active_delete {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_delete::before {
  background-image: url("assets/img/theme-focus/focus-delete-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .visibility-button::before {
  background-image: url("assets/img/theme-focus/focus-show.png");
}
.focus-theme .activity_header_custom .activity-header .active_visibility {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_visibility::before {
  background-image: url("assets/img/theme-focus/focus-show-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .visibility_hidden {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .visibility_hidden::before {
  background-image: url("assets/img/theme-focus/focus-hide.png") !important;
}
.focus-theme .activity_header_custom .activity-header .keyboard-button::before {
  background-image: url("assets/img/theme-focus/focus-keyboard.png");
}
.focus-theme .activity_header_custom .activity-header .active_keyboard {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_keyboard::before {
  background-image: url("assets/img/theme-focus/focus-keyboard-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .assignment-button::before {
  background-image: url("assets/img/theme-focus/focus-lines-onoff.png");
}
.focus-theme .activity_header_custom .activity-header .active_assignment {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_assignment::before {
  background-image: url("assets/img/theme-focus/focus-lines-onoff-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .format_size-button::before {
  background-image: url("assets/img/theme-focus/focus-capitalize.png");
}
.focus-theme .activity_header_custom .activity-header .active_format_size {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_format_size::before {
  background-image: url("assets/img/theme-focus/focus-capitalize-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .aspect_ratio-button::before {
  background-image: url("assets/img/theme-focus/focus-resize.png");
}
.focus-theme .activity_header_custom .activity-header .active_aspect_ratio {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_aspect_ratio::before {
  background-image: url("assets/img/theme-focus/focus-resize-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .search-plus-button::before {
  background-image: url("assets/img/theme-focus/focus-zoom-in.png");
}
.focus-theme .activity_header_custom .activity-header .active_search_plus {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_search_plus::before {
  background-image: url("assets/img/theme-focus/focus-zoom-in-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .search-minus-button::before {
  background-image: url("assets/img/theme-focus/focus-zoom-out.png");
}
.focus-theme .activity_header_custom .activity-header .active_search_minus {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_search_minus::before {
  background-image: url("assets/img/theme-focus/focus-zoom-out-active.png") !important;
  background-repeat: no-repeat;
}
.focus-theme .activity_header_custom .activity-header .landscape-button::before {
  background-image: url("assets/img/theme-focus/focus-whole-word.png");
}
.focus-theme .activity_header_custom .activity-header .active_landscape {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_landscape::before {
  background-image: url("assets/img/theme-focus/focus-whole-word-active.png") !important;
  background-repeat: no-repeat;
}
.focus-theme .activity_header_custom .activity-header .bumping-tiles-button::before {
  background-image: url("assets/img/theme-focus/focus-tile-bumping-onoff.png");
}
.focus-theme .activity_header_custom .activity-header .active_bumping_tiles {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_bumping_tiles::before {
  background-image: url("assets/img/theme-focus/focus-tile-bumping-onoff-active.png") !important;
  background-repeat: no-repeat;
}
.focus-theme .activity_header_custom .activity-header .rotate-canvas-button::before {
  appearance: none;
  background-image: none;
}
.focus-theme .activity_header_custom .activity-header .active_pdf_search_minus {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_pdf_search_minus::before {
  background-image: url("assets/img/theme-focus/focus-zoom-out-active.png") !important;
  background-repeat: no-repeat;
}
.focus-theme .activity_header_custom .activity-header .view_array-button::before {
  background-image: url("assets/img/theme-focus/focus-tiles.png");
}
.focus-theme .activity_header_custom .activity-header .active_view_array {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_view_array::before {
  background-image: url("assets/img/theme-focus/focus-tiles-active.png") !important;
  background-repeat: no-repeat;
}
.focus-theme .activity_header_custom .activity-header .info-button::before {
  width: 27px !important;
  height: 26px !important;
  background-image: url("assets/img/theme-focus/focus-help.png");
  position: relative;
  top: -3px;
  right: 3px;
}
.focus-theme .activity_header_custom .activity-header .active_info {
  background-color: #f1eeff;
}
.focus-theme .activity_header_custom .activity-header .active_info::before {
  background-image: url("assets/img/theme-focus/focus-help-active.png") !important;
}
.focus-theme .activity_header_custom .activity-header .color-button .mdc-button__label {
  position: relative;
  bottom: 22px;
}
.focus-theme .activity_header_custom .activity-header .activity_dropdown {
  position: absolute !important;
  left: 21px;
  top: 0;
  /*.mat-mdc-form-field-wrapper{
  	padding-bottom: 10px !important;
  }
  .mat-select-value-text{
  	font-size: 32px;
  	color: #210854;
  	font-weight: bold;
  }
  .mat-mdc-form-field-underline{
  	background-color: transparent;
  }*/
}
.focus-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_div {
  position: relative;
  height: 27px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 12px;
  top: -12px;
}
.focus-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_div .top_dropdown {
  font-size: 14px;
  color: #866dff;
  position: relative;
  height: 30px;
  align-items: center;
  display: flex;
}
.focus-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_div button {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  height: 16px;
  position: relative;
  top: -3px;
  cursor: pointer;
}
.focus-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_div button img {
  height: 16px;
}
.focus-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_child_div {
  position: relative;
  top: -35px;
  height: 32px;
  display: flex;
  align-items: center;
}
.focus-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_child_div span {
  font-size: 32px;
  font-weight: bold;
  color: #210854;
  height: 50px;
  display: flex;
  align-items: center;
  line-height: 0px;
}
.focus-theme .activity_header_custom .activity-header .activity_dropdown .dropdown_child_div button {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.focus-theme .activity_header_custom .activity-header .visibility-button .mdc-button__label {
  position: absolute;
  top: -3px;
  left: 19px;
}
.focus-theme .activity_header_custom .activity-header .active-indicator {
  width: 100%;
  height: 5px;
  position: relative;
  background-color: #210854;
}
.focus-theme .activity_header_custom .pad-container {
  box-shadow: 0 1px 10px 1px rgba(0, 0, 0, 0.2);
}
.focus-theme .activity_header_custom .pad-container .pullout-handle {
  z-index: 120;
  width: 40px;
  height: 40px;
  border: solid 1px rgba(33, 8, 84, 0.08);
  background-color: white;
  border-radius: 50%;
  position: relative;
  background-image: none;
  margin: auto;
  opacity: 0;
}
.focus-theme .activity_header_custom .pad-container .pullout-handle::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 7px;
}
.focus-theme .activity_header_custom .pad-container .up {
  background-image: url("assets/img/theme-focus/down.png");
  background-position: center;
}
.focus-theme .activity_header_custom .pad-container .down {
  background-image: url("assets/img/up.png");
  background-position: center;
}
.focus-theme .activity_header_custom .pad-container .pullout-handle-up {
  z-index: 116;
  width: 40px;
  height: 40px;
  border: solid 1px rgba(33, 8, 84, 0.08);
  background-color: #ffffff;
  border-radius: 50%;
  position: relative;
  bottom: -22px;
  background-image: none;
  margin: auto;
}
.focus-theme .activity_header_custom .pad-container .pullout-handle-up::before {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  left: 7px;
}
.focus-theme .new-tile-container {
  overflow: hidden;
}
@media only screen and (min-height: 2160px) {
  .focus-theme .blackboard_custom {
    height: 91%;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .focus-theme .blackboard_custom {
    height: 86%;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .focus-theme .blackboard_custom {
    height: 86%;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .focus-theme .blackboard_custom {
    height: 82%;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .focus-theme .blackboard_custom {
    height: 80%;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .focus-theme .blackboard_custom {
    height: 78%;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .focus-theme .blackboard_custom {
    height: 74%;
  }
}
.focus-theme .blackboard_custom .new-tile-container {
  height: 100%;
  background-color: #e4e2ed;
}
.focus-theme .blackboard_custom .mat-figure {
  background-color: transparent;
}
.focus-theme .blackboard_custom .mat-figure .inside-tile {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
}
.focus-theme .blackboard_custom .line-holder .line {
  border-bottom: 1px solidrgba(#210854, 0.16);
}
.focus-theme .blackboard_custom .frozen-row-shadow {
  -webkit-box-shadow: 0 2px 4px 0 #dfd8f4;
  -moz-box-shadow: 0 2px 4px 0 #dfd8f4;
  box-shadow: 0 2px 4px 0 #dfd8f4;
  background: #e4e2ed;
}
.focus-theme .sentence_div {
  margin: 0px 0px;
}
.focus-theme .sentence_div .sentence_mat_card {
  height: 433px !important;
  padding: 179px 0px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: #ffffff !important;
  text-align: center;
  width: 100%;
}
.focus-theme .sentence_div .sentence_mat_card span {
  font-size: 64px;
  color: #210854;
  font-weight: bold;
}
.focus-theme .sentence_tally {
  top: 10px !important;
  padding: 10px 10px;
  position: relative !important;
  display: flex;
  justify-content: center;
}
.focus-theme .pdf-container {
  display: flex;
  margin-top: 180px;
}
.focus-theme .pdf-container .pdf-navigators {
  z-index: 111;
  display: flex;
  justify-content: center;
  width: 125px;
  height: 150px;
  position: relative;
  top: 35vh;
}
.focus-theme .pdf-container .blackboard-canvas {
  position: absolute;
  z-index: 110;
  overflow: hidden;
}
.focus-theme .pdf-container .pdf-screen {
  flex: 0;
  align-items: center;
  border: none;
  box-shadow: none;
  width: 70vw;
}
.focus-theme .pdf_control_custom {
  justify-content: space-between !important;
  z-index: 111;
}
.focus-theme .pdf_control_custom .control_left .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 375px;
  height: 56px;
  border-radius: 4px;
  border: solid 1px rgba(33, 8, 84, 0.32);
  padding: 0 24px !important;
  display: flex;
  align-items: center;
  color: #210854;
}
.focus-theme .pdf_control_custom .control_left .mat-mdc-form-field-underline {
  display: none;
}
.focus-theme .pdf_control_custom .control_left .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.focus-theme .pdf_control_custom .control_left .mat-mdc-form-field-label {
  top: 35px;
  left: 16px;
  color: #210854;
  font-size: 16px;
  background-color: #e4e2ed;
  padding: 5px 10px;
}
.focus-theme .pdf_control_custom .control_left.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: #e4e2ed;
  padding: 5px 10px;
  width: auto;
  top: 20px;
  left: 16px;
}
.focus-theme .pdf_control_custom .control_right .mat-mdc-form-field-flex {
  width: 40px;
}
.focus-theme .pdf_control_custom .control_right span {
  margin-left: 25px;
}
.focus-theme .pdf_control_custom .control_right .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 59px;
  width: 100px;
  height: 56px;
  border-radius: 4px;
  border: solid 1px rgba(33, 8, 84, 0.32);
  padding: 0 0px !important;
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: #210854;
}
.focus-theme .pdf_control_custom .control_right .mat-mdc-form-field-infix input {
  text-align: center !important;
}
.focus-theme .pdf_control_custom .control_right .mat-mdc-form-field-underline {
  display: none;
}
.focus-theme .pdf_control_custom .control_right .mat-mdc-form-field-label-wrapper {
  left: -70px;
  top: 19px;
}
.focus-theme .pdf_control_custom .control_right .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  font-size: 16px;
  color: #210854;
}
.focus-theme #overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 101 !important;
  opacity: 0.4;
}
.focus-theme #overlay pdf-viewer {
  z-index: 0 !important;
  position: absolute;
  height: 100%;
  width: 100%;
}
.focus-theme #overlay pdf-viewer.hidden {
  opacity: 0;
}
.focus-theme .pdf_div {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  height: auto !important;
}
.focus-theme .pdf_div .back {
  display: flex;
  flex-direction: column;
}
.focus-theme .pdf_div .next {
  display: flex;
  flex-direction: column;
}
.focus-theme .pdf_div .pdf_mat_card {
  height: 100% !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: #ffffff !important;
  text-align: center;
  width: 100%;
}
.focus-theme .tile-content_div .cdk-drag-placeholder {
  opacity: 0;
  display: none;
}
.focus-theme .tile-content_div .mat-figure {
  background-color: transparent;
}
.focus-theme .tile-content_div .mat-figure .inside-tile {
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
}
.focus-theme .tile-lay-out-bottom {
  background-color: white;
}
.focus-theme .tilelayout_div_footer {
  background-color: white;
}
.focus-theme .tilelayout_div_footer .mat-card-content {
  font-size: 1.6837vw !important;
}
.focus-theme .tilelayout_div_footer .mat-card {
  min-width: 49px;
  min-height: 60px;
  padding: 0px;
  border-radius: 4px;
  text-align: center;
  box-shadow: none;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 6px;
  padding-right: 6px;
}
.focus-theme .ng-resizable-handle {
  background-color: #866dff !important;
  width: 40px !important;
  height: 40px !important;
}
.focus-theme .ng-resizable-handle::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-focus/down-white.png");
  background-repeat: no-repeat;
  display: block;
  top: 15px;
  left: 8px;
}
.focus-theme .ng-resizable-handle::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-focus/down-white.png");
  background-repeat: no-repeat;
  display: block;
  top: 2px;
  left: 8px;
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
.focus-theme .whiteboard-container {
  height: 100%;
  width: 91%;
  position: absolute;
  left: 0;
  overflow: hidden;
  z-index: 0;
}
.focus-theme .whiteboard-container .wordcard {
  color: #210854;
  background-color: white;
}
.focus-theme .phrases_div {
  margin: 50px 0px;
  height: 100% !important;
}
.focus-theme .phrases_tally {
  padding: 10px 10px;
  position: relative !important;
  display: flex;
  justify-content: center;
}
.focus-theme .tally-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  top: 50px !important;
  height: auto !important;
  z-index: 1;
}
.focus-theme .spelling_div .spelling_footer_div {
  background-color: white;
  display: flex;
  flex-direction: column;
}
.focus-theme .spelling_div .spelling_footer_div .spelling_span_words {
  margin-left: 76px;
  font-size: 16px;
  font-weight: 600;
  color: #bcbdbf;
  display: block;
  height: 32px;
}
.focus-theme .spelling_div .spelling_footer_div .spelling_btn {
  background: #ffb5a0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: #210854;
}
.focus-theme .spelling_div .spelling_footer_div .skip_btn {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  background: #eeeeee;
  color: #888888;
  max-width: 75px;
}
.focus-theme .spelling_div .spelling_footer_div .spelling_icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 40px;
  margin-right: 32px;
}
.focus-theme .spelling_div .spelling_footer_div .spelling_icon i {
  color: #866dff;
}
.focus-theme .workbook_tile_div {
  margin-top: 180px;
  height: 66%;
  position: relative;
  background-color: white;
}
@media only screen and (min-height: 2160px) {
  .focus-theme .workbook_tile_div {
    height: 91%;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .focus-theme .workbook_tile_div {
    height: 86%;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .focus-theme .workbook_tile_div {
    height: 86%;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .focus-theme .workbook_tile_div {
    height: 82%;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .focus-theme .workbook_tile_div {
    height: 80%;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .focus-theme .workbook_tile_div {
    height: 78%;
  }
}
@media only screen and (min-height: 768px) and (max-height: 900px) {
  .focus-theme .workbook_tile_div {
    height: 75%;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .focus-theme .workbook_tile_div {
    height: 74%;
  }
}
.focus-theme .workbook_tile_div .mat-figure {
  background-color: transparent;
}
.focus-theme .workbook_tile_div .mat-figure .inside-tile {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0px !important;
}
.focus-theme .workbook_tile_div .line-holder .line {
  border-bottom: 1px solid rgba(33, 8, 84, 0.16);
}
.focus-theme .workbook_tile_div .dnd-drag-start {
  width: 50px;
  height: 50px;
}
.focus-theme .tilelayout_div .mat-figure {
  background-color: transparent;
}
.focus-theme .tilelayout_div .mat-figure .inside-tile {
  width: 100%;
  height: 100%;
  border-radius: 0px !important;
}
.focus-theme .tilelayout_div .mat-figure:empty {
  border: none !important;
}
.focus-theme .flashcard-box {
  position: relative;
}
.focus-theme .flashcard-box .back {
  top: 45%;
  position: relative;
  height: 100px;
}
@media only screen and (max-width: 1280px) {
  .focus-theme .flashcard-box .back .mat-button {
    top: 0% !important;
  }
}
.focus-theme .flashcard-box .next {
  top: 45%;
  position: relative;
  height: 100px;
}
@media only screen and (max-width: 1280px) {
  .focus-theme .flashcard-box .next .mat-button {
    top: 0% !important;
  }
}
.focus-theme .state-box {
  position: relative;
  top: 57px;
  border-radius: 16px;
  border: solid 1px rgba(33, 8, 84, 0.16);
  background: transparent !important;
  font-size: 14px;
  color: #210854 !important;
}
.focus-theme .mini_tile {
  margin-top: 180px;
  height: 66%;
}
@media only screen and (min-height: 2160px) {
  .focus-theme .mini_tile {
    height: 91%;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .focus-theme .mini_tile {
    height: 86%;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .focus-theme .mini_tile {
    height: 86%;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .focus-theme .mini_tile {
    height: 82%;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .focus-theme .mini_tile {
    height: 80%;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .focus-theme .mini_tile {
    height: 78%;
  }
}
@media only screen and (min-height: 768px) and (max-height: 900px) {
  .focus-theme .mini_tile {
    height: 75%;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .focus-theme .mini_tile {
    height: 74%;
  }
}
.focus-theme .mini_tile .center_align_tile > div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.focus-theme .mini_tile .center_align_tile > div .new-tile-block {
  position: relative !important;
  left: 0 !important;
  margin-right: 10px;
}
.focus-theme .mini_tile .center_align_tile > div .new-tile-block:last-child {
  margin-right: 0 !important;
}
.focus-theme .mini_tile .line-holder .line {
  border-bottom: 1px solid rgba(33, 8, 84, 0.16);
}
.focus-theme .mini_tile .dnd-drag-start {
  width: 50px;
  height: 50px;
}
@media only screen and (min-height: 2160px) {
  .focus-theme .blank_tile {
    height: 91vh;
  }
}
@media only screen and (min-height: 1440px) and (max-height: 2160px) {
  .focus-theme .blank_tile {
    height: 86vh;
  }
}
@media only screen and (min-height: 1200px) and (max-height: 1440px) {
  .focus-theme .blank_tile {
    height: 86vh;
  }
}
@media only screen and (min-height: 1080px) and (max-height: 1200px) {
  .focus-theme .blank_tile {
    height: 82vh;
  }
}
@media only screen and (min-height: 1024px) and (max-height: 1080px) {
  .focus-theme .blank_tile {
    height: 80vh;
  }
}
@media only screen and (min-height: 900px) and (max-height: 1024px) {
  .focus-theme .blank_tile {
    height: 78vh;
  }
}
@media only screen and (min-height: 700px) and (max-height: 768px) {
  .focus-theme .blank_tile {
    height: 72vh;
  }
}
.focus-theme .blank_tile .center_align_tile > div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.focus-theme .blank_tile .center_align_tile > div .new-tile-block {
  position: relative !important;
  left: 0 !important;
  margin-right: 10px;
}
.focus-theme .blank_tile .center_align_tile > div .new-tile-block:last-child {
  margin-right: 0 !important;
}
.focus-theme .passage_tally {
  position: relative !important;
  display: flex;
  justify-content: center;
  height: 100px;
}
.focus-theme .passage_div .passage_mat_card {
  padding: 68px 88px !important;
  height: 500px !important;
  overflow-y: scroll !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: #ffffff !important;
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.focus-theme .passage_div .passage_mat_card span {
  color: #210854;
  font-size: 64px !important;
  font-weight: bold;
}
.focus-theme .time_reading {
  margin: 0px 0px;
}
.focus-theme .time_reading .wordcards {
  font-size: 24px !important;
  color: #210854;
}
.focus-theme .time_reading .button-box {
  position: fixed;
  top: 40px;
  z-index: 117;
  right: 32px;
}
.focus-theme .time_reading .button-box .mat-mdc-button-base {
  background-color: #ffb5a0;
  border-radius: 4px;
  box-shadow: none;
}
.focus-theme .time_reading .button-box .mat-mdc-button-base .mdc-button__label {
  line-height: 1em !important;
  color: #210854;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .time_reading .wrong {
  text-decoration: line-through;
  color: #ff7463 !important;
}
.focus-theme .tile-container {
  width: 100% !important;
  position: relative;
  display: flex !important;
  flex-direction: row;
  padding-right: 8vw;
}
.focus-theme .tile-container .line-holder {
  display: none !important;
}
.focus-theme .tile-container .flashcard {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: auto !important;
  height: 433px !important;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08);
  background-color: white;
  font-size: 6vw !important;
  color: #210854 !important;
  margin: 0px -5px;
  border: none !important;
}
.focus-theme .tile-container .flashcard-altered {
  display: flex;
  flex-direction: column;
  justify-self: center;
  width: auto !important;
  height: 433px !important;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08);
  background-color: white;
  font-size: 6vw !important;
  color: #210854 !important;
  margin: 0px -5px;
  border: none !important;
}
.focus-theme .letter-box .lettercard {
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
}
.focus-theme .letter-box .back {
  position: relative;
  top: 42%;
}
.focus-theme .letter-box .next {
  position: relative;
  top: 42%;
}
.focus-theme .card {
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: white;
  font-size: 64px !important;
  padding: 38px 0px !important;
}
.focus-theme .card span {
  color: #210854;
}
.focus-theme .mat-mdc-card-subtitle,
.focus-theme .mat-mdc-card-content {
  font-size: 14px;
}
.focus-theme .side_bar_display {
  width: 72px !important;
}
.focus-theme .side_bar_display .side-top {
  padding: 32px 15px 0px !important;
}
.focus-theme .side_bar_display .side-middle {
  overflow-y: auto !important;
  overflow: hidden !important;
}
.focus-theme .side_bar_display .side-middle .ng-scroll-view {
  overflow-x: hidden;
}
.focus-theme .side_bar_display .side-middle .mat-expansion-panel-content {
  display: none;
}
.focus-theme .side_bar_display .side-middle .mat-tree {
  padding: 0px 0px 0px 0px !important;
}
.focus-theme .side_bar_display .side-middle .mat-tree .nav-icons {
  left: 12px !important;
}
.focus-theme .side_bar_display .side-middle .mat-tree .mat-tree-node {
  padding-left: 5px !important;
}
.focus-theme .side_bar_display .side-middle .mat-tree .mat-tree-node[role=treeitem] {
  display: none;
}
.focus-theme .side_bar_display .side-bottom {
  position: relative;
  left: -17px;
}
.focus-theme .side_bar_display .side-bottom .user-icon {
  margin-right: 20px !important;
}
.focus-theme .side_bar_display .content_div {
  width: 95%;
}
.focus-theme .side_navbar, .focus-theme .element-drag-disabled {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.focus-theme .add-save-setting-modalbox {
  width: 350px !important;
}
.focus-theme .add-save-setting-modalbox .mat-mdc-dialog-container {
  padding: 0 !important;
  background-color: white;
}
.focus-theme .add-save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content {
  margin: 0;
  padding: 25px 24px;
}
.focus-theme .add-save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.focus-theme .add-save-setting-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-wrapper {
  padding: 0;
  width: 100%;
}
.focus-theme .add-save-setting-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-infix {
  color: #210854;
}
.focus-theme .add-save-setting-modalbox .add-save-setting {
  width: 350px;
}
.focus-theme .add-save-setting-modalbox .add-save-setting .popup-header {
  border-bottom: solid 1px rgba(72, 30, 159, 0.08);
  padding: 19px 24px;
}
.focus-theme .add-save-setting-modalbox .add-save-setting .popup-header h1 {
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  color: #210854;
  font-weight: 600;
}
.focus-theme .add-save-setting-modalbox .add-save-setting .btn-container {
  text-align: right;
  justify-content: flex-end;
  padding: 0 24px;
}
.focus-theme .add-save-setting-modalbox .add-save-setting .btn-container .mat-button {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.focus-theme .add-save-setting-modalbox .add-save-setting .btn-container .mat-button .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .add-save-setting-modalbox .add-save-setting .btn-container .disable_save {
  opacity: 0.5;
}
.focus-theme .add-save-setting-modalbox .add-save-setting .mat-mdc-dialog-actions .mat-mdc-button-base {
  margin-left: 0;
}
.focus-theme .save-setting-modalbox .mat-mdc-dialog-container {
  padding: 0 !important;
  background-color: white;
}
.focus-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content {
  margin: 0;
  padding: 25px 24px;
}
.focus-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.focus-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.focus-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-form-field-infix {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .delete {
  margin: 18px 0px;
}
.focus-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .delete .mat-button {
  padding: 0px 0px;
  background-color: transparent;
}
.focus-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-dialog-content .delete span {
  margin: 0px 18px;
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .save-setting-modalbox .mat-mdc-dialog-container .mat-mdc-form-field-wrapper {
  padding: 0;
  width: 100%;
}
.focus-theme .save-setting-modalbox .add-save-setting {
  width: 350px;
}
.focus-theme .save-setting-modalbox .add-save-setting .popup-header {
  border-bottom: solid 1px rgba(33, 8, 84, 0.08);
  padding: 19px 24px;
}
.focus-theme .save-setting-modalbox .add-save-setting .popup-header h1 {
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  color: #210854;
  font-weight: 600;
}
.focus-theme .save-setting-modalbox .add-save-setting .btn-container {
  text-align: right;
  justify-content: flex-end;
  padding: 0 24px;
}
.focus-theme .save-setting-modalbox .add-save-setting .btn-container .mat-button {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.focus-theme .save-setting-modalbox .add-save-setting .btn-container .mat-button .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .save-setting-modalbox .add-save-setting .btn-container .disable_save {
  opacity: 0.5;
}
.focus-theme .save-setting-modalbox .add-save-setting .mat-mdc-dialog-actions .mat-mdc-button-base {
  margin-left: 0;
}
.focus-theme .activity-modalbox .mat-mdc-dialog-title {
  color: #210854 !important;
  font-weight: bold !important;
  letter-spacing: normal;
  margin-top: 24px;
}
.focus-theme .activity-modalbox .mat-mdc-dialog-content {
  max-height: 45vh;
  border-bottom: solid 1px rgba(33, 8, 84, 0.08);
  border-top: solid 1px rgba(33, 8, 84, 0.08);
  padding: 0 !important;
}
.focus-theme .activity-modalbox .mat-mdc-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(33, 8, 84, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 24px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
  cursor: pointer;
}
.focus-theme .activity-modalbox .mat-mdc-dialog-content .mat-mdc-list-item.selected {
  background: #EEEEEE !important;
}
.focus-theme .activity-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item {
  font-size: 18px !important;
  color: #210854;
  line-height: 30px !important;
}
.focus-theme .activity-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item_child {
  font-size: 14px !important;
  color: rgba(13, 2, 35, 0.87);
}
.focus-theme .activity-modalbox .mat-mdc-button-base {
  float: right;
  position: relative;
  top: 4px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px 24px;
  margin: 6px 0;
}
.focus-theme .activity-modalbox .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .activity-modalbox .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .time_reading-modalbox {
  width: 350px;
}
.focus-theme .time_reading-modalbox .mat-mdc-dialog-container {
  background-color: white;
}
.focus-theme .time_reading-modalbox .mat-mdc-dialog-title {
  margin: 0 0 0px;
  color: #0d0223;
  font-weight: bold !important;
  font-size: 20px;
  margin-bottom: 16px;
}
.focus-theme .time_reading-modalbox .mat-dialog-content p {
  font-size: 16px;
  color: rgba(13, 2, 35, 0.56);
  line-height: 20px;
}
.focus-theme .time_reading-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  margin-right: 16px;
  margin-bottom: 8px;
}
.focus-theme .time_reading-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  background: transparent;
}
.focus-theme .time_reading-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  font-weight: bolder !important;
  letter-spacing: 0.4px;
}
.focus-theme .score-modalbox {
  width: 350px;
}
.focus-theme .score-modalbox .mat-mdc-dialog-title {
  margin: 0 0 0px;
  color: #0d0223;
  font-weight: bold;
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 8px;
}
.focus-theme .score-modalbox .mat-dialog-content p {
  font-size: 16px;
  color: rgba(13, 2, 35, 0.56);
  line-height: 20px;
}
.focus-theme .score-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0px 0px !important;
  margin-right: 24px;
  margin-bottom: 8px;
}
.focus-theme .score-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .score-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  font-weight: bolder !important;
  letter-spacing: 0.4px;
}
.focus-theme .score-modalbox .mat-mdc-dialog-actions button:first-child {
  margin-right: 8px;
}
.focus-theme .display-score-modalbox {
  width: 400px;
  height: 350px;
}
.focus-theme .display-score-modalbox .mat-mdc-dialog-title {
  margin: 0 0 12px;
  color: #0d0223;
  font-weight: bold;
  font-size: 20px;
}
.focus-theme .display-score-modalbox .mat-dialog-content {
  height: 70%;
}
.focus-theme .display-score-modalbox .mat-dialog-content .mat-mdc-list-item {
  height: 30px;
  font-size: 16px;
  color: rgba(33, 8, 84, 0.56);
}
.focus-theme .display-score-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0px 0px !important;
}
.focus-theme .display-score-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .activity-common-modalbox {
  height: 364px !important;
}
.focus-theme .activity-common-modalbox .mat-mdc-dialog-container {
  padding: 0px 0px;
  background-color: white;
}
.focus-theme .course-list-modalbox {
  width: 350px !important;
  height: auto !important;
}
.focus-theme .course-list-modalbox .mat-mdc-dialog-container {
  overflow: hidden;
  background-color: white;
}
.focus-theme .course-list-modalbox .mat-mdc-dialog-title,
.focus-theme .course-list-modalbox .mdc-dialog__title {
  color: #210854 !important;
  font-weight: bold !important;
  margin-top: 24px;
  font-size: 18px !important;
}
.focus-theme .course-list-modalbox .mat-mdc-dialog-content {
  max-height: 45vh;
  border-bottom: solid 1px rgba(72, 30, 159, 0.08);
  border-top: solid 1px rgba(72, 30, 159, 0.08);
  padding: 0px 0px !important;
}
.focus-theme .course-list-modalbox .mat-mdc-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(72, 30, 159, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 0px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
  cursor: pointer;
}
.focus-theme .course-list-modalbox .mat-mdc-dialog-content .mdc-list-item__content {
  padding: 0px 24px !important;
}
.focus-theme .course-list-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item {
  font-size: 16px !important;
  color: #210854;
  line-height: 30px !important;
}
.focus-theme .course-list-modalbox .mat-mdc-dialog-content .mdc-list-item__content .activity_item_child {
  font-size: 14px !important;
}
.focus-theme .course-list-modalbox .mat-mdc-button-base {
  color: #866dff !important;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  float: right;
  margin: 10px;
  position: relative;
  background: transparent;
}
.dark-theme .focus-theme .missing-tiles-modalbox .mat-mdc-dialog-title, .dark-theme .focus-theme .missing-tiles-modalbox .description {
  color: white !important;
}
.focus-theme .missing-tiles-modalbox .mat-mdc-dialog-container {
  background-color: white;
}
.focus-theme .missing-tiles-modalbox .mat-dialog-content {
  margin: 0;
  padding: 0;
}
.focus-theme .missing-tiles-modalbox .mat-dialog-content .mat-mdc-dialog-title {
  color: #210854;
  font-size: 24px;
}
.focus-theme .missing-tiles-modalbox .mat-dialog-content .mat-typography .mat-typography-p {
  color: rgba(33, 8, 84, 0.56);
}
.focus-theme .missing-tiles-modalbox .mat-mdc-dialog-actions .add_tile_missing {
  background-color: #ffb5a0;
  color: #210854;
  box-shadow: none;
  font-size: 14px;
  display: flex;
  flex-direction: row;
}
.focus-theme .missing-tiles-modalbox .mat-mdc-dialog-actions .add_tile_missing .mdc-button__label {
  width: 100%;
  color: #210854;
}
.focus-theme .missing-tiles-modalbox .mat-mdc-dialog-actions .skip_tile_missing {
  color: #210854;
  font-size: 14px;
  align-self: center;
  display: flex;
  flex-direction: row;
  background-color: lightgray;
}
.focus-theme .missing-tiles-modalbox .mat-mdc-dialog-actions .skip_tile_missing .mdc-button__label {
  width: 100%;
  color: #210854;
}
.focus-theme .small-dialog {
  max-width: 20vw !important;
}
.focus-theme .small-dialog .mat-mdc-dialog-title {
  color: #210854;
}
.focus-theme .small-dialog .mat-dialog-content {
  color: rgba(13, 2, 35, 0.5607843137);
}
.focus-theme .ng-scrollbar {
  --scrollbar-size: 8px !important;
  --scrollbar-thumb-color: rgba(94, 175, 255, 0.5)!important;
  --scrollbar-thumb-hover-color: dodgerblue;
  --scrollbar-border-radius: 4px;
}
.focus-theme .scrollbar {
  overflow-y: auto;
}
.focus-theme .scrollbar::-webkit-scrollbar {
  width: 10px;
}
.focus-theme .scrollbar::-webkit-scrollbar-track {
  border-radius: 5px;
}
.focus-theme .scrollbar::-webkit-scrollbar-thumb {
  background: rgba(94, 175, 255, 0.3);
  border-radius: 5px;
}
.focus-theme .scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(94, 175, 255, 0.5);
}
.focus-theme .scrollbar-grey {
  overflow-y: auto;
}
.focus-theme .scrollbar-grey::-webkit-scrollbar {
  width: 15px;
}
.focus-theme .scrollbar-grey::-webkit-scrollbar-track {
  background: white;
}
.focus-theme .scrollbar-grey::-webkit-scrollbar-thumb {
  background: rgba(179, 179, 179, 0.5);
  border-radius: 5px;
}
.focus-theme .scrollbar-grey::-webkit-scrollbar-thumb:hover {
  background: rgba(179, 179, 179, 0.5);
}
.focus-theme .course_content_custom {
  height: 154px !important;
  min-height: 154px !important;
  padding: 32px 24px !important;
  background-color: white;
}
.focus-theme .course_content_custom .header {
  position: relative;
  display: flex;
  align-items: center;
}
.focus-theme .course_content_custom .header h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0;
}
.focus-theme .course_content_custom .header .help_icon {
  position: relative;
  left: 10px;
  top: 0px;
}
.focus-theme .course_content_custom .course-button {
  background-color: #ffb5a0 !important;
  border: 1px solid #ffb5a0;
  border-radius: 4px;
  height: 40px !important;
  width: auto !important;
  box-shadow: none !important;
  top: 50px;
  right: 15px;
  min-width: auto;
}
.focus-theme .course_content_custom .course-button .mdc-button__label {
  line-height: 40px !important;
  color: #210854;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.focus-theme .course_content_custom .course-button .mdc-button__label {
  line-height: 40px !important;
}
.focus-theme .dashboard_content_toggle {
  background-color: #dfdee5 !important;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper {
  padding: 0px 24px;
  overflow: initial;
  margin-bottom: 50px !important;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .reoder {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  margin: 32px 10px 0px;
  box-shadow: none !important;
  line-height: 1.2;
  height: 40px;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .reoder .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .reoder .mdc-button__label {
  color: #fff;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .done {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  margin: 32px 10px 0px;
  box-shadow: none !important;
  line-height: 1.2;
  height: 40px;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .done .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .done .mdc-button__label {
  color: #fff;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .search-and-actions {
  background-color: #dfdee5 !important;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .quick-start {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  margin: 32px 10px 0px;
  box-shadow: none !important;
  line-height: 1.2;
  height: 40px;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .quick-start .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-body-wrapper .quick-start .mat-button-wrapper {
  color: #fff;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-header {
  border: none;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container {
  background-color: white;
  padding: 0px 24px;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 1;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab[aria-disabled=true] {
  opacity: 0.32;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab__text-label {
  color: #481e9f !important;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
.focus-theme .dashboard_content_toggle .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
  border-color: #481e9f !important;
}
.focus-theme .dashboard_custom_card {
  padding: 24px 0px;
}
.focus-theme .dashboard_custom_card .blank_div_container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 32px 0px;
}
.focus-theme .dashboard_custom_card .blank_div_container .add_workbook_blank {
  background-color: #866dff !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 9px 5px 0px !important;
  background-image: none;
}
.focus-theme .dashboard_custom_card .blank_div_container .add_workbook_blank span {
  color: #fff !important;
  margin-bottom: 10px;
}
.focus-theme .dashboard_custom_card .blank_div_container .blank_div {
  background-color: #d8d5e7 !important;
  height: 118px !important;
  border-radius: 8px;
  box-shadow: none !important;
  margin: 9px 5px 0px !important;
}
.focus-theme .dashboard_custom_card .new-workbook {
  height: 118px !important;
  border: none !important;
  margin-right: 5px !important;
  border-radius: 8px !important;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (max-width: 1920px) {
  .focus-theme .dashboard_custom_card .new-workbook {
    width: 19.2% !important;
  }
}
@media only screen and (max-width: 1680px) {
  .focus-theme .dashboard_custom_card .new-workbook {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1366px) {
  .focus-theme .dashboard_custom_card .new-workbook {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .focus-theme .dashboard_custom_card .new-workbook {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .focus-theme .dashboard_custom_card .new-workbook {
    width: 31% !important;
  }
}
.focus-theme .dashboard_custom_card .new-workbook span {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.focus-theme .dashboard_custom_card .workbook {
  height: 118px !important;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: white !important;
  margin: 9px 5px 0px !important;
}
.focus-theme .dashboard_custom_card .workbook .mat-mdc-card-header {
  color: #210854;
  text-align: left;
  font-weight: 600;
}
.focus-theme .dashboard_custom_card .workbook .mat-mdc-card-header .mat-mdc-card-header-text {
  margin: 0 0px !important;
}
.focus-theme .dashboard_custom_card .workbook .mat-mdc-card-header .red-circle {
  width: 8px;
  height: 8px;
  background: #ff5a5a;
  border-radius: 50px;
  position: absolute;
  right: 4px;
  top: 4px;
}
.focus-theme .dashboard_custom_card .workbook .actions {
  position: relative;
}
.focus-theme .dashboard_custom_card .workbook .actions .begin-button {
  position: absolute;
  left: -6px;
  top: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.focus-theme .dashboard_custom_card .workbook .actions .begin-button .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .dashboard_custom_card .workbook .actions .ellipsis {
  position: absolute;
  right: 0;
  background: none;
  box-shadow: none;
}
.focus-theme .dashboard_custom_card .workbook .actions .ellipsis .mdc-button__label {
  color: rgba(0, 0, 0, 0.3) !important;
}
@media only screen and (max-width: 1920px) {
  .focus-theme .dashboard_custom_card .content {
    width: 19.2% !important;
  }
}
@media only screen and (max-width: 1680px) {
  .focus-theme .dashboard_custom_card .content {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1366px) {
  .focus-theme .dashboard_custom_card .content {
    width: 18.8% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .focus-theme .dashboard_custom_card .content {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .focus-theme .dashboard_custom_card .content {
    width: 31% !important;
  }
}
.focus-theme .dashboard_custom_card_reoder {
  padding: 24px 0px;
}
.focus-theme .dashboard_custom_card_reoder .new-workbook {
  width: 18.8% !important;
  height: 118px !important;
  border: none !important;
  border-radius: 8px !important;
  margin-right: 5px !important;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.focus-theme .dashboard_custom_card_reoder .new-workbook div {
  margin-top: 10px;
}
.focus-theme .dashboard_custom_card_reoder .new-workbook span {
  color: #866dff;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.focus-theme .dashboard_custom_card_reoder .cdk-drop-list {
  width: 18.8% !important;
}
.focus-theme .dashboard_custom_card_reoder .cdk-drop-list .workbook {
  background-color: white !important;
}
.focus-theme .dashboard_custom_card_reoder .cdk-drop-list .workbook .example-handle .mat-mdc-card-header {
  color: #210854;
}
.focus-theme .dashboard_custom_card_reoder .content {
  padding: 7px 3px !important;
}
.focus-theme .dashboard_custom_card_reoder .workbook {
  height: 118px;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  background-color: white !important;
  margin: 9px 5px 0px !important;
}
.focus-theme .dashboard_custom_card_reoder .workbook .mat-mdc-card-header {
  color: #210854;
  text-align: left;
}
.focus-theme .dashboard_custom_card_reoder .workbook .mat-mdc-card-header .mat-mdc-card-header-text {
  margin: 0 0px !important;
}
.focus-theme .dashboard_custom_card_reoder .workbook .actions {
  color: #210854;
  position: relative;
  height: auto;
  padding: 0px 24px !important;
  justify-content: start !important;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px !important;
}
.focus-theme .dashboard_custom_card_reoder .workbook .ellipsis {
  background: none;
  box-shadow: none;
}
.focus-theme .dashboard_custom_card_reoder .workbook .ellipsis .mdc-button__label {
  color: #cac4d7 !important;
}
.focus-theme .dashboard_custom_card_reoder .workbook .action-div {
  display: flex;
  justify-content: space-between;
}
.focus-theme .dashboard_custom_card_reoder .workbook .remove-btn {
  font-weight: bold;
  background-color: transparent;
  border: none;
  margin-top: -16px;
  text-transform: uppercase;
  cursor: pointer;
}
.focus-theme .search-field {
  position: relative;
}
.focus-theme .search-field .search-field_input {
  width: 100%;
  border: 1px solid rgba(33, 8, 84, 0.08);
  padding: 10px 0px 10px 40px;
  border-radius: 4px;
  outline: none;
  background-color: white;
  color: black;
  font: inherit;
}
.focus-theme .search-field .search-field_input::placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.focus-theme .search-field .search-field_input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.focus-theme .search-field .search-field_input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.focus-theme .search-field .clear-button {
  position: absolute;
  right: 0rem;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  color: #999;
}
.focus-theme .search-field .clear-button:hover {
  color: #333;
}
.focus-theme .search-field::before {
  position: absolute;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-focus/search.png");
  top: 7px;
  left: 10px;
}
.focus-theme .work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item {
  color: #210854;
  background-color: transparent;
}
.focus-theme .work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item:last-child {
  color: #ff7463;
}
.focus-theme .work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item:hover:not([disabled]) {
  background: rgba(33, 8, 84, 0.04);
}
.focus-theme .work-book-menu .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.focus-theme .manage-course-modalbox {
  width: 350px !important;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container {
  padding: 0px 0px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
  background-color: white;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  margin: 0 0 20px;
  display: block;
  padding: 19px 24px;
  border-bottom: 1px solid rgba(33, 8, 84, 0.08);
  color: #210854;
  font-size: 20px;
  font-weight: bold;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding: 0 24px;
  max-height: 65vh;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix {
  color: #0d0223;
  font-size: 16px;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  color: #0d0223;
  opacity: 0.56;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix .mdc-form-field-outline-thick {
  color: rgba(33, 8, 84, 0.08);
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .manage_modal_p {
  font-size: 16px;
  color: rgba(33, 8, 84, 0.56);
  margin: 0 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .manage_modal_p span {
  margin-right: 24px;
  height: 23px;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 52px;
  align-items: center;
  margin: 6px 10px;
  justify-content: flex-end;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .cancel {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .cancel .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .save {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .save .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .manage-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .share_copy_btn {
  color: #866dff;
  margin-left: 5px;
  font-size: smaller;
}
.focus-theme .wordlist_modal {
  width: 350px !important;
}
.focus-theme .wordlist_modal .mat-mdc-dialog-container {
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
  background-color: white;
}
.focus-theme .wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: #210854;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 5px;
  padding: 0;
}
.focus-theme .wordlist_modal .mat-mdc-dialog-container .scrollable {
  overflow: hidden !important;
}
.focus-theme .wordlist_modal .mat-mdc-dialog-container .scrollable p {
  color: rgba(33, 8, 84, 0.56);
  font-size: 16px;
}
.focus-theme .wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  padding: 0px 0;
  min-height: 32px;
}
.focus-theme .wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-button {
  position: relative;
  top: 20px;
  left: 15px;
}
.focus-theme .wordlist_modal .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-button .mdc-button__label {
  font-size: 14px;
  color: #866dff;
  font-weight: 600;
  text-transform: uppercase;
}
.focus-theme .tutorial_dialog_modal .mat-mdc-dialog-container {
  padding: 1rem 0.5rem;
  border-radius: 8px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
  background-color: white;
}
.focus-theme .setting_header_div_tutorials {
  background: white;
  padding: 6px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
}
.focus-theme .setting_header_div_tutorials h2 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0 !important;
}
.focus-theme .page_tutorials p {
  color: rgba(13, 2, 35, 0.5607843137);
  font-size: 16px !important;
  line-height: normal !important;
  margin: 15px 0 24px;
}
.focus-theme .sharing_header_div {
  background: white;
  padding: 0px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 96px;
  align-items: center;
  justify-content: space-between;
}
.focus-theme .sharing_header_div .help_icon {
  position: relative;
  top: 7px;
  left: 10px;
}
.focus-theme .sharing_header_div .sharing_header_div_left {
  display: flex;
}
.focus-theme .sharing_header_div .sharing_header_div_left .settings-header {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0;
}
.focus-theme .sharing_header_div .mat-mdc-button-base {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
  line-height: 1.2;
  box-shadow: none;
  height: auto;
  padding: 0 16px;
}
.focus-theme .sharing_header_div .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.focus-theme .sharing_header_div .mat-mdc-button-base .mdc-button__label {
  font-weight: bold;
  letter-spacing: 0.4px !important;
}
.focus-theme .sharing_header_div .import-btn {
  background: #ffb5a0;
  border-color: #ffb5a0;
}
.focus-theme .sharing_header_div .import-btn .mdc-button__label {
  color: #210854;
}
.focus-theme .page_sharing {
  padding: 34px 24px;
  background: #dfdee5;
}
.focus-theme .page_sharing .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}
.focus-theme .page_sharing .page_sharing_heading {
  color: #210854;
  font-size: 20px;
  font-weight: bold;
}
.focus-theme .page_sharing .page_sharing_p {
  font-size: 16px;
  color: #777183;
  width: 38vw;
  line-height: normal;
}
.focus-theme .page_sharing .sharing_from, .focus-theme .page_sharing .search-input-box {
  margin: 24px 0px 0px;
  width: 209px;
  position: relative;
}
.focus-theme .page_sharing .sharing_from .sharing_from_input, .focus-theme .page_sharing .search-input-box .sharing_from_input {
  border: 1px solid rgba(33, 8, 84, 0.08);
  padding: 10px 0px 10px 40px;
  border-radius: 50px;
  outline: none;
  background-color: white;
  color: black;
  font: inherit;
}
.focus-theme .page_sharing .sharing_from .sharing_from_input::placeholder, .focus-theme .page_sharing .search-input-box .sharing_from_input::placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.focus-theme .page_sharing .sharing_from .sharing_from_input::-moz-placeholder, .focus-theme .page_sharing .search-input-box .sharing_from_input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.focus-theme .page_sharing .sharing_from .sharing_from_input::-webkit-input-placeholder, .focus-theme .page_sharing .search-input-box .sharing_from_input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.32);
}
.focus-theme .page_sharing .sharing_from::before, .focus-theme .page_sharing .search-input-box::before {
  position: absolute;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-focus/search.png");
  top: 7px;
  left: 10px;
}
.focus-theme .sharing_custom_card {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 24px;
}
.focus-theme .sharing_custom_card .sharing_custom_card {
  font-size: 19px;
  color: #210854;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 30px;
}
.focus-theme .sharing_custom_card .new-workbook {
  width: 23.6% !important;
  height: 374px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  margin-right: 5px !important;
  padding: 24px 0px;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
@media only screen and (max-width: 1024px) {
  .focus-theme .sharing_custom_card .new-workbook {
    width: 30% !important;
  }
}
.focus-theme .sharing_custom_card .new-workbook .icon {
  width: 48px;
  height: 48px;
  background: #866dff;
  border-radius: 5px;
  margin: 10px 0px 10px 0px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}
.focus-theme .sharing_custom_card .new-workbook .new_workbook_heading {
  font-size: 20px;
  color: #210854;
  font-weight: bold;
  margin-bottom: 18px;
}
.focus-theme .sharing_custom_card .new-workbook .mdc-button__label {
  color: #866dff;
  font-size: 14px;
}
.focus-theme .sharing_custom_card .content {
  width: 23.6% !important;
  padding: 17px 0px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(33, 8, 84, 0.04);
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin: 9px 5px 0px !important;
}
@media only screen and (max-width: 1024px) {
  .focus-theme .sharing_custom_card .content {
    width: 30% !important;
  }
}
.focus-theme .sharing_custom_card .content::before {
  position: absolute;
  width: 100%;
  content: "";
  height: 1px;
  display: block;
  left: 0;
  top: 153px;
  z-index: 1;
  border-top: 1px solid #f1edf8;
}
.dark-theme .focus-theme .sharing_custom_card .content::before {
  border-top: 1px solid rgba(255, 255, 255, 0.08) !important;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-header {
  display: block;
  flex-direction: row;
  font-size: 20px;
  font-weight: bold;
  color: #210854;
  text-align: center;
  min-height: 48px;
  padding: 0;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .icon {
  width: 48px;
  height: 48px;
  background: #866dff;
  border-radius: 5px;
  margin: 20px 0px 10px 0px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .share_code {
  margin-bottom: 15px;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .share_code p {
  margin: 15px 0 10px;
  font-size: 16px;
  color: #210854;
  font-weight: 600;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .share_code span {
  color: rgba(33, 8, 84, 0.56);
  font-size: 16px;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .share_code .sharing_copy {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .setting_include {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 163px;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .setting_include p {
  margin: 15px 0 10px;
  font-size: 16px;
  color: #210854;
  font-weight: 600;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .setting_include span {
  color: rgba(33, 8, 84, 0.56);
  font-size: 16px;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .setting_include .mdc-button__label {
  color: #866dff;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .delete-button {
  margin: 5px 0px 0px;
}
.focus-theme .sharing_custom_card .content .mat-mdc-card-actions .delete-button .mdc-button__label {
  color: #ff7463;
  font-size: 14px;
}
.focus-theme .add-collection-modalbox {
  width: 350px !important;
}
.focus-theme .add-collection-modalbox .mat-mdc-dialog-container {
  padding: 0px;
  overflow: hidden;
  background-color: white;
}
.focus-theme .add-collection-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  padding: 19px 24px;
  display: block;
  color: #0d0223;
  font-size: 20px;
  font-weight: bold;
}
.focus-theme .add-collection-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  padding: 0;
}
.focus-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list {
  padding: 0px 0px;
}
.focus-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item {
  height: auto;
}
.focus-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item:not(:last-child) {
  border-bottom: 1px solid #f1edf8;
}
.dark-theme .focus-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item:not(:last-child) {
  border-bottom: 1px solid rgba(241, 237, 248, 0.12) !important;
}
.focus-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item .mdc-list-item__content {
  padding: 0px 24px 16px;
  cursor: pointer;
}
.focus-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item .mdc-list-item__content .activity_item {
  font-size: 16px !important;
  color: #210854;
}
.focus-theme .add-collection-modalbox .mat-mdc-dialog-container .mdc-list .mat-mdc-list-item .mdc-list-item__content .activity_item_child {
  color: rgba(33, 8, 84, 0.56);
  font-size: 14px !important;
}
.focus-theme .add-collection-modalbox .mat-mdc-button {
  float: right;
  margin-right: 10px;
  margin-bottom: 10px;
}
.focus-theme .add-collection-modalbox .mat-mdc-button .mdc-button__label {
  text-transform: uppercase;
  color: #866dff;
  font-size: 14px;
}
.focus-theme .rename-modalbox {
  width: 350px !important;
}
.focus-theme .rename-modalbox .mat-mdc-dialog-container {
  background-color: white;
}
.focus-theme .rename-modalbox .mat-mdc-dialog-title {
  color: #0d0223;
  padding: 19px 24px 0;
  display: block;
  font-size: 20px;
  font-weight: bold;
}
.focus-theme .rename-modalbox .mat-mdc-form-field {
  width: 100%;
}
.focus-theme .rename-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 1em 0 1em 0;
  color: #210854;
}
.focus-theme .rename-modalbox .mat-mdc-form-field .mat-mdc-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.focus-theme .rename-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0 24px 10px;
}
.focus-theme .rename-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.focus-theme .rename-modalbox .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .import-collection-modalbox {
  width: 350px !important;
}
.focus-theme .import-collection-modalbox .mat-mdc-dialog-container {
  background-color: white;
}
.focus-theme .import-collection-modalbox .mat-mdc-dialog-title {
  color: #0d0223 !important;
  padding: 19px 24px 0;
  display: block;
  font-size: 20px !important;
  font-weight: bold !important;
}
.focus-theme .import-collection-modalbox .mat-mdc-form-field {
  width: 100%;
}
.focus-theme .import-collection-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 0.8em 0 1em 0;
  color: #210854;
}
.focus-theme .import-collection-modalbox .mat-mdc-form-field .mat-mdc-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.focus-theme .import-collection-modalbox .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.focus-theme .import-collection-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 0 24px 10px;
}
.focus-theme .import-collection-modalbox .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.focus-theme .mat-mdc-dialog-container .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
}
.focus-theme .delete-lesson-plan-step-actions .mat-flat-button {
  color: #866dff !important;
}
.focus-theme .type-edit-modalbox {
  overflow: hidden;
  width: 70vw !important;
}
.focus-theme .type-edit-modalbox .mat-mdc-dialog-title {
  padding: 19px 24px 0;
  display: block;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: normal;
}
body:not(.dark-theme) .focus-theme .type-edit-modalbox .mat-mdc-dialog-title {
  color: #210854 !important;
}
.dark-theme .focus-theme .type-edit-modalbox .mat-mdc-dialog-title {
  color: white !important;
}
.focus-theme .type-edit-modalbox .mat-mdc-form-field {
  width: 100%;
}
.focus-theme .type-edit-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 1em 0 1em 0;
  color: #210854;
}
.focus-theme .type-edit-modalbox .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.focus-theme .type-edit-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
  padding: 8px 24px;
}
.focus-theme .type-edit-modalbox .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.focus-theme .type-edit-modalbox .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
  height: 0px !important;
}
.focus-theme .type-edit-modalbox .mat-mdc-focused {
  background-color: none;
}
.focus-theme .type-edit-modalbox #no-padding .mat-mdc-form-field-infix {
  padding: 0px !important;
  border-top: 0px !important;
}
.focus-theme .type-edit-modalbox #no-padding .mat-mdc-form-field-infix textarea {
  background-color: white;
  border: 2px solid #210854;
  color: black;
}
.focus-theme .collection-added-modalbox {
  width: 350px !important;
}
.focus-theme .collection-added-modalbox .mat-mdc-dialog-title {
  color: #0d0223;
  padding: 19px 24px 0;
  display: block;
  font-size: 20px;
  font-weight: bold;
}
.focus-theme .collection-added-modalbox .mat-dialog-content {
  font-size: 16px;
  color: rgba(33, 8, 84, 0.56);
}
.focus-theme .collection-added-modalbox .mat-dialog-content span {
  color: #210854;
}
.focus-theme .collection-added-modalbox .mat-mdc-dialog-actions {
  justify-content: flex-end;
}
.focus-theme .collection-added-modalbox .mat-mdc-dialog-actions .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.focus-theme .sharing_header_div_collection {
  background: white;
  padding: 0px 24px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 120px;
  align-items: center;
  justify-content: space-between;
}
.focus-theme .sharing_header_div_collection .sharing_header_left_collection {
  display: flex;
  flex-direction: column;
}
.focus-theme .sharing_header_div_collection .sharing_header_left_collection span {
  color: #866dff;
  font-size: 14px;
  margin-bottom: 8px;
}
.focus-theme .sharing_header_div_collection .sharing_header_left_collection .settings-header {
  color: #210854;
  margin: 0 0 0;
}
.focus-theme .sharing_header_div_collection button {
  height: 40px !important;
  width: auto !important;
  right: 15px;
  min-width: auto;
  box-shadow: none !important;
  background-color: #ffb5a0 !important;
  border: 1px solid #ffb5a0;
  border-radius: 4px;
}
.focus-theme .sharing_header_div_collection button .mdc-button__label {
  line-height: 40px !important;
  color: #210854;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .collection_field {
  margin: 24px 24px !important;
  padding: 0 !important;
}
.focus-theme .collection_field .mat-mdc-form-field-infix {
  width: 302px;
  color: #210854;
  font-size: 16px;
}
.focus-theme .collection_field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  color: #210854;
  opacity: 0.64;
}
.focus-theme .collection_field .mdc-form-field-outline {
  color: rgba(33, 8, 84, 0.64);
}
.focus-theme .sharing_custom_card_collection {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 24px;
}
.focus-theme .sharing_custom_card_collection .content {
  width: 23.6% !important;
  padding: 17px 17px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(33, 8, 84, 0.04);
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin: 9px 5px 0px !important;
}
.focus-theme .sharing_custom_card_collection .content .mat-mdc-card-header {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #210854;
  padding: 0 !important;
}
.focus-theme .sharing_custom_card_collection .content .mat-mdc-card-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 10px 0 0;
  margin-bottom: -10px;
  min-height: auto;
}
.focus-theme .sharing_custom_card_collection .content .mat-mdc-card-actions .add-button .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  font-weight: bold;
}
.focus-theme .sharing_custom_card_collection .content .mat-mdc-card-actions .delete-button .mdc-button__label {
  color: #ff7463;
  font-size: 14px;
  font-weight: bold;
}
.focus-theme .sharing_custom_card_collection .content .mat-mdc-card-actions .ellipsis {
  position: absolute;
  right: -20px;
}
.focus-theme .sharing_custom_card_collection .content .mat-mdc-card-actions .ellipsis .mdc-button__label {
  color: #cac4d7 !important;
}
.focus-theme .account_content_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #FFF;
  margin: 24px;
  border-radius: 8px;
}
.focus-theme .account_content_div .each_row {
  display: flex;
  border-bottom: 1px solid #210854;
}
.focus-theme .account_content_div .each_row .description-section {
  padding: 24px 24px;
}
@media only screen and (max-width: 1024px) {
  .focus-theme .account_content_div .each_row .description-section {
    width: 30% !important;
  }
}
.focus-theme .account_content_div .each_row .description-section h3 {
  font-size: 20px;
  font-weight: bold;
  color: #210854;
}
.focus-theme .account_content_div .each_row .description_option {
  padding: 24px 24px;
  width: 50%;
}
@media only screen and (max-width: 1024px) {
  .focus-theme .account_content_div .each_row .description_option {
    width: 70% !important;
  }
}
.focus-theme .account_content_div .each_row .description_option h4 {
  color: #210854;
  font-size: 16px;
}
.focus-theme .account_content_div .each_row .description_option span {
  color: #777183;
  font-size: 16px;
  margin-left: 12px;
}
.focus-theme .account_content_div .each_row .description_option .add_card {
  font-size: 14px;
  color: #866dff;
  text-transform: uppercase;
  cursor: pointer;
}
.focus-theme .plan_description_div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: transparent;
  padding: 24px 24px;
  flex-wrap: wrap;
}
.focus-theme .plan_description_div .mat-mdc-button-base {
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
}
.focus-theme .plan_description_div .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.focus-theme .plan_description_div .mat-mdc-button-base .mdc-button__label {
  font-style: italic;
  letter-spacing: 0.4px;
  text-transform: capitalize !important;
  font-size: 18px;
}
.focus-theme .add-credit-card-modalbox {
  width: 350px;
  border-radius: 8px !important;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-dialog-title {
  font-size: 20px;
  color: #0d0223;
  font-weight: bold;
  margin: 0 0 0;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0px !important;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 0 0;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix input[type=text] {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: #210854 !important;
  border: 1px solid rgba(33, 8, 84, 0.64);
  padding-left: 10px;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  top: 37px;
  left: 16px;
  color: #210854;
  font-size: 16px;
  opacity: 0.64;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-underline {
  background-color: transparent !important;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-form-field .mat-mdc-form-field-ripple {
  display: none;
}
.focus-theme .add-credit-card-modalbox .mat-stroked-button {
  border-color: rgba(0, 0, 0, 0.12);
}
.focus-theme .add-credit-card-modalbox .mat-mdc-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.12);
}
.focus-theme .add-credit-card-modalbox .mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  background-color: white;
  width: auto !important;
  top: 32px !important;
  padding: 0px 7px;
  opacity: 1 !important;
}
.focus-theme .add-credit-card-modalbox .card_date_details {
  display: flex;
  justify-content: space-between;
}
.focus-theme .add-credit-card-modalbox .btn-default {
  background-color: transparent !important;
  font-weight: bold;
  color: #866dff;
  text-transform: uppercase;
  font-size: 14px !important;
}
.focus-theme .add-credit-card-modalbox .agree_span {
  color: #210854;
  opacity: 56%;
}
.focus-theme .add-credit-card-modalbox .mat-mdc-checkbox-checked .mat-mdc-checkbox-background {
  background-color: #866dff !important;
}
.focus-theme .successful-update-modalbox {
  width: 350px !important;
}
.focus-theme .successful-update-modalbox .mat-mdc-dialog-container {
  padding: 19px 24px 5px;
  background-color: white;
}
.focus-theme .successful-update-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: #0d0223;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.focus-theme .successful-update-modalbox .mat-mdc-dialog-container .msg_success {
  font-size: 16px;
  color: rgba(33, 8, 84, 0.56);
}
.focus-theme .successful-update-modalbox .mat-mdc-dialog-container .ok_success_btn {
  width: auto !important;
  margin-right: 0px;
  float: right;
  margin-top: 20px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.focus-theme .successful-update-modalbox .mat-mdc-dialog-container .ok_success_btn .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .successful-update-modalbox .mat-mdc-dialog-container .cdk-focused {
  display: none;
}
.focus-theme .selected-plan-modalbox {
  width: 350px !important;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container {
  padding: 17px 24px 5px;
  background-color: white;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .titel {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #0d0223;
  display: block;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field {
  width: 100%;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0px !important;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-infix {
  padding: 0 0;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-infix input[type=text] {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: #210854 !important;
  border: 1px solid rgba(33, 8, 84, 0.64);
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  top: 37px;
  left: 16px;
  color: #210854;
  font-size: 16px;
  opacity: 0.64;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-underline {
  background-color: transparent !important;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field .mat-mdc-form-field-ripple {
  display: none;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .apply_promocode_form .mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  background-color: #fff;
  width: auto !important;
  background: #fff;
  top: 32px !important;
  padding: 0px 7px;
  opacity: 1 !important;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .comfrm_new_plan_btn {
  width: auto !important;
  margin-right: 0px;
  float: right;
  margin-top: 20px;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .comfrm_new_plan_btn .mdc-button__label {
  color: #866dff;
}
.focus-theme .selected-plan-modalbox .mat-mdc-dialog-container .cdk-focused {
  display: none;
}
.focus-theme .cancel-subscriptiton-modalbox {
  transform: scale(0.9) !important;
}
.focus-theme .cancel-subscriptiton-modalbox app-cancel-subscription {
  display: block;
  height: 100%;
}
.focus-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container {
  padding: 17px 24px 10px;
  background-color: white;
}
.focus-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #0d0223;
  display: block;
}
.focus-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .mat-typography {
  padding: 0px;
}
.focus-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .btn-default {
  width: auto !important;
  margin-right: 0px;
  margin-bottom: 10px;
  background-color: transparent;
}
.focus-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .btn-default .mdc-button__label {
  color: #866dff;
}
.focus-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .cdk-focused {
  display: none;
}
.focus-theme .cancel-subscriptiton-modalbox .mat-mdc-dialog-container .are-you-sure-span {
  color: rgba(13, 2, 35, 0.5607843137);
  font-size: 16px;
}
.focus-theme .feedback-form-panel {
  width: 500px;
}
.focus-theme .feedback-form-panel .feedback-form {
  width: 100%;
  height: 100%;
}
.focus-theme .feedback-form-panel .feedback-form .feedback-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.focus-theme .feedback-form-panel .feedback-form .feedback-form-container .header {
  color: rgba(13, 2, 35, 0.56);
}
.dark-theme .focus-theme .feedback-form-panel .feedback-form .feedback-form-container .header {
  color: white !important;
}
.focus-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-title {
  font-size: 20px;
  margin-bottom: 20px;
  color: #210854;
  letter-spacing: normal;
  display: block;
}
.dark-theme .focus-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-title {
  color: white !important;
}
.focus-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-content {
  color: rgba(13, 2, 35, 0.56);
  margin: 0;
  padding: 4px 24px;
  letter-spacing: normal;
  line-height: normal;
  overflow-x: hidden;
  display: flex;
}
.dark-theme .focus-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-mdc-dialog-content {
  color: white !important;
}
.focus-theme .feedback-form-panel .feedback-form .feedback-form-container .mat-typography {
  padding: 0px;
}
.focus-theme .unsaved-changes-panel {
  width: 400px;
}
.focus-theme .unsaved-changes-panel .mat-mdc-dialog-title {
  font-size: 20px;
  margin-bottom: 20px;
  color: #210854 !important;
  letter-spacing: normal;
  display: block;
}
.dark-theme .focus-theme .unsaved-changes-panel .mat-mdc-dialog-title {
  color: white !important;
}
.focus-theme .unsaved-changes-panel .mat-mdc-dialog-content {
  color: rgba(13, 2, 35, 0.56);
  margin: 0;
  padding: 4px 24px;
  letter-spacing: normal;
  line-height: normal;
  overflow-x: hidden;
  display: flex;
}
.dark-theme .focus-theme .unsaved-changes-panel .mat-mdc-dialog-content {
  color: white !important;
}
.focus-theme .unsaved-changes-panel .mat-typography {
  padding: 0px;
}
@media only screen and (max-width: 1280px) {
  .focus-theme .whizzimolibraries_container .drag-drop-box .new-workbook {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .focus-theme .whizzimolibraries_container .drag-drop-box .new-workbook {
    width: 31% !important;
  }
}
.focus-theme .whizzimolibraries_container .drag-drop-box .content {
  margin: 0px 9px 9px;
  background-color: white;
}
@media only screen and (max-width: 1280px) {
  .focus-theme .whizzimolibraries_container .drag-drop-box .content {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .focus-theme .whizzimolibraries_container .drag-drop-box .content {
    width: 31% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .focus-theme .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .new-workbook {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .focus-theme .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .new-workbook {
    width: 31% !important;
  }
}
.focus-theme .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .content {
  margin: 0px 9px 9px;
  background-color: white;
}
@media only screen and (max-width: 1280px) {
  .focus-theme .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .content {
    width: 23.5% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .focus-theme .whizzimolibraries_container .drag-drop-box-whizzimoLibrary .content {
    width: 31% !important;
  }
}
.focus-theme .whizzimolibraries_container .whizzimolibraries_header {
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  align-items: center;
}
.focus-theme .whizzimolibraries_container .whizzimolibraries_header .header h2 {
  font-size: 32px;
  color: #210854;
}
.focus-theme .whizzimolibraries_container .whizzimolibraries_header .course-button {
  height: 40px !important;
  width: auto !important;
  box-shadow: none;
  right: 15px;
  min-width: auto;
  background-color: #866dff;
  border: 1px solid #866dff;
  border-radius: 4px;
}
.focus-theme .whizzimolibraries_container .whizzimolibraries_header .course-button .mdc-button__label {
  line-height: 40px !important;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.focus-theme .whizzimolibraries_container .mat-tab-header {
  background: white;
  border: none;
}
.focus-theme .whizzimolibraries_container .mat-tab-header .mdc-tab__content {
  color: #481e9f;
  text-transform: uppercase;
  font-weight: 600;
}
.focus-theme .whizzimolibraries_container .mat-tab-header .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content {
  padding: 24px 24px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .new-workbook {
  height: 125px;
  min-width: 200px;
  width: 18.8%;
  border-radius: 8px;
  background: rgba(134, 109, 255, 0.04);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23866DFFFF' stroke-width='2' stroke-dasharray='6%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border: none;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  margin: 0px 9px 9px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .new-workbook span {
  color: #866dff;
  text-transform: uppercase;
  font-size: 14px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .mat-mdc-card-header {
  color: #210854;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .mat-mdc-card-header .mat-mdc-card-header-text {
  margin: 0 0px !important;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .add_to_course {
  padding-left: 10px;
  padding-right: 10px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .add_to_course .mdc-button__label {
  color: #866dff;
  text-transform: uppercase;
  font-size: 14px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .Added {
  padding-left: 10px;
  padding-right: 10px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .Added .mdc-button__label {
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .remove {
  padding-left: 10px;
  padding-right: 10px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .remove .mdc-button__label {
  color: #ff7463;
  text-transform: uppercase;
  font-size: 14px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .ellipsis {
  position: absolute;
  right: 10px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .ellipsis .mdc-button__label {
  color: #866dff;
  opacity: 0.32;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from, .focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box {
  margin: 24px 0px 10px;
  width: 209px;
  position: relative;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input, .focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input {
  border: 1px solid rgba(33, 8, 84, 0.08);
  padding: 10px 0px 10px 40px;
  border-radius: 50px;
  outline: none;
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  font: inherit;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input::placeholder, .focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input::placeholder {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input::-moz-placeholder, .focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from .sharing_from_input::-webkit-input-placeholder, .focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box .sharing_from_input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .sharing_from::before, .focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .search-input-box::before {
  position: absolute;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-focus/search.png");
  top: 7px;
  left: 10px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .length_p {
  color: rgba(33, 8, 84, 0.56);
  font-size: 16px;
}
.focus-theme .whizzimolibraries_container .mat-mdc-tab-body-content .mat-progress-bar {
  border-radius: 50px;
}
.focus-theme .add-wordbook-modalbox .mat-mdc-dialog-container {
  border-radius: 8px;
  background-color: white;
}
.focus-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: #0d0223;
  font-weight: bold;
  margin-top: 24px;
  letter-spacing: normal;
}
.focus-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  max-height: 45vh;
  border-bottom: solid 1px rgba(13, 2, 35, 0.08);
  border-top: solid 1px rgba(13, 2, 35, 0.08);
  padding: 0px 0px;
}
.focus-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(13, 2, 35, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 0px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
  cursor: pointer;
}
.focus-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-list-item:last-of-type {
  border-bottom: none;
}
.focus-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mdc-list-item__content {
  padding: 0px 24px !important;
}
.focus-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mdc-list-item__content .addwork_item {
  font-size: 16px !important;
  color: #210854;
  line-height: 30px !important;
}
.focus-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mdc-list-item__content .addwork_p {
  font-size: 14px !important;
  color: rgba(13, 2, 35, 0.87);
  margin-bottom: 7px;
}
.focus-theme .add-wordbook-modalbox .mat-mdc-dialog-container .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  float: right;
  position: relative;
  top: 15px;
  font-family: "Roboto", sans-serif;
  padding: 4px 24px;
}
.focus-theme .add-wordbook-myLibrary-modalbox {
  width: 350px !important;
}
.focus-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container {
  border-radius: 8px;
  background-color: white;
}
.focus-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: #0d0223;
  font-weight: bold;
  padding: 19px 24px 0;
}
.focus-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content {
  max-height: 45vh;
  padding: 0px 0px;
}
.focus-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content .mat-mdc-list-item {
  border-bottom: solid 1px rgba(13, 2, 35, 0.08);
  margin-bottom: 0px !important;
  padding: 6px 0px 6px;
  margin-top: 0px;
  outline: none;
  height: auto !important;
}
.focus-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content .mdc-list-item__content {
  padding: 0px 24px !important;
}
.focus-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-dialog-content .mdc-list-item__content .workbook_option_item {
  font-size: 16px !important;
  color: #210854;
  line-height: 30px !important;
}
.focus-theme .add-wordbook-myLibrary-modalbox .mat-mdc-dialog-container .mat-mdc-button-base {
  color: #866dff;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  float: right;
  position: relative;
  top: 15px;
}
.focus-theme .header_activity_top {
  position: fixed;
  display: flex;
  height: 85px !important;
  z-index: 115 !important;
  top: 0;
  background-color: white;
}
.focus-theme .header_activity_top .nav- {
  cursor: pointer;
  height: 38px;
  padding-top: 5px;
}
.focus-theme .header_activity_top .header-activity-name {
  font-size: 32px;
  font-weight: bold;
  color: #210854;
  height: 150px;
  align-items: center;
  line-height: 0px;
}
.focus-theme .header_activity_top .header-workbook-name {
  font-size: 14px;
  color: #866dff;
  position: relative;
  height: 30px;
  align-items: center;
  text-transform: uppercase;
}
.focus-theme .header_activity_top .mat-ripple-element {
  display: none;
}
.focus-theme .add-course-modalbox {
  width: 350px !important;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container {
  padding: 0px;
  border-radius: 8px;
  background-color: white;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-title {
  margin: 0 0 20px;
  display: block;
  padding: 19px 24px;
  color: #210854;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid rgba(13, 2, 35, 0.08);
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content {
  margin: 0 0px;
  padding: 0 24px;
  max-height: 66vh;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .add_cousre_modal_p_course {
  color: #210854;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 16px;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .add_cousre_modal_subscription {
  color: grey;
  font-weight: bold;
  font-size: 16px;
  margin-top: 0;
}
.dark-theme .focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .add_cousre_modal_subscription {
  color: white !important;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field {
  width: 100%;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .mat-mdc-form-field .mat-mdc-form-field-infix {
  color: #0d0223;
  font-size: 16px;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .course_description {
  margin-top: 12px;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-content .course_description .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  height: 59.5px;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  padding: 0px 10px;
  margin-bottom: 0px;
  justify-content: flex-end;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-mdc-button-base {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.focus-theme .add-course-modalbox .mat-mdc-dialog-container .mat-mdc-dialog-actions .mat-mdc-button-base .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .setting_screens {
  height: 100vh;
  overflow: auto;
  background-color: #dfdee5 !important;
}
.focus-theme .setting_screens .setting_header_div {
  height: 96px;
}
.focus-theme .setting_screens .tile-content_div {
  height: calc(100% - 79px);
  overflow-x: hidden;
}
.focus-theme .setting_screens .tile-content_div .mat-tab-group .mat-tab-header {
  top: 0px;
}
.focus-theme .setting_screens .tile-content_div .mat-mdc-tab-header {
  border: none;
}
.focus-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container {
  background-color: white;
}
.focus-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 1;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.focus-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}
.focus-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
body:not(.dark-theme) .focus-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #866dff !important;
}
.dark-theme .focus-theme .setting_screens .tile-content_div .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: white !important;
}
.focus-theme .new-tile-container {
  overflow: scroll;
  background-color: #dfdee5;
}
.focus-theme .new-tile-container table {
  border: 10px;
  margin: auto;
}
.focus-theme .new-tile-container table tr {
  margin-bottom: 10px;
  border: none !important;
}
.focus-theme .new-tile-container table tr:last-child {
  margin-bottom: 0px;
}
.focus-theme .new-tile-container table tr td {
  margin: 5px;
  min-height: 60px;
  min-width: 60px;
  overflow: visible;
}
.focus-theme .inside-tile {
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-stretch: normal;
  font-size: 1.6837vw;
  font-style: normal;
  line-height: 60px;
  text-align: center;
  letter-spacing: normal;
  border-radius: 4px !important;
  border: solid 1px rgba(72, 30, 159, 0.24);
  min-width: 60px;
  min-height: 60px;
  z-index: 20;
}
.focus-theme .inside-tile > img {
  position: absolute;
  top: 20px;
  left: 5px;
}
.focus-theme .tile-layout-top {
  height: 74%;
}
.focus-theme .edit-lesson-plan-header {
  background-color: white;
  padding: 24px 24px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}
.focus-theme .edit-lesson-plan-header .edit-lesson-plan-top-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.focus-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .lesson-plan-date {
  color: #866dff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}
.focus-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div {
  width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.focus-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .begin-lesson-btn {
  background-color: #ffb5a0;
  border-radius: 4px;
}
.focus-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .begin-lesson-btn .mdc-button__label {
  line-height: 40px !important;
  color: #210854;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .save-later-btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}
.focus-theme .edit-lesson-plan-header .edit-lesson-plan-top-header .header-btn-div .save-later-btn .mdc-button__label {
  line-height: 40px !important;
  color: #866dff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
.focus-theme .edit-lesson-plan-header .mat-mdc-card-title {
  padding: 0;
}
.focus-theme .edit-lesson-plan-header .mat-mdc-card-title .errors_field {
  color: red;
  font-size: 16px;
  display: block;
}
.focus-theme .edit-lesson-plan-header .mat-mdc-card-title .lesson_plan_name {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  line-height: 1;
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.focus-theme .edit-lesson-plan-header .mat-mdc-card-title .lesson_plan_input {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  width: 40vw;
}
.focus-theme .edit-lesson-plan-header .mat-mdc-card-title .lesson_plan_title {
  margin: 0px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}
.focus-theme .missing-tiles-button {
  color: #866dff !important;
}
.focus-theme .lesson_notes {
  display: flex;
  width: 100%;
  margin: 32px 0px 0px;
}
.focus-theme .lesson_notes .mat-mdc-form-field-infix {
  font-size: 16px;
}
.focus-theme .lesson_notes .lesson_notes_label {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: bold;
  color: #210854;
}
.focus-theme .lesson_notes .lesson_notes_left {
  display: flex;
  flex-direction: column;
  margin-right: 250px;
}
.focus-theme .lesson_notes .lesson_notes_left .lesson_notes_text {
  font-size: 16px;
  color: rgba(13, 2, 35, 0.5607843137);
  font-weight: normal;
}
.focus-theme .lesson_notes .lesson_notes_right {
  display: flex;
  flex-direction: column;
}
.focus-theme .lesson_notes .lesson_notes_right .lesson_notes_right_text {
  font-size: 16px;
  color: rgba(13, 2, 35, 0.5607843137);
  font-weight: normal;
}
.focus-theme .lesson_notes .lesson_notes_right input {
  font-size: 14px;
  color: rgba(13, 2, 35, 0.5607843137);
  font-weight: normal;
  width: 400px;
}
.focus-theme .edit-lesson-plan-body {
  border-radius: 8px;
  height: calc(100vh - 209px);
  overflow: auto;
}
.focus-theme .edit-lesson-plan-body > .content {
  padding: 24px;
}
.focus-theme .edit-lesson-plan-body .long-card {
  border-bottom: 1px solid #210854;
  border-radius: 0px;
  z-index: 1;
}
.focus-theme .edit-lesson-plan-body .long-card .more_words_modal_activity, .focus-theme .edit-lesson-plan-body .long-card .more_tiles_modal_activity {
  margin-bottom: 16px !important;
}
.focus-theme .edit-lesson-plan-body .long-card .pdf_file_step {
  color: #210854;
  font-size: 16px;
  font-weight: bold;
}
.focus-theme .edit-lesson-plan-body .long-card .pdf_files {
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  min-height: 34px;
  background-color: transparent !important;
  border: 1px solid #210854;
}
.focus-theme .edit-lesson-plan-body .long-card .pdf_files .mdc-button__label {
  color: #210854 !important;
}
.focus-theme .edit-lesson-plan-body .long-card .no_file_btn {
  padding: 0px;
  border: none;
}
.focus-theme .edit-lesson-plan-body .long-card .no_file_btn .mdc-button__label {
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
}
.focus-theme .add_step {
  cursor: pointer;
}
.focus-theme .add_step > .long-card {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.focus-theme .add_step span {
  font-size: 14px;
  font-weight: 500;
  color: #866dff;
  text-transform: uppercase;
}
.focus-theme .add_step mat-card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.focus-theme .long-card {
  margin: 0;
  background-color: white;
  box-shadow: none !important;
  padding: 24px 24px !important;
}
.focus-theme .long-card .long-card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
}
.focus-theme .long-card .long-card-header .long-card-header-right img {
  margin-right: 8px;
}
.focus-theme .long-card .long-card-header .long-card-header-right img:first-child {
  margin-right: 20px;
}
.focus-theme .long-card .long-card-header .long-card-header-right img:last-child {
  margin-left: 12px;
  margin-right: 0px;
}
.focus-theme .long-card .long-card-header .long-card-header-left {
  display: flex;
}
.focus-theme .long-card .long-card-header .long-card-header-left span {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #210854;
  font-weight: bold;
  letter-spacing: -0.01em;
}
.focus-theme .long-card .mat-mdc-form-field-underline {
  display: none;
}
.focus-theme .long-card .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.focus-theme .long-card .mat-mdc-form-field-label {
  top: 25px;
  left: 10px;
  color: #210854;
  font-size: 16px;
  padding: 5px 10px;
  background: white;
  width: auto !important;
}
.focus-theme .long-card.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: white;
  padding: 5px 10px;
  width: auto;
  top: 20px;
  left: 16px;
}
.focus-theme .long-card .words_shuffle_btn_div {
  display: inline-flex;
  height: 56px;
  align-items: flex-start;
  margin-left: 16px;
}
.focus-theme .long-card .words_shuffle_btn_div .words_view_input .mat-mdc-text-field-wrapper {
  width: 94px;
  height: 56px;
  padding: 0 24px !important;
  display: flex;
  align-items: center;
  color: #210854;
}
.focus-theme .long-card .words_shuffle_btn_div .words_view_input .mat-mdc-text-field-wrapper .mat-select-value-text {
  color: #210854;
  font-size: 16px;
}
.focus-theme .long-card .words_shuffle_btn_div .shuffle_btn {
  width: 94px;
  height: 56px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border: 1px solid #866dff;
  border-left: none;
  color: #866dff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  background: transparent;
}
.focus-theme .long-card .words_shuffle_btn_div .shuffle_btn:disabled {
  border-color: gray !important;
  color: gray !important;
}
.focus-theme .long-card .starting_view_dropdown .mat-mdc-form-field-infix {
  display: block;
  position: relative;
  flex: auto;
  min-width: 0;
  width: 191px;
  height: 56px;
  padding: 0 24px !important;
  display: flex;
  align-items: center;
  color: #210854;
}
.focus-theme .long-card .starting_view_dropdown .mat-mdc-form-field-infix .mat-select-value-text {
  color: #210854;
  font-size: 16px;
}
.focus-theme .long-card .starting_view_dropdown .mat-mdc-form-field-underline {
  display: none;
}
.focus-theme .long-card .starting_view_dropdown .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}
.focus-theme .long-card .starting_view_dropdown .mat-mdc-form-field-label {
  top: 29px;
  left: 10px;
  color: #210854;
  font-size: 16px;
  padding: 5px 10px;
}
.focus-theme .long-card .starting_view_dropdown.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  font-size: 13px;
  background-color: #fff;
  padding: 5px 10px;
  width: auto;
  top: 20px;
  left: 16px;
}
.dark-theme .focus-theme .long-card .starting_view_dropdown.mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  background-color: #18112f !important;
}
.focus-theme .long-card .missing_tile_btn {
  border: 1px solid #210854;
  color: #210854;
  font-size: 14px;
  height: 57px;
  background-color: transparent;
}
.focus-theme .long-card .disabled_field .mat-mdc-form-field-infix {
  border: 1px solid #ccc6d7 !important;
}
.focus-theme .long-card .disabled_field .mat-mdc-form-field-infix .mat-mdc-form-field-label {
  color: #ccc6d7 !important;
}
.focus-theme .long-card .example-box {
  height: 56px;
}
.focus-theme .long-card .example-box .mat-button-wrapper {
  font-size: 24px;
  font-weight: bold;
  position: relative;
  padding: 0px 0px 0px 24px;
}
.focus-theme .long-card .cdk-drop-list .mat-mdc-button-base {
  height: 56px;
  border: 1px solid #210854 !important;
}
.focus-theme .long-card .cdk-drop-list .mat-mdc-button-base .mdc-button__label {
  font-size: 24px;
  font-weight: bold;
  position: relative;
  padding: 0px 0px 0px 30px;
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .long-card .cdk-drop-list .mat-mdc-button-base .mdc-button__label::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  left: 0;
  top: 3px;
}
.focus-theme .long-card .cdk-drop-list .mat-mdc-button-base:not(.cdk-drag-disabled) .mdc-button__label::before {
  background-image: url("assets/img/theme-focus/move-modal.png");
}
.focus-theme .long-card .cdk-drop-list .mat-mdc-button-base.text-button {
  border: 0 !important;
  cursor: default;
}
.focus-theme .long-card .cdk-drop-list .mat-mdc-button-base.text-button .mdc-button__label::before {
  background-image: none !important;
}
.focus-theme .long-card .cdk-drop-list .moveIcon .mdc-button__label::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background-image: url("assets/img/theme-dark/add-circle-1.png");
  background-repeat: no-repeat;
  left: 0;
  top: 3px;
}
.focus-theme .long-card .custom-margin-activity-popup {
  margin-right: 24px !important;
  margin-bottom: 24px;
}
.focus-theme .long-card .custom-margin-activity-popup .mat-option[aria-disabled=true] {
  opacity: 0.32;
}
.focus-theme #registration_container {
  margin: 0px;
  width: 100%;
  height: 100%;
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}
.focus-theme #registration_container .left-box {
  width: 72px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 0px;
  background-color: #210854;
  position: fixed;
  left: 0;
  top: 0;
}
.focus-theme #registration_container .left-box img {
  border-radius: 4px;
}
.focus-theme #registration_container .right-box {
  width: calc(100% - 72px);
  float: left;
  margin-left: 72px;
}
.focus-theme #registration_container .right-box .registration_header {
  height: 153px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}
.focus-theme #registration_container .right-box .registration_header .registration_header_left {
  display: flex;
  flex-direction: column;
}
.focus-theme #registration_container .right-box .registration_header .registration_header_left span {
  color: #866dff;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 0 8px;
}
.focus-theme #registration_container .right-box .registration_header .registration_header_left h1 {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #210854 !important;
  font-weight: bold;
  letter-spacing: -0.01em;
  margin: 0 0 16px;
  line-height: 1;
}
.focus-theme #registration_container .right-box .registration_header .registration_header_left p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #777183;
  margin: 0;
}
.focus-theme #registration_container .right-box .registration_header .registration_header_right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 91px;
}
.focus-theme #registration_container .right-box .registration_header .registration_header_right a {
  background-color: #ffb5a0;
  border-radius: 4px;
  color: #210854;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  padding: 12px 19px;
}
.focus-theme #registration_container .right-box .form_container {
  width: 435px;
  background: #fff;
  border-radius: 8px;
  margin: 24px;
  padding: 32px 24px;
}
.focus-theme #registration_container .right-box .form_container .mat-mdc-form-field {
  width: 100%;
}
.focus-theme #registration_container .right-box .form_container .mat-mdc-form-field .mat-input-element {
  color: #210854 !important;
  font-size: 16px !important;
}
.focus-theme #registration_container .right-box .form_container .mat-mdc-form-field .mat-mdc-form-field-label {
  color: rgba(33, 8, 84, 0.56);
}
.focus-theme #registration_container .right-box .form_container .mdc-label {
  white-space: normal;
  color: #210854;
  font-size: 16px;
  font-weight: 500;
}
.focus-theme #registration_container .right-box .form_container .mdc-label a {
  color: #866dff !important;
}
.focus-theme #registration_container .right-box .form_container .mat-mdc-checkbox {
  margin-bottom: 18px;
  display: flex;
}
.focus-theme #registration_container .right-box .form_container .mat-mdc-checkbox .mdc-checkbox {
  margin: 0px 16px 0px 0px;
  top: 3px;
  width: 18px;
  height: 18px;
}
.focus-theme #registration_container .right-box .form_container .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  border: 2px solid rgba(33, 8, 84, 0.16);
}
.focus-theme #registration_container .right-box .form_container .mat-select-value-text {
  color: #210854;
}
.focus-theme #registration_container .right-box .form_container .mat-select-arrow {
  color: #210854;
}
.focus-theme #registration_container .right-box .form_container .mat-mdc-checkbox-checked .mdc-checkbox__background {
  background-color: transparent !important;
  border: 2px solid #866dff !important;
}
.focus-theme #registration_container .right-box .form_container .mat-mdc-checkbox-checked .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke: #866dff !important;
}
.focus-theme #registration_container .right-box .form_container .mat-mdc-checkbox-checked .mdc-checkbox__checkmark-path {
  stroke-width: 3.13333px;
}
.focus-theme #registration_container .right-box .form_container .vp-bit span {
  margin-right: 16px;
  color: #210854;
}
.focus-theme #registration_container .right-box .form_container .alert {
  margin: 0px 0px 20px;
}
.focus-theme #registration_container .right-box .form_container .alert i {
  margin-right: 16px;
  color: #210854;
}
.focus-theme #registration_container .right-box .form_container .passwords_match {
  margin: 0px 0px 20px;
}
.focus-theme #registration_container .right-box .form_container .register_btn {
  background-color: #210854;
  border-radius: 4px;
  box-shadow: none !important;
  padding: 14px 17px;
  color: #fff;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  outline: 0;
  border: none;
}
.focus-theme .workbook-body .mat-mdc-tab-header {
  padding: 0px 22px;
  background-color: white;
}
.focus-theme .workbook-body .mat-mdc-tab-header .mdc-tab__content {
  opacity: 1;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #481e9f;
  text-transform: uppercase;
}
.focus-theme .workbook-body .mat-mdc-tab-header .mdc-tab--active {
  opacity: 1 !important;
}
.focus-theme .workbook-body .mat-mdc-tab-header .mdc-tab--active .mdc-tab__content {
  opacity: 1 !important;
}
.focus-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}
.focus-theme .workbook_builder_filter .help_icon {
  position: relative;
  top: 2px;
  font-size: 18px;
  left: 10px;
}
.focus-theme .demo_plan_wordlist {
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px 0;
  font-size: 14px;
  color: #210854;
  text-align: center;
}
.focus-theme .workbook_builder_filter_main {
  background-color: white !important;
}
.focus-theme .workbook_builder_filter_main .filter-close span {
  color: black;
}
.focus-theme .words_container_filter {
  background-color: #dfdee5 !important;
}
.focus-theme .words_container_filter .filters-container .workbook_builder_filter_main .filter-body .filter-header .filter-title {
  color: #210854;
}
.focus-theme .words_container_filter .filters-container .workbook_builder_filter_main .filter-body .filter-header .filter-description {
  color: rgba(13, 2, 35, 0.5607843137);
}
.focus-theme .words_container_filter .results-container {
  margin-right: 24px;
}
.focus-theme .words_container_filter .results-container .word-list-header {
  background-color: #210854;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.focus-theme .words_container_filter .results-container .word-panel {
  background-color: white;
}
.focus-theme .words_container_filter .results-container .word-panel .word-content {
  color: #210854;
}
.focus-theme .words_container_filter .workbook_chat-container {
  background-color: white;
  color: rgba(13, 2, 35, 0.5607843137);
}
.focus-theme .words_container_filter .workbook_chat-container .chat-container {
  background-color: white;
  color: rgba(13, 2, 35, 0.5607843137);
}
.focus-theme .words_container_filter .workbook_chat-container .chat-container .chat-title {
  color: #210854;
}
.focus-theme .words_container_filter .workbook_chat-container .chat-container .chat-header {
  border-bottom: 1px solid #f1edf8;
}
.focus-theme .words_container_filter .workbook_chat-container .chat-messages .user-message {
  background-color: #866dff;
  color: white;
}
.focus-theme .words_container_filter .workbook_chat-container .chat-messages .ai-message {
  background-color: #866dff;
  color: white;
}
.focus-theme .words_container_filter .workbook_chat-container .chat-messages .loading-bubble {
  background-color: #866dff;
}
.focus-theme .words_container_filter .workbook_chat-container .chat-messages .dot {
  background-color: white;
}
.focus-theme .words_container_filter .workbook_chat-container .chat-input {
  border-top: 1px solid #f1edf8;
}
.focus-theme .words_container_filter .workbook_chat-container .chat-input textarea {
  border: 1px solid #f1edf8;
  background-color: #f2f2f2;
}
.focus-theme .words_container_filter .workbook_chat-container .chat-input .send-button {
  background: #866DFF !important;
  color: white !important;
}
.focus-theme .words_container_filter .selection-container {
  background-color: white;
}
.focus-theme .sentences-and-more-container {
  padding: 0 0px;
}
.focus-theme .sentences-and-more-container .search-container {
  background-color: #dfdee5 !important;
}
.focus-theme .sentences-and-more-container .section-container {
  background-color: #dfdee5 !important;
  padding: 0 24px 24px;
  border-radius: 0 !important;
}
.focus-theme .sentences-and-more-container .section-container .section {
  padding: 24px;
  background-color: white;
}
.focus-theme .sentences-and-more-container .section-container .section .section-header .section-header-text h2 {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #210854;
  margin: 0 0 8px;
}
.focus-theme .sentences-and-more-container .section-container .section .section-header .section-header-text .description {
  color: rgba(13, 2, 35, 0.5607843137);
  margin: 0 0 24px;
}
.focus-theme .sentences-and-more-container .section-container .section:not(:first-child) {
  border-top: solid 1px #f1edf8;
}
.dark-theme .focus-theme .sentences-and-more-container .section-container .section:not(:first-child) {
  border-top: 1px solid rgba(241, 237, 248, 0.12) !important;
}
.focus-theme .sentences-and-more-container .section-container .section:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.focus-theme .sentences-and-more-container .section-container .section:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.focus-theme .sentences-and-more-container .divider {
  width: 100%;
  height: 1px;
  border: solid 1px #210854;
}
.focus-theme .sentences-and-more-container .section-button {
  color: #866dff;
}
.focus-theme .sentences-and-more-container .content-card {
  display: flex;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #210854;
  background-color: transparent;
}
.focus-theme .sentences-and-more-container .content-card span:first-child {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .sentences-and-more-container .content-card button {
  background-color: transparent;
}
.focus-theme .sentences-and-more-container .content-card button .mdc-button__label {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .sentences-and-more-container .card-text {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #210854;
}
.focus-theme .sentences-and-more-container .new-card-button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #866dff;
  border-color: #866dff;
  padding: 10px;
}
.focus-theme .workbook_item .activity_item_child {
  font-size: 14px !important;
  color: rgba(13, 2, 35, 0.5607843137);
  margin-bottom: 7px;
}
.focus-theme .custom_filter_card_content .custom_input_content textarea {
  width: 170px;
  height: 61px;
  font-size: 16px;
  padding: 10px 17px;
  background: transparent;
  box-shadow: none;
  border: 1px solid #210854;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto";
}
.focus-theme .custom_filter_card_content .text-box-wrapper {
  border: 1px solid #210854;
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .quick-unselected {
  color: #210854;
}
.focus-theme .workbook_builder_header_main {
  background-color: white;
}
.focus-theme .workbook_builder_header_main .workbook_builder_header {
  min-width: calc(100% - 230px);
}
.focus-theme .workbook_builder_header_main .workbook_builder_header .workbook-title {
  color: #210854;
}
.focus-theme .workbook_builder_header_main .workbook_builder_header .workbook-owner {
  color: #866dff;
}
.focus-theme .workbook_builder_header_main .workbook_builder_header .workbook-description {
  color: rgba(13, 2, 35, 0.5607843137);
}
.focus-theme .workbook_builder_header_main .workbook_builder_header .mat-mdc-form-field {
  width: 90% !important;
}
.focus-theme .sentences-search-box .search_img {
  background-image: url("assets/img/theme-focus/search.png");
  width: 22px !important;
  height: 24px;
}
.focus-theme .files_search_container .search-box {
  padding: 8px !important;
  background-color: white;
}
.focus-theme .files_search_container .search-box .search_img {
  background-image: url("assets/img/theme-focus/search.png");
  width: 22px !important;
  height: 24px;
  margin-right: 10px;
}
.focus-theme .files_search_container .search-box input {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .mat-form-field-appearance-outline .mat-mdc-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.focus-theme .plan-description {
  background-color: white;
  width: 220px;
  border: 1px solid rgb(234, 236, 238);
  margin-bottom: 16px;
  z-index: 1;
  transition: transform 0.5s ease-in-out;
}
.focus-theme .plan-description:hover {
  transform: scale(1.02);
  z-index: 2;
  transition: transform 0.5s ease-in-out;
}
.focus-theme .plan-description .plan-price-text {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .plan-description .plan-price-sub-text {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .account_note {
  font-size: 16px;
  color: #777183;
}
.focus-theme .mat-mdc-select-panel {
  background-color: white;
  box-shadow: 0 4px 9px 0 rgba(33, 8, 84, 0.21);
}
.focus-theme .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.focus-theme .tile_footer {
  background-color: white;
}
.focus-theme .mat-mdc-menu-panel {
  background-color: white;
  box-shadow: 0 4px 9px 0 rgba(33, 8, 84, 0.21);
  max-width: 300px !important;
}
.focus-theme .mat-mdc-dialog-container {
  background-color: white;
  border: 2px solid white;
}
.focus-theme .tile-layout-top {
  background-color: #e4e2ed;
  overflow: hidden;
}
.focus-theme .tilelayout_div_footer .tileset_name {
  color: #210854;
}
.focus-theme .custom_filter_card_content .mat-mdc-form-field-infix {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .account-container {
  padding: 20px;
  margin: 20px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.focus-theme .account-container .account-information {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.focus-theme .account-container .account-information-title h3 {
  color: #210854;
}
.focus-theme .account-container .detail {
  color: #210854;
  font-weight: 500;
  font-size: 16px;
}
.focus-theme .account-container button {
  background-color: transparent;
}
.focus-theme .account-container button .mdc-button__label {
  color: #866dff;
}
.focus-theme .account-container .info {
  color: rgba(13, 2, 35, 0.5607843137);
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
}
.focus-theme .account-container .divider {
  width: 100%;
  border-bottom: #210854 1px solid;
  margin-top: 20px;
  margin-bottom: 20px;
}
.focus-theme .account-container .card-input {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: #210854;
  border: 1px solid #210854 !important;
  padding-left: 10px;
}
.focus-theme .account-modal {
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.focus-theme .account-modal .account-information {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.focus-theme .account-modal .account-information-title h3 {
  color: #210854;
}
.focus-theme .account-modal .detail {
  color: #210854;
  font-weight: 500;
  font-size: 16px;
}
.focus-theme .account-modal button {
  background-color: transparent;
}
.focus-theme .account-modal button .mdc-button__label {
  color: #866dff;
}
.focus-theme .account-modal .info {
  color: rgba(13, 2, 35, 0.5607843137);
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
}
.focus-theme .account-modal .divider {
  width: 100%;
  border-bottom: #210854 1px solid;
  margin-top: 20px;
  margin-bottom: 20px;
}
.focus-theme .account-modal .card-input {
  border-radius: 4px;
  width: 100% !important;
  height: 56px !important;
  font-size: 16px !important;
  color: #210854;
  border: 1px solid #210854 !important;
  padding-left: 10px;
}
.focus-theme .agreement-text {
  margin-left: 20px !important;
  color: rgba(33, 8, 84, 0.56) !important;
  font-size: 14px;
}
.focus-theme .blackboard-wrap {
  height: 100vh;
  background-color: #dfdee5;
  overflow: hidden;
}
.focus-theme .mini-tile-wrap {
  height: 100vh;
  background-color: #dfdee5;
}
.focus-theme .blackboard_footer {
  background-color: white;
  touch-action: none;
  top: 0;
}
.focus-theme .blackboard_footer .line {
  height: 62px;
  border-bottom: 2px solid #210854;
}
.focus-theme .account_ht3 {
  color: #210854;
}
.focus-theme .files_search_container {
  background-color: #dfdee5;
}
.focus-theme .file-container {
  background-color: #dfdee5;
}
.focus-theme .file-container .upload_info {
  color: rgba(13, 2, 35, 0.5607843137);
}
.dark-theme .focus-theme .file-container .upload_info {
  color: white !important;
}
.focus-theme .bg_transparent_dark_salmon {
  background-color: transparent;
  color: #ff7463 !important;
}
.focus-theme .bg_salmon_btn {
  background-color: #ffb5a0 !important;
}
.focus-theme .bg_salmon_btn .mdc-button__label {
  color: #210854;
}
.focus-theme .bg_transparent_light_purple {
  background-color: transparent;
}
.focus-theme .bg_transparent_light_purple .mdc-button__label {
  color: #866dff;
}
.focus-theme .modal-container .header {
  color: #210854;
}
.focus-theme .workbook-body app-workbook-builder-words .button-container .find-words-button {
  background: #866DFF !important;
  color: white !important;
}
.focus-theme .workbook-body app-workbook-builder-words .button-container .save-close {
  color: #866DFF !important;
}
.focus-theme .workbook-body .mat-mdc-tab-header {
  border: none;
  border-bottom: solid 1px rgba(134, 109, 255, 0.48);
}
.focus-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container {
  background-color: white;
}
.focus-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 1;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.focus-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}
.focus-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
.focus-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
}
body:not(.dark-theme) .focus-theme .workbook-body .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #481e9f !important;
}
.focus-theme .file-content {
  background: white;
}
.focus-theme .file-content .file-header h2 {
  color: #210854;
  margin: 20px 0 8px;
}
.focus-theme .file-content .description {
  color: rgba(13, 2, 35, 0.5607843137);
}
.focus-theme .file-content:not(:first-child) {
  border-top: solid 1px #f1edf8;
}
.dark-theme .focus-theme .file-content:not(:first-child) {
  border-top: 1px solid rgba(241, 237, 248, 0.12) !important;
}
.focus-theme .file-content:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.focus-theme .file-content:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.focus-theme .file-card-container {
  border: solid 1px #291f4f;
  color: black;
}
.focus-theme .file-card-container span:first-child {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .file-card-container button {
  background-color: transparent;
}
.focus-theme .file-card-container button .mdc-button__label {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .file-card-container .button-active {
  background-color: #866dff;
  border: 1px solid #866dff;
  height: 35px;
}
.focus-theme .file-card-container .button-active .mdc-button__label {
  color: white;
}
.focus-theme .file-card-container .button-inactive {
  background-color: transparent;
}
.focus-theme .file-card-container .button-inactive .mdc-button__label {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .page_tutorials {
  background: #dfdee5;
}
.focus-theme .mat-drawer-content {
  background-color: #dfdee5;
}
.focus-theme .sharing_modal_delete .mat-mdc-dialog-title {
  color: #210854;
}
.focus-theme .sharing_modal_delete .mat-dialog-content p {
  color: rgba(13, 2, 35, 0.5607843137);
  margin: 0 0 0;
}
.focus-theme .TutorialDialog_modal .mat-mdc-dialog-title {
  color: #210854 !important;
  padding: 24px 24px 19px;
}
.focus-theme .sentences-search-box {
  background-color: white;
}
.focus-theme .sentences-search-box input {
  color: rgba(0, 0, 0, 0.87);
}
.focus-theme .word_group_bank h2 {
  color: #210854;
}
.focus-theme .word_group_bank table .card-text {
  color: #210854 !important;
}
.focus-theme .word_group_bank_menu_item .mat-menu-item {
  color: #210854;
}
.focus-theme .file_card_menu .mat-menu-item {
  color: #210854;
}
.focus-theme .words_tile_box {
  background-color: transparent !important;
  border: 1px solid #210854 !important;
}
.focus-theme .words_tile_box .mdc-button__label {
  color: #210854 !important;
}
.focus-theme .sentence_example_box {
  background-color: transparent !important;
  border: 1px solid #210854 !important;
}
.focus-theme .sentence_example_box .mdc-button__label {
  color: #210854 !important;
}
.focus-theme .edit_words_tile_mattab .mdc-tab__content {
  color: #481e9f;
}
.focus-theme .edit_words_tile_mattab .mat-mdc-tab-body-content {
  overflow: hidden;
}
.focus-theme .edit_words_tile_mattab .mat-mdc-tab-label-container {
  background-color: white;
}
.focus-theme .edit_words_tile_mattab .mat-mdc-tab-label-container .mat-mdc-tab {
  opacity: 0.6;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px !important;
  font-weight: 500 !important;
  flex-grow: 0 !important;
  min-width: 160px;
}
.focus-theme .edit_words_tile_mattab .mat-mdc-tab-label-container .mat-ink-bar {
  height: 4px;
  background-color: #481e9f;
}
.focus-theme .edit_words_tile_mattab .mat-mdc-tab-label-container .mdc-tab--active {
  opacity: 1 !important;
}
.focus-theme .edit_words_tile_mattab .mat-mdc-tab-label-container .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-color: #866dff !important;
}
.focus-theme .pdf-right-control {
  color: #210854;
}
.focus-theme .input-divider {
  color: rgba(13, 2, 35, 0.56);
}
.focus-theme .same_plan_title {
  color: #210854;
}
.focus-theme .same_plan_content {
  color: rgba(13, 2, 35, 0.5607843137);
  font-weight: 500;
}
.focus-theme .demoplan_content {
  color: rgba(13, 2, 35, 0.5607843137);
  font-weight: 500;
}
.focus-theme .add_card_menu_h3 {
  color: #210854;
}
.focus-theme .focus-theme .side-container {
  border-right: solid 1px rgba(134, 109, 255, 0.48);
}
.focus-theme .delete_file_dialog .popup-header h1 {
  color: #210854;
}
.focus-theme .filter-card-tiles-header .search-box span {
  color: rgba(0, 0, 0, 0.72);
}
.focus-theme .filter-card-tiles-header .search-box input {
  color: rgba(0, 0, 0, 0.72);
}
.focus-theme .quick_container_h3 {
  color: #210854;
  font-size: 16px;
}
.focus-theme .pairing-section-container-span {
  color: #210854;
}
.focus-theme .pairing-section-container-h3 {
  color: #210854;
  margin-top: 0 !important;
}
.focus-theme .mat-bottom-sheet-container {
  background-color: #481e9f !important;
}
.focus-theme input.editable-text {
  color: gray;
  font-weight: normal;
  border: none;
  outline: none;
}
.focus-theme .hidden-btn {
  visibility: hidden;
}
.focus-theme mat-mdc-list-item.selected {
  background: #EEEEEE;
}
.focus-theme mat-mdc-list-item.selected.dark-mode {
  background: #866DFF;
}
.focus-theme .add-course.course-name .mat-mdc-form-field-wrapper, .focus-theme .for-internal-container .mat-mdc-form-field-wrapper {
  height: 56px;
}
.focus-theme .add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex, .focus-theme .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
  height: 100%;
}
.focus-theme .add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-label-wrapper, .focus-theme .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-label-wrapper {
  top: -16px;
}
.focus-theme .add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-infix, .focus-theme .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-infix {
  padding: 0.5em 0;
}
.focus-theme .add-course.course-name .mat-mdc-form-field-wrapper .mat-mdc-form-field-wrapper, .focus-theme .for-internal-container .mat-mdc-form-field-wrapper .mat-mdc-form-field-wrapper {
  margin: 0;
}
.focus-theme .add-course.course-name mat-label, .focus-theme .for-internal-container mat-label {
  color: #0d0223;
  opacity: 0.56;
}
.dark-theme .focus-theme .add-course.course-name mat-label, .dark-theme .focus-theme .for-internal-container mat-label {
  color: white !important;
}
.dark-theme .focus-theme .add-course.course-name input, .dark-theme .focus-theme .for-internal-container input {
  color: white;
}
.focus-theme .add-course.course_description .mat-mdc-form-field-infix {
  padding: 11px 0;
}
.focus-theme .add-course.course_description textarea {
  resize: none;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: normal;
}
.focus-theme .add-course.course_description textarea.mat-input-element {
  margin: 0;
}
.focus-theme .add-course.course_description .mat-mdc-form-field-wrapper {
  margin: 0;
}
.focus-theme .add-course.course_description mat-label {
  color: #0d0223;
  opacity: 0.56;
}
.dark-theme .focus-theme .add-course.course_description mat-label {
  color: white !important;
}
.dark-theme .focus-theme .add-course.course_description input {
  color: white;
}
.dark-theme .focus-theme .add_cousre_modal_subscription {
  color: white !important;
}
.focus-theme .custom-confirm-dialog {
  width: 360px;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container {
  display: flex;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container > * {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: #210854;
  margin-bottom: 0;
  padding: 19px 24px;
  letter-spacing: normal;
}
.dark-theme .focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-title {
  color: white !important;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content {
  color: rgba(13, 2, 35, 0.56);
  margin: 0;
  padding: 4px 24px;
  letter-spacing: normal;
  line-height: normal;
  overflow-x: hidden;
}
.dark-theme .focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content {
  color: white !important;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content.show-scores-dialog-content {
  margin-top: 8px;
  align-self: flex-start;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-content.show-scores-dialog-content .value {
  font-weight: 500;
  margin-left: 8px;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  min-height: unset;
  padding: 0 14px 10px;
  justify-content: flex-end;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .confirm-btn {
  padding: 10px;
  line-height: unset;
  background-color: transparent !important;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .delete-btn {
  padding: 10px;
  line-height: unset;
  background-color: transparent !important;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .delete-btn .mdc-button__label {
  color: var(---mdc-text-button-label-text-color);
  font-weight: bold !important;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions button {
  padding: 10px;
  line-height: unset;
  background-color: transparent !important;
}
.focus-theme .custom-confirm-dialog .mat-mdc-dialog-container .mat-mdc-dialog-actions .mdc-button__label {
  font-weight: bold !important;
}
.focus-theme .add-course-item .mdc-list-item__primary-text {
  color: #866DFF !important;
  letter-spacing: normal;
}
.focus-theme .side_bar_display mat-expansion-panel-header > span {
  display: none;
}
.focus-theme .custom-form-field label.mat-mdc-form-field-label {
  font-size: 13px;
  background-color: white;
  padding: 5px 10px;
  width: auto !important;
  top: 21px;
  left: 16px;
}
.dark-theme .focus-theme .custom-form-field label.mat-mdc-form-field-label {
  background-color: #18112f !important;
}
.focus-theme .word-selection-list .mdc-list-item__content {
  flex-direction: row !important;
}
.dark-theme .focus-theme .word-selection-list .mat-list-text {
  color: white;
}
.focus-theme .word-selection-list .mat-pseudo-checkbox {
  margin-right: 8px;
  border: 2px solid #866DFF;
  color: #866DFF;
}
.focus-theme .word-selection-list .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  color: #866DFF;
  border-color: #866DFF;
}
.focus-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox {
  color: #210854;
}
.focus-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mat-mdc-checkbox-frame {
  border-color: #866dff;
}
.focus-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox:not(.mat-mdc-checkbox-checked) .mat-mdc-checkbox-frame {
  border-color: rgba(33, 8, 84, 0.24);
}
.focus-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mdc-checkbox__checkmark-path {
  stroke: #866dff;
}
.focus-theme .dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox {
  color: white !important;
}
.focus-theme .dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mat-mdc-checkbox-frame {
  border-color: white !important;
}
.focus-theme .dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox:not(.mat-mdc-checkbox-checked) .mat-mdc-checkbox-frame {
  border-color: rgba(211, 211, 211, 0.3) !important;
}
.focus-theme .dark-theme mat-mdc-checkbox.whizzimo-mat-mdc-checkbox .mdc-checkbox__checkmark-path {
  stroke: white !important;
}
.dark-theme .focus-theme .sort-tile-btn {
  color: #866dff !important;
}
.focus-theme .example-box.letter-tile {
  min-width: 72px;
  height: 64px !important;
  padding-left: 8px;
  padding-right: 12px;
}
.focus-theme .example-box.letter-tile .mdc-button__label {
  padding-left: 24px !important;
  font-size: 32px !important;
}
.focus-theme .example-box.letter-tile .mdc-button__label::before {
  top: 10px !important;
}
.focus-theme .example-box.letter-tile .mdc-button__label > span {
  display: inline-block;
  min-width: 26px;
  text-align: left;
}
.focus-theme .example-box.words_tile_box {
  min-width: 72px;
  height: 56px !important;
  padding-left: 8px;
  padding-right: 12px;
}
.focus-theme .example-box.words_tile_box .mdc-button__label {
  padding-left: 24px !important;
  display: inline-block;
  min-width: 26px;
  text-align: left;
}
.focus-theme .activity-content-label {
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  width: 160px;
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #866dff;
  border-bottom: 2px solid #866dff;
  opacity: 1 !important;
  margin-bottom: 24px;
}
.focus-theme .text-holder::before {
  background-image: url("assets/img/theme-focus/move-modal.png");
}
.focus-theme .course-workbook-edit-workbook-items-container .drag-n-drop-zone {
  display: inline-block;
  margin-right: 10px;
  width: 100%;
  min-height: 60px;
  position: relative;
}
.focus-theme .course-workbook-edit-workbook-items-container .drag-n-drop-zone .text-button {
  position: absolute;
}
.focus-theme .course-workbook-edit-workbook-items-container .drag-n-drop-zone .tile-button {
  margin-right: 8px;
  margin-bottom: 8px;
  min-height: 34px;
}
.focus-theme .vertical-center {
  display: flex !important;
  align-items: center;
}
:not(.dark-theme) .focus-theme mat-tab-group .mdc-tab__content .mdc-tab__text-label {
  color: #481e9f;
}
.dark-theme .focus-theme mat-tab-group .mdc-tab__content .mdc-tab__text-label {
  color: white !important;
}
.dark-theme .focus-theme mat-tab-group .mat-ink-bar {
  background-color: white !important;
}
.focus-theme .search-input-box input {
  font-size: 16px;
  padding-right: 16px !important;
}
.focus-theme .search-input-box input::placeholder {
  color: rgba(33, 8, 84, 0.32) !important;
}
.focus-theme .input-error-message {
  font-size: 12px;
  text-align: left;
  margin: 0 13px;
  color: #ff8a65;
}
.focus-theme .mat-mdc-button[disabled=true] {
  border-color: gray !important;
  color: gray !important;
}
.focus-theme .mat-mdc-button[disabled=true] .mdc-button__label {
  color: #a6a6a6 !important;
}
.focus-theme .mat-mdc-form-field-invalid .mat-mdc-form-field-outline-thick {
  color: #f44336;
}
.focus-theme .workbook-name-modal .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
  padding: 0;
}
.focus-theme .rename-file-dialog .mat-mdc-form-field-wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}
.focus-theme .words-sort-label {
  font-size: 14px;
  color: #866dff;
}
.focus-theme .course-workbook-edit-tiles-container .top-tiles-container {
  max-height: 210px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 64px;
}
.focus-theme .course-workbook-edit-tiles-container .top-tiles-container .top-tile:last-child {
  flex: 1;
}
.focus-theme .more_tiles_modal_activity.disabled {
  color: gray;
  cursor: not-allowed;
}
.focus-theme .tile-updating-options {
  width: 360px;
}
.focus-theme .word-updating-options {
  width: 560px;
}
.focus-theme span.disabled, .focus-theme img.disabled {
  color: gray !important;
  pointer-events: none !important;
  cursor: not-allowed !important;
}
.focus-theme mat-mdc-form-field.disabled .mat-mdc-form-field-infix {
  border-color: gray !important;
}
.focus-theme mat-mdc-form-field.disabled .mat-mdc-form-field-infix input {
  color: gray !important;
}
.focus-theme .mat-button-disabled {
  color: gray !important;
  border-color: gray !important;
}
.focus-theme .label.disabled {
  color: gray !important;
}
.focus-theme .workbook-edit-menu-dialog {
  max-height: 900px;
  max-width: 88vw !important;
}
.focus-theme .workbook-edit-menu-dialog .mat-mdc-dialog-container {
  padding: 24px;
  overflow: hidden;
}
.focus-theme .course-workkbook-edit-words-table-container {
  overflow: auto;
  max-height: 200px;
}
.focus-theme app-course-workbook-edit-menu app-course-workbook-edit .long-card {
  cursor: pointer;
  padding: 0 !important;
}
.focus-theme .shuffle_tiles {
  border-radius: 4px !important;
  border: 1px solid #866dff !important;
  color: #866dff !important;
  width: 94px;
  height: 58px;
}
.focus-theme .shuffle_tiles:disabled {
  color: gray !important;
  border: 1px solid gray !important;
  cursor: default !important;
}
.focus-theme .color-menu .mat-button-toggle-checked {
  background: #f1eeff;
}
.focus-theme .letter-card-dialog {
  overflow: hidden;
}
.focus-theme .letter-card-dialog .mat-mdc-dialog-container {
  padding: 0;
  border-radius: 8px !important;
  box-shadow: 0 4px 16px 0 rgba(33, 8, 84, 0.08) !important;
  overflow: hidden;
  border: 0 !important;
}
.focus-theme .letter-card-dialog .mat-dialog-content {
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.focus-theme .mat-mdc-menu-panel.mat-menu-color {
  overflow: hidden;
}
.focus-theme .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 1em 0 1em 0;
}
.focus-theme .mat-mdc-form-field-error-wrapper {
  font-size: 75%;
}
.focus-theme .mdc-list {
  padding: 0px !important;
}
.focus-theme .mat-mdc-list-base .mat-mdc-list-item {
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  padding: 0;
}
.focus-theme .mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__primary-text {
  display: flex;
  flex-direction: column;
  letter-spacing: normal;
}
.focus-theme .mat-mdc-dialog-container {
  border-radius: 4px;
  box-shadow: 0 12px 32px 0 rgba(12, 1, 35, 0.36) !important;
}
.focus-theme .mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden !important;
  box-shadow: none !important;
}
.focus-theme .mat-mdc-dialog-container .mat-mdc-dialog-actions .mdc-button__label {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #866dff;
}
.focus-theme .mat-form-field-invalid .mdc-notched-outline--upgraded {
  color: #f44336 !important;
}
.focus-theme .mat-form-field-invalid .mat-mdc-floating-label {
  color: rgba(0, 0, 0, 0.6) !important;
}
.focus-theme .mat-mdc-form-field-should-float .mat-mdc-form-field-label {
  color: #210854 !important;
}
.focus-theme .mat-form-field-appearance-outline .mdc-form-field-outline {
  color: rgba(33, 8, 84, 0.18);
}
.focus-theme .mat-mdc-form-field-should-float .mdc-form-field-outline {
  color: #210854 !important;
}
.focus-theme .mdc-text-field .mdc-floating-label {
  top: 1.84375em !important;
  margin-top: -0.25em;
}
.focus-theme .mat-icon-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border: none;
  border-radius: 50%;
}
.focus-theme .mat-mdc-tab .mdc-tab__text-label {
  justify-content: center;
  white-space: nowrap;
  letter-spacing: normal;
}
.focus-theme .mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: normal;
}
.focus-theme .mat-mdc-option .mat-pseudo-checkbox {
  display: none;
}
.focus-theme .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  border-color: #866dff !important;
  background-color: white !important;
}
.focus-theme .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #866dff !important;
}
.focus-theme .mdc-dialog__title::before {
  height: auto !important;
}
.focus-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.focus-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.focus-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #e0e0e0;
}
.focus-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.focus-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.focus-theme .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #f44336);
}